import Vue from "vue";
import store from "@/store/";

import fastclick from "fastclick";

import { ACCESS_TOKEN } from "@/store/mutation-types";

export default function Initializer() {
  store.commit("SET_TOKEN", Vue.ls.get(ACCESS_TOKEN));

  const str = navigator.userAgent.toLowerCase();
    const ver = str.match(/cpu iphone os (.*?) like mac os/);

    if (!ver || parseInt(ver[1]) < 11) {
      fastclick.attach(document.body);
    }
    // 解决ios 点击 :active伪类 动画
    if(ver) {
      document.body.addEventListener("touchstart", () => {}, false);
    }
}
