import { RouteView } from "@/components/layouts";

const CertifiedLogin = resolve => {
  import("@views/certifiedPaymentInfo/CertifiedLogin").then(module => {
    resolve(module);
  });
};

const CertifiedHome = resolve => {
  import("@views/certifiedPaymentInfo/CertifiedHome").then(module => {
    resolve(module);
  });
};

const CertifiedResult = resolve => {
  import("@views/certifiedPaymentInfo/CertifiedResult").then(module => {
    resolve(module);
  });
};

const certifiedPaymentInfoRouter = {
  path: "/certifiedPaymentInfo",
  name: "certifiedPaymentInfo",
  redirect: "/certifiedPaymentInfo/certifiedLogin",
  component: RouteView,
  meta: { title: "爱满借" },
  children: [
    {
      path: "certifiedLogin",
      name: "certifiedLogin",
      component: CertifiedLogin,
      meta: {
        title: "登录爱满借"
      }
    },
    {
      path: "certifiedHome",
      name: "certifiedHome",
      component: CertifiedHome,
      meta: {
        title: "支付信息认证"
      }
    },
    {
      path: "certifiedResult",
      name: "certifiedResult",
      component: CertifiedResult,
      meta: {
        title: "认证结果"
      }
    }
  ]
};

export default certifiedPaymentInfoRouter;
