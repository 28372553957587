/*
 * @Author: cpb
 * @Date: 2020-07-24 15:58:23
 * @Last Modified by: cpb
 * @Last Modified time: 2020-08-14 16:28:47
 * @Desc: 目前此项目没使用
 */

import Vue from "vue";
// ! FIXE 此处造成webpack 解析依赖时， requestRepayment 比request.js文件早，导致引用import {a} from request.js a为undefined
// ! 先注释
// import { login, getInfo } from "@/api/user";

import { ACCESS_TOKEN } from "@/store/mutation-types";

const user = {
  state: {
    token: "",
    name: "",
    welcome: "",
    avatar: "",
    roles: [],
    info: {},
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_NAME: (state, { name, welcome }) => {
      state.name = name;
      state.welcome = welcome;
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar;
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles;
    },
    SET_INFO: (state, info) => {
      state.info = info;
    },
  },

  actions: {
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo)
          .then((response) => {
            if (response.success) {
              if (response.data.manageType == 1) {
                const result = response.data;
                const opId = result.opId;
                Vue.ls.set(ACCESS_TOKEN, opId, 31 * 24 * 60 * 60 * 1000);
                commit("SET_TOKEN", opId);
              }

              resolve(response);
            } else {
              reject(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 获取用户信息
    GetInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((response) => {
            const result = response.result;

            if (result.role && result.role.permissions.length > 0) {
              const role = result.role;
              role.permissions = result.role.permissions;
              role.permissions.map((per) => {
                if (
                  per.actionEntitySet != null &&
                  per.actionEntitySet.length > 0
                ) {
                  const action = per.actionEntitySet.map((action) => {
                    return action.action;
                  });
                  per.actionList = action;
                }
              });
              role.permissionList = role.permissions.map((permission) => {
                return permission.permissionId;
              });
              commit("SET_ROLES", result.role);
              commit("SET_INFO", result);
            } else {
              reject(new Error("getInfo: roles must be a non-null array !"));
            }

            // commit("SET_NAME", { name: result.name, welcome: welcome() });
            // commit("SET_AVATAR", result.avatar);

            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // 登出
    Logout({ commit }) {
      return new Promise((resolve) => {
        commit("SET_TOKEN", "");
        commit("SET_ROLES", []);
        Vue.ls.remove(ACCESS_TOKEN);

        resolve();
      });
    },
  },
};

export default user;
