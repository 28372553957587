import Field from "./Field.vue";
import FieldItem from "./FieldItem.vue";
import InputItem from "./InputItem.vue";
import WhiteSpace from "./WhiteSpace.vue";

export const CField = {
  install: function(Vue) {
    Vue.component("CField", Field);
  },
};
export const CFieldItem = {
  install: function(Vue) {
    Vue.component("CFieldItem", FieldItem);
  },
};
export const CInputItem = {
  install: function(Vue) {
    Vue.component("CInputItem", InputItem);
  },
};
export const CWhiteSpace = {
  install: function(Vue) {
    Vue.component("CWhiteSpace", WhiteSpace);
  },
};

export { Field, FieldItem, InputItem,WhiteSpace };
