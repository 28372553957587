<!--
 * @Autor: CPB
 * @Date: 2020-11-25 09:56:40
 * @LastEditors: CPB
 * @LastEditTime: 2020-11-30 18:54:33
 * @Description: 
-->
<template>
  <div class="pd20 flex">
    <OSSUpload
      :multiple="true"
      :tips="{ title: '多选', desc: '123' }"
      class="mgt20"
    ></OSSUpload>
    <OSSUpload
      class="mgt20"
      accept="video/*"
      :maxSize="10 * 1024 * 1024"
      :tips="{ title: '录像', desc: '123' }"
    ></OSSUpload>
    <OSSUpload class="mgt20" capture="user"  accept="video/*" :tips="{ title: '直接录像', desc: '前置摄像头' }"></OSSUpload>
    <OSSUpload class="mgt20" capture="user"  accept="video/*" :multiple="true" :tips="{ title: '多选直接录像', desc: '前置摄像头' }"></OSSUpload>
  </div>
</template>

<script type="text/ecmascript-6">
import OSSUpload from "@/components/Upload/OSSUpload";
export default {
  name: "",
  components: {
    OSSUpload,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style scoped lang="less">
</style>
