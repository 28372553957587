var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("img", {
      staticClass: "logo",
      attrs: { src: require("@/assets/images/logo.png"), alt: "" }
    }),
    _vm._m(0),
    _c(
      "div",
      { staticClass: "flex border-bottom-1px field-item" },
      [
        _c("div", [_vm._v("官方微信")]),
        _c("CopySpan", { staticClass: "tel-num", attrs: { text: "爱保数科" } })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex border-bottom-1px field-item" }, [
      _c("div", [_vm._v("服务热线")]),
      _c("a", { staticClass: "tel-num", attrs: { href: "tel:020-38686775" } }, [
        _vm._v("020-38686775")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }