
<template>
  <div class="container">
    <!-- 上标成功 -->
    <div v-if="showResultType === 'bidHandle'">
      <div class="result-title">请不要关闭该页面!</div>
      <div class="result-tips">正在处理中，请稍等...</div>
      <img class="resutl-icon mgt40" src="@assets/images/spin.gif" />
    </div>

    <!-- 放款失败 -->
    <div v-if="showResultType === 'loanFail'">
      <img class="resutl-icon mgt20" src="@assets/images/error.png" />
      <div class="result-title">处理失败</div>
      <div class="result-tips">系统处理中遇到点问题，请联系客服或稍后重试</div>
    </div>

    <!-- 放款失败 -->
    <div v-if="showResultType === 'loanWaitting'">
      <img class="resutl-icon mgt20" src="@assets/images/waitting.png" />
      <div class="result-title">审批放款中</div>
      <div class="result-tips">
        您的资料已提交，正在审批放款，请留意短信通知。
      </div>
    </div>

    <!-- 成功 -->
    <div v-if="showResultType === 'stagedSuccess'">
      <img class="resutl-icon" src="@assets/images/success.png" />
      <div class="result-title">申请成功</div>
      <div class="result-tips">恭喜您，分期申请成功！请返回商户页面</div>
      <div class="result-tips">进行后续操作或与商户确认。</div>

      <cube-button v-if="returnOrderUrl" @click="checkOrder" class="handle-btn"
        >查看订单</cube-button
      >

      <img
        ref="gonghzhonghao-poster"
        class="gonghzhonghao-poster"
        src="@assets/images/gongzhonghao.png"
        alt
      />
    </div>

    <!-- 申请拒绝 -->
    <div v-if="showResultType === 'applyReject'">
      <img class="resutl-icon mgt20" src="@assets/images/error.png" />
      <div class="result-title">审核不通过</div>
      <div class="result-tips">很遗憾！您本次申请未获得通过，请选择</div>
      <div class="result-tips">其他支付方式进行付款。</div>

      <!-- <cube-button @click="checkOrder" class="handle-btn">返回订单</cube-button> -->
    </div>

    <!-- 资料已提交 -->
    <div v-if="showResultType === 'infoSubmitted'">
      <img class="resutl-gif" src="@assets/images/waitting.gif" />
      <div class="result-title">资料已提交</div>
      <!-- <div class="result-tips">工作人员将与您电话核实，请保持电话畅通。</div> -->
      <!-- <div class="result-tips">审核结果请留意短信通知。</div> -->
      <div class="company-box">
        <p class="c_title">请务必添加官方企业微信</p>
        <p class="result-tips">以便核实申请信息以及获取专属服务等</p>
        <img class="c_img mgt20" src="@assets/images/companyWC.jpg" />

        <cube-button class="c-btn">点击添加 豆乐派专属客服</cube-button> 
      </div>
    </div>

    <div v-if="showResultType === 'fadadaToBeVerified'">
      <img class="resutl-gif mgt20" src="@assets/images/waitting.gif" />
      <div class="result-title">资料已提交</div>
      <!-- <div class="result-tips">工作人员将对您的认证资料进行审核！</div> -->
      <!-- <div class="result-tips">审核结果请留意短信通知。</div> -->
      <div class="company-box">
        <p class="c_title">请务必添加官方企业微信</p>
        <p class="result-tips">以便核实申请信息以及获取专属服务等</p>
        <img class="c_img mgt20" src="@assets/images/companyWC.jpg" />

        <cube-button class="c-btn">点击添加 豆乐派专属客服</cube-button> 
      </div>
    </div>

    <div v-if="showResultType === 'fadadaReviewReject'">
      <img class="resutl-icon mgt20" src="@assets/images/error.png" />
      <div class="result-title">复审拒绝</div>
      <div class="result-tips">
        很遗憾，您的认证资料审核不通过，请确保使用本人真实资料进行认证。
      </div>
      <!-- <div class="result-tips">审核结果请留意短信通知。</div> -->
    </div>

    <!-- 付款提交成功 -->
    <div v-if="showResultType === 'repaymentSuccess'">
      <img class="resutl-icon" src="@assets/images/success.png" />
      <div class="result-title">付款提交成功</div>
      <div class="result-tips">预计10分钟内处理完成，请留意短信通知。</div>
    </div>

    <div v-if="showResultType === 'redirecting'">
      <c-spin
        style="margin-top: 30vh"
        tip="页面跳转中..."
        size="large"
      ></c-spin>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { obtainOciResult, autoBid } from "@/api/application";
import { nextProcess } from "./redirectAction";
import { stringify } from "qs";

const WechatPublicAccount = "金豆子微服务";

const WechatBrowser = (function getIsWxClient() {
  var ua = navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == "micromessenger") {
    return true;
  }
  return false;
})();

export default {
  name: "",
  data() {
    return {
      showResultType: "",
      returnOrderUrl: "",
    };
  },
  components: {},
  watch: {
    // $route: {
    //   handler(to, from) {
    //     console.log("监听路由 $route to, from", to, from, this);
    //     this.$mounted();
    //   },
    //   immediate: true
    // }
  },
  methods: {
    checkOrder() {
      window.location.href = this.returnOrderUrl;
    },
    checkWechatBrowserAndHandleCopy() {
    setTimeout(() => {
      if (!WechatBrowser) {
        const poster = this.$refs["gonghzhonghao-poster"];

        poster.addEventListener("click", () => {
          const copyInput = document.createElement("input");

          copyInput.value = WechatPublicAccount;
          document.body.appendChild(copyInput);
          copyInput.select();
          if (typeof document.execCommand === "function") {
            document.execCommand("Copy"); // 执行浏览器复制命令

            this.$createToast({
              txt: `已复制'${WechatPublicAccount}'公众号名称,可到微信搜索关注!`,
              type: "success",
              time: 3800,
            }).show();

            copyInput.remove();
          }
        });
      }
    });
  },
  },
  mounted() {
    console.log("result页面=== router", this.$route);
    const { status, returnUrl, resultType } = this.$route.query;
    
    if (returnUrl) {
      this.returnOrderUrl = returnUrl;
    }
    
    if (resultType) {
      this.showResultType = resultType;
      if (resultType === "stagedSuccess") {
        this.checkWechatBrowserAndHandleCopy();
      }

      if (this.showResultType === "bidHandle") {
        setTimeout(() => {
          document.title = "处理中";
        }, 0);
        autoBid()
          .then((res) => {
            console.log(res);
            if (res.success) {
              const { other, data } = res;

              // eslint-disable-next-line no-inner-declarations
              function setBrowserUrl(queryObj) {
                // stringify是queryString的一个api，具体可以查看node官网，也可以自己实现
                var url = `${location.pathname}?${stringify(queryObj)}`;
                history.replaceState({ url: url }, "", url);
              }

              if (other.status === 18) {
                this.showResultType = "infoSubmitted";

                setBrowserUrl({ resultType: "infoSubmitted" });
                return;
              } else if (other.status === 17 || other.status === 20) {
                this.showResultType = "applyReject";

                setBrowserUrl({ resultType: "applyReject" });
                return;
              } else if (other.status === 21) {
                this.showResultType = "stagedSuccess";
                this.returnOrderUrl = `${other.returnUrl || ""}${
                  (data && data.dto && data.dto.params) || ""
                }`;

                setBrowserUrl({ resultType: "stagedSuccess" });
                return;
              } else if (other.status === 22) {
                this.showResultType = "loanFail";
                // 更新 url
                setBrowserUrl({ resultType: "loanFail" });
                return;
              }
              // 成功 拒绝 失败 三种状态

              nextProcess(other, data && data.dto);
            } else {
              console.log(res.data);
              if (res.data && res.data.code == 529) {
                this.errorToast = this.$createToast({
                  txt:
                    (res.data && res.data.msg) ||
                    "用户已经上标成功，请勿重新操作",
                  type: "error",
                  time: 5000,
                });
                this.errorToast.show();
                this.$router.push({
                  name: "installmentLoan",
                });

                return;
              }
              this.showResultType = "loanFail";
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
      
    }
  },
  
  created() {},

  destroyed() {
    this.errorToast && this.errorToast.hide();
  },
};
</script>

<style scoped lang="less">
.container {
  padding: 20px 16px 0;
  text-align: center;

  .resutl-icon {
    //   display: block;
    width: 64px;
    height: 64px;
  }

  .resutl-gif {
    width: 252px;
    height: 183px;
  }
  .company-box{
    margin-top: 40px;
    .c_title{
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .c_img{
      width: 120px;
      height: 120px;
      object-fit: contain;
    }
    .c-btn{
      width:fit-content;
      text-align:center;
      margin: 20px auto 0;
      padding: 10px 20px;
      color:#333;
      background: transparent;
      border: 1px solid #999;
    }
  }

  .result-title {
    margin: 20px 0 16px;
    font-size: 20px;
    // line-height: 28px;
  }

  .result-tips {
    font-size: 14px;
    line-height: 1.3;
    color: #666;
  }

  .handle-btn {
    border-radius: 4px;
    margin-top: 92px;
  }

  .gonghzhonghao-poster {
    display: block;
    width: 375px;
    // height: 100px;
    margin-top: 60px;
    margin-left: -16px;
  }
}
</style>
