<template>
   <div class="tips-top">
         <span class="iconfont icon-dunpai mgr10 fs22"></span>
         <div class="flex-1 tl">
             <slot name="default"></slot>
         </div>
         <slot name="extra"></slot>
   </div>
</template>

<script type="text/ecmascript-6">
export default {
   name: '',
   props: {

   },
   data() {
       return {}
   },
  components: {}
}
</script>

<style scoped lang="less">
    .tips-top {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;

        height: 44px;
        padding-left: 16px;
        background: #fff;
        font-size: 14px;
        color: #999;
    }

</style>
