<template>
  <cube-popup type="extend-popup" ref="popup">
    <div class="extend-popup-content" :style="{'width': contentWidth}">
      <div class="title-block">
        <span class="title-text">{{title}}</span>
        <md-icon @click="hide" class="close-icon" name="close" size="lg"></md-icon>
      </div>
      <slot></slot>
      <slot name="footer"></slot>
    </div>
  </cube-popup>
</template>


<script type="text/ecmascript-6">
import { Icon } from "mand-mobile";
const COMPONENT_NAME = "c-extend-popup";
export default {
  name: COMPONENT_NAME,
  components: {
    [Icon.name]: Icon
  },
  props: {
    content: {
      type: String
    },
    title: {
      type: String
    },
    contentWidth: {
      type: String,
      default() {
        return '80vw'
      }
    }
  },
  methods: {
    show() {
      this.$refs.popup.show();
    },
    hide() {
      this.$refs.popup.hide();
      this.$emit("hide");
    }
  }
};
</script>


<style lang="less" scpoed>
.extend-popup-content {
  position: relative;
  //   padding: 20px 10px;
  border-radius: 4px;
  background: #fff;
  overflow: hidden;
  

  .title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //   height: 28px;
    line-height: 28px;
    padding: 6px;
    font-size: 18px;
    background: cornflowerblue;
    color: cornsilk;

    .title-text {
      padding-left: 6px;
    }

    .close-icon {
      font-size: 26px;
      padding: 3px;
    }
  }
}
</style>