<template>
  <div class="container">
    <iframe
      id="iframe"
      class="agreement-iframe"
      frameborder="0"
      name="showHere"
      scrolling="auto"
      :src="url"
    ></iframe>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: "agreementView",
  data() {
    return {
      url: ""
    };
  },
  components: {},
  mounted() {
    const { url } = this.$route.query;
    console.log("url", url);
    this.url = url;
  },
  activated() {
    const { url } = this.$route.query;
    console.log("url", url);
    this.url = url;

   this.lodingToast = this.$createToast({
      time: 0,
      txt: "加载中"
    }).show();

    this.$nextTick(() => {
      document.getElementById("iframe").onload = () => {
        this.lodingToast.hide();
      };
    });
  },
  deactivated () {
    this.lodingToast.hide();
  }
};
</script>

<style scoped lang="less">
.agreement-iframe {
  height: 100vh;
  width: 100%;
  // background: #ddd;
}
</style>
