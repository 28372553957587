<template>
  <div class="container">
    <md-field title>
      <md-input-item title="ㅤ手机号码" type="text" v-model="form.oldpassword" align="right" placeholder></md-input-item>
      <md-input-item
        title="ㅤㅤ新密码"
        type="text"
        v-model="form.oldpassword"
        align="right"
        placeholder="请输入新密码"
      ></md-input-item>
      <md-input-item
        :solid="false"
        title="确定新密码"
        type="text"
        v-model="form.oldpassword"
        align="right"
        placeholder="请输入确定新密码"
      ></md-input-item>
    </md-field>

    <div class="pd20">
      <md-button @click="handleSubmit" type="primary">确认</md-button>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { updatePassword } from "@/api/carFinance";
import { Field, FieldItem, InputItem, Button } from "mand-mobile";
export default {
  name: "",
  components: {
    [Field.name]: Field,
    [FieldItem.name]: FieldItem,
    [InputItem.name]: InputItem,
    [Button.name]: Button
  },
  data() {
    return {
      form: {
        oldpassword: "",
        newpassword: "",
        newpasswords: ""
      }
    };
  },
  computed: {},
  methods: {
    handleSubmit() {}
  }
};
</script>

<style scoped lang="less">
.container {
  text-align: left;
}
</style>
