
<!--
 * @Autor: CPB
 * @Date: 2020-11-25 09:56:40
 * @LastEditors: CZK
 * @LastEditTime: 2023-12-27 16:05:14
 * @Description: 默认用于阿里云直传方案, 通过action type 更改自定义上传
-->

<template>
  <div class="upload-container">
    <Upload
      ref="upload"
      class="upload-box"
      v-model="files"
      :action="action"
      @files-added="addedHandler"
      @file-error="errHandler"
    >
      <div class="clear-fix upload-box-wrapper">
        <UploadFile
          :class="[{ 'multiple-upload-file': multiple }, 'upload-box-file']"
          v-for="(file, i) in files"
          :file="file"
          :key="i"></UploadFile>
        <UploadBtn
          class="upload-box-btn"
          :style="{
            'background-image':
              `url(` + require(`@/assets/images/${backgroundImgName}`) + ')',
          }"
          :multiple="multiple"
          :simultaneousUploads="simultaneousUploads"
          :accept="accept"
          :capture="capture"
        >
          <div></div>
        </UploadBtn>
      </div>
    </Upload>
    <div v-if="tips" class="upload-tips">
      <div v-if="tips.title">{{ tips.title }}</div>

      <span v-if="tips.desc">{{ tips.desc }}</span>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { Upload, UploadBtn, UploadFile } from "@/components/Upload";

export default {
  name: "",
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    // todo 上传框背景图，约定放在asset/images/下
    backgroundImgName: {
      type: String,
      default: "idcard-back.png",
    },
    maxSize: {
      type: [String, Number],
      default: 10 * 1024 * 1024,
    },
    // 并发上传数
    simultaneousUploads: {
      type: Number,
      default: 1,
    },
    accept: {
      type: String,
      default: "image/*",
    },
    capture: {
      type: String,
    },
    tips: {
      type: Object,
      default() {
        return null;
      },
    },
    files: {
      type: Array,
      default() {
        return [];
      },
    },
    action: {
      type: Object,
      default() {
        return {
          target: `https://wfwloan-app.oss-cn-shenzhen.aliyuncs.com/`,
          type: "oss",
          headers: {},
          // 用于检验请求成功与否
          // eslint-disable-next-line no-unused-vars
          checkSuccess(res, file) {
            if (res.Status === "OK") {
              return true;
            } else {
              return false;
            }
          },
        };
      },
    },
  },
  components: {
    Upload,
    UploadBtn,
    UploadFile,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    vaildMaxSize(files) {
      let hasIgnore = false;
      const maxSize = this.maxSize;
      for (let k in files) {
        const file = files[k];

        if (file.size > maxSize) {
          file.ignore = true;
          hasIgnore = true;
        }
      }
      hasIgnore &&
        this.$createToast({
          type: "warn",
          time: 3000,
          txt: `文件不得超过 > ${this.maxSize / 1024 / 1024}M`,
        }).show();
    },

    async addedHandler(files) {
      console.log("addedHandler", files);
      this.vaildMaxSize(files);

      if (!this.multiple) {
        const file = this.files[0];
        file && this.$refs.upload.removeFile(file);
      }
      this.$emit("update:files", this.files);
    },

    errHandler(file) {
      console.log("errHandler", file);
      const { _xhr } = file;

      //   todo 阿里云错误返回xml string
      const responseText = _xhr.response || _xhr.responseText;

      const startIndex = responseText.indexOf("<Message>") + 9;
      const endIndex = responseText.indexOf("</Message>");
      const message = responseText.substring(startIndex, endIndex);

      this.$createToast({
        type: "warn",
        txt: `Upload fail : ${message}`,
        time: 2500,
      }).show();
    },
  },
};
</script>



<style scoped lang="less">
/deep/ .upload-box {
  .upload-box-file,
  .upload-box-btn {
    margin: 0px auto 0;
    height: 180px;
    width: 300px;
  }

  .upload-box-wrapper {
    display: flex;
  }

  .upload-box-btn {
    background-repeat: no-repeat;
    background-size: contain;
  }

  // 针对单个上传样式替换 上传btn定位
  .cube-upload-file {
    + .cube-upload-btn {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto !important;
      background-size: cover;
    }
  }

  .cube-upload-file-def {
    width: 100%;
    height: 100%;

    .cubeic-wrong {
      display: none;
    }
  }

  // 多选显示删除按钮 上传input 显示
  .multiple-upload-file {
    .cubeic-wrong {
      display: block;
      margin: 10px 10px;
      font-size: 20px;
      color: #2c3e50;
    }

    + .cube-upload-btn {
      opacity: 1 !important;
      position: relative !important;
    }
  }

  // btn插槽样式
  .cube-upload-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      text-align: center;
    }

    i {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
      font-size: 16px;
      line-height: 1;
      font-style: normal;
      color: #fff;
      background-color: #ccc;
      border-radius: 50%;
      opacity: 0;
    }
  }
}

.upload-tips {
  margin-top: 10px;
  font-size: 12px;
  color: #999;
  span {
    margin-left: 3px;
    color: #579af1;
  }
}
</style>




