var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "cube-dialog-fade" } },
    [
      _c(
        "cube-popup",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isVisible,
              expression: "isVisible"
            }
          ],
          ref: "mydialog",
          attrs: {
            type: "dialog",
            "z-index": _vm.zIndex,
            mask: true,
            center: true
          }
        },
        [
          _c("div", { staticClass: "cube-dialog-main" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showClose,
                    expression: "showClose"
                  }
                ],
                staticClass: "cube-dialog-close",
                on: { click: _vm.close }
              },
              [_c("i", { staticClass: "cubeic-close" })]
            ),
            _c("div", { staticClass: "cube-dialog-confirm" }, [
              _c("div", { staticClass: "cube-dialog-title" }, [
                _vm._v("请输入短信验证码")
              ]),
              _c("div", { staticClass: "cube-dialog-content" }, [
                _vm._v(
                  "\n          短信验证码发送至 " +
                    _vm._s(_vm.phone || "银行预留手机") +
                    "\n        "
                )
              ]),
              _c("div", { staticClass: "code-area flex border-bottom-1px" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.smsCode,
                      expression: "smsCode"
                    }
                  ],
                  staticClass: "code-input flex-1",
                  attrs: {
                    type: "tel",
                    placeholder: "请输入验证码",
                    maxlength: "6"
                  },
                  domProps: { value: _vm.smsCode },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.smsCode = $event.target.value
                    }
                  }
                }),
                _c(
                  "div",
                  {
                    staticClass: "code-btn",
                    class: { disabled: _vm.state.smsSendBtn },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.getCaptcha($event)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          _vm.state.smsSendBtn
                            ? _vm.state.time + "s后重发"
                            : "发送验证码"
                        ) +
                        "\n          "
                    )
                  ]
                )
              ]),
              _c(
                "div",
                { staticClass: "cube-dialog-btns" },
                [
                  _vm._t("btns", [
                    _c(
                      "a",
                      {
                        staticClass: "cube-dialog-btn border-top-1px",
                        class: {
                          disabled: _vm.submitting,
                          "cube-dialog-btn_highlight": true
                        },
                        on: { click: _vm.confirm }
                      },
                      [_vm._v("确认")]
                    )
                  ])
                ],
                2
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }