var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "md-example-child container" },
    [
      _c(
        "md-bill",
        [
          _c(
            "div",
            {
              staticClass: "header-slot",
              attrs: { slot: "header" },
              slot: "header"
            },
            [
              _c("div", { staticClass: "flex-1" }, [
                _c("h1", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.orderInfo.orderStatusCn))
                ]),
                _c("p", { staticClass: "desc" }, [
                  _vm._v(_vm._s(_vm.orderInfo.desc))
                ])
              ]),
              _vm.orderInfo.finishBtn
                ? _c(
                    "div",
                    [
                      _c(
                        "md-button",
                        {
                          attrs: { type: "primary", size: "small", inline: "" },
                          on: {
                            click: function($event) {
                              return _vm.handleContinue(_vm.orderInfo.id)
                            }
                          }
                        },
                        [_vm._v("去完成")]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.orderInfo.againBtn
                ? _c(
                    "div",
                    [
                      _c(
                        "md-button",
                        {
                          attrs: { type: "primary", size: "small", inline: "" },
                          on: {
                            click: function($event) {
                              return _vm.handleAgain(_vm.orderInfo.id)
                            }
                          }
                        },
                        [_vm._v("再次提交")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          ),
          _c("md-detail-item", { attrs: { title: "订单编号" } }, [
            _vm._v(_vm._s(_vm.orderInfo.id))
          ]),
          _c("md-detail-item", { attrs: { title: "合作车商" } }, [
            _vm._v(_vm._s(_vm.orderInfo.leagueName))
          ]),
          _c("md-detail-item", { attrs: { title: "合作银行" } }, [
            _vm._v(_vm._s(_vm.orderInfo.upLeagueName))
          ]),
          _c("md-detail-item", { attrs: { title: "客户姓名" } }, [
            _vm._v(_vm._s(_vm.orderInfo.userName))
          ]),
          _c("md-detail-item", { attrs: { title: "客户手机" } }, [
            _c(
              "a",
              {
                staticClass: "flex fs14 main-color",
                attrs: { href: "tel::" + _vm.orderInfo.moblie }
              },
              [
                _c("md-icon", {
                  staticStyle: { "margin-right": "4px" },
                  attrs: { name: "phone" }
                }),
                _vm._v("\n        " + _vm._s(_vm.orderInfo.moblie) + "\n      ")
              ],
              1
            )
          ]),
          _c("md-detail-item", { attrs: { title: "银行订单" } }, [
            _vm._v(_vm._s(_vm.orderInfo.contractNo || "--"))
          ]),
          _c("md-detail-item", { attrs: { title: "垫资总额" } }, [
            _vm._v(_vm._s(_vm.orderInfo.loanTotal) + " 元")
          ]),
          _c("md-detail-item", { attrs: { title: "垫资金额" } }, [
            _vm._v(_vm._s(_vm.orderInfo.loanAmount) + " 元")
          ]),
          _c("md-detail-item", { attrs: { title: "客户利率" } }, [
            _vm._v(_vm._s(_vm.orderInfo.realInterest) + " %")
          ]),
          _c("md-detail-item", { attrs: { title: "返佣利率" } }, [
            _vm._v(_vm._s(_vm.orderInfo.downCommissionPriceRate) + " %")
          ]),
          _c("md-detail-item", { attrs: { title: "车架号" } }, [
            _vm._v(_vm._s(_vm.orderInfo.carFrameNo))
          ]),
          _c(
            "md-detail-item",
            { attrs: { title: "补充资料" } },
            [
              _c(
                "md-button",
                {
                  attrs: { type: "link" },
                  on: { click: _vm.viewSupplementInfo }
                },
                [_vm._v("查看")]
              )
            ],
            1
          ),
          _c("md-detail-item", { attrs: { title: "提交时间" } }, [
            _vm._v(_vm._s(_vm.orderInfo.createTime))
          ]),
          _c(
            "div",
            {
              staticClass: "footer-slot",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _vm.carRestsInfo.auditStep
                ? _c("div", [
                    _c("span", [_vm._v("审批环节：")]),
                    _c("span", [_vm._v(_vm._s(_vm.carRestsInfo.auditStep))])
                  ])
                : _vm._e(),
              _vm.carRestsInfo.auditOpinion
                ? _c("div", [
                    _c("span", [_vm._v("审批意见：")]),
                    _c("span", [_vm._v(_vm._s(_vm.carRestsInfo.auditOpinion))])
                  ])
                : _vm._e(),
              _vm.carRestsInfo.operaterMap
                ? _c("div", { staticClass: "flex flex-start" }, [
                    _c("span", [_vm._v("审批人员：")]),
                    _c(
                      "div",
                      { staticClass: "flex-1" },
                      _vm._l(_vm.carRestsInfo.operaterMap, function(
                        value,
                        name
                      ) {
                        return _c("div", { key: value }, [
                          _vm._v(
                            "\n            " + _vm._s(name) + "\n            "
                          ),
                          _c("a", { attrs: { href: "tel::" + value } }, [
                            _c("i", { staticClass: "iconfont icon-shoujihao" }),
                            _vm._v(_vm._s(value))
                          ])
                        ])
                      }),
                      0
                    )
                  ])
                : _vm._e()
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }