<template>
  <div class="container">
    <template v-if="repaymentInfo.repayStatusCn">
      <div class="amount-section flex">
        <div class="flex-1">
          <div class="fs20 bold">{{repaymentInfo.realAmount}}</div>
          <div class="fs14 mgt14">本期应付( 元)</div>
        </div>
        <div v-if="repaymentInfo.notRepaument" class="flex-1 not-repayment">
          <div class="fs20 bold">{{repaymentInfo.none}}</div>
          <div class="fs14 mgt14">未付总额(元)</div>
        </div>
      </div>
      <div class="white-sapce"></div>

      <div class="repayment-info-list">
        <div class="flex space-between border-bottom-1px">
          <div class="c-333">应付金额：</div>
          <div class="c-666">{{repaymentInfo.realAmount}}元</div>
        </div>

        <div class="flex space-between border-bottom-1px">
          <div class="c-333">付款账户：</div>
          <div class="c-666">{{repaymentInfo.bankInfo}}</div>
        </div>

        <div class="flex space-between border-bottom-1px">
          <div class="c-333">付款方式：</div>
          <div class="c-666">{{repaymentInfo.typeCn}}</div>
        </div>

        <div class="flex space-between border-bottom-1px">
          <div class="c-333">付款时间：</div>
          <div class="c-666">{{repaymentInfo.repaymentTimeCn}}</div>
        </div>

        <div class="flex space-between border-bottom-1px">
          <div class="c-333">付款状态：</div>
          <div class="c-666">{{repaymentInfo.repayStatusCn}}</div>
        </div>
      </div>
    </template>
  </div>
</template>

<script type="text/ecmascript-6">
import { xingShuaiRepaymentInfo } from "@/api/repayment";
export default {
  name: "",
  data() {
    return {
      repaymentInfo: {}
    };
  },
  components: {},
  mounted() {
    const { planId } = this.$route.query;
    xingShuaiRepaymentInfo({ planId }).then(res => {
      if (res.success) {
        const repaymentInfo = res.data;
        repaymentInfo.bankInfo = `${
          repaymentInfo.bankName
        } ( 尾号 ${repaymentInfo.bankCardNo.slice(-4)} )`;
        this.repaymentInfo = repaymentInfo;
      }
    });
  }
};
</script>

<style scoped lang="less">
.amount-section {
  position: relative;
  height: 100px;
  color: #fff;
  background: #579af1;

  .mgt14 {
    margin-top: 14px;
  }
}

.white-sapce {
  height: 10px;
  background: #eef1f6;
}

.repayment-info-list {
  padding: 0 16px;
  font-size: 14px;
  line-height: 46px;
}
.not-repayment {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    z-index: 1;
    transform: translate(-50%, -50%) scale(0.5);
    width: 1px;
    height: 80px;
    background: #eef1f6;
  }
}
</style>
