<template>
  <div class="md-example-child container">
    <md-bill>
      <div class="header-slot" slot="header">
        <div class="flex-1">
          <h1 class="title">{{ orderInfo.orderStatusCn }}</h1>
          <p class="desc">{{ orderInfo.desc }}</p>
        </div>
        <div v-if="orderInfo.finishBtn">
          <md-button
            @click="handleContinue(orderInfo.id)"
            type="primary"
            size="small"
            inline
            >去完成</md-button
          >
        </div>
        <div v-if="orderInfo.againBtn">
          <md-button
            @click="handleAgain(orderInfo.id)"
            type="primary"
            size="small"
            inline
            >再次提交</md-button
          >
        </div>
      </div>

      <md-detail-item title="订单编号">{{ orderInfo.id }}</md-detail-item>
      <md-detail-item title="合作车商">{{
        orderInfo.leagueName
      }}</md-detail-item>
      <md-detail-item title="合作银行">{{
        orderInfo.upLeagueName
      }}</md-detail-item>
      <md-detail-item title="客户姓名">{{ orderInfo.userName }}</md-detail-item>
      <md-detail-item title="客户手机">
        <a class="flex fs14 main-color" :href="`tel::${orderInfo.moblie}`">
          <md-icon name="phone" style="margin-right: 4px"></md-icon>
          {{ orderInfo.moblie }}
        </a>
      </md-detail-item>
      <md-detail-item title="银行订单">{{
        orderInfo.contractNo || "--"
      }}</md-detail-item>

      <md-detail-item title="垫资总额"
        >{{ orderInfo.loanTotal }} 元</md-detail-item
      >
      <md-detail-item title="垫资金额"
        >{{ orderInfo.loanAmount }} 元</md-detail-item
      >
      <md-detail-item title="客户利率"
        >{{ orderInfo.realInterest }} %</md-detail-item
      >
      <md-detail-item title="返佣利率"
        >{{ orderInfo.downCommissionPriceRate }} %</md-detail-item
      >
      <md-detail-item title="车架号">{{ orderInfo.carFrameNo }}</md-detail-item>
      <md-detail-item title="补充资料">
        <md-button @click="viewSupplementInfo" type="link">查看</md-button>
      </md-detail-item>

      <md-detail-item title="提交时间">{{
        orderInfo.createTime
      }}</md-detail-item>

      <div class="footer-slot" slot="footer">
        <!-- 1 账单生成后显示在滴水贷首页，请按时还款避免逾期。
        <br />2 整笔账单还款完成后，额度将恢复。暂不支持额度实时恢复。
        <br />3 还款日将自动扣款，扣款顺序优先余额，其次还款账户：工商银行 (尾号xxxx)。-->
        <div v-if="carRestsInfo.auditStep">
          <span>审批环节：</span>
          <span>{{ carRestsInfo.auditStep }}</span>
        </div>
        <div v-if="carRestsInfo.auditOpinion">
          <span>审批意见：</span>
          <span>{{ carRestsInfo.auditOpinion }}</span>
        </div>
        <div class="flex flex-start" v-if="carRestsInfo.operaterMap">
          <span>审批人员：</span>
          <div class="flex-1">
            <div v-for="(value, name) in carRestsInfo.operaterMap" :key="value">
              {{ name }}
              <a :href="'tel::' + value"
                ><i class="iconfont icon-shoujihao"></i>{{ value }}</a
              >
            </div>
          </div>
        </div>
      </div>
    </md-bill>
  </div>
</template>

<script>
// import "@examples/assets/images/bank-zs.svg";
import { Bill, DetailItem, Icon, Tag, Button } from "mand-mobile";
import { orderDetail } from "@/api/carFinance";

export default {
  name: "",

  components: {
    [Bill.name]: Bill,
    [DetailItem.name]: DetailItem,
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [Button.name]: Button,
  },
  data() {
    return {
      orderInfo: {},
    };
  },
  computed: {
    carRestsInfo() {
      return this.orderInfo.carRestsInfo || {};
    },
  },
  methods: {
    handleContinue(id) {
      this.$router.push({
        name: "supplementInfo",
        query: {
          orderCarId: id,
        },
      });
    },
    handleAgain(id) {
      this.$router.push({
        name: "orderForm",
        query: {
          continueOrder: "yes",
          id,
        },
      });
    },
    viewSupplementInfo() {
      const { id } = this.orderInfo;
      this.$router.push({
        name: "supplementInfo",
        query: {
          orderCarId: id,
          type: "view",
        },
      });
    },
  },
  mounted() {
    const { id } = this.$route.params;
    orderDetail({
      id,
    }).then((res) => {
      if (res.success) {
        // FXIME 状态判断可能用new Map() 更合适
        const statusMap = {
          CommitApply: {
            desc: "请继续完成资料提交",
            finishBtn: true,
          },
          ReplenishFile: {
            desc: "订单待审核,请耐心等待",
          },
          AuditPass: {
            desc: "订单待放款,请耐心等待",
          },

          PayOutOver: {
            desc: "请协助跟进回款进展",
          },
          RepayOver: {
            desc: "感谢使用",
          },
          AuditFail: {
            desc: "很遗憾，您本次申请未通过",
            againBtn: true,
          },
        };

        const header = statusMap[res.data.orderStatus] || {};

        this.orderInfo = { ...res.data, ...header };
      }
    });
  },
};
</script>

<style scoped lang="less">
.container {
  text-align: left;

  .main-color {
    color: #41485d;
    font-weight: 500;
  }
  .md-bill {
    background-color: #fff;
    box-shadow: 0 3px 12px rgba(17, 26, 52, 0.05);

    &.md-water-mark {
      overflow: visible;
    }

    .md-bill-neck {
      &:before,
      &:after {
        content: "";
        position: absolute;
        top: 0;
        width: 18px;
        height: 18px;
        border-radius: 9px;
        background-color: #f3f4f5;
      }

      &:before {
        left: -23px;
      }

      &:after {
        right: -23px;
      }
    }

    .header-slot {
      padding: 20px 0 10px 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 100%;

      .title {
        color: #111a34;
        font-size: 30px;
        font-family: Songti SC;
        line-height: 1;
      }

      .desc {
        margin-top: 8px;
        color: #858b9c;
        font-size: 13px;
        white-space: nowrap;
      }
    }

    .footer-slot {
      padding: 16px 0;
      color: #858b9c;
      font-size: 11px;
      line-height: 1.5;
      border-top: solid 1px #e1e4eb;
    }
  }
}
</style>
