/*
 * @Author: cpb 
 * @Date: 2020-10-21 10:59:55 
 * @Last Modified by: cpb
 * @Last Modified time: 2020-11-12 16:32:50
 * @Desc: 不适合做通用缓存，返回的数据不统一
 */

<template>
  <div
    class="code-btn"
    v-bind:class="{ disabled: state.smsSendBtn }"
    @click.stop.prevent="getCaptcha"
  >
    {{ state.smsSendBtn ? state.time + "s后重发" : "发送验证码" }}
  </div>
</template>

<script type="text/ecmascript-6">
const LIMITTIME = 60;

export default {
  name: "SmsButton",
  props: {
    storageModel: {
      type: Boolean,
      defalut: true,
    },
    params: {
      type: Object,
      default() {
        return {};
      },
    },
    requestFn: {
      type: Function,
      required: true,
    },
    verifyHooks: {
      type: Function,
      default() {
        return () => true;
      },
    },
  },
  components: {},
  data() {
    return {
      state: {
        time: LIMITTIME,
        smsSendBtn: false,
      },
    };
  },
  computed: {
    startTimeKey() {
      return `startTimeKey${this._uid}`;
    },
    authIdKey() {
      return `authIdKey${this._uid}`;
    },
  },
  methods: {
    // 恢复按钮
    regainCheckBtn() {
      const { state } = this;
      state.time = LIMITTIME;
      state.smsSendBtn = false;
    },

    continueCountDown() {
      const nowTime = +new Date();
      const prevTime = this.$ls.get(this.startTimeKey);

      if (prevTime && this.storageModel) {
        const timeDifference = ((nowTime - prevTime) / 1000) | 0;
        const { state } = this;
        state.time = LIMITTIME - timeDifference;
        state.smsSendBtn = true;
        const interval = window.setInterval(() => {
          if (state.time-- <= 0) {
            this.regainCheckBtn();
            window.clearInterval(interval);
          }
        }, 1000);
        return;
      }
    },
    getCaptcha() {
      const { state, startTimeKey, authIdKey, regainCheckBtn } = this;

      if (!this.verifyHooks()) {
        return false;
      }

      setTimeout(() => {
        state.smsSendBtn = true;
        const interval = window.setInterval(() => {
          if (state.time-- <= 0) {
            regainCheckBtn();
            window.clearInterval(interval);
          }
        }, 1000);

        const toastSuccess = this.$createToast({
          txt: "验证码发送中",
          type: "correct",
          time: 0,
        });
        toastSuccess.show();

        this.requestFn({ ...this.params })
          .then((res) => {
            toastSuccess.hide();

            if (res.success) {
              this.$createToast({
                txt: "验证码已发送，请留意",
                type: "correct",
              }).show();
              if (this.storageModel) {
                //   fixeme 返回值可能会变更，待处理
                const authId = res.data.authId;
                // 记录发送时间
                //   fixme 时间并不精确
                const expireTime = LIMITTIME * 1000;
                this.$ls.set(startTimeKey, +new Date(), expireTime);
                this.$ls.set(authIdKey, authId);
                this.emitAuthIdKey(authId);
              } else {
                this.emitResponse(res.data);
              }
            } else {
              clearInterval(interval);
              regainCheckBtn();
            }
          })
          .catch((err) => {
            console.log("err", err);
            toastSuccess.hide();
            clearInterval(interval);
            regainCheckBtn();
          });
      }, 0);
    },
    emitAuthIdKey(value) {
      this.$emit("authIdKey", value);
    },
    emitResponse(value) {
      this.$emit("response", value);
    },
  },
  created() {
    console.log("thisSmsBtn", this);
    const preAuthIdKey = this.$ls.get(this.authIdKey);
    if (preAuthIdKey && this.storageModel) {
      this.emitAuthIdKey(preAuthIdKey);
    }

    this.continueCountDown();
  },
};
</script>

<style scoped lang="less">
.code-btn {
  width: 90px;
  margin-left: 20px;
  padding: 10px 0;
  background: #579af1;
  font-size: 14px;
  color: #fff;
  border-radius: 4px;
  &:active {
    background: #5079f7;
  }
}
</style>
