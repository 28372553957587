<script>
import { mapState } from "vuex";

export default {
  name: "RouteView",
  components: {},
  data() {
    return {};
  },
  computed: {
    ...mapState({
      cacheView: (state) => state.cacheView.cacheView,
    }),
  },
  render() {
    return (
      <keep-alive include={this.cacheView || []}>
          <router-view />
      </keep-alive>
    );
  },
};
</script>



