var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("tips-top", [_vm._v("银行级安全防护，信息安全有保障。")]),
      _c("div", { staticClass: "fill-section" }, [
        _c("div", { staticClass: "fill-area" }, [
          _c("div", { staticClass: "fill-item border-bottom-1px" }, [
            _c("div", { staticClass: "fill-name" }, [_vm._v("电子邮箱")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email"
                }
              ],
              staticClass: "fill-input",
              attrs: {
                value: "",
                maxlength: "25",
                type: "email",
                placeholder: "请填写常用邮箱地址"
              },
              domProps: { value: _vm.email },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.email = $event.target.value
                }
              }
            })
          ]),
          _c("div", { staticClass: "fill-item border-bottom-1px" }, [
            _c("div", { staticClass: "fill-name" }, [_vm._v("现居城市")]),
            _c(
              "div",
              { staticClass: "fill-select", on: { click: _vm.selectCity } },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex-1",
                    class: { "c-666": _vm.addressArr.length }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.addressText()) +
                        "\n          "
                    )
                  ]
                ),
                _c("span", {
                  staticClass: "iconfont icon-jiantou fill-select-icon"
                })
              ]
            )
          ]),
          _c("div", { staticClass: "fill-item border-bottom-1px" }, [
            _c("div", { staticClass: "fill-name" }, [_vm._v("详细地址")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.value1,
                  expression: "value1"
                }
              ],
              staticClass: "input-box",
              domProps: { value: _vm.value1 },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.value1 = $event.target.value
                }
              }
            }),
            _c(
              "select",
              { staticClass: "select-box", on: { change: _vm.valueTap } },
              [
                _c("option", { attrs: { label: "街道", value: "街道" } }),
                _c("option", { attrs: { label: "乡镇", value: "乡镇" } })
              ]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.value3,
                  expression: "value3"
                }
              ],
              staticClass: "input-box",
              domProps: { value: _vm.value3 },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.value3 = $event.target.value
                }
              }
            }),
            _c(
              "select",
              { staticClass: "select-box", on: { change: _vm.valueTap2 } },
              [
                _c("option", { attrs: { label: "路", value: "路" } }),
                _c("option", { attrs: { label: "村", value: "村" } })
              ]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.value5,
                  expression: "value5"
                }
              ],
              staticClass: "input-box",
              domProps: { value: _vm.value5 },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.value5 = $event.target.value
                }
              }
            }),
            _c("span", [_vm._v(_vm._s(_vm.value6))])
          ]),
          _c("div", { staticClass: "fill-item border-bottom-1px" }, [
            _c("div", { staticClass: "fill-name" }, [_vm._v("单位类型")]),
            _c(
              "div",
              { staticClass: "fill-select", on: { click: _vm.selectCompany } },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex-1",
                    class: { "c-666": _vm.companyName }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.companyName || "请选择") +
                        "\n          "
                    )
                  ]
                ),
                _c("span", {
                  staticClass: "iconfont icon-jiantou fill-select-icon"
                })
              ]
            )
          ])
        ]),
        _c("div", { staticClass: "fill-area" }, [
          _c("div", { staticClass: "fill-item border-bottom-1px" }, [
            _c("div", { staticClass: "fill-name" }, [_vm._v("亲属关系")]),
            _c(
              "div",
              {
                staticClass: "fill-select",
                on: { click: _vm.selectRelatives }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex-1",
                    class: { "c-666": _vm.relativesArr.length }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.relativesText()) +
                        "\n          "
                    )
                  ]
                ),
                _c("span", {
                  staticClass: "iconfont icon-jiantou fill-select-icon"
                })
              ]
            )
          ]),
          _c("div", { staticClass: "fill-item border-bottom-1px" }, [
            _c("div", { staticClass: "fill-name" }, [_vm._v("姓ㅤㅤ名")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.relativeName,
                  expression: "relativeName",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "fill-input",
              attrs: {
                maxlength: "8",
                type: "text",
                placeholder: "请输入亲属姓名"
              },
              domProps: { value: _vm.relativeName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.relativeName = $event.target.value.trim()
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            })
          ]),
          _c("div", { staticClass: "fill-item border-bottom-1px" }, [
            _c("div", { staticClass: "fill-name" }, [_vm._v("手机号码")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.relativeMobile,
                  expression: "relativeMobile",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "fill-input",
              attrs: {
                maxlength: "11",
                type: "tel",
                placeholder: "请输入亲属手机号"
              },
              domProps: { value: _vm.relativeMobile },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.relativeMobile = $event.target.value.trim()
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            })
          ])
        ]),
        _c("div", { staticClass: "fill-area" }, [
          _c("div", { staticClass: "fill-item border-bottom-1px" }, [
            _c("div", { staticClass: "fill-name" }, [_vm._v("紧急联系")]),
            _c(
              "div",
              {
                staticClass: "fill-select",
                on: { click: _vm.selectSocialRelations }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex-1",
                    class: { "c-666": _vm.socialRelationsArr.length }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.socialRelationsText()) +
                        "\n          "
                    )
                  ]
                ),
                _c("span", {
                  staticClass: "iconfont icon-jiantou fill-select-icon"
                })
              ]
            )
          ]),
          _c("div", { staticClass: "fill-item border-bottom-1px" }, [
            _c("div", { staticClass: "fill-name" }, [_vm._v("姓ㅤㅤ名")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.emeryName,
                  expression: "emeryName",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "fill-input",
              attrs: {
                maxlength: "8",
                type: "text",
                placeholder: "请输入紧急联系人姓名"
              },
              domProps: { value: _vm.emeryName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.emeryName = $event.target.value.trim()
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            })
          ]),
          _c("div", { staticClass: "fill-item border-bottom-1px" }, [
            _c("div", { staticClass: "fill-name" }, [_vm._v("手机号码")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.emeryMobile,
                  expression: "emeryMobile",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "fill-input",
              attrs: {
                maxlength: "11",
                type: "tel",
                placeholder: "请输入紧急联系人手机号"
              },
              domProps: { value: _vm.emeryMobile },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.emeryMobile = $event.target.value.trim()
                },
                blur: function($event) {
                  return _vm.$forceUpdate()
                }
              }
            })
          ])
        ]),
        _c(
          "div",
          { staticClass: "submit-section" },
          [
            _c(
              "c-button",
              { staticClass: "submit-btn", on: { click: _vm.submit } },
              [_vm._v("下一步")]
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }