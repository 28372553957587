var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["field-item", { activeAnimation: _vm.activeAnimation }] },
    [
      _c("div", { staticClass: "field-inner-item border-bottom-1px" }, [
        _c("label", { staticClass: "field-item-title" }, [
          _vm.iconName
            ? _c("i", {
                staticClass: "field-item-title-icon iconfont",
                class: _vm.iconName
              })
            : _vm._e(),
          _vm._v(_vm._s(_vm.title) + "\n    ")
        ]),
        _c(
          "div",
          { staticClass: "field-item-content" },
          [_vm._t("default")],
          2
        ),
        _vm.arrow ? _c("i", { staticClass: "cubeic-arrow" }) : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }