var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.title
      ? _c("div", { staticClass: "chart-title" }, [_vm._v(_vm._s(_vm.title))])
      : _vm._e(),
    _c("canvas", { staticClass: "canvas", attrs: { id: _vm.elementId } })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }