var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm.loanList.length !== 0 && _vm.loanListLoaded
        ? _c(
            "div",
            { staticClass: "bill-list" },
            [
              _c(
                "cube-scroll",
                {
                  ref: "scroll",
                  attrs: { options: _vm.options },
                  on: {
                    "pulling-down": _vm.onPullingDown,
                    "pulling-up": _vm.onPullingUp
                  }
                },
                _vm._l(_vm.loanList, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "history-bill-item border-bottom-1px",
                      on: {
                        click: function($event) {
                          return _vm.viewLoanDetail(item.receiptId)
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "flex-1 flex" }, [
                        _c("div", { staticClass: "flex-1 bill-item-info" }, [
                          _c("div", { staticClass: "bill-item-name" }, [
                            _vm._v(_vm._s(item.productName))
                          ]),
                          _c("div", { staticClass: "bill-item-time" }, [
                            _vm._v(_vm._s(item.createTime))
                          ])
                        ]),
                        _c("div", { staticClass: "bill-item-right" }, [
                          _c("div", { staticClass: "bill-item-amount" }, [
                            _vm._v(_vm._s(item.loanAmount) + "元")
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "bill-item-status",
                              style:
                                item.biddingStatus == 3 ? "color:F47636" : ""
                            },
                            [_vm._v(_vm._s(item.orderStatus))]
                          )
                        ]),
                        _c("span", {
                          staticClass:
                            "iconfont icon-jiantou item-right-select-icon"
                        })
                      ])
                    ]
                  )
                }),
                0
              )
            ],
            1
          )
        : _vm.loanList.length === 0 && _vm.loanListLoaded
        ? _c("div", [
            _c("img", {
              staticClass: "empty-list",
              attrs: { src: require("@/assets/images/empty.png"), alt: "" }
            }),
            _c("div", { staticClass: "c-666 mgt10" }, [_vm._v("暂无记录")])
          ])
        : _c("c-spin", {
            staticStyle: { "margin-top": "40vh" },
            attrs: { tip: "Loading...", size: "large" }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }