<template>
  <div class="checkbox" :class="_containerClass">
    <label class="checkbox-wrap">
      <input class="checkbox-input" type="checkbox" v-model="checkValue">
      <span class="checkbox-ui cubeic-round-border">
        <i class="cubeic-right"></i>
      </span>
      <span v-if="$slots.default" class="checkbox-label">
        <slot></slot>
      </span>
    </label>
  </div>
</template>

<script type="text/ecmascript-6">
const EVENT_INPUT = "input";

export default {
  name: "",
  props: {
    value: {
      type: Boolean,
      default: true
    },
    index:{
      type: [String, Number]
    }
  },
  data() {
    return {
      
    };
  },
  computed: {
    checkValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        // let emitValue = newValue;
        //this.$emit(EVENT_INPUT, emitValue);
      
        this.$emit('update:idx',this.index)
      }
    },
    _containerClass() {
      return {
        'checkbox_checked': this.value
      };
    }
  },
  components: {}
};
</script>

<style scoped lang="less">

@checkbox-color : #579AF1;
@checkbox-checked-icon-color : #579AF1;
@checkbox-icon-color :  #579AF1;
@ui-width : 1.42em;

.checkbox {
  position: relative;
  padding: 0 16px;
  text-align: left;
  font-size: 100%;
  color: @checkbox-color;
  font-size: 20px;
}

.checkbox-wrap {
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 11px 0;
  line-height: 1.5;
  word-break: break-word;
  word-wrap: break-word;
}


.checkbox-input {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.checkbox-ui {
  position: relative;
  width: 1em;
  height: 1em;
  margin-right: 0;
  line-height: 1;
  border-radius: 50%;


  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.4px;
  -moz-osx-font-smoothing: grayscale;

  &.square-border {
    border-radius: 2px;
  }

  &::before,
  i {
    transition: all 0.2s;
  }

  &::before {
    color: @checkbox-icon-color;
    color: inherit;
    display: inline-block;
    transform: scale(1.24);
  }

  i {
    position: absolute;
    top: 0;
    left: 0;
    color: transparent;
    transform: scale(0.4);
  }
}

.checkbox_checked {
  .checkbox-ui {
    &::before {
      color: transparent;
    }

    i {
      color: @checkbox-checked-icon-color;
      transform: scale(1.23);
    }
  }
}
.checkbox-label {
  margin-left: 10px;
  font-size: 16px;
  color: #333;
} 
</style>
