import { axios } from "@/utils/requestRepayment";

/**
 * 短信登录
 * @param data String mobile,tattedId,tattedCode
 * @returns {*}
 */
export function msgLogin(data = {}) {
  return axios({
    url: "/msgLogin.do",
    method: "post",
    data
  });
}

/**
 * 签约支付验证 登录 
 * @param data String mobile,tattedId,tattedCode
 * @returns {*}
 */
export function certLogin(data = {}) {
  return axios({
    url: "/Login.do",
    method: "post",
    data
  });
}

/**
 * 获取上传凭证
 *
 * @param {*} query 
 * @returns {*}
 */
 export function getUploadProof(query) {
  return axios({
    url: "/oss/getUploadProof.do",
    method: "get",
    params: query
  });
}


/**
 * 获取用户信息
 * @param {*} query 
 */

export function getUser(query) {
  return axios({
    url: "/repayment/getUser.do",
    method: "get",
    params: query
  });
}

/**
 * 退出登录
 * @param {*} data 
 */

export function logout(query) {
  return axios({
    url: "/delCookei.do",
    method: "get",
    params: query
  });
}

/**
 * 发送登录短信验证码
 * @param {*} data mobile 手机号
 */
export function verificationCode(data = {}) {
  return axios({
    url: "/verificationCode.do",
    method: "post",
    data
  });
}
