<template>
  <div :class="['field-item', { activeAnimation: activeAnimation }]">
    <div class="field-inner-item border-bottom-1px">
      <label class="field-item-title">
        <i
          v-if="iconName"
          class="field-item-title-icon iconfont"
          :class="iconName"
        ></i
        >{{ title }}
      </label>
      <div class="field-item-content">
        <slot></slot>
      </div>
      <i v-if="arrow" class="cubeic-arrow"></i>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: "",
  props: {
    title: {
      type: String,
      default: "label",
    },
    iconName: {
      type: String,
      default: "",
    },
    arrow: {
      type: Boolean,
      default: true,
    },
    arrow: {
      type: Boolean,
      default: true,
    },
    activeAnimation: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style scoped lang="less">
.field-item {
  padding: 0 16px;

  &.activeAnimation {
    &:active {
      background: rgba(0, 0, 0, 0.04);
    }
  }
}

.field-inner-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  color: #666;
  .field-item-title {
    // width: 100px;
    text-align: left;

    .field-item-title-icon {
      margin-right: 10px;
    }
  }

  .field-item-content {
    flex: 1;
    text-align: right;
  }

  .cubeic-arrow {
    color: #c9c9ce;
    margin-left: 10px;
  }
}
</style>
