<template>
  <div class="upload-box-container">
    <cube-upload
      ref="upload"
      v-model="files"
      :action="action"
      @files-added="addedHandler"
      @file-error="errHandler"
    >
      <div class="clear-fix upload-box-wrapper">
        <cube-upload-file
          :class="{ 'multiple-upload-file': multiple, 'view-type': !editType }"
          v-for="(file, i) in files"
          :file="file"
          :key="i"
          @click="showImagePreview(i)"
        ></cube-upload-file>
        <cube-upload-btn
          v-if="editType"
          :class="{ 'multiple-style': multiple }"
          :multiple="multiple"
        >
          <div>
            <i>＋</i>
            <p>添加图片</p>
          </div>
        </cube-upload-btn>
      </div>
    </cube-upload>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: "",
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    files: {
      type: Array,
      default() {
        return [];
      },
    },
    editType: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {
      action: {
        target: `/api/car/operatorCenter/uploadImage.do`,
        fileName: "file",
        headers: {},
        data: {},
        // 用于检验请求成功与否
        // eslint-disable-next-line no-unused-vars
        checkSuccess(res, file) {
          // console.log("上传返回数据", res);
          if (res.success) {
            return true;
          } else {
            return false;
          }
        },
      },
    };
  },
  computed: {},
  methods: {
    filesAdded(files) {
      let hasIgnore = false;
      const maxSize = 1 * 1024 * 1024; // 1M
      for (let k in files) {
        const file = files[k];
        if (file.size > maxSize) {
          file.ignore = true;
          hasIgnore = true;
        }
      }
      hasIgnore &&
        this.$createToast({
          type: "warn",
          time: 1000,
          txt: "You selected >1M files",
        }).show();
    },

    addedHandler() {
      const file = this.files[0];
      if (!this.multiple) {
        file && this.$refs.upload.removeFile(file);
      }
      this.$emit("update:files", this.files);
    },
    errHandler(file) {
      // const msg = file.response.message
      console.log("errHandler", file);
      if (file._xhr && file._xhr && file._xhr.status === 401) {
        this.$createToast({
          type: "warn",
          txt: "Unauthorized,请重新登录",
          time: 3000,
          onTimeout: () => {
            console.log("Timeout");
            this.$router.push({
              name: "carLogin",
            });
          },
        }).show();
      } else {
        this.$createToast({
          type: "warn",
          txt: "Upload fail",
          time: 1500,
        }).show();
      }
    },

    showImagePreview(index) {
      this.initialIndex = index;
      const params = {
        $props: {
          imgs: this.files.map((item) => item.url),
          initialIndex: "initialIndex", // 响应式数据的key名
          loop: false,
          zIndex: 9999,
        },
        $events: {
          change: (i) => {
            // 必须更新 initialIndex
            this.initialIndex = i;
          },
        },
      };
      this.$createImagePreview({ ...params }).show();
    },
  },
};
</script>

<style lang="less">
.upload-box-container {
  //   overflow: auto;
  background: #fff;
  box-shadow: 0 2px 10px rgba(197, 202, 213, 0.25);

  .cube-upload {
    .cube-upload-file,
    .cube-upload-btn {
      margin: 0;
      height: 200px;
    }

    .upload-box-wrapper {
      overflow-x: auto;
      white-space: nowrap;
    }

    .cube-upload-file {
      display: inline-block;
      margin: 0;

      + .cube-upload-btn {
        margin-top: -200px;
        opacity: 0;
      }

      + .multiple-style {
        margin-top: 0;
        opacity: 1;

        display: inline-block;
        vertical-align: bottom;
        width: 158px;
        height: 200px;
        padding-top: 56px;
      }
    }

    .cube-upload-file-def {
      //   width: 100%;
      //   height: 100%;
      width: 158px;
      height: 200px;

      .cubeic-wrong {
        display: none;
      }
    }

    .multiple-upload-file {
      .cubeic-wrong {
        display: block;
        margin: 10px 10px;
        font-size: 20px;
        color: #2c3e50;
      }
    }

    // 查看状态隐藏删除按钮
    .view-type {
      .cubeic-wrong {
        display: none;
      }
    }

    .cube-upload-btn {
      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        text-align: center;
      }

      i {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        height: 50px;
        margin-bottom: 20px;
        font-size: 32px;
        line-height: 1;
        font-style: normal;
        color: #fff;
        background-color: #333;
        border-radius: 50%;
      }
    }
  }
}
</style>

