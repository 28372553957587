var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("tips-top", [_vm._v("请使用本人身份信息，勿使用他人身份信息尝试")]),
      _c("cube-popup", { ref: "ORCPopup", attrs: { type: "ORCPopup" } }, [
        _c("div", { staticClass: "orc-result" }, [
          _c("img", {
            staticClass: "close-icon",
            attrs: {
              src: require("../../assets/images/icon-close.png"),
              alt: ""
            },
            on: { click: _vm.closePopup }
          }),
          _c("div", { staticClass: "orc-title" }, [_vm._v("信息确认")]),
          _c("div", { staticClass: "orc-tips border-bottom-1px" }, [
            _vm._v("*请确认以下信息是否正确，如有误请重新识别")
          ]),
          _c("div", { staticClass: "orc-info" }, [
            _c("div", { staticClass: "orc-item" }, [
              _c("div", { staticClass: "orc-item-left" }, [_vm._v("姓ㅤㅤ名")]),
              _c("div", { staticClass: "orc-item-right" }, [
                _vm._v(_vm._s(_vm.OCRResult.name))
              ])
            ]),
            _c("div", { staticClass: "orc-item" }, [
              _c("div", { staticClass: "orc-item-left" }, [_vm._v("身份证号")]),
              _c("div", { staticClass: "orc-item-right" }, [
                _vm._v(_vm._s(_vm.OCRResult.idCard))
              ])
            ]),
            _c("div", { staticClass: "orc-item" }, [
              _c("div", { staticClass: "orc-item-left" }, [_vm._v("签发机关")]),
              _c("div", { staticClass: "orc-item-right" }, [
                _vm._v(_vm._s(_vm.OCRResult.issue))
              ])
            ]),
            _c("div", { staticClass: "orc-item" }, [
              _c("div", { staticClass: "orc-item-left" }, [_vm._v("有效期限")]),
              _c("div", { staticClass: "orc-item-right" }, [
                _vm._v(
                  _vm._s(_vm.OCRResult.valid_date_start) +
                    "—" +
                    _vm._s(_vm.OCRResult.valid_date_end)
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "handle-section flex border-top-1px" }, [
            _c(
              "div",
              { staticClass: "back flex-1", on: { click: _vm.closePopup } },
              [_vm._v("返回修改")]
            ),
            _c(
              "div",
              {
                staticClass: "confirm flex-1",
                on: { click: _vm.confirmSubmit }
              },
              [_vm._v("确认无误")]
            )
          ])
        ])
      ]),
      _c(
        "div",
        { attrs: { id: "upload-section" } },
        [
          _c(
            "cube-upload",
            {
              ref: "uploadface",
              staticClass: "upload-box",
              attrs: { action: _vm.action, "process-file": _vm.processFile },
              on: {
                "files-added": _vm.addedHandlerFace,
                "file-error": _vm.errHandler
              },
              model: {
                value: _vm.idcardface,
                callback: function($$v) {
                  _vm.idcardface = $$v
                },
                expression: "idcardface"
              }
            },
            [
              _c(
                "div",
                { staticClass: "clear-fix" },
                [
                  _vm._l(_vm.idcardface, function(file, i) {
                    return _c("cube-upload-file", {
                      key: i,
                      staticClass: "upload-file-idcard",
                      attrs: { file: file }
                    })
                  }),
                  _c(
                    "cube-upload-btn",
                    {
                      staticClass: "upload-btn-idcard idcard-face",
                      attrs: { capture: _vm.capture, multiple: false }
                    },
                    [_c("div", [_c("i", [_vm._v("＋")])])]
                  )
                ],
                2
              )
            ]
          ),
          _c("div", { staticClass: "upload-tips" }, [
            _vm._v("\n      点击开始识别正面\n      ")
          ]),
          _c(
            "cube-upload",
            {
              ref: "uploadback",
              staticClass: "upload-box",
              attrs: { action: _vm.action, "process-file": _vm.processFile },
              on: {
                "files-added": _vm.addedHandlerBack,
                "file-error": _vm.errHandler
              },
              model: {
                value: _vm.idcardback,
                callback: function($$v) {
                  _vm.idcardback = $$v
                },
                expression: "idcardback"
              }
            },
            [
              _c(
                "div",
                { staticClass: "clear-fix" },
                [
                  _vm._l(_vm.idcardback, function(file, i) {
                    return _c("cube-upload-file", {
                      key: i,
                      staticClass: "upload-file-idcard",
                      attrs: { file: file }
                    })
                  }),
                  _c(
                    "cube-upload-btn",
                    {
                      staticClass: "upload-btn-idcard idcard-back",
                      attrs: { capture: _vm.capture, multiple: false }
                    },
                    [_c("div", [_c("i", [_vm._v("＋")])])]
                  )
                ],
                2
              )
            ]
          ),
          _c("div", { staticClass: "upload-tips" }, [
            _vm._v("\n      点击开始识别背面\n      ")
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "btn-section" },
        [
          _c("div", { staticClass: "fs14 c-999" }, [
            _vm._v("*拍摄时请确保身份证边框完整、字迹清晰、亮度均衡")
          ]),
          _c(
            "cube-button",
            {
              staticClass: "sumit-btn",
              class: { disabled: _vm.loading },
              on: { click: _vm.OERCDeal }
            },
            [_vm.loading ? _c("carousel") : _c("span", [_vm._v("下一步")])],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }