import moment from "moment";
import "moment/locale/zh-cn";
moment.locale("zh-cn");

// import parseTime, formatTime and set to filter
export { parseTime, formatTime } from "@/utils/util";

export function numberFormat(value) {
  if (!value) {
    return "0";
  }
  const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,"); // 将整数部分逢三一断
  return intPartFormat;
}

export function dayjs(dataStr, pattern = "YYYY-MM-DD HH:mm:ss") {
  return moment(dataStr).format(pattern);
}

/**
 * Show plural label if time is plural number
 * @param {number} time
 * @param {string} label
 * @return {string}
 */
function pluralize(time, label) {
  if (time === 1) {
    return time + label;
  }
  return time + label + "s";
}

/**
 * @param {number} time
 */
export function timeAgo(time) {
  const between = Date.now() / 1000 - Number(time);
  if (between < 3600) {
    return pluralize(~~(between / 60), " minute");
  } else if (between < 86400) {
    return pluralize(~~(between / 3600), " hour");
  } else {
    return pluralize(~~(between / 86400), " day");
  }
}

/**
 * Number formatting
 * like 10000 => 10k
 * @param {number} num
 * @param {number} digits
 */
export function numberFormatter(num, digits) {
  const si = [
    { value: 1e18, symbol: "E" },
    { value: 1e15, symbol: "P" },
    { value: 1e12, symbol: "T" },
    { value: 1e9, symbol: "G" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "k" }
  ];
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (
        (num / si[i].value + 0.1)
          .toFixed(digits)
          .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[i].symbol
      );
    }
  }
  return num.toString();
}

/**
 * 10000 => "10,000"
 * @param {number} num
 */
export function toThousandFilter(num) {
  return (+num || 0)
    .toString()
    .replace(/^-?\d+/g, m => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","));
}


/**
 * 10000 => "10,000"
 * type 为0 不带小数点
 */
 export function millionFormat(s, type){
  if(s == 0.00){
    s = 0;
  }
  if(/[^[\-|0-9][0-9]*]/.test(s))
    return "0";
  if(s == null || s === "")
    return "";
  s = s.toString().replace(/^(-\d*)$/, "$1.");
  s = s.toString().replace(/^(\d*)$/, "$1.");
  s = (s + "00").replace(/(\d*\.\d\d)\d*/, "$1");
  s = s.replace(".", ",");
  var re = /(\d)(\d{3},)/;
  while(re.test(s))
    s = s.replace(re, "$1,$2");
  s = s.replace(/,(\d\d)$/, ".$1");
  if(type == 0) { // 不带小数位(默认是有小数位)  
    var a = s.split(".");
    if(a[1] == "00") {
      s = a[0];
    }
  }
  return s;
}


/**
 * Upper case first char
 * @param {String} string
 */
export function uppercaseFirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

/**
 * 加元 修饰符
 * @param {*} string
 */

export function plusyuan(string) {
  if (!string && typeof string !== 'number') {
    return;
  }
  return `${string}元`;
}

/**
 *  处理20190808 为 年月
 */

export function dealDate(string, split = "/") {
  if (!string && string.length !== 8) {
    return;
  }

  let arr = [];
  string = string.toString();
  arr.push(string.substring(0, 4));
  arr.push(string.substring(4, 6));
  arr.push(string.substring(6, 8));

  return arr.join(split);
}

export function nameFilter(el) {
  if (!el) return;

  let charArr = el.split("");

  let result = charArr.reduce((arr, next, index) => {
    index === 0 ? arr.push(next) : arr.push(" *");
    return arr;
  }, []);

  return result.join("");
}

export function mobileFilter(el) {
  if (!el) return;

  let charArr = el.split("");

  let result = charArr.reduce((arr, next, index) => {
    index < 3 || index > 7 ? arr.push(next) : arr.push("*");
    return arr;
  }, []);

  return result.join("");
}
