var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container all-order-container" }, [
    _c(
      "div",
      { staticClass: "search-section" },
      [
        _c("md-date-picker", {
          ref: "datePicker",
          attrs: {
            "today-text": "今天",
            type: "custom",
            "custom-types": ["yyyy", "MM"],
            "min-date": _vm.minDate,
            "max-date": _vm.maxDate,
            "default-date": _vm.currentDate,
            "is-view": false
          },
          on: {
            initialed: _vm.onDatePickerInitialed,
            confirm: _vm.onDatePickerConfirm
          },
          model: {
            value: _vm.isDatePickerShow,
            callback: function($$v) {
              _vm.isDatePickerShow = $$v
            },
            expression: "isDatePickerShow"
          }
        }),
        _c(
          "div",
          { staticClass: "pd10" },
          [
            _c(
              "md-input-item",
              {
                ref: "input4",
                attrs: {
                  clearable: "",
                  type: "search",
                  placeholder: "请输入客户姓名或手机号查询",
                  align: "right"
                },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "flex search-date-block",
                    attrs: { slot: "left" },
                    on: { click: _vm.handleDateSearch },
                    slot: "left"
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.searchDate || "搜索日期"))]),
                    _c("md-icon", { attrs: { name: "calendar", size: "lg" } })
                  ],
                  1
                ),
                _c("md-icon", {
                  staticClass: "pdl6 mgl10",
                  attrs: { slot: "right", name: "search", size: "lg" },
                  on: { click: _vm.handleSearch },
                  slot: "right"
                })
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "order-status-list" },
          _vm._l(_vm.orderStatusList, function(item, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "order-status-item active",
                class: index === _vm.selectedIndex ? "selected" : "",
                on: {
                  click: function($event) {
                    return _vm.handleSelectOrderStatus(index)
                  }
                }
              },
              [
                _c("div", { staticClass: "order-item-count" }, [
                  _vm._v(_vm._s(item.count))
                ]),
                _c("div", { staticClass: "order-item-title" }, [
                  _vm._v(_vm._s(item.title))
                ])
              ]
            )
          }),
          0
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "list-section" },
      [
        _c(
          "md-scroll-view",
          {
            ref: "scrollView",
            attrs: { "scrolling-x": false },
            on: {
              refreshing: _vm.$_onRefresh,
              "end-reached": _vm.$_onEndReached
            },
            scopedSlots: _vm._u([
              {
                key: "refresh",
                fn: function(ref) {
                  var scrollTop = ref.scrollTop
                  var isRefreshActive = ref.isRefreshActive
                  var isRefreshing = ref.isRefreshing
                  return _c("md-scroll-view-refresh", {
                    attrs: {
                      "scroll-top": scrollTop,
                      "is-refreshing": isRefreshing,
                      "is-refresh-active": isRefreshActive
                    }
                  })
                }
              }
            ])
          },
          [
            _vm._l(_vm.list, function(item, index) {
              return _c(
                "md-cell-item",
                {
                  key: index,
                  staticClass: "hover pdl16 pdr16",
                  attrs: {
                    title: item.userName,
                    brief: item.createDate,
                    arrow: ""
                  },
                  on: {
                    click: function($event) {
                      return _vm.handleViewOrderDetail(item)
                    }
                  }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "holder",
                      attrs: { slot: "left" },
                      slot: "left"
                    },
                    [_vm._v(_vm._s(_vm._f("firstNameFilter")(item.userName)))]
                  ),
                  _c(
                    "span",
                    {
                      staticClass: "tr",
                      attrs: { slot: "right" },
                      slot: "right"
                    },
                    [
                      _c("div", { staticClass: "order-amount" }, [
                        _vm._v(_vm._s(item.loanAmount) + "元")
                      ]),
                      _c(
                        "div",
                        { staticClass: "order-status" },
                        [
                          _vm.filterTagShow(item)
                            ? _c(
                                "md-tag",
                                {
                                  attrs: {
                                    size: "large",
                                    shape: "fillet",
                                    type: "fill",
                                    "fill-color": "rgba(255, 91, 96, .1)",
                                    "font-weight": "normal",
                                    "font-color": "#FF5B60"
                                  }
                                },
                                [_vm._v(_vm._s(item.orderStatusCn))]
                              )
                            : _c("span", [_vm._v(_vm._s(item.orderStatusCn))])
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            }),
            _vm.list.length === 0 && _vm.hasLoaded
              ? _c("md-result-page", {
                  attrs: { "img-url": require("@/assets/images/empty.png") }
                })
              : !_vm.hasLoaded
              ? _c(
                  "div",
                  {
                    staticClass: "tc",
                    staticStyle: {
                      position: "absolute",
                      left: "0",
                      top: "0",
                      "z-index": "10",
                      width: "100%",
                      "padding-top": "10vh"
                    }
                  },
                  [
                    _c("c-spin", {
                      attrs: { stip: "Loading...", size: "large" }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c("md-scroll-view-more", {
              attrs: { slot: "more", "is-finished": _vm.isFinished },
              slot: "more"
            })
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }