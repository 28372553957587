var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "code-btn",
      class: { disabled: _vm.state.smsSendBtn },
      on: {
        click: function($event) {
          $event.stopPropagation()
          $event.preventDefault()
          return _vm.getCaptcha($event)
        }
      }
    },
    [
      _vm._v(
        "\n  " +
          _vm._s(
            _vm.state.smsSendBtn ? _vm.state.time + "s后重发" : "发送验证码"
          ) +
          "\n"
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }