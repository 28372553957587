var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm.showResultType === "success"
      ? _c("div", [
          _c("img", {
            staticClass: "resutl-icon",
            attrs: { src: require("@assets/images/success.png") }
          }),
          _c("div", { staticClass: "result-title" }, [_vm._v("签约成功")]),
          _c("div", { staticClass: "result-tips" }, [
            _vm._v("恭喜，您已签约成功")
          ])
        ])
      : _vm._e(),
    _vm.showResultType === "successed"
      ? _c("div", [
          _c("img", {
            staticClass: "resutl-icon",
            attrs: { src: require("@/assets/images/logo.png") }
          }),
          _c(
            "div",
            {
              staticClass: "result-tips",
              staticStyle: { "margin-top": "20px" }
            },
            [_vm._v("您已签约成功，无须再进行签约")]
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }