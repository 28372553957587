
export const testData = [{
   
        text: '北京市',
        value: '110000',
        children:[
            {
              province: '北京市',
              text: '北京市',
              value: '110100',
              children: [
                {
                  city: '市辖区',
                  text: '东城区',
                  value: '110101'
                },
                {
                  city: '市辖区',
                  text: '西城区',
                  value: '110102'
                }
              ],
            }
          ],
      
}]

export const provinceObject = {
    "北京市": {
        "北京市": {
            id: 110000,
            value: [[3, '110101', '', '', '东城区', '2'], [4, '110102', '', '', '西城区', '2'], [5, '110105', '', '',
                '朝阳区', '2'], [6, '110106', '', '', '丰台区', '2'], [7, '110107', '', '', '石景山区', '2'], [8,
                '110108', '', '', '海淀区', '2'], [9, '110109', '', '', '门头沟区', '2'], [10, '110111', '', '',
                '房山区', '2'], [11, '110112', '', '', '通州区', '2'], [12, '110113', '', '', '顺义区', '2'], [13,
                '110114', '', '', '昌平区', '2'], [14, '110115', '', '', '大兴区', '2'], [15, '110116', '', '',
                '怀柔区', '2'], [16, '110117', '', '', '平谷区', '2'], [17, '110228', '', '', '密云县', '2'], [18,
                '110229', '', '', '延庆县', '2']]
        }
    },
    "天津市": {
        "天津市": {
            id: 120000,
            value: [[20, '120101', '', '', '和平区', '19'], [21, '120102', '', '', '河东区', '19'], [22, '120103', '', '',
                '河西区', '19'], [23, '120104', '', '', '南开区', '19'], [24, '120105', '', '', '河北区', '19'], [25,
                '120106', '', '', '红桥区', '19'], [26, '120110', '', '', '东丽区', '19'], [27, '120111', '', '',
                '西青区', '19'], [28, '120112', '', '', '津南区', '19'], [29, '120113', '', '', '北辰区', '19'], [30,
                '120114', '', '', '武清区', '19'], [31, '120115', '', '', '宝坻区', '19'], [32, '120116', '', '',
                '滨海新区', '19'], [33, '120221', '', '', '宁河县', '19'], [34, '120223', '', '', '静海县', '19'], [
                35, '120225', '', '', '蓟县', '19']]
        }
    },
    "河北省": {
        "石家庄市": {
            id: 130100,
            value: [[38, '130101', '', '', '市辖区', '37'], [39, '130102', '', '', '长安区', '37'], [40, '130104', '', '',
                    '桥西区', '37'], [41, '130105', '', '', '新华区', '37'], [42, '130107', '', '', '井陉矿区', '37'], [
                    43, '130108', '', '', '裕华区', '37'], [44, '130109', '', '', '藁城区', '37'], [45, '130110', '',
                    '', '鹿泉区', '37'], [46, '130111', '', '', '栾城区', '37'], [47, '130121', '', '', '井陉县', '37'],
                [48, '130123', '', '', '正定县', '37'], [49, '130125', '', '', '行唐县', '37'], [50, '130126', '', '',
                    '灵寿县', '37'], [51, '130127', '', '', '高邑县', '37'], [52, '130128', '', '', '深泽县', '37'], [53,
                    '130129', '', '', '赞皇县', '37'], [54, '130130', '', '', '无极县', '37'], [55, '130131', '', '',
                    '平山县', '37'], [56, '130132', '', '', '元氏县', '37'], [57, '130133', '', '', '赵县', '37'], [58,
                    '130181', '', '', '辛集市', '37'], [59, '130183', '', '', '晋州市', '37'], [60, '130184', '', '',
                    '新乐市', '37']]
        },
        "唐山市": {
            id: 130200,
            value: [[62, '130201', '', '', '市辖区', '61'], [63, '130202', '', '', '路南区', '61'], [64, '130203', '', '',
                '路北区', '61'], [65, '130204', '', '', '古冶区', '61'], [66, '130205', '', '', '开平区', '61'], [67,
                '130207', '', '', '丰南区', '61'], [68, '130208', '', '', '丰润区', '61'], [69, '130209', '', '',
                '曹妃甸区', '61'], [70, '130223', '', '', '滦县', '61'], [71, '130224', '', '', '滦南县', '61'], [72,
                '130225', '', '', '乐亭县', '61'], [73, '130227', '', '', '迁西县', '61'], [74, '130229', '', '',
                '玉田县', '61'], [75, '130281', '', '', '遵化市', '61'], [76, '130283', '', '', '迁安市', '61']]
        },
        "秦皇岛市": {
            id: 130300,
            value: [[78, '130301', '', '', '市辖区', '77'], [79, '130302', '', '', '海港区', '77'], [80, '130303', '', '',
                    '山海关区', '77'], [81, '130304', '', '', '北戴河区', '77'], [82, '130321', '', '', '青龙满族自治县', '77'],
                [83, '130322', '', '', '昌黎县', '77'], [84, '130323', '', '', '抚宁县', '77'], [85, '130324', '', '',
                    '卢龙县', '77']]
        },
        "邯郸市": {
            id: 130400,
            value: [[87, '130401', '', '', '市辖区', '86'], [88, '130402', '', '', '邯山区', '86'], [89, '130403', '', '',
                    '丛台区', '86'], [90, '130404', '', '', '复兴区', '86'], [91, '130406', '', '', '峰峰矿区', '86'], [
                    92, '130421', '', '', '邯郸县', '86'], [93, '130423', '', '', '临漳县', '86'], [94, '130424', '',
                    '', '成安县', '86'], [95, '130425', '', '', '大名县', '86'], [96, '130426', '', '', '涉县', '86'],
                [97, '130427', '', '', '磁县', '86'], [98, '130428', '', '', '肥乡县', '86'], [99, '130429', '', '',
                    '永年县', '86'], [100, '130430', '', '', '邱县', '86'], [101, '130431', '', '', '鸡泽县', '86'], [
                    102, '130432', '', '', '广平县', '86'], [103, '130433', '', '', '馆陶县', '86'], [104, '130434',
                    '', '', '魏县', '86'], [105, '130435', '', '', '曲周县', '86'], [106, '130481', '', '', '武安市',
                    '86']]
        },
        "邢台市": {
            id: 130500,
            value: [[108, '130501', '', '', '市辖区', '107'], [109, '130502', '', '', '桥东区', '107'], [110, '130503',
                '', '', '桥西区', '107'], [111, '130521', '', '', '邢台县', '107'], [112, '130522', '', '', '临城县',
                '107'], [113, '130523', '', '', '内丘县', '107'], [114, '130524', '', '', '柏乡县', '107'], [115,
                '130525', '', '', '隆尧县', '107'], [116, '130526', '', '', '任县', '107'], [117, '130527', '',
                '', '南和县', '107'], [118, '130528', '', '', '宁晋县', '107'], [119, '130529', '', '', '巨鹿县',
                '107'], [120, '130530', '', '', '新河县', '107'], [121, '130531', '', '', '广宗县', '107'], [122,
                '130532', '', '', '平乡县', '107'], [123, '130533', '', '', '威县', '107'], [124, '130534', '',
                '', '清河县', '107'], [125, '130535', '', '', '临西县', '107'], [126, '130581', '', '', '南宫市',
                '107'], [127, '130582', '', '', '沙河市', '107']]
        },
        "保定市": {
            id: 130600,
            value: [[129, '130601', '', '', '市辖区', '128'], [130, '130602', '', '', '新市区', '128'], [131, '130603',
                '', '', '北市区', '128'], [132, '130604', '', '', '南市区', '128'], [133, '130621', '', '', '满城县',
                '128'], [134, '130622', '', '', '清苑县', '128'], [135, '130623', '', '', '涞水县', '128'], [136,
                '130624', '', '', '阜平县', '128'], [137, '130625', '', '', '徐水县', '128'], [138, '130626', '',
                '', '定兴县', '128'], [139, '130627', '', '', '唐县', '128'], [140, '130628', '', '', '高阳县',
                '128'], [141, '130629', '', '', '容城县', '128'], [142, '130630', '', '', '涞源县', '128'], [143,
                '130631', '', '', '望都县', '128'], [144, '130632', '', '', '安新县', '128'], [145, '130633', '',
                '', '易县', '128'], [146, '130634', '', '', '曲阳县', '128'], [147, '130635', '', '', '蠡县',
                '128'], [148, '130636', '', '', '顺平县', '128'], [149, '130637', '', '', '博野县', '128'], [150,
                '130638', '', '', '雄县', '128'], [151, '130681', '', '', '涿州市', '128'], [152, '130682', '',
                '', '定州市', '128'], [153, '130683', '', '', '安国市', '128'], [154, '130684', '', '', '高碑店市',
                '128']]
        },
        "张家口市": {
            id: 130700,
            value: [[156, '130701', '', '', '市辖区', '155'], [157, '130702', '', '', '桥东区', '155'], [158, '130703',
                    '', '', '桥西区', '155'], [159, '130705', '', '', '宣化区', '155'], [160, '130706', '', '',
                    '下花园区', '155'], [161, '130721', '', '', '宣化县', '155'], [162, '130722', '', '', '张北县', '155'],
                [163, '130723', '', '', '康保县', '155'], [164, '130724', '', '', '沽源县', '155'], [165, '130725',
                    '', '', '尚义县', '155'], [166, '130726', '', '', '蔚县', '155'], [167, '130727', '', '', '阳原县',
                    '155'], [168, '130728', '', '', '怀安县', '155'], [169, '130729', '', '', '万全县', '155'], [170,
                    '130730', '', '', '怀来县', '155'], [171, '130731', '', '', '涿鹿县', '155'], [172, '130732', '',
                    '', '赤城县', '155'], [173, '130733', '', '', '崇礼县', '155']]
        },
        "承德市": {
            id: 130800,
            value: [[175, '130801', '', '', '市辖区', '174'], [176, '130802', '', '', '双桥区', '174'], [177, '130803',
                    '', '', '双滦区', '174'], [178, '130804', '', '', '鹰手营子矿区', '174'], [179, '130821', '', '',
                    '承德县', '174'], [180, '130822', '', '', '兴隆县', '174'], [181, '130823', '', '', '平泉县', '174'],
                [182, '130824', '', '', '滦平县', '174'], [183, '130825', '', '', '隆化县', '174'], [184, '130826',
                    '', '', '丰宁满族自治县', '174'], [185, '130827', '', '', '宽城满族自治县', '174'], [186, '130828', '',
                    '', '围场满族蒙古族自治县', '174']]
        },
        "沧州市": {
            id: 130900,
            value: [[188, '130901', '', '', '市辖区', '187'], [189, '130902', '', '', '新华区', '187'], [190, '130903',
                '', '', '运河区', '187'], [191, '130921', '', '', '沧县', '187'], [192, '130922', '', '', '青县',
                '187'], [193, '130923', '', '', '东光县', '187'], [194, '130924', '', '', '海兴县', '187'], [195,
                '130925', '', '', '盐山县', '187'], [196, '130926', '', '', '肃宁县', '187'], [197, '130927', '',
                '', '南皮县', '187'], [198, '130928', '', '', '吴桥县', '187'], [199, '130929', '', '', '献县',
                '187'], [200, '130930', '', '', '孟村回族自治县', '187'], [201, '130981', '', '', '泊头市', '187'], [
                202, '130982', '', '', '任丘市', '187'], [203, '130983', '', '', '黄骅市', '187'], [204, '130984',
                '', '', '河间市', '187']]
        },
        "廊坊市": {
            id: 131000,
            value: [[206, '131001', '', '', '市辖区', '205'], [207, '131002', '', '', '安次区', '205'], [208, '131003',
                '', '', '广阳区', '205'], [209, '131022', '', '', '固安县', '205'], [210, '131023', '', '', '永清县',
                '205'], [211, '131024', '', '', '香河县', '205'], [212, '131025', '', '', '大城县', '205'], [213,
                '131026', '', '', '文安县', '205'], [214, '131028', '', '', '大厂回族自治县', '205'], [215, '131081',
                '', '', '霸州市', '205'], [216, '131082', '', '', '三河市', '205']]
        },
        "衡水市": {
            id: 131100,
            value: [[218, '131101', '', '', '市辖区', '217'], [219, '131102', '', '', '桃城区', '217'], [220, '131121',
                '', '', '枣强县', '217'], [221, '131122', '', '', '武邑县', '217'], [222, '131123', '', '', '武强县',
                '217'], [223, '131124', '', '', '饶阳县', '217'], [224, '131125', '', '', '安平县', '217'], [225,
                '131126', '', '', '故城县', '217'], [226, '131127', '', '', '景县', '217'], [227, '131128', '',
                '', '阜城县', '217'], [228, '131181', '', '', '冀州市', '217'], [229, '131182', '', '', '深州市',
                '217']]
        }
    },
    "山西省": {
        "太原市": {
            id: 140100,
            value: [[232, '140101', '', '', '市辖区', '231'], [233, '140105', '', '', '小店区', '231'], [234, '140106',
                '', '', '迎泽区', '231'], [235, '140107', '', '', '杏花岭区', '231'], [236, '140108', '', '',
                '尖草坪区', '231'], [237, '140109', '', '', '万柏林区', '231'], [238, '140110', '', '', '晋源区',
                '231'], [239, '140121', '', '', '清徐县', '231'], [240, '140122', '', '', '阳曲县', '231'], [241,
                '140123', '', '', '娄烦县', '231'], [242, '140181', '', '', '古交市', '231']]
        },
        "大同市": {
            id: 140200,
            value: [[244, '140201', '', '', '市辖区', '243'], [245, '140202', '', '', '城区', '243'], [246, '140203', '',
                '', '矿区', '243'], [247, '140211', '', '', '南郊区', '243'], [248, '140212', '', '', '新荣区',
                '243'], [249, '140221', '', '', '阳高县', '243'], [250, '140222', '', '', '天镇县', '243'], [251,
                '140223', '', '', '广灵县', '243'], [252, '140224', '', '', '灵丘县', '243'], [253, '140225', '',
                '', '浑源县', '243'], [254, '140226', '', '', '左云县', '243'], [255, '140227', '', '', '大同县',
                '243']]
        },
        "阳泉市": {
            id: 140300,
            value: [[257, '140301', '', '', '市辖区', '256'], [258, '140302', '', '', '城区', '256'], [259, '140303', '',
                '', '矿区', '256'], [260, '140311', '', '', '郊区', '256'], [261, '140321', '', '', '平定县',
                '256'], [262, '140322', '', '', '盂县', '256']]
        },
        "长治市": {
            id: 140400,
            value: [[264, '140401', '', '', '市辖区', '263'], [265, '140402', '', '', '城区', '263'], [266, '140411', '',
                '', '郊区', '263'], [267, '140421', '', '', '长治县', '263'], [268, '140423', '', '', '襄垣县',
                '263'], [269, '140424', '', '', '屯留县', '263'], [270, '140425', '', '', '平顺县', '263'], [271,
                '140426', '', '', '黎城县', '263'], [272, '140427', '', '', '壶关县', '263'], [273, '140428', '',
                '', '长子县', '263'], [274, '140429', '', '', '武乡县', '263'], [275, '140430', '', '', '沁县',
                '263'], [276, '140431', '', '', '沁源县', '263'], [277, '140481', '', '', '潞城市', '263']]
        },
        "晋城市": {
            id: 140500,
            value: [[279, '140501', '', '', '市辖区', '278'], [280, '140502', '', '', '城区', '278'], [281, '140521', '',
                '', '沁水县', '278'], [282, '140522', '', '', '阳城县', '278'], [283, '140524', '', '', '陵川县',
                '278'], [284, '140525', '', '', '泽州县', '278'], [285, '140581', '', '', '高平市', '278']]
        },
        "朔州市": {
            id: 140600,
            value: [[287, '140601', '', '', '市辖区', '286'], [288, '140602', '', '', '朔城区', '286'], [289, '140603',
                '', '', '平鲁区', '286'], [290, '140621', '', '', '山阴县', '286'], [291, '140622', '', '', '应县',
                '286'], [292, '140623', '', '', '右玉县', '286'], [293, '140624', '', '', '怀仁县', '286']]
        },
        "晋中市": {
            id: 140700,
            value: [[295, '140701', '', '', '市辖区', '294'], [296, '140702', '', '', '榆次区', '294'], [297, '140721',
                '', '', '榆社县', '294'], [298, '140722', '', '', '左权县', '294'], [299, '140723', '', '', '和顺县',
                '294'], [300, '140724', '', '', '昔阳县', '294'], [301, '140725', '', '', '寿阳县', '294'], [302,
                '140726', '', '', '太谷县', '294'], [303, '140727', '', '', '祁县', '294'], [304, '140728', '',
                '', '平遥县', '294'], [305, '140729', '', '', '灵石县', '294'], [306, '140781', '', '', '介休市',
                '294']]
        },
        "运城市": {
            id: 140800,
            value: [[308, '140801', '', '', '市辖区', '307'], [309, '140802', '', '', '盐湖区', '307'], [310, '140821',
                '', '', '临猗县', '307'], [311, '140822', '', '', '万荣县', '307'], [312, '140823', '', '', '闻喜县',
                '307'], [313, '140824', '', '', '稷山县', '307'], [314, '140825', '', '', '新绛县', '307'], [315,
                '140826', '', '', '绛县', '307'], [316, '140827', '', '', '垣曲县', '307'], [317, '140828', '',
                '', '夏县', '307'], [318, '140829', '', '', '平陆县', '307'], [319, '140830', '', '', '芮城县',
                '307'], [320, '140881', '', '', '永济市', '307'], [321, '140882', '', '', '河津市', '307']]
        },
        "忻州市": {
            id: 140900,
            value: [[323, '140901', '', '', '市辖区', '322'], [324, '140902', '', '', '忻府区', '322'], [325, '140921',
                '', '', '定襄县', '322'], [326, '140922', '', '', '五台县', '322'], [327, '140923', '', '', '代县',
                '322'], [328, '140924', '', '', '繁峙县', '322'], [329, '140925', '', '', '宁武县', '322'], [330,
                '140926', '', '', '静乐县', '322'], [331, '140927', '', '', '神池县', '322'], [332, '140928', '',
                '', '五寨县', '322'], [333, '140929', '', '', '岢岚县', '322'], [334, '140930', '', '', '河曲县',
                '322'], [335, '140931', '', '', '保德县', '322'], [336, '140932', '', '', '偏关县', '322'], [337,
                '140981', '', '', '原平市', '322']]
        },
        "临汾市": {
            id: 141000,
            value: [[339, '141001', '', '', '市辖区', '338'], [340, '141002', '', '', '尧都区', '338'], [341, '141021',
                '', '', '曲沃县', '338'], [342, '141022', '', '', '翼城县', '338'], [343, '141023', '', '', '襄汾县',
                '338'], [344, '141024', '', '', '洪洞县', '338'], [345, '141025', '', '', '古县', '338'], [346,
                '141026', '', '', '安泽县', '338'], [347, '141027', '', '', '浮山县', '338'], [348, '141028', '',
                '', '吉县', '338'], [349, '141029', '', '', '乡宁县', '338'], [350, '141030', '', '', '大宁县',
                '338'], [351, '141031', '', '', '隰县', '338'], [352, '141032', '', '', '永和县', '338'], [353,
                '141033', '', '', '蒲县', '338'], [354, '141034', '', '', '汾西县', '338'], [355, '141081', '',
                '', '侯马市', '338'], [356, '141082', '', '', '霍州市', '338']]
        },
        "吕梁市": {
            id: 141100,
            value: [[358, '141101', '', '', '市辖区', '357'], [359, '141102', '', '', '离石区', '357'], [360, '141121',
                '', '', '文水县', '357'], [361, '141122', '', '', '交城县', '357'], [362, '141123', '', '', '兴县',
                '357'], [363, '141124', '', '', '临县', '357'], [364, '141125', '', '', '柳林县', '357'], [365,
                '141126', '', '', '石楼县', '357'], [366, '141127', '', '', '岚县', '357'], [367, '141128', '',
                '', '方山县', '357'], [368, '141129', '', '', '中阳县', '357'], [369, '141130', '', '', '交口县',
                '357'], [370, '141181', '', '', '孝义市', '357'], [371, '141182', '', '', '汾阳市', '357']]
        }
    },
    "内蒙古自治区": {
        "呼和浩特市": {
            id: 150100,
            value: [[374, '150101', '', '', '市辖区', '373'], [375, '150102', '', '', '新城区', '373'], [376, '150103',
                '', '', '回民区', '373'], [377, '150104', '', '', '玉泉区', '373'], [378, '150105', '', '', '赛罕区',
                '373'], [379, '150121', '', '', '土默特左旗', '373'], [380, '150122', '', '', '托克托县', '373'], [
                381, '150123', '', '', '和林格尔县', '373'], [382, '150124', '', '', '清水河县', '373'], [383,
                '150125', '', '', '武川县', '373']]
        },
        "包头市": {
            id: 150200,
            value: [[385, '150201', '', '', '市辖区', '384'], [386, '150202', '', '', '东河区', '384'], [387, '150203',
                '', '', '昆都仑区', '384'], [388, '150204', '', '', '青山区', '384'], [389, '150205', '', '',
                '石拐区', '384'], [390, '150206', '', '', '白云鄂博矿区', '384'], [391, '150207', '', '', '九原区',
                '384'], [392, '150221', '', '', '土默特右旗', '384'], [393, '150222', '', '', '固阳县', '384'], [
                394, '150223', '', '', '达尔罕茂明安联合旗', '384']]
        },
        "乌海市": {
            id: 150300,
            value: [[396, '150301', '', '', '市辖区', '395'], [397, '150302', '', '', '海勃湾区', '395'], [398, '150303',
                '', '', '海南区', '395'], [399, '150304', '', '', '乌达区', '395']]
        },
        "赤峰市": {
            id: 150400,
            value: [[401, '150401', '', '', '市辖区', '400'], [402, '150402', '', '', '红山区', '400'], [403, '150403',
                '', '', '元宝山区', '400'], [404, '150404', '', '', '松山区', '400'], [405, '150421', '', '',
                '阿鲁科尔沁旗', '400'], [406, '150422', '', '', '巴林左旗', '400'], [407, '150423', '', '', '巴林右旗',
                '400'], [408, '150424', '', '', '林西县', '400'], [409, '150425', '', '', '克什克腾旗', '400'], [
                410, '150426', '', '', '翁牛特旗', '400'], [411, '150428', '', '', '喀喇沁旗', '400'], [412,
                '150429', '', '', '宁城县', '400'], [413, '150430', '', '', '敖汉旗', '400']]
        },
        "通辽市": {
            id: 150500,
            value: [[415, '150501', '', '', '市辖区', '414'], [416, '150502', '', '', '科尔沁区', '414'], [417, '150521',
                '', '', '科尔沁左翼中旗', '414'], [418, '150522', '', '', '科尔沁左翼后旗', '414'], [419, '150523', '',
                '', '开鲁县', '414'], [420, '150524', '', '', '库伦旗', '414'], [421, '150525', '', '', '奈曼旗',
                '414'], [422, '150526', '', '', '扎鲁特旗', '414'], [423, '150581', '', '', '霍林郭勒市', '414']]
        },
        "鄂尔多斯市": {
            id: 150600,
            value: [[425, '150601', '', '', '市辖区', '424'], [426, '150602', '', '', '东胜区', '424'], [427, '150621',
                '', '', '达拉特旗', '424'], [428, '150622', '', '', '准格尔旗', '424'], [429, '150623', '', '',
                '鄂托克前旗', '424'], [430, '150624', '', '', '鄂托克旗', '424'], [431, '150625', '', '', '杭锦旗',
                '424'], [432, '150626', '', '', '乌审旗', '424'], [433, '150627', '', '', '伊金霍洛旗', '424']]
        },
        "呼伦贝尔市": {
            id: 150700,
            value: [[435, '150701', '', '', '市辖区', '434'], [436, '150702', '', '', '海拉尔区', '434'], [437, '150703',
                '', '', '扎赉诺尔区', '434'], [438, '150721', '', '', '阿荣旗', '434'], [439, '150722', '', '',
                '莫力达瓦达斡尔族自治旗', '434'], [440, '150723', '', '', '鄂伦春自治旗', '434'], [441, '150724', '', '',
                '鄂温克族自治旗', '434'], [442, '150725', '', '', '陈巴尔虎旗', '434'], [443, '150726', '', '',
                '新巴尔虎左旗', '434'], [444, '150727', '', '', '新巴尔虎右旗', '434'], [445, '150781', '', '', '满洲里市',
                '434'], [446, '150782', '', '', '牙克石市', '434'], [447, '150783', '', '', '扎兰屯市', '434'], [
                448, '150784', '', '', '额尔古纳市', '434'], [449, '150785', '', '', '根河市', '434']]
        },
        "巴彦淖尔市": {
            id: 150800,
            value: [[451, '150801', '', '', '市辖区', '450'], [452, '150802', '', '', '临河区', '450'], [453, '150821',
                '', '', '五原县', '450'], [454, '150822', '', '', '磴口县', '450'], [455, '150823', '', '',
                '乌拉特前旗', '450'], [456, '150824', '', '', '乌拉特中旗', '450'], [457, '150825', '', '', '乌拉特后旗',
                '450'], [458, '150826', '', '', '杭锦后旗', '450']]
        },
        "乌兰察布市": {
            id: 150900,
            value: [[460, '150901', '', '', '市辖区', '459'], [461, '150902', '', '', '集宁区', '459'], [462, '150921',
                '', '', '卓资县', '459'], [463, '150922', '', '', '化德县', '459'], [464, '150923', '', '', '商都县',
                '459'], [465, '150924', '', '', '兴和县', '459'], [466, '150925', '', '', '凉城县', '459'], [467,
                '150926', '', '', '察哈尔右翼前旗', '459'], [468, '150927', '', '', '察哈尔右翼中旗', '459'], [469,
                '150928', '', '', '察哈尔右翼后旗', '459'], [470, '150929', '', '', '四子王旗', '459'], [471, '150981',
                '', '', '丰镇市', '459']]
        },
        "兴安盟": {
            id: 152200,
            value: [[473, '152201', '', '', '乌兰浩特市', '472'], [474, '152202', '', '', '阿尔山市', '472'], [475, '152221',
                '', '', '科尔沁右翼前旗', '472'], [476, '152222', '', '', '科尔沁右翼中旗', '472'], [477, '152223', '',
                '', '扎赉特旗', '472'], [478, '152224', '', '', '突泉县', '472']]
        },
        "锡林郭勒盟": {
            id: 152500,
            value: [[480, '152501', '', '', '二连浩特市', '479'], [481, '152502', '', '', '锡林浩特市', '479'], [482,
                '152522', '', '', '阿巴嘎旗', '479'], [483, '152523', '', '', '苏尼特左旗', '479'], [484, '152524',
                '', '', '苏尼特右旗', '479'], [485, '152525', '', '', '东乌珠穆沁旗', '479'], [486, '152526', '', '',
                '西乌珠穆沁旗', '479'], [487, '152527', '', '', '太仆寺旗', '479'], [488, '152528', '', '', '镶黄旗',
                '479'], [489, '152529', '', '', '正镶白旗', '479'], [490, '152530', '', '', '正蓝旗', '479'], [491,
                '152531', '', '', '多伦县', '479']]
        },
        "阿拉善盟": {
            id: 152900,
            value: [[493, '152921', '', '', '阿拉善左旗', '492'], [494, '152922', '', '', '阿拉善右旗', '492'], [495,
                '152923', '', '', '额济纳旗', '492']]
        }
    },
    "辽宁省": {
        "沈阳市": {
            id: 210100,
            value: [[498, '210101', '', '', '市辖区', '497'], [499, '210102', '', '', '和平区', '497'], [500, '210103',
                '', '', '沈河区', '497'], [501, '210104', '', '', '大东区', '497'], [502, '210105', '', '', '皇姑区',
                '497'], [503, '210106', '', '', '铁西区', '497'], [504, '210111', '', '', '苏家屯区', '497'], [505,
                '210112', '', '', '浑南区', '497'], [506, '210113', '', '', '沈北新区', '497'], [507, '210114', '',
                '', '于洪区', '497'], [508, '210122', '', '', '辽中县', '497'], [509, '210123', '', '', '康平县',
                '497'], [510, '210124', '', '', '法库县', '497'], [511, '210181', '', '', '新民市', '497']]
        },
        "大连市": {
            id: 210200,
            value: [[513, '210201', '', '', '市辖区', '512'], [514, '210202', '', '', '中山区', '512'], [515, '210203',
                '', '', '西岗区', '512'], [516, '210204', '', '', '沙河口区', '512'], [517, '210211', '', '',
                '甘井子区', '512'], [518, '210212', '', '', '旅顺口区', '512'], [519, '210213', '', '', '金州区',
                '512'], [520, '210224', '', '', '长海县', '512'], [521, '210281', '', '', '瓦房店市', '512'], [522,
                '210282', '', '', '普兰店市', '512'], [523, '210283', '', '', '庄河市', '512']]
        },
        "鞍山市": {
            id: 210300,
            value: [[525, '210301', '', '', '市辖区', '524'], [526, '210302', '', '', '铁东区', '524'], [527, '210303',
                '', '', '铁西区', '524'], [528, '210304', '', '', '立山区', '524'], [529, '210311', '', '', '千山区',
                '524'], [530, '210321', '', '', '台安县', '524'], [531, '210323', '', '', '岫岩满族自治县', '524'], [
                532, '210381', '', '', '海城市', '524']]
        },
        "抚顺市": {
            id: 210400,
            value: [[534, '210401', '', '', '市辖区', '533'], [535, '210402', '', '', '新抚区', '533'], [536, '210403',
                '', '', '东洲区', '533'], [537, '210404', '', '', '望花区', '533'], [538, '210411', '', '', '顺城区',
                '533'], [539, '210421', '', '', '抚顺县', '533'], [540, '210422', '', '', '新宾满族自治县', '533'], [
                541, '210423', '', '', '清原满族自治县', '533']]
        },
        "本溪市": {
            id: 210500,
            value: [[543, '210501', '', '', '市辖区', '542'], [544, '210502', '', '', '平山区', '542'], [545, '210503',
                '', '', '溪湖区', '542'], [546, '210504', '', '', '明山区', '542'], [547, '210505', '', '', '南芬区',
                '542'], [548, '210521', '', '', '本溪满族自治县', '542'], [549, '210522', '', '', '桓仁满族自治县', '542']]
        },
        "丹东市": {
            id: 210600,
            value: [[551, '210601', '', '', '市辖区', '550'], [552, '210602', '', '', '元宝区', '550'], [553, '210603',
                '', '', '振兴区', '550'], [554, '210604', '', '', '振安区', '550'], [555, '210624', '', '',
                '宽甸满族自治县', '550'], [556, '210681', '', '', '东港市', '550'], [557, '210682', '', '', '凤城市',
                '550']]
        },
        "锦州市": {
            id: 210700,
            value: [[559, '210701', '', '', '市辖区', '558'], [560, '210702', '', '', '古塔区', '558'], [561, '210703',
                '', '', '凌河区', '558'], [562, '210711', '', '', '太和区', '558'], [563, '210726', '', '', '黑山县',
                '558'], [564, '210727', '', '', '义县', '558'], [565, '210781', '', '', '凌海市', '558'], [566,
                '210782', '', '', '北镇市', '558']]
        },
        "营口市": {
            id: 210800,
            value: [[568, '210801', '', '', '市辖区', '567'], [569, '210802', '', '', '站前区', '567'], [570, '210803',
                '', '', '西市区', '567'], [571, '210804', '', '', '鲅鱼圈区', '567'], [572, '210811', '', '',
                '老边区', '567'], [573, '210881', '', '', '盖州市', '567'], [574, '210882', '', '', '大石桥市', '567']]
        },
        "阜新市": {
            id: 210900,
            value: [[576, '210901', '', '', '市辖区', '575'], [577, '210902', '', '', '海州区', '575'], [578, '210903',
                '', '', '新邱区', '575'], [579, '210904', '', '', '太平区', '575'], [580, '210905', '', '',
                '清河门区', '575'], [581, '210911', '', '', '细河区', '575'], [582, '210921', '', '', '阜新蒙古族自治县',
                '575'], [583, '210922', '', '', '彰武县', '575']]
        },
        "辽阳市": {
            id: 211000,
            value: [[585, '211001', '', '', '市辖区', '584'], [586, '211002', '', '', '白塔区', '584'], [587, '211003',
                '', '', '文圣区', '584'], [588, '211004', '', '', '宏伟区', '584'], [589, '211005', '', '',
                '弓长岭区', '584'], [590, '211011', '', '', '太子河区', '584'], [591, '211021', '', '', '辽阳县',
                '584'], [592, '211081', '', '', '灯塔市', '584']]
        },
        "盘锦市": {
            id: 211100,
            value: [[594, '211101', '', '', '市辖区', '593'], [595, '211102', '', '', '双台子区', '593'], [596, '211103',
                '', '', '兴隆台区', '593'], [597, '211121', '', '', '大洼县', '593'], [598, '211122', '', '',
                '盘山县', '593']]
        },
        "铁岭市": {
            id: 211200,
            value: [[600, '211201', '', '', '市辖区', '599'], [601, '211202', '', '', '银州区', '599'], [602, '211204',
                '', '', '清河区', '599'], [603, '211221', '', '', '铁岭县', '599'], [604, '211223', '', '', '西丰县',
                '599'], [605, '211224', '', '', '昌图县', '599'], [606, '211281', '', '', '调兵山市', '599'], [607,
                '211282', '', '', '开原市', '599']]
        },
        "朝阳市": {
            id: 211300,
            value: [[609, '211301', '', '', '市辖区', '608'], [610, '211302', '', '', '双塔区', '608'], [611, '211303',
                    '', '', '龙城区', '608'], [612, '211321', '', '', '朝阳县', '608'], [613, '211322', '', '', '建平县',
                    '608'], [614, '211324', '', '', '喀喇沁左翼蒙古族自治县', '608'], [615, '211381', '', '', '北票市', '608'],
                [616, '211382', '', '', '凌源市', '608']]
        },
        "葫芦岛市": {
            id: 211400,
            value: [[618, '211401', '', '', '市辖区', '617'], [619, '211402', '', '', '连山区', '617'], [620, '211403',
                '', '', '龙港区', '617'], [621, '211404', '', '', '南票区', '617'], [622, '211421', '', '', '绥中县',
                '617'], [623, '211422', '', '', '建昌县', '617'], [624, '211481', '', '', '兴城市', '617']]
        }
    },
    "吉林省": {
        "长春市": {
            id: 220100,
            value: [[627, '220101', '', '', '市辖区', '626'], [628, '220102', '', '', '南关区', '626'], [629, '220103',
                '', '', '宽城区', '626'], [630, '220104', '', '', '朝阳区', '626'], [631, '220105', '', '', '二道区',
                '626'], [632, '220106', '', '', '绿园区', '626'], [633, '220112', '', '', '双阳区', '626'], [634,
                '220113', '', '', '九台区', '626'], [635, '220122', '', '', '农安县', '626'], [636, '220182', '',
                '', '榆树市', '626'], [637, '220183', '', '', '德惠市', '626']]
        },
        "吉林市": {
            id: 220200,
            value: [[639, '220201', '', '', '市辖区', '638'], [640, '220202', '', '', '昌邑区', '638'], [641, '220203',
                '', '', '龙潭区', '638'], [642, '220204', '', '', '船营区', '638'], [643, '220211', '', '', '丰满区',
                '638'], [644, '220221', '', '', '永吉县', '638'], [645, '220281', '', '', '蛟河市', '638'], [646,
                '220282', '', '', '桦甸市', '638'], [647, '220283', '', '', '舒兰市', '638'], [648, '220284', '',
                '', '磐石市', '638']]
        },
        "四平市": {
            id: 220300,
            value: [[650, '220301', '', '', '市辖区', '649'], [651, '220302', '', '', '铁西区', '649'], [652, '220303',
                '', '', '铁东区', '649'], [653, '220322', '', '', '梨树县', '649'], [654, '220323', '', '',
                '伊通满族自治县', '649'], [655, '220381', '', '', '公主岭市', '649'], [656, '220382', '', '', '双辽市',
                '649']]
        },
        "辽源市": {
            id: 220400,
            value: [[658, '220401', '', '', '市辖区', '657'], [659, '220402', '', '', '龙山区', '657'], [660, '220403',
                '', '', '西安区', '657'], [661, '220421', '', '', '东丰县', '657'], [662, '220422', '', '', '东辽县',
                '657']]
        },
        "通化市": {
            id: 220500,
            value: [[664, '220501', '', '', '市辖区', '663'], [665, '220502', '', '', '东昌区', '663'], [666, '220503',
                    '', '', '二道江区', '663'], [667, '220521', '', '', '通化县', '663'], [668, '220523', '', '',
                    '辉南县', '663'], [669, '220524', '', '', '柳河县', '663'], [670, '220581', '', '', '梅河口市', '663'],
                [671, '220582', '', '', '集安市', '663']]
        },
        "白山市": {
            id: 220600,
            value: [[673, '220601', '', '', '市辖区', '672'], [674, '220602', '', '', '浑江区', '672'], [675, '220605',
                '', '', '江源区', '672'], [676, '220621', '', '', '抚松县', '672'], [677, '220622', '', '', '靖宇县',
                '672'], [678, '220623', '', '', '长白朝鲜族自治县', '672'], [679, '220681', '', '', '临江市', '672']]
        },
        "松原市": {
            id: 220700,
            value: [[681, '220701', '', '', '市辖区', '680'], [682, '220702', '', '', '宁江区', '680'], [683, '220721',
                '', '', '前郭尔罗斯蒙古族自治县', '680'], [684, '220722', '', '', '长岭县', '680'], [685, '220723', '',
                '', '乾安县', '680'], [686, '220781', '', '', '扶余市', '680']]
        },
        "白城市": {
            id: 220800,
            value: [[688, '220801', '', '', '市辖区', '687'], [689, '220802', '', '', '洮北区', '687'], [690, '220821',
                '', '', '镇赉县', '687'], [691, '220822', '', '', '通榆县', '687'], [692, '220881', '', '', '洮南市',
                '687'], [693, '220882', '', '', '大安市', '687']]
        },
        "延边朝鲜族自治州": {
            id: 220900,
            value: [[695, '222401', '', '', '延吉市', '694'], [696, '222402', '', '', '图们市', '694'], [697, '222403',
                '', '', '敦化市', '694'], [698, '222404', '', '', '珲春市', '694'], [699, '222405', '', '', '龙井市',
                '694'], [700, '222406', '', '', '和龙市', '694'], [701, '222424', '', '', '汪清县', '694'], [702,
                '222426', '', '', '安图县', '694']]
        }
    },
    "黑龙江省": {
        "哈尔滨市": {
            id: 230100,
            value: [[705, '230101', '', '', '市辖区', '704'], [706, '230102', '', '', '道里区', '704'], [707, '230103',
                '', '', '南岗区', '704'], [708, '230104', '', '', '道外区', '704'], [709, '230108', '', '', '平房区',
                '704'], [710, '230109', '', '', '松北区', '704'], [711, '230110', '', '', '香坊区', '704'], [712,
                '230111', '', '', '呼兰区', '704'], [713, '230112', '', '', '阿城区', '704'], [714, '230113', '',
                '', '双城区', '704'], [715, '230123', '', '', '依兰县', '704'], [716, '230124', '', '', '方正县',
                '704'], [717, '230125', '', '', '宾县', '704'], [718, '230126', '', '', '巴彦县', '704'], [719,
                '230127', '', '', '木兰县', '704'], [720, '230128', '', '', '通河县', '704'], [721, '230129', '',
                '', '延寿县', '704'], [722, '230183', '', '', '尚志市', '704'], [723, '230184', '', '', '五常市',
                '704']]
        },
        "齐齐哈尔市": {
            id: 230200,
            value: [[725, '230201', '', '', '市辖区', '724'], [726, '230202', '', '', '龙沙区', '724'], [727, '230203',
                    '', '', '建华区', '724'], [728, '230204', '', '', '铁锋区', '724'], [729, '230205', '', '',
                    '昂昂溪区', '724'], [730, '230206', '', '', '富拉尔基区', '724'], [731, '230207', '', '', '碾子山区',
                    '724'], [732, '230208', '', '', '梅里斯达斡尔族区', '724'], [733, '230221', '', '', '龙江县', '724'],
                [734, '230223', '', '', '依安县', '724'], [735, '230224', '', '', '泰来县', '724'], [736, '230225',
                    '', '', '甘南县', '724'], [737, '230227', '', '', '富裕县', '724'], [738, '230229', '', '', '克山县',
                    '724'], [739, '230230', '', '', '克东县', '724'], [740, '230231', '', '', '拜泉县', '724'], [741,
                    '230281', '', '', '讷河市', '724']]
        },
        "鸡西市": {
            id: 230300,
            value: [[743, '230301', '', '', '市辖区', '742'], [744, '230302', '', '', '鸡冠区', '742'], [745, '230303',
                '', '', '恒山区', '742'], [746, '230304', '', '', '滴道区', '742'], [747, '230305', '', '', '梨树区',
                '742'], [748, '230306', '', '', '城子河区', '742'], [749, '230307', '', '', '麻山区', '742'], [750,
                '230321', '', '', '鸡东县', '742'], [751, '230381', '', '', '虎林市', '742'], [752, '230382', '',
                '', '密山市', '742']]
        },
        "鹤岗市": {
            id: 230400,
            value: [[754, '230401', '', '', '市辖区', '753'], [755, '230402', '', '', '向阳区', '753'], [756, '230403',
                '', '', '工农区', '753'], [757, '230404', '', '', '南山区', '753'], [758, '230405', '', '', '兴安区',
                '753'], [759, '230406', '', '', '东山区', '753'], [760, '230407', '', '', '兴山区', '753'], [761,
                '230421', '', '', '萝北县', '753'], [762, '230422', '', '', '绥滨县', '753']]
        },
        "双鸭山市": {
            id: 230500,
            value: [[764, '230501', '', '', '市辖区', '763'], [765, '230502', '', '', '尖山区', '763'], [766, '230503',
                    '', '', '岭东区', '763'], [767, '230505', '', '', '四方台区', '763'], [768, '230506', '', '',
                    '宝山区', '763'], [769, '230521', '', '', '集贤县', '763'], [770, '230522', '', '', '友谊县', '763'],
                [771, '230523', '', '', '宝清县', '763'], [772, '230524', '', '', '饶河县', '763']]
        },
        "大庆市": {
            id: 230600,
            value: [[774, '230601', '', '', '市辖区', '773'], [775, '230602', '', '', '萨尔图区', '773'], [776, '230603',
                    '', '', '龙凤区', '773'], [777, '230604', '', '', '让胡路区', '773'], [778, '230605', '', '',
                    '红岗区', '773'], [779, '230606', '', '', '大同区', '773'], [780, '230621', '', '', '肇州县', '773'],
                [781, '230622', '', '', '肇源县', '773'], [782, '230623', '', '', '林甸县', '773'], [783, '230624',
                    '', '', '杜尔伯特蒙古族自治县', '773']]
        },
        "伊春市": {
            id: 230700,
            value: [[785, '230701', '', '', '市辖区', '784'], [786, '230702', '', '', '伊春区', '784'], [787, '230703',
                '', '', '南岔区', '784'], [788, '230704', '', '', '友好区', '784'], [789, '230705', '', '', '西林区',
                '784'], [790, '230706', '', '', '翠峦区', '784'], [791, '230707', '', '', '新青区', '784'], [792,
                '230708', '', '', '美溪区', '784'], [793, '230709', '', '', '金山屯区', '784'], [794, '230710', '',
                '', '五营区', '784'], [795, '230711', '', '', '乌马河区', '784'], [796, '230712', '', '', '汤旺河区',
                '784'], [797, '230713', '', '', '带岭区', '784'], [798, '230714', '', '', '乌伊岭区', '784'], [799,
                '230715', '', '', '红星区', '784'], [800, '230716', '', '', '上甘岭区', '784'], [801, '230722', '',
                '', '嘉荫县', '784'], [802, '230781', '', '', '铁力市', '784']]
        },
        "佳木斯市": {
            id: 230800,
            value: [[804, '230801', '', '', '市辖区', '803'], [805, '230803', '', '', '向阳区', '803'], [806, '230804',
                '', '', '前进区', '803'], [807, '230805', '', '', '东风区', '803'], [808, '230811', '', '', '郊区',
                '803'], [809, '230822', '', '', '桦南县', '803'], [810, '230826', '', '', '桦川县', '803'], [811,
                '230828', '', '', '汤原县', '803'], [812, '230833', '', '', '抚远县', '803'], [813, '230881', '',
                '', '同江市', '803'], [814, '230882', '', '', '富锦市', '803']]
        },
        "七台河市": {
            id: 230900,
            value: [[816, '230901', '', '', '市辖区', '815'], [817, '230902', '', '', '新兴区', '815'], [818, '230903',
                '', '', '桃山区', '815'], [819, '230904', '', '', '茄子河区', '815'], [820, '230921', '', '',
                '勃利县', '815']]
        },
        "牡丹江市": {
            id: 231000,
            value: [[822, '231001', '', '', '市辖区', '821'], [823, '231002', '', '', '东安区', '821'], [824, '231003',
                '', '', '阳明区', '821'], [825, '231004', '', '', '爱民区', '821'], [826, '231005', '', '', '西安区',
                '821'], [827, '231024', '', '', '东宁县', '821'], [828, '231025', '', '', '林口县', '821'], [829,
                '231081', '', '', '绥芬河市', '821'], [830, '231083', '', '', '海林市', '821'], [831, '231084', '',
                '', '宁安市', '821'], [832, '231085', '', '', '穆棱市', '821']]
        },
        "黑河市": {
            id: 231100,
            value: [[834, '231101', '', '', '市辖区', '833'], [835, '231102', '', '', '爱辉区', '833'], [836, '231121',
                '', '', '嫩江县', '833'], [837, '231123', '', '', '逊克县', '833'], [838, '231124', '', '', '孙吴县',
                '833'], [839, '231181', '', '', '北安市', '833'], [840, '231182', '', '', '五大连池市', '833']]
        },
        "绥化市": {
            id: 231200,
            value: [[842, '231201', '', '', '市辖区', '841'], [843, '231202', '', '', '北林区', '841'], [844, '231221',
                '', '', '望奎县', '841'], [845, '231222', '', '', '兰西县', '841'], [846, '231223', '', '', '青冈县',
                '841'], [847, '231224', '', '', '庆安县', '841'], [848, '231225', '', '', '明水县', '841'], [849,
                '231226', '', '', '绥棱县', '841'], [850, '231281', '', '', '安达市', '841'], [851, '231282', '',
                '', '肇东市', '841'], [852, '231283', '', '', '海伦市', '841']]
        },
        "大兴安岭地区": {
            id: 232700,
            value: [[854, '232721', '', '', '呼玛县', '853'], [855, '232722', '', '', '塔河县', '853'], [856, '232723',
                '', '', '漠河县', '853']]
        }
    },
    "上海市": {
        "上海市": {
            id: 310000,
            value: [[858, '310101', '', '', '黄浦区', '857'], [859, '310104', '', '', '徐汇区', '857'], [860, '310105',
                '', '', '长宁区', '857'], [861, '310106', '', '', '静安区', '857'], [862, '310107', '', '', '普陀区',
                '857'], [863, '310108', '', '', '闸北区', '857'], [864, '310109', '', '', '虹口区', '857'], [865,
                '310110', '', '', '杨浦区', '857'], [866, '310112', '', '', '闵行区', '857'], [867, '310113', '',
                '', '宝山区', '857'], [868, '310114', '', '', '嘉定区', '857'], [869, '310115', '', '', '浦东新区',
                '857'], [870, '310116', '', '', '金山区', '857'], [871, '310117', '', '', '松江区', '857'], [872,
                '310118', '', '', '青浦区', '857'], [873, '310120', '', '', '奉贤区', '857'], [874, '310230', '',
                '', '崇明县', '857']]
        }
    },
    "江苏省": {
        "南京市": {
            id: 320100,
            value: [[877, '320101', '', '', '市辖区', '876'], [878, '320102', '', '', '玄武区', '876'], [879, '320104',
                '', '', '秦淮区', '876'], [880, '320105', '', '', '建邺区', '876'], [881, '320106', '', '', '鼓楼区',
                '876'], [882, '320111', '', '', '浦口区', '876'], [883, '320113', '', '', '栖霞区', '876'], [884,
                '320114', '', '', '雨花台区', '876'], [885, '320115', '', '', '江宁区', '876'], [886, '320116', '',
                '', '六合区', '876'], [887, '320117', '', '', '溧水区', '876'], [888, '320118', '', '', '高淳区',
                '876']]
        },
        "无锡市": {
            id: 320200,
            value: [[890, '320201', '', '', '市辖区', '889'], [891, '320202', '', '', '崇安区', '889'], [892, '320203',
                '', '', '南长区', '889'], [893, '320204', '', '', '北塘区', '889'], [894, '320205', '', '', '锡山区',
                '889'], [895, '320206', '', '', '惠山区', '889'], [896, '320211', '', '', '滨湖区', '889'], [897,
                '320281', '', '', '江阴市', '889'], [898, '320282', '', '', '宜兴市', '889']]
        },
        "徐州市": {
            id: 320300,
            value: [[900, '320301', '', '', '市辖区', '899'], [901, '320302', '', '', '鼓楼区', '899'], [902, '320303',
                '', '', '云龙区', '899'], [903, '320305', '', '', '贾汪区', '899'], [904, '320311', '', '', '泉山区',
                '899'], [905, '320312', '', '', '铜山区', '899'], [906, '320321', '', '', '丰县', '899'], [907,
                '320322', '', '', '沛县', '899'], [908, '320324', '', '', '睢宁县', '899'], [909, '320381', '',
                '', '新沂市', '899'], [910, '320382', '', '', '邳州市', '899']]
        },
        "常州市": {
            id: 320400,
            value: [[912, '320401', '', '', '市辖区', '911'], [913, '320402', '', '', '天宁区', '911'], [914, '320404',
                    '', '', '钟楼区', '911'], [915, '320405', '', '', '戚墅堰区', '911'], [916, '320411', '', '',
                    '新北区', '911'], [917, '320412', '', '', '武进区', '911'], [918, '320481', '', '', '溧阳市', '911'],
                [919, '320482', '', '', '金坛市', '911']]
        },
        "苏州市": {
            id: 320500,
            value: [[921, '320501', '', '', '市辖区', '920'], [922, '320505', '', '', '虎丘区', '920'], [923, '320506',
                '', '', '吴中区', '920'], [924, '320507', '', '', '相城区', '920'], [925, '320508', '', '', '姑苏区',
                '920'], [926, '320509', '', '', '吴江区', '920'], [927, '320581', '', '', '常熟市', '920'], [928,
                '320582', '', '', '张家港市', '920'], [929, '320583', '', '', '昆山市', '920'], [930, '320585', '',
                '', '太仓市', '920']]
        },
        "南通市": {
            id: 320600,
            value: [[932, '320601', '', '', '市辖区', '931'], [933, '320602', '', '', '崇川区', '931'], [934, '320611',
                '', '', '港闸区', '931'], [935, '320612', '', '', '通州区', '931'], [936, '320621', '', '', '海安县',
                '931'], [937, '320623', '', '', '如东县', '931'], [938, '320681', '', '', '启东市', '931'], [939,
                '320682', '', '', '如皋市', '931'], [940, '320684', '', '', '海门市', '931']]
        },
        "连云港市": {
            id: 320700,
            value: [[942, '320701', '', '', '市辖区', '941'], [943, '320703', '', '', '连云区', '941'], [944, '320706',
                '', '', '海州区', '941'], [945, '320707', '', '', '赣榆区', '941'], [946, '320722', '', '', '东海县',
                '941'], [947, '320723', '', '', '灌云县', '941'], [948, '320724', '', '', '灌南县', '941']]
        },
        "淮安市": {
            id: 320800,
            value: [[950, '320801', '', '', '市辖区', '949'], [951, '320802', '', '', '清河区', '949'], [952, '320803',
                '', '', '淮安区', '949'], [953, '320804', '', '', '淮阴区', '949'], [954, '320811', '', '', '清浦区',
                '949'], [955, '320826', '', '', '涟水县', '949'], [956, '320829', '', '', '洪泽县', '949'], [957,
                '320830', '', '', '盱眙县', '949'], [958, '320831', '', '', '金湖县', '949']]
        },
        "盐城市": {
            id: 320900,
            value: [[960, '320901', '', '', '市辖区', '959'], [961, '320902', '', '', '亭湖区', '959'], [962, '320903',
                '', '', '盐都区', '959'], [963, '320921', '', '', '响水县', '959'], [964, '320922', '', '', '滨海县',
                '959'], [965, '320923', '', '', '阜宁县', '959'], [966, '320924', '', '', '射阳县', '959'], [967,
                '320925', '', '', '建湖县', '959'], [968, '320981', '', '', '东台市', '959'], [969, '320982', '',
                '', '大丰市', '959']]
        },
        "扬州市": {
            id: 321000,
            value: [[971, '321001', '', '', '市辖区', '970'], [972, '321002', '', '', '广陵区', '970'], [973, '321003',
                '', '', '邗江区', '970'], [974, '321012', '', '', '江都区', '970'], [975, '321023', '', '', '宝应县',
                '970'], [976, '321081', '', '', '仪征市', '970'], [977, '321084', '', '', '高邮市', '970']]
        },
        "镇江市": {
            id: 321100,
            value: [[979, '321101', '', '', '市辖区', '978'], [980, '321102', '', '', '京口区', '978'], [981, '321111',
                '', '', '润州区', '978'], [982, '321112', '', '', '丹徒区', '978'], [983, '321181', '', '', '丹阳市',
                '978'], [984, '321182', '', '', '扬中市', '978'], [985, '321183', '', '', '句容市', '978']]
        },
        "泰州市": {
            id: 321200,
            value: [[987, '321201', '', '', '市辖区', '986'], [988, '321202', '', '', '海陵区', '986'], [989, '321203',
                '', '', '高港区', '986'], [990, '321204', '', '', '姜堰区', '986'], [991, '321281', '', '', '兴化市',
                '986'], [992, '321282', '', '', '靖江市', '986'], [993, '321283', '', '', '泰兴市', '986']]
        },
        "宿迁市": {
            id: 321300,
            value: [[995, '321301', '', '', '市辖区', '994'], [996, '321302', '', '', '宿城区', '994'], [997, '321311',
                '', '', '宿豫区', '994'], [998, '321322', '', '', '沭阳县', '994'], [999, '321323', '', '', '泗阳县',
                '994'], [1000, '321324', '', '', '泗洪县', '994']]
        }
    },
    "浙江省": {
        "杭州市": {
            id: 330100,
            value: [[1003, '330101', '', '', '市辖区', '1002'], [1004, '330102', '', '', '上城区', '1002'], [1005,
                    '330103', '', '', '下城区', '1002'], [1006, '330104', '', '', '江干区', '1002'], [1007, '330105',
                    '', '', '拱墅区', '1002'], [1008, '330106', '', '', '西湖区', '1002'], [1009, '330108', '', '',
                    '滨江区', '1002'], [1010, '330109', '', '', '萧山区', '1002'], [1011, '330110', '', '', '余杭区',
                    '1002'], [1012, '330111', '', '', '富阳区', '1002'], [1013, '330122', '', '', '桐庐县', '1002'],
                [1014, '330127', '', '', '淳安县', '1002'], [1015, '330182', '', '', '建德市', '1002'], [1016,
                    '330185', '', '', '临安市', '1002']]
        },
        "宁波市": {
            id: 330200,
            value: [[1018, '330201', '', '', '市辖区', '1017'], [1019, '330203', '', '', '海曙区', '1017'], [1020,
                    '330204', '', '', '江东区', '1017'], [1021, '330205', '', '', '江北区', '1017'], [1022, '330206',
                    '', '', '北仑区', '1017'], [1023, '330211', '', '', '镇海区', '1017'], [1024, '330212', '', '',
                    '鄞州区', '1017'], [1025, '330225', '', '', '象山县', '1017'], [1026, '330226', '', '', '宁海县',
                    '1017'], [1027, '330281', '', '', '余姚市', '1017'], [1028, '330282', '', '', '慈溪市', '1017'],
                [1029, '330283', '', '', '奉化市', '1017']]
        },
        "温州市": {
            id: 330300,
            value: [[1031, '330301', '', '', '市辖区', '1030'], [1032, '330302', '', '', '鹿城区', '1030'], [1033,
                    '330303', '', '', '龙湾区', '1030'], [1034, '330304', '', '', '瓯海区', '1030'], [1035, '330322',
                    '', '', '洞头县', '1030'], [1036, '330324', '', '', '永嘉县', '1030'], [1037, '330326', '', '',
                    '平阳县', '1030'], [1038, '330327', '', '', '苍南县', '1030'], [1039, '330328', '', '', '文成县',
                    '1030'], [1040, '330329', '', '', '泰顺县', '1030'], [1041, '330381', '', '', '瑞安市', '1030'],
                [1042, '330382', '', '', '乐清市', '1030']]
        },
        "嘉兴市": {
            id: 330400,
            value: [[1044, '330401', '', '', '市辖区', '1043'], [1045, '330402', '', '', '南湖区', '1043'], [1046,
                '330411', '', '', '秀洲区', '1043'], [1047, '330421', '', '', '嘉善县', '1043'], [1048, '330424',
                '', '', '海盐县', '1043'], [1049, '330481', '', '', '海宁市', '1043'], [1050, '330482', '', '',
                '平湖市', '1043'], [1051, '330483', '', '', '桐乡市', '1043']]
        },
        "湖州市": {
            id: 330500,
            value: [[1053, '330501', '', '', '市辖区', '1052'], [1054, '330502', '', '', '吴兴区', '1052'], [1055,
                '330503', '', '', '南浔区', '1052'], [1056, '330521', '', '', '德清县', '1052'], [1057, '330522',
                '', '', '长兴县', '1052'], [1058, '330523', '', '', '安吉县', '1052']]
        },
        "绍兴市": {
            id: 330600,
            value: [[1060, '330601', '', '', '市辖区', '1059'], [1061, '330602', '', '', '越城区', '1059'], [1062,
                '330603', '', '', '柯桥区', '1059'], [1063, '330604', '', '', '上虞区', '1059'], [1064, '330624',
                '', '', '新昌县', '1059'], [1065, '330681', '', '', '诸暨市', '1059'], [1066, '330683', '', '',
                '嵊州市', '1059']]
        },
        "金华市": {
            id: 330700,
            value: [[1068, '330701', '', '', '市辖区', '1067'], [1069, '330702', '', '', '婺城区', '1067'], [1070,
                '330703', '', '', '金东区', '1067'], [1071, '330723', '', '', '武义县', '1067'], [1072, '330726',
                '', '', '浦江县', '1067'], [1073, '330727', '', '', '磐安县', '1067'], [1074, '330781', '', '',
                '兰溪市', '1067'], [1075, '330782', '', '', '义乌市', '1067'], [1076, '330783', '', '', '东阳市',
                '1067'], [1077, '330784', '', '', '永康市', '1067']]
        },
        "衢州市": {
            id: 330800,
            value: [[1079, '330801', '', '', '市辖区', '1078'], [1080, '330802', '', '', '柯城区', '1078'], [1081,
                '330803', '', '', '衢江区', '1078'], [1082, '330822', '', '', '常山县', '1078'], [1083, '330824',
                '', '', '开化县', '1078'], [1084, '330825', '', '', '龙游县', '1078'], [1085, '330881', '', '',
                '江山市', '1078']]
        },
        "舟山市": {
            id: 330900,
            value: [[1087, '330901', '', '', '市辖区', '1086'], [1088, '330902', '', '', '定海区', '1086'], [1089,
                '330903', '', '', '普陀区', '1086'], [1090, '330921', '', '', '岱山县', '1086'], [1091, '330922',
                '', '', '嵊泗县', '1086']]
        },
        "台州市": {
            id: 331000,
            value: [[1093, '331001', '', '', '市辖区', '1092'], [1094, '331002', '', '', '椒江区', '1092'], [1095,
                '331003', '', '', '黄岩区', '1092'], [1096, '331004', '', '', '路桥区', '1092'], [1097, '331021',
                '', '', '玉环县', '1092'], [1098, '331022', '', '', '三门县', '1092'], [1099, '331023', '', '',
                '天台县', '1092'], [1100, '331024', '', '', '仙居县', '1092'], [1101, '331081', '', '', '温岭市',
                '1092'], [1102, '331082', '', '', '临海市', '1092']]
        },
        "丽水市": {
            id: 331100,
            value: [[1104, '331101', '', '', '市辖区', '1103'], [1105, '331102', '', '', '莲都区', '1103'], [1106,
                '331121', '', '', '青田县', '1103'], [1107, '331122', '', '', '缙云县', '1103'], [1108, '331123',
                '', '', '遂昌县', '1103'], [1109, '331124', '', '', '松阳县', '1103'], [1110, '331125', '', '',
                '云和县', '1103'], [1111, '331126', '', '', '庆元县', '1103'], [1112, '331127', '', '', '景宁畲族自治县',
                '1103'], [1113, '331181', '', '', '龙泉市', '1103']]
        }
    },
    "安徽省": {
        "合肥市": {
            id: 340100,
            value: [[1116, '340101', '', '', '市辖区', '1115'], [1117, '340102', '', '', '瑶海区', '1115'], [1118,
                '340103', '', '', '庐阳区', '1115'], [1119, '340104', '', '', '蜀山区', '1115'], [1120, '340111',
                '', '', '包河区', '1115'], [1121, '340121', '', '', '长丰县', '1115'], [1122, '340122', '', '',
                '肥东县', '1115'], [1123, '340123', '', '', '肥西县', '1115'], [1124, '340124', '', '', '庐江县',
                '1115'], [1125, '340181', '', '', '巢湖市', '1115']]
        },
        "芜湖市": {
            id: 340200,
            value: [[1127, '340201', '', '', '市辖区', '1126'], [1128, '340202', '', '', '镜湖区', '1126'], [1129,
                '340203', '', '', '弋江区', '1126'], [1130, '340207', '', '', '鸠江区', '1126'], [1131, '340208',
                '', '', '三山区', '1126'], [1132, '340221', '', '', '芜湖县', '1126'], [1133, '340222', '', '',
                '繁昌县', '1126'], [1134, '340223', '', '', '南陵县', '1126'], [1135, '340225', '', '', '无为县',
                '1126']]
        },
        "蚌埠市": {
            id: 340300,
            value: [[1137, '340301', '', '', '市辖区', '1136'], [1138, '340302', '', '', '龙子湖区', '1136'], [1139,
                '340303', '', '', '蚌山区', '1136'], [1140, '340304', '', '', '禹会区', '1136'], [1141, '340311',
                '', '', '淮上区', '1136'], [1142, '340321', '', '', '怀远县', '1136'], [1143, '340322', '', '',
                '五河县', '1136'], [1144, '340323', '', '', '固镇县', '1136']]
        },
        "淮南市": {
            id: 340400,
            value: [[1146, '340401', '', '', '市辖区', '1145'], [1147, '340402', '', '', '大通区', '1145'], [1148,
                '340403', '', '', '田家庵区', '1145'], [1149, '340404', '', '', '谢家集区', '1145'], [1150,
                '340405', '', '', '八公山区', '1145'], [1151, '340406', '', '', '潘集区', '1145'], [1152, '340421',
                '', '', '凤台县', '1145']]
        },
        "马鞍山市": {
            id: 340500,
            value: [[1154, '340501', '', '', '市辖区', '1153'], [1155, '340503', '', '', '花山区', '1153'], [1156,
                '340504', '', '', '雨山区', '1153'], [1157, '340506', '', '', '博望区', '1153'], [1158, '340521',
                '', '', '当涂县', '1153'], [1159, '340522', '', '', '含山县', '1153'], [1160, '340523', '', '',
                '和县', '1153']]
        },
        "淮北市": {
            id: 340600,
            value: [[1162, '340601', '', '', '市辖区', '1161'], [1163, '340602', '', '', '杜集区', '1161'], [1164,
                '340603', '', '', '相山区', '1161'], [1165, '340604', '', '', '烈山区', '1161'], [1166, '340621',
                '', '', '濉溪县', '1161']]
        },
        "铜陵市": {
            id: 340700,
            value: [[1168, '340701', '', '', '市辖区', '1167'], [1169, '340702', '', '', '铜官山区', '1167'], [1170,
                '340703', '', '', '狮子山区', '1167'], [1171, '340711', '', '', '郊区', '1167'], [1172, '340721',
                '', '', '铜陵县', '1167']]
        },
        "安庆市": {
            id: 340800,
            value: [[1174, '340801', '', '', '市辖区', '1173'], [1175, '340802', '', '', '迎江区', '1173'], [1176,
                    '340803', '', '', '大观区', '1173'], [1177, '340811', '', '', '宜秀区', '1173'], [1178, '340822',
                    '', '', '怀宁县', '1173'], [1179, '340823', '', '', '枞阳县', '1173'], [1180, '340824', '', '',
                    '潜山县', '1173'], [1181, '340825', '', '', '太湖县', '1173'], [1182, '340826', '', '', '宿松县',
                    '1173'], [1183, '340827', '', '', '望江县', '1173'], [1184, '340828', '', '', '岳西县', '1173'],
                [1185, '340881', '', '', '桐城市', '1173']]
        },
        "黄山市": {
            id: 341000,
            value: [[1187, '341001', '', '', '市辖区', '1186'], [1188, '341002', '', '', '屯溪区', '1186'], [1189,
                '341003', '', '', '黄山区', '1186'], [1190, '341004', '', '', '徽州区', '1186'], [1191, '341021',
                '', '', '歙县', '1186'], [1192, '341022', '', '', '休宁县', '1186'], [1193, '341023', '', '',
                '黟县', '1186'], [1194, '341024', '', '', '祁门县', '1186']]
        },
        "滁州市": {
            id: 341100,
            value: [[1196, '341101', '', '', '市辖区', '1195'], [1197, '341102', '', '', '琅琊区', '1195'], [1198,
                '341103', '', '', '南谯区', '1195'], [1199, '341122', '', '', '来安县', '1195'], [1200, '341124',
                '', '', '全椒县', '1195'], [1201, '341125', '', '', '定远县', '1195'], [1202, '341126', '', '',
                '凤阳县', '1195'], [1203, '341181', '', '', '天长市', '1195'], [1204, '341182', '', '', '明光市',
                '1195']]
        },
        "阜阳市": {
            id: 341200,
            value: [[1206, '341201', '', '', '市辖区', '1205'], [1207, '341202', '', '', '颍州区', '1205'], [1208,
                '341203', '', '', '颍东区', '1205'], [1209, '341204', '', '', '颍泉区', '1205'], [1210, '341221',
                '', '', '临泉县', '1205'], [1211, '341222', '', '', '太和县', '1205'], [1212, '341225', '', '',
                '阜南县', '1205'], [1213, '341226', '', '', '颍上县', '1205'], [1214, '341282', '', '', '界首市',
                '1205']]
        },
        "宿州市": {
            id: 341300,
            value: [[1216, '341301', '', '', '市辖区', '1215'], [1217, '341302', '', '', '埇桥区', '1215'], [1218,
                '341321', '', '', '砀山县', '1215'], [1219, '341322', '', '', '萧县', '1215'], [1220, '341323',
                '', '', '灵璧县', '1215'], [1221, '341324', '', '', '泗县', '1215']]
        },
        "六安市": {
            id: 341500,
            value: [[1223, '341501', '', '', '市辖区', '1222'], [1224, '341502', '', '', '金安区', '1222'], [1225,
                '341503', '', '', '裕安区', '1222'], [1226, '341521', '', '', '寿县', '1222'], [1227, '341522',
                '', '', '霍邱县', '1222'], [1228, '341523', '', '', '舒城县', '1222'], [1229, '341524', '', '',
                '金寨县', '1222'], [1230, '341525', '', '', '霍山县', '1222']]
        },
        "亳州市": {
            id: 341600,
            value: [[1232, '341601', '', '', '市辖区', '1231'], [1233, '341602', '', '', '谯城区', '1231'], [1234,
                '341621', '', '', '涡阳县', '1231'], [1235, '341622', '', '', '蒙城县', '1231'], [1236, '341623',
                '', '', '利辛县', '1231']]
        },
        "池州市": {
            id: 341700,
            value: [[1238, '341701', '', '', '市辖区', '1237'], [1239, '341702', '', '', '贵池区', '1237'], [1240,
                '341721', '', '', '东至县', '1237'], [1241, '341722', '', '', '石台县', '1237'], [1242, '341723',
                '', '', '青阳县', '1237']]
        },
        "宣城市": {
            id: 341800,
            value: [[1244, '341801', '', '', '市辖区', '1243'], [1245, '341802', '', '', '宣州区', '1243'], [1246,
                '341821', '', '', '郎溪县', '1243'], [1247, '341822', '', '', '广德县', '1243'], [1248, '341823',
                '', '', '泾县', '1243'], [1249, '341824', '', '', '绩溪县', '1243'], [1250, '341825', '', '',
                '旌德县', '1243'], [1251, '341881', '', '', '宁国市', '1243']]
        }
    },
    "福建省": {
        "福州市": {
            id: 350100,
            value: [[1254, '350101', '', '', '市辖区', '1253'], [1255, '350102', '', '', '鼓楼区', '1253'], [1256,
                    '350103', '', '', '台江区', '1253'], [1257, '350104', '', '', '仓山区', '1253'], [1258, '350105',
                    '', '', '马尾区', '1253'], [1259, '350111', '', '', '晋安区', '1253'], [1260, '350121', '', '',
                    '闽侯县', '1253'], [1261, '350122', '', '', '连江县', '1253'], [1262, '350123', '', '', '罗源县',
                    '1253'], [1263, '350124', '', '', '闽清县', '1253'], [1264, '350125', '', '', '永泰县', '1253'],
                [1265, '350128', '', '', '平潭县', '1253'], [1266, '350181', '', '', '福清市', '1253'], [1267,
                    '350182', '', '', '长乐市', '1253']]
        },
        "厦门市": {
            id: 350200,
            value: [[1269, '350201', '', '', '市辖区', '1268'], [1270, '350203', '', '', '思明区', '1268'], [1271,
                '350205', '', '', '海沧区', '1268'], [1272, '350206', '', '', '湖里区', '1268'], [1273, '350211',
                '', '', '集美区', '1268'], [1274, '350212', '', '', '同安区', '1268'], [1275, '350213', '', '',
                '翔安区', '1268']]
        },
        "莆田市": {
            id: 350300,
            value: [[1277, '350301', '', '', '市辖区', '1276'], [1278, '350302', '', '', '城厢区', '1276'], [1279,
                '350303', '', '', '涵江区', '1276'], [1280, '350304', '', '', '荔城区', '1276'], [1281, '350305',
                '', '', '秀屿区', '1276'], [1282, '350322', '', '', '仙游县', '1276']]
        },
        "三明市": {
            id: 350400,
            value: [[1284, '350401', '', '', '市辖区', '1283'], [1285, '350402', '', '', '梅列区', '1283'], [1286,
                    '350403', '', '', '三元区', '1283'], [1287, '350421', '', '', '明溪县', '1283'], [1288, '350423',
                    '', '', '清流县', '1283'], [1289, '350424', '', '', '宁化县', '1283'], [1290, '350425', '', '',
                    '大田县', '1283'], [1291, '350426', '', '', '尤溪县', '1283'], [1292, '350427', '', '', '沙县',
                    '1283'], [1293, '350428', '', '', '将乐县', '1283'], [1294, '350429', '', '', '泰宁县', '1283'],
                [1295, '350430', '', '', '建宁县', '1283'], [1296, '350481', '', '', '永安市', '1283']]
        },
        "泉州市": {
            id: 350500,
            value: [[1298, '350501', '', '', '市辖区', '1297'], [1299, '350502', '', '', '鲤城区', '1297'], [1300,
                    '350503', '', '', '丰泽区', '1297'], [1301, '350504', '', '', '洛江区', '1297'], [1302, '350505',
                    '', '', '泉港区', '1297'], [1303, '350521', '', '', '惠安县', '1297'], [1304, '350524', '', '',
                    '安溪县', '1297'], [1305, '350525', '', '', '永春县', '1297'], [1306, '350526', '', '', '德化县',
                    '1297'], [1307, '350527', '', '', '金门县', '1297'], [1308, '350581', '', '', '石狮市', '1297'],
                [1309, '350582', '', '', '晋江市', '1297'], [1310, '350583', '', '', '南安市', '1297']]
        },
        "漳州市": {
            id: 350600,
            value: [[1312, '350601', '', '', '市辖区', '1311'], [1313, '350602', '', '', '芗城区', '1311'], [1314,
                    '350603', '', '', '龙文区', '1311'], [1315, '350622', '', '', '云霄县', '1311'], [1316, '350623',
                    '', '', '漳浦县', '1311'], [1317, '350624', '', '', '诏安县', '1311'], [1318, '350625', '', '',
                    '长泰县', '1311'], [1319, '350626', '', '', '东山县', '1311'], [1320, '350627', '', '', '南靖县',
                    '1311'], [1321, '350628', '', '', '平和县', '1311'], [1322, '350629', '', '', '华安县', '1311'],
                [1323, '350681', '', '', '龙海市', '1311']]
        },
        "南平市": {
            id: 350700,
            value: [[1325, '350701', '', '', '市辖区', '1324'], [1326, '350702', '', '', '延平区', '1324'], [1327,
                '350703', '', '', '建阳区', '1324'], [1328, '350721', '', '', '顺昌县', '1324'], [1329, '350722',
                '', '', '浦城县', '1324'], [1330, '350723', '', '', '光泽县', '1324'], [1331, '350724', '', '',
                '松溪县', '1324'], [1332, '350725', '', '', '政和县', '1324'], [1333, '350781', '', '', '邵武市',
                '1324'], [1334, '350782', '', '', '武夷山市', '1324'], [1335, '350783', '', '', '建瓯市', '1324']]
        },
        "龙岩市": {
            id: 350800,
            value: [[1337, '350801', '', '', '市辖区', '1336'], [1338, '350802', '', '', '新罗区', '1336'], [1339,
                '350803', '', '', '永定区', '1336'], [1340, '350821', '', '', '长汀县', '1336'], [1341, '350823',
                '', '', '上杭县', '1336'], [1342, '350824', '', '', '武平县', '1336'], [1343, '350825', '', '',
                '连城县', '1336'], [1344, '350881', '', '', '漳平市', '1336']]
        },
        "宁德市": {
            id: 350900,
            value: [[1346, '350901', '', '', '市辖区', '1345'], [1347, '350902', '', '', '蕉城区', '1345'], [1348,
                '350921', '', '', '霞浦县', '1345'], [1349, '350922', '', '', '古田县', '1345'], [1350, '350923',
                '', '', '屏南县', '1345'], [1351, '350924', '', '', '寿宁县', '1345'], [1352, '350925', '', '',
                '周宁县', '1345'], [1353, '350926', '', '', '柘荣县', '1345'], [1354, '350981', '', '', '福安市',
                '1345'], [1355, '350982', '', '', '福鼎市', '1345']]
        }
    },
    "江西省": {
        "南昌市": {
            id: 360100,
            value: [[1358, '360101', '', '', '市辖区', '1357'], [1359, '360102', '', '', '东湖区', '1357'], [1360,
                '360103', '', '', '西湖区', '1357'], [1361, '360104', '', '', '青云谱区', '1357'], [1362, '360105',
                '', '', '湾里区', '1357'], [1363, '360111', '', '', '青山湖区', '1357'], [1364, '360121', '', '',
                '南昌县', '1357'], [1365, '360122', '', '', '新建县', '1357'], [1366, '360123', '', '', '安义县',
                '1357'], [1367, '360124', '', '', '进贤县', '1357']]
        },
        "景德镇市": {
            id: 360200,
            value: [[1369, '360201', '', '', '市辖区', '1368'], [1370, '360202', '', '', '昌江区', '1368'], [1371,
                '360203', '', '', '珠山区', '1368'], [1372, '360222', '', '', '浮梁县', '1368'], [1373, '360281',
                '', '', '乐平市', '1368']]
        },
        "萍乡市": {
            id: 360300,
            value: [[1375, '360301', '', '', '市辖区', '1374'], [1376, '360302', '', '', '安源区', '1374'], [1377,
                '360313', '', '', '湘东区', '1374'], [1378, '360321', '', '', '莲花县', '1374'], [1379, '360322',
                '', '', '上栗县', '1374'], [1380, '360323', '', '', '芦溪县', '1374']]
        },
        "九江市": {
            id: 360400,
            value: [[1382, '360401', '', '', '市辖区', '1381'], [1383, '360402', '', '', '庐山区', '1381'], [1384,
                    '360403', '', '', '浔阳区', '1381'], [1385, '360421', '', '', '九江县', '1381'], [1386, '360423',
                    '', '', '武宁县', '1381'], [1387, '360424', '', '', '修水县', '1381'], [1388, '360425', '', '',
                    '永修县', '1381'], [1389, '360426', '', '', '德安县', '1381'], [1390, '360427', '', '', '星子县',
                    '1381'], [1391, '360428', '', '', '都昌县', '1381'], [1392, '360429', '', '', '湖口县', '1381'],
                [1393, '360430', '', '', '彭泽县', '1381'], [1394, '360481', '', '', '瑞昌市', '1381'], [1395,
                    '360482', '', '', '共青城市', '1381']]
        },
        "新余市": {
            id: 360500,
            value: [[1397, '360501', '', '', '市辖区', '1396'], [1398, '360502', '', '', '渝水区', '1396'], [1399,
                '360521', '', '', '分宜县', '1396']]
        },
        "鹰潭市": {
            id: 360600,
            value: [[1401, '360601', '', '', '市辖区', '1400'], [1402, '360602', '', '', '月湖区', '1400'], [1403,
                '360622', '', '', '余江县', '1400'], [1404, '360681', '', '', '贵溪市', '1400']]
        },
        "赣州市": {
            id: 360700,
            value: [[1406, '360701', '', '', '市辖区', '1405'], [1407, '360702', '', '', '章贡区', '1405'], [1408,
                    '360703', '', '', '南康区', '1405'], [1409, '360721', '', '', '赣县', '1405'], [1410, '360722',
                    '', '', '信丰县', '1405'], [1411, '360723', '', '', '大余县', '1405'], [1412, '360724', '', '',
                    '上犹县', '1405'], [1413, '360725', '', '', '崇义县', '1405'], [1414, '360726', '', '', '安远县',
                    '1405'], [1415, '360727', '', '', '龙南县', '1405'], [1416, '360728', '', '', '定南县', '1405'],
                [1417, '360729', '', '', '全南县', '1405'], [1418, '360730', '', '', '宁都县', '1405'], [1419,
                    '360731', '', '', '于都县', '1405'], [1420, '360732', '', '', '兴国县', '1405'], [1421, '360733',
                    '', '', '会昌县', '1405'], [1422, '360734', '', '', '寻乌县', '1405'], [1423, '360735', '', '',
                    '石城县', '1405'], [1424, '360781', '', '', '瑞金市', '1405']]
        },
        "吉安市": {
            id: 360800,
            value: [[1426, '360801', '', '', '市辖区', '1425'], [1427, '360802', '', '', '吉州区', '1425'], [1428,
                    '360803', '', '', '青原区', '1425'], [1429, '360821', '', '', '吉安县', '1425'], [1430, '360822',
                    '', '', '吉水县', '1425'], [1431, '360823', '', '', '峡江县', '1425'], [1432, '360824', '', '',
                    '新干县', '1425'], [1433, '360825', '', '', '永丰县', '1425'], [1434, '360826', '', '', '泰和县',
                    '1425'], [1435, '360827', '', '', '遂川县', '1425'], [1436, '360828', '', '', '万安县', '1425'],
                [1437, '360829', '', '', '安福县', '1425'], [1438, '360830', '', '', '永新县', '1425'], [1439,
                    '360881', '', '', '井冈山市', '1425']]
        },
        "宜春市": {
            id: 360900,
            value: [[1441, '360901', '', '', '市辖区', '1440'], [1442, '360902', '', '', '袁州区', '1440'], [1443,
                '360921', '', '', '奉新县', '1440'], [1444, '360922', '', '', '万载县', '1440'], [1445, '360923',
                '', '', '上高县', '1440'], [1446, '360924', '', '', '宜丰县', '1440'], [1447, '360925', '', '',
                '靖安县', '1440'], [1448, '360926', '', '', '铜鼓县', '1440'], [1449, '360981', '', '', '丰城市',
                '1440'], [1450, '360982', '', '', '樟树市', '1440'], [1451, '360983', '', '', '高安市', '1440']]
        },
        "抚州市": {
            id: 361000,
            value: [[1453, '361001', '', '', '市辖区', '1452'], [1454, '361002', '', '', '临川区', '1452'], [1455,
                    '361021', '', '', '南城县', '1452'], [1456, '361022', '', '', '黎川县', '1452'], [1457, '361023',
                    '', '', '南丰县', '1452'], [1458, '361024', '', '', '崇仁县', '1452'], [1459, '361025', '', '',
                    '乐安县', '1452'], [1460, '361026', '', '', '宜黄县', '1452'], [1461, '361027', '', '', '金溪县',
                    '1452'], [1462, '361028', '', '', '资溪县', '1452'], [1463, '361029', '', '', '东乡县', '1452'],
                [1464, '361030', '', '', '广昌县', '1452']]
        },
        "上饶市": {
            id: 361100,
            value: [[1466, '361101', '', '', '市辖区', '1465'], [1467, '361102', '', '', '信州区', '1465'], [1468,
                    '361121', '', '', '上饶县', '1465'], [1469, '361122', '', '', '广丰县', '1465'], [1470, '361123',
                    '', '', '玉山县', '1465'], [1471, '361124', '', '', '铅山县', '1465'], [1472, '361125', '', '',
                    '横峰县', '1465'], [1473, '361126', '', '', '弋阳县', '1465'], [1474, '361127', '', '', '余干县',
                    '1465'], [1475, '361128', '', '', '鄱阳县', '1465'], [1476, '361129', '', '', '万年县', '1465'],
                [1477, '361130', '', '', '婺源县', '1465'], [1478, '361181', '', '', '德兴市', '1465']]
        }
    },
    "山东省": {
        "济南市": {
            id: 370100,
            value: [[1481, '370101', '', '', '市辖区', '1480'], [1482, '370102', '', '', '历下区', '1480'], [1483,
                '370103', '', '', '市中区', '1480'], [1484, '370104', '', '', '槐荫区', '1480'], [1485, '370105',
                '', '', '天桥区', '1480'], [1486, '370112', '', '', '历城区', '1480'], [1487, '370113', '', '',
                '长清区', '1480'], [1488, '370124', '', '', '平阴县', '1480'], [1489, '370125', '', '', '济阳县',
                '1480'], [1490, '370126', '', '', '商河县', '1480'], [1491, '370181', '', '', '章丘市', '1480']]
        },
        "青岛市": {
            id: 370200,
            value: [[1493, '370201', '', '', '市辖区', '1492'], [1494, '370202', '', '', '市南区', '1492'], [1495,
                '370203', '', '', '市北区', '1492'], [1496, '370211', '', '', '黄岛区', '1492'], [1497, '370212',
                '', '', '崂山区', '1492'], [1498, '370213', '', '', '李沧区', '1492'], [1499, '370214', '', '',
                '城阳区', '1492'], [1500, '370281', '', '', '胶州市', '1492'], [1501, '370282', '', '', '即墨市',
                '1492'], [1502, '370283', '', '', '平度市', '1492'], [1503, '370285', '', '', '莱西市', '1492']]
        },
        "淄博市": {
            id: 370300,
            value: [[1505, '370301', '', '', '市辖区', '1504'], [1506, '370302', '', '', '淄川区', '1504'], [1507,
                '370303', '', '', '张店区', '1504'], [1508, '370304', '', '', '博山区', '1504'], [1509, '370305',
                '', '', '临淄区', '1504'], [1510, '370306', '', '', '周村区', '1504'], [1511, '370321', '', '',
                '桓台县', '1504'], [1512, '370322', '', '', '高青县', '1504'], [1513, '370323', '', '', '沂源县',
                '1504']]
        },
        "枣庄市": {
            id: 370400,
            value: [[1515, '370401', '', '', '市辖区', '1514'], [1516, '370402', '', '', '市中区', '1514'], [1517,
                '370403', '', '', '薛城区', '1514'], [1518, '370404', '', '', '峄城区', '1514'], [1519, '370405',
                '', '', '台儿庄区', '1514'], [1520, '370406', '', '', '山亭区', '1514'], [1521, '370481', '', '',
                '滕州市', '1514']]
        },
        "东营市": {
            id: 370500,
            value: [[1523, '370501', '', '', '市辖区', '1522'], [1524, '370502', '', '', '东营区', '1522'], [1525,
                '370503', '', '', '河口区', '1522'], [1526, '370521', '', '', '垦利县', '1522'], [1527, '370522',
                '', '', '利津县', '1522'], [1528, '370523', '', '', '广饶县', '1522']]
        },
        "烟台市": {
            id: 370600,
            value: [[1530, '370601', '', '', '市辖区', '1529'], [1531, '370602', '', '', '芝罘区', '1529'], [1532,
                    '370611', '', '', '福山区', '1529'], [1533, '370612', '', '', '牟平区', '1529'], [1534, '370613',
                    '', '', '莱山区', '1529'], [1535, '370634', '', '', '长岛县', '1529'], [1536, '370681', '', '',
                    '龙口市', '1529'], [1537, '370682', '', '', '莱阳市', '1529'], [1538, '370683', '', '', '莱州市',
                    '1529'], [1539, '370684', '', '', '蓬莱市', '1529'], [1540, '370685', '', '', '招远市', '1529'],
                [1541, '370686', '', '', '栖霞市', '1529'], [1542, '370687', '', '', '海阳市', '1529']]
        },
        "潍坊市": {
            id: 370700,
            value: [[1544, '370701', '', '', '市辖区', '1543'], [1545, '370702', '', '', '潍城区', '1543'], [1546,
                    '370703', '', '', '寒亭区', '1543'], [1547, '370704', '', '', '坊子区', '1543'], [1548, '370705',
                    '', '', '奎文区', '1543'], [1549, '370724', '', '', '临朐县', '1543'], [1550, '370725', '', '',
                    '昌乐县', '1543'], [1551, '370781', '', '', '青州市', '1543'], [1552, '370782', '', '', '诸城市',
                    '1543'], [1553, '370783', '', '', '寿光市', '1543'], [1554, '370784', '', '', '安丘市', '1543'],
                [1555, '370785', '', '', '高密市', '1543'], [1556, '370786', '', '', '昌邑市', '1543']]
        },
        "济宁市": {
            id: 370800,
            value: [[1558, '370801', '', '', '市辖区', '1557'], [1559, '370811', '', '', '任城区', '1557'], [1560,
                    '370812', '', '', '兖州区', '1557'], [1561, '370826', '', '', '微山县', '1557'], [1562, '370827',
                    '', '', '鱼台县', '1557'], [1563, '370828', '', '', '金乡县', '1557'], [1564, '370829', '', '',
                    '嘉祥县', '1557'], [1565, '370830', '', '', '汶上县', '1557'], [1566, '370831', '', '', '泗水县',
                    '1557'], [1567, '370832', '', '', '梁山县', '1557'], [1568, '370881', '', '', '曲阜市', '1557'],
                [1569, '370883', '', '', '邹城市', '1557']]
        },
        "泰安市": {
            id: 370900,
            value: [[1571, '370901', '', '', '市辖区', '1570'], [1572, '370902', '', '', '泰山区', '1570'], [1573,
                '370911', '', '', '岱岳区', '1570'], [1574, '370921', '', '', '宁阳县', '1570'], [1575, '370923',
                '', '', '东平县', '1570'], [1576, '370982', '', '', '新泰市', '1570'], [1577, '370983', '', '',
                '肥城市', '1570']]
        },
        "威海市": {
            id: 371000,
            value: [[1579, '371001', '', '', '市辖区', '1578'], [1580, '371002', '', '', '环翠区', '1578'], [1581,
                '371081', '', '', '文登市', '1578'], [1582, '371082', '', '', '荣成市', '1578'], [1583, '371083',
                '', '', '乳山市', '1578']]
        },
        "日照市": {
            id: 371100,
            value: [[1585, '371101', '', '', '市辖区', '1584'], [1586, '371102', '', '', '东港区', '1584'], [1587,
                '371103', '', '', '岚山区', '1584'], [1588, '371121', '', '', '五莲县', '1584'], [1589, '371122',
                '', '', '莒县', '1584']]
        },
        "莱芜市": {
            id: 371200,
            value: [[1591, '371201', '', '', '市辖区', '1590'], [1592, '371202', '', '', '莱城区', '1590'], [1593,
                '371203', '', '', '钢城区', '1590']]
        },
        "临沂市": {
            id: 371300,
            value: [[1595, '371301', '', '', '市辖区', '1594'], [1596, '371302', '', '', '兰山区', '1594'], [1597,
                    '371311', '', '', '罗庄区', '1594'], [1598, '371312', '', '', '河东区', '1594'], [1599, '371321',
                    '', '', '沂南县', '1594'], [1600, '371322', '', '', '郯城县', '1594'], [1601, '371323', '', '',
                    '沂水县', '1594'], [1602, '371324', '', '', '兰陵县', '1594'], [1603, '371325', '', '', '费县',
                    '1594'], [1604, '371326', '', '', '平邑县', '1594'], [1605, '371327', '', '', '莒南县', '1594'],
                [1606, '371328', '', '', '蒙阴县', '1594'], [1607, '371329', '', '', '临沭县', '1594']]
        },
        "德州市": {
            id: 371400,
            value: [[1609, '371401', '', '', '市辖区', '1608'], [1610, '371402', '', '', '德城区', '1608'], [1611,
                    '371403', '', '', '陵城区', '1608'], [1612, '371422', '', '', '宁津县', '1608'], [1613, '371423',
                    '', '', '庆云县', '1608'], [1614, '371424', '', '', '临邑县', '1608'], [1615, '371425', '', '',
                    '齐河县', '1608'], [1616, '371426', '', '', '平原县', '1608'], [1617, '371427', '', '', '夏津县',
                    '1608'], [1618, '371428', '', '', '武城县', '1608'], [1619, '371481', '', '', '乐陵市', '1608'],
                [1620, '371482', '', '', '禹城市', '1608']]
        },
        "聊城市": {
            id: 371500,
            value: [[1622, '371501', '', '', '市辖区', '1621'], [1623, '371502', '', '', '东昌府区', '1621'], [1624,
                '371521', '', '', '阳谷县', '1621'], [1625, '371522', '', '', '莘县', '1621'], [1626, '371523',
                '', '', '茌平县', '1621'], [1627, '371524', '', '', '东阿县', '1621'], [1628, '371525', '', '',
                '冠县', '1621'], [1629, '371526', '', '', '高唐县', '1621'], [1630, '371581', '', '', '临清市',
                '1621']]
        },
        "滨州市": {
            id: 371600,
            value: [[1632, '371601', '', '', '市辖区', '1631'], [1633, '371602', '', '', '滨城区', '1631'], [1634,
                '371603', '', '', '沾化区', '1631'], [1635, '371621', '', '', '惠民县', '1631'], [1636, '371622',
                '', '', '阳信县', '1631'], [1637, '371623', '', '', '无棣县', '1631'], [1638, '371625', '', '',
                '博兴县', '1631'], [1639, '371626', '', '', '邹平县', '1631']]
        },
        "菏泽市": {
            id: 371700,
            value: [[1641, '371701', '', '', '市辖区', '1640'], [1642, '371702', '', '', '牡丹区', '1640'], [1643,
                '371721', '', '', '曹县', '1640'], [1644, '371722', '', '', '单县', '1640'], [1645, '371723',
                '', '', '成武县', '1640'], [1646, '371724', '', '', '巨野县', '1640'], [1647, '371725', '', '',
                '郓城县', '1640'], [1648, '371726', '', '', '鄄城县', '1640'], [1649, '371727', '', '', '定陶县',
                '1640'], [1650, '371728', '', '', '东明县', '1640']]
        }
    },
    "河南省": {
        "郑州市": {
            id: 410100,
            value: [[1653, '410101', '', '', '市辖区', '1652'], [1654, '410102', '', '', '中原区', '1652'], [1655,
                '410103', '', '', '二七区', '1652'], [1656, '410104', '', '', '管城回族区', '1652'], [1657,
                '410105', '', '', '金水区', '1652'], [1658, '410106', '', '', '上街区', '1652'], [1659, '410108',
                '', '', '惠济区', '1652'], [1660, '410122', '', '', '中牟县', '1652'], [1661, '410181', '', '',
                '巩义市', '1652'], [1662, '410182', '', '', '荥阳市', '1652'], [1663, '410183', '', '', '新密市',
                '1652'], [1664, '410184', '', '', '新郑市', '1652'], [1665, '410185', '', '', '登封市', '1652']]
        },
        "开封市": {
            id: 410200,
            value: [[1667, '410201', '', '', '市辖区', '1666'], [1668, '410202', '', '', '龙亭区', '1666'], [1669,
                '410203', '', '', '顺河回族区', '1666'], [1670, '410204', '', '', '鼓楼区', '1666'], [1671,
                '410205', '', '', '禹王台区', '1666'], [1672, '410212', '', '', '祥符区', '1666'], [1673, '410221',
                '', '', '杞县', '1666'], [1674, '410222', '', '', '通许县', '1666'], [1675, '410223', '', '',
                '尉氏县', '1666'], [1676, '410225', '', '', '兰考县', '1666']]
        },
        "洛阳市": {
            id: 410300,
            value: [[1678, '410301', '', '', '市辖区', '1677'], [1679, '410302', '', '', '老城区', '1677'], [1680,
                    '410303', '', '', '西工区', '1677'], [1681, '410304', '', '', '瀍河回族区', '1677'], [1682,
                    '410305', '', '', '涧西区', '1677'], [1683, '410306', '', '', '吉利区', '1677'], [1684, '410311',
                    '', '', '洛龙区', '1677'], [1685, '410322', '', '', '孟津县', '1677'], [1686, '410323', '', '',
                    '新安县', '1677'], [1687, '410324', '', '', '栾川县', '1677'], [1688, '410325', '', '', '嵩县',
                    '1677'], [1689, '410326', '', '', '汝阳县', '1677'], [1690, '410327', '', '', '宜阳县', '1677'],
                [1691, '410328', '', '', '洛宁县', '1677'], [1692, '410329', '', '', '伊川县', '1677'], [1693,
                    '410381', '', '', '偃师市', '1677']]
        },
        "平顶山市": {
            id: 410400,
            value: [[1695, '410401', '', '', '市辖区', '1694'], [1696, '410402', '', '', '新华区', '1694'], [1697,
                '410403', '', '', '卫东区', '1694'], [1698, '410404', '', '', '石龙区', '1694'], [1699, '410411',
                '', '', '湛河区', '1694'], [1700, '410421', '', '', '宝丰县', '1694'], [1701, '410422', '', '',
                '叶县', '1694'], [1702, '410423', '', '', '鲁山县', '1694'], [1703, '410425', '', '', '郏县',
                '1694'], [1704, '410481', '', '', '舞钢市', '1694'], [1705, '410482', '', '', '汝州市', '1694']]
        },
        "安阳市": {
            id: 410500,
            value: [[1707, '410501', '', '', '市辖区', '1706'], [1708, '410502', '', '', '文峰区', '1706'], [1709,
                '410503', '', '', '北关区', '1706'], [1710, '410505', '', '', '殷都区', '1706'], [1711, '410506',
                '', '', '龙安区', '1706'], [1712, '410522', '', '', '安阳县', '1706'], [1713, '410523', '', '',
                '汤阴县', '1706'], [1714, '410526', '', '', '滑县', '1706'], [1715, '410527', '', '', '内黄县',
                '1706'], [1716, '410581', '', '', '林州市', '1706']]
        },
        "鹤壁市": {
            id: 410600,
            value: [[1718, '410601', '', '', '市辖区', '1717'], [1719, '410602', '', '', '鹤山区', '1717'], [1720,
                '410603', '', '', '山城区', '1717'], [1721, '410611', '', '', '淇滨区', '1717'], [1722, '410621',
                '', '', '浚县', '1717'], [1723, '410622', '', '', '淇县', '1717']]
        },
        "新乡市": {
            id: 410700,
            value: [[1725, '410701', '', '', '市辖区', '1724'], [1726, '410702', '', '', '红旗区', '1724'], [1727,
                    '410703', '', '', '卫滨区', '1724'], [1728, '410704', '', '', '凤泉区', '1724'], [1729, '410711',
                    '', '', '牧野区', '1724'], [1730, '410721', '', '', '新乡县', '1724'], [1731, '410724', '', '',
                    '获嘉县', '1724'], [1732, '410725', '', '', '原阳县', '1724'], [1733, '410726', '', '', '延津县',
                    '1724'], [1734, '410727', '', '', '封丘县', '1724'], [1735, '410728', '', '', '长垣县', '1724'],
                [1736, '410781', '', '', '卫辉市', '1724'], [1737, '410782', '', '', '辉县市', '1724']]
        },
        "焦作市": {
            id: 410800,
            value: [[1739, '410801', '', '', '市辖区', '1738'], [1740, '410802', '', '', '解放区', '1738'], [1741,
                '410803', '', '', '中站区', '1738'], [1742, '410804', '', '', '马村区', '1738'], [1743, '410811',
                '', '', '山阳区', '1738'], [1744, '410821', '', '', '修武县', '1738'], [1745, '410822', '', '',
                '博爱县', '1738'], [1746, '410823', '', '', '武陟县', '1738'], [1747, '410825', '', '', '温县',
                '1738'], [1748, '410882', '', '', '沁阳市', '1738'], [1749, '410883', '', '', '孟州市', '1738']]
        },
        "濮阳市": {
            id: 410900,
            value: [[1751, '410901', '', '', '市辖区', '1750'], [1752, '410902', '', '', '华龙区', '1750'], [1753,
                '410922', '', '', '清丰县', '1750'], [1754, '410923', '', '', '南乐县', '1750'], [1755, '410926',
                '', '', '范县', '1750'], [1756, '410927', '', '', '台前县', '1750'], [1757, '410928', '', '',
                '濮阳县', '1750']]
        },
        "许昌市": {
            id: 411000,
            value: [[1759, '411001', '', '', '市辖区', '1758'], [1760, '411002', '', '', '魏都区', '1758'], [1761,
                '411023', '', '', '许昌县', '1758'], [1762, '411024', '', '', '鄢陵县', '1758'], [1763, '411025',
                '', '', '襄城县', '1758'], [1764, '411081', '', '', '禹州市', '1758'], [1765, '411082', '', '',
                '长葛市', '1758']]
        },
        "漯河市": {
            id: 411100,
            value: [[1767, '411101', '', '', '市辖区', '1766'], [1768, '411102', '', '', '源汇区', '1766'], [1769,
                '411103', '', '', '郾城区', '1766'], [1770, '411104', '', '', '召陵区', '1766'], [1771, '411121',
                '', '', '舞阳县', '1766'], [1772, '411122', '', '', '临颍县', '1766']]
        },
        "三门峡市": {
            id: 411200,
            value: [[1774, '411201', '', '', '市辖区', '1773'], [1775, '411202', '', '', '湖滨区', '1773'], [1776,
                '411221', '', '', '渑池县', '1773'], [1777, '411222', '', '', '陕县', '1773'], [1778, '411224',
                '', '', '卢氏县', '1773'], [1779, '411281', '', '', '义马市', '1773'], [1780, '411282', '', '',
                '灵宝市', '1773']]
        },
        "南阳市": {
            id: 411300,
            value: [[1782, '411301', '', '', '市辖区', '1781'], [1783, '411302', '', '', '宛城区', '1781'], [1784,
                    '411303', '', '', '卧龙区', '1781'], [1785, '411321', '', '', '南召县', '1781'], [1786, '411322',
                    '', '', '方城县', '1781'], [1787, '411323', '', '', '西峡县', '1781'], [1788, '411324', '', '',
                    '镇平县', '1781'], [1789, '411325', '', '', '内乡县', '1781'], [1790, '411326', '', '', '淅川县',
                    '1781'], [1791, '411327', '', '', '社旗县', '1781'], [1792, '411328', '', '', '唐河县', '1781'],
                [1793, '411329', '', '', '新野县', '1781'], [1794, '411330', '', '', '桐柏县', '1781'], [1795,
                    '411381', '', '', '邓州市', '1781']]
        },
        "商丘市": {
            id: 411400,
            value: [[1797, '411401', '', '', '市辖区', '1796'], [1798, '411402', '', '', '梁园区', '1796'], [1799,
                '411403', '', '', '睢阳区', '1796'], [1800, '411421', '', '', '民权县', '1796'], [1801, '411422',
                '', '', '睢县', '1796'], [1802, '411423', '', '', '宁陵县', '1796'], [1803, '411424', '', '',
                '柘城县', '1796'], [1804, '411425', '', '', '虞城县', '1796'], [1805, '411426', '', '', '夏邑县',
                '1796'], [1806, '411481', '', '', '永城市', '1796']]
        },
        "信阳市": {
            id: 411500,
            value: [[1808, '411501', '', '', '市辖区', '1807'], [1809, '411502', '', '', '浉河区', '1807'], [1810,
                '411503', '', '', '平桥区', '1807'], [1811, '411521', '', '', '罗山县', '1807'], [1812, '411522',
                '', '', '光山县', '1807'], [1813, '411523', '', '', '新县', '1807'], [1814, '411524', '', '',
                '商城县', '1807'], [1815, '411525', '', '', '固始县', '1807'], [1816, '411526', '', '', '潢川县',
                '1807'], [1817, '411527', '', '', '淮滨县', '1807'], [1818, '411528', '', '', '息县', '1807']]
        },
        "周口市": {
            id: 411600,
            value: [[1820, '411601', '', '', '市辖区', '1819'], [1821, '411602', '', '', '川汇区', '1819'], [1822,
                '411621', '', '', '扶沟县', '1819'], [1823, '411622', '', '', '西华县', '1819'], [1824, '411623',
                '', '', '商水县', '1819'], [1825, '411624', '', '', '沈丘县', '1819'], [1826, '411625', '', '',
                '郸城县', '1819'], [1827, '411626', '', '', '淮阳县', '1819'], [1828, '411627', '', '', '太康县',
                '1819'], [1829, '411628', '', '', '鹿邑县', '1819'], [1830, '411681', '', '', '项城市', '1819']]
        },
        "驻马店市": {
            id: 411700,
            value: [[1832, '411701', '', '', '市辖区', '1831'], [1833, '411702', '', '', '驿城区', '1831'], [1834,
                '411721', '', '', '西平县', '1831'], [1835, '411722', '', '', '上蔡县', '1831'], [1836, '411723',
                '', '', '平舆县', '1831'], [1837, '411724', '', '', '正阳县', '1831'], [1838, '411725', '', '',
                '确山县', '1831'], [1839, '411726', '', '', '泌阳县', '1831'], [1840, '411727', '', '', '汝南县',
                '1831'], [1841, '411728', '', '', '遂平县', '1831'], [1842, '411729', '', '', '新蔡县', '1831']]
        },
        "省直辖县级行政区划": {
            id: 419000,
            value: [[1844, '419001', '', '', '济源市', '1843']]
        }
    },
    "湖北省": {
        "武汉市": {
            id: 420100,
            value: [[1847, '420101', '', '', '市辖区', '1846'], [1848, '420102', '', '', '江岸区', '1846'], [1849,
                    '420103', '', '', '江汉区', '1846'], [1850, '420104', '', '', '硚口区', '1846'], [1851, '420105',
                    '', '', '汉阳区', '1846'], [1852, '420106', '', '', '武昌区', '1846'], [1853, '420107', '', '',
                    '青山区', '1846'], [1854, '420111', '', '', '洪山区', '1846'], [1855, '420112', '', '', '东西湖区',
                    '1846'], [1856, '420113', '', '', '汉南区', '1846'], [1857, '420114', '', '', '蔡甸区', '1846'],
                [1858, '420115', '', '', '江夏区', '1846'], [1859, '420116', '', '', '黄陂区', '1846'], [1860,
                    '420117', '', '', '新洲区', '1846']]
        },
        "黄石市": {
            id: 420200,
            value: [[1862, '420201', '', '', '市辖区', '1861'], [1863, '420202', '', '', '黄石港区', '1861'], [1864,
                '420203', '', '', '西塞山区', '1861'], [1865, '420204', '', '', '下陆区', '1861'], [1866, '420205',
                '', '', '铁山区', '1861'], [1867, '420222', '', '', '阳新县', '1861'], [1868, '420281', '', '',
                '大冶市', '1861']]
        },
        "十堰市": {
            id: 420300,
            value: [[1870, '420301', '', '', '市辖区', '1869'], [1871, '420302', '', '', '茅箭区', '1869'], [1872,
                '420303', '', '', '张湾区', '1869'], [1873, '420304', '', '', '郧阳区', '1869'], [1874, '420322',
                '', '', '郧西县', '1869'], [1875, '420323', '', '', '竹山县', '1869'], [1876, '420324', '', '',
                '竹溪县', '1869'], [1877, '420325', '', '', '房县', '1869'], [1878, '420381', '', '', '丹江口市',
                '1869']]
        },
        "宜昌市": {
            id: 420500,
            value: [[1880, '420501', '', '', '市辖区', '1879'], [1881, '420502', '', '', '西陵区', '1879'], [1882,
                    '420503', '', '', '伍家岗区', '1879'], [1883, '420504', '', '', '点军区', '1879'], [1884, '420505',
                    '', '', '猇亭区', '1879'], [1885, '420506', '', '', '夷陵区', '1879'], [1886, '420525', '', '',
                    '远安县', '1879'], [1887, '420526', '', '', '兴山县', '1879'], [1888, '420527', '', '', '秭归县',
                    '1879'], [1889, '420528', '', '', '长阳土家族自治县', '1879'], [1890, '420529', '', '', '五峰土家族自治县',
                    '1879'], [1891, '420581', '', '', '宜都市', '1879'], [1892, '420582', '', '', '当阳市', '1879'],
                [1893, '420583', '', '', '枝江市', '1879']]
        },
        "襄阳市": {
            id: 420600,
            value: [[1895, '420601', '', '', '市辖区', '1894'], [1896, '420602', '', '', '襄城区', '1894'], [1897,
                '420606', '', '', '樊城区', '1894'], [1898, '420607', '', '', '襄州区', '1894'], [1899, '420624',
                '', '', '南漳县', '1894'], [1900, '420625', '', '', '谷城县', '1894'], [1901, '420626', '', '',
                '保康县', '1894'], [1902, '420682', '', '', '老河口市', '1894'], [1903, '420683', '', '', '枣阳市',
                '1894'], [1904, '420684', '', '', '宜城市', '1894']]
        },
        "鄂州市": {
            id: 420700,
            value: [[1906, '420701', '', '', '市辖区', '1905'], [1907, '420702', '', '', '梁子湖区', '1905'], [1908,
                '420703', '', '', '华容区', '1905'], [1909, '420704', '', '', '鄂城区', '1905']]
        },
        "荆门市": {
            id: 420800,
            value: [[1911, '420801', '', '', '市辖区', '1910'], [1912, '420802', '', '', '东宝区', '1910'], [1913,
                '420804', '', '', '掇刀区', '1910'], [1914, '420821', '', '', '京山县', '1910'], [1915, '420822',
                '', '', '沙洋县', '1910'], [1916, '420881', '', '', '钟祥市', '1910']]
        },
        "孝感市": {
            id: 420900,
            value: [[1918, '420901', '', '', '市辖区', '1917'], [1919, '420902', '', '', '孝南区', '1917'], [1920,
                '420921', '', '', '孝昌县', '1917'], [1921, '420922', '', '', '大悟县', '1917'], [1922, '420923',
                '', '', '云梦县', '1917'], [1923, '420981', '', '', '应城市', '1917'], [1924, '420982', '', '',
                '安陆市', '1917'], [1925, '420984', '', '', '汉川市', '1917']]
        },
        "荆州市": {
            id: 421000,
            value: [[1927, '421001', '', '', '市辖区', '1926'], [1928, '421002', '', '', '沙市区', '1926'], [1929,
                '421003', '', '', '荆州区', '1926'], [1930, '421022', '', '', '公安县', '1926'], [1931, '421023',
                '', '', '监利县', '1926'], [1932, '421024', '', '', '江陵县', '1926'], [1933, '421081', '', '',
                '石首市', '1926'], [1934, '421083', '', '', '洪湖市', '1926'], [1935, '421087', '', '', '松滋市',
                '1926']]
        },
        "黄冈市": {
            id: 421100,
            value: [[1937, '421101', '', '', '市辖区', '1936'], [1938, '421102', '', '', '黄州区', '1936'], [1939,
                '421121', '', '', '团风县', '1936'], [1940, '421122', '', '', '红安县', '1936'], [1941, '421123',
                '', '', '罗田县', '1936'], [1942, '421124', '', '', '英山县', '1936'], [1943, '421125', '', '',
                '浠水县', '1936'], [1944, '421126', '', '', '蕲春县', '1936'], [1945, '421127', '', '', '黄梅县',
                '1936'], [1946, '421181', '', '', '麻城市', '1936'], [1947, '421182', '', '', '武穴市', '1936']]
        },
        "咸宁市": {
            id: 421200,
            value: [[1949, '421201', '', '', '市辖区', '1948'], [1950, '421202', '', '', '咸安区', '1948'], [1951,
                '421221', '', '', '嘉鱼县', '1948'], [1952, '421222', '', '', '通城县', '1948'], [1953, '421223',
                '', '', '崇阳县', '1948'], [1954, '421224', '', '', '通山县', '1948'], [1955, '421281', '', '',
                '赤壁市', '1948']]
        },
        "随州市": {
            id: 421300,
            value: [[1957, '421301', '', '', '市辖区', '1956'], [1958, '421303', '', '', '曾都区', '1956'], [1959,
                '421321', '', '', '随县', '1956'], [1960, '421381', '', '', '广水市', '1956']]
        },
        "恩施土家族苗族自治州": {
            id: 422800,
            value: [[1962, '422801', '', '', '恩施市', '1961'], [1963, '422802', '', '', '利川市', '1961'], [1964,
                '422822', '', '', '建始县', '1961'], [1965, '422823', '', '', '巴东县', '1961'], [1966, '422825',
                '', '', '宣恩县', '1961'], [1967, '422826', '', '', '咸丰县', '1961'], [1968, '422827', '', '',
                '来凤县', '1961'], [1969, '422828', '', '', '鹤峰县', '1961']]
        },
        "省直辖县级行政区划": {
            id: 429000,
            value: [[1971, '429004', '', '', '仙桃市', '1970'], [1972, '429005', '', '', '潜江市', '1970'], [1973,
                '429006', '', '', '天门市', '1970'], [1974, '429021', '', '', '神农架林区', '1970']]
        }
    },
    "湖南省": {
        "长沙市": {
            id: 430100,
            value: [[1977, '430101', '', '', '市辖区', '1976'], [1978, '430102', '', '', '芙蓉区', '1976'], [1979,
                '430103', '', '', '天心区', '1976'], [1980, '430104', '', '', '岳麓区', '1976'], [1981, '430105',
                '', '', '开福区', '1976'], [1982, '430111', '', '', '雨花区', '1976'], [1983, '430112', '', '',
                '望城区', '1976'], [1984, '430121', '', '', '长沙县', '1976'], [1985, '430124', '', '', '宁乡县',
                '1976'], [1986, '430181', '', '', '浏阳市', '1976']]
        },
        "株洲市": {
            id: 430200,
            value: [[1988, '430201', '', '', '市辖区', '1987'], [1989, '430202', '', '', '荷塘区', '1987'], [1990,
                '430203', '', '', '芦淞区', '1987'], [1991, '430204', '', '', '石峰区', '1987'], [1992, '430211',
                '', '', '天元区', '1987'], [1993, '430221', '', '', '株洲县', '1987'], [1994, '430223', '', '',
                '攸县', '1987'], [1995, '430224', '', '', '茶陵县', '1987'], [1996, '430225', '', '', '炎陵县',
                '1987'], [1997, '430281', '', '', '醴陵市', '1987']]
        },
        "湘潭市": {
            id: 430300,
            value: [[1999, '430301', '', '', '市辖区', '1998'], [2000, '430302', '', '', '雨湖区', '1998'], [2001,
                '430304', '', '', '岳塘区', '1998'], [2002, '430321', '', '', '湘潭县', '1998'], [2003, '430381',
                '', '', '湘乡市', '1998'], [2004, '430382', '', '', '韶山市', '1998']]
        },
        "衡阳市": {
            id: 430400,
            value: [[2006, '430401', '', '', '市辖区', '2005'], [2007, '430405', '', '', '珠晖区', '2005'], [2008,
                    '430406', '', '', '雁峰区', '2005'], [2009, '430407', '', '', '石鼓区', '2005'], [2010, '430408',
                    '', '', '蒸湘区', '2005'], [2011, '430412', '', '', '南岳区', '2005'], [2012, '430421', '', '',
                    '衡阳县', '2005'], [2013, '430422', '', '', '衡南县', '2005'], [2014, '430423', '', '', '衡山县',
                    '2005'], [2015, '430424', '', '', '衡东县', '2005'], [2016, '430426', '', '', '祁东县', '2005'],
                [2017, '430481', '', '', '耒阳市', '2005'], [2018, '430482', '', '', '常宁市', '2005']]
        },
        "邵阳市": {
            id: 430500,
            value: [[2020, '430501', '', '', '市辖区', '2019'], [2021, '430502', '', '', '双清区', '2019'], [2022,
                    '430503', '', '', '大祥区', '2019'], [2023, '430511', '', '', '北塔区', '2019'], [2024, '430521',
                    '', '', '邵东县', '2019'], [2025, '430522', '', '', '新邵县', '2019'], [2026, '430523', '', '',
                    '邵阳县', '2019'], [2027, '430524', '', '', '隆回县', '2019'], [2028, '430525', '', '', '洞口县',
                    '2019'], [2029, '430527', '', '', '绥宁县', '2019'], [2030, '430528', '', '', '新宁县', '2019'],
                [2031, '430529', '', '', '城步苗族自治县', '2019'], [2032, '430581', '', '', '武冈市', '2019']]
        },
        "岳阳市": {
            id: 430600,
            value: [[2034, '430601', '', '', '市辖区', '2033'], [2035, '430602', '', '', '岳阳楼区', '2033'], [2036,
                '430603', '', '', '云溪区', '2033'], [2037, '430611', '', '', '君山区', '2033'], [2038, '430621',
                '', '', '岳阳县', '2033'], [2039, '430623', '', '', '华容县', '2033'], [2040, '430624', '', '',
                '湘阴县', '2033'], [2041, '430626', '', '', '平江县', '2033'], [2042, '430681', '', '', '汨罗市',
                '2033'], [2043, '430682', '', '', '临湘市', '2033']]
        },
        "常德市": {
            id: 430700,
            value: [[2045, '430701', '', '', '市辖区', '2044'], [2046, '430702', '', '', '武陵区', '2044'], [2047,
                '430703', '', '', '鼎城区', '2044'], [2048, '430721', '', '', '安乡县', '2044'], [2049, '430722',
                '', '', '汉寿县', '2044'], [2050, '430723', '', '', '澧县', '2044'], [2051, '430724', '', '',
                '临澧县', '2044'], [2052, '430725', '', '', '桃源县', '2044'], [2053, '430726', '', '', '石门县',
                '2044'], [2054, '430781', '', '', '津市市', '2044']]
        },
        "张家界市": {
            id: 430800,
            value: [[2056, '430801', '', '', '市辖区', '2055'], [2057, '430802', '', '', '永定区', '2055'], [2058,
                '430811', '', '', '武陵源区', '2055'], [2059, '430821', '', '', '慈利县', '2055'], [2060, '430822',
                '', '', '桑植县', '2055']]
        },
        "益阳市": {
            id: 430900,
            value: [[2062, '430901', '', '', '市辖区', '2061'], [2063, '430902', '', '', '资阳区', '2061'], [2064,
                '430903', '', '', '赫山区', '2061'], [2065, '430921', '', '', '南县', '2061'], [2066, '430922',
                '', '', '桃江县', '2061'], [2067, '430923', '', '', '安化县', '2061'], [2068, '430981', '', '',
                '沅江市', '2061']]
        },
        "郴州市": {
            id: 431000,
            value: [[2070, '431001', '', '', '市辖区', '2069'], [2071, '431002', '', '', '北湖区', '2069'], [2072,
                    '431003', '', '', '苏仙区', '2069'], [2073, '431021', '', '', '桂阳县', '2069'], [2074, '431022',
                    '', '', '宜章县', '2069'], [2075, '431023', '', '', '永兴县', '2069'], [2076, '431024', '', '',
                    '嘉禾县', '2069'], [2077, '431025', '', '', '临武县', '2069'], [2078, '431026', '', '', '汝城县',
                    '2069'], [2079, '431027', '', '', '桂东县', '2069'], [2080, '431028', '', '', '安仁县', '2069'],
                [2081, '431081', '', '', '资兴市', '2069']]
        },
        "永州市": {
            id: 431100,
            value: [[2083, '431101', '', '', '市辖区', '2082'], [2084, '431102', '', '', '零陵区', '2082'], [2085,
                    '431103', '', '', '冷水滩区', '2082'], [2086, '431121', '', '', '祁阳县', '2082'], [2087, '431122',
                    '', '', '东安县', '2082'], [2088, '431123', '', '', '双牌县', '2082'], [2089, '431124', '', '',
                    '道县', '2082'], [2090, '431125', '', '', '江永县', '2082'], [2091, '431126', '', '', '宁远县',
                    '2082'], [2092, '431127', '', '', '蓝山县', '2082'], [2093, '431128', '', '', '新田县', '2082'],
                [2094, '431129', '', '', '江华瑶族自治县', '2082']]
        },
        "怀化市": {
            id: 431200,
            value: [[2096, '431201', '', '', '市辖区', '2095'], [2097, '431202', '', '', '鹤城区', '2095'], [2098,
                '431221', '', '', '中方县', '2095'], [2099, '431222', '', '', '沅陵县', '2095'], [2100, '431223',
                '', '', '辰溪县', '2095'], [2101, '431224', '', '', '溆浦县', '2095'], [2102, '431225', '', '',
                '会同县', '2095'], [2103, '431226', '', '', '麻阳苗族自治县', '2095'], [2104, '431227', '', '',
                '新晃侗族自治县', '2095'], [2105, '431228', '', '', '芷江侗族自治县', '2095'], [2106, '431229', '', '',
                '靖州苗族侗族自治县', '2095'], [2107, '431230', '', '', '通道侗族自治县', '2095'], [2108, '431281', '', '',
                '洪江市', '2095']]
        },
        "娄底市": {
            id: 431300,
            value: [[2110, '431301', '', '', '市辖区', '2109'], [2111, '431302', '', '', '娄星区', '2109'], [2112,
                '431321', '', '', '双峰县', '2109'], [2113, '431322', '', '', '新化县', '2109'], [2114, '431381',
                '', '', '冷水江市', '2109'], [2115, '431382', '', '', '涟源市', '2109']]
        },
        "湘西土家族苗族自治州": {
            id: 433100,
            value: [[2117, '433101', '', '', '吉首市', '2116'], [2118, '433122', '', '', '泸溪县', '2116'], [2119,
                '433123', '', '', '凤凰县', '2116'], [2120, '433124', '', '', '花垣县', '2116'], [2121, '433125',
                '', '', '保靖县', '2116'], [2122, '433126', '', '', '古丈县', '2116'], [2123, '433127', '', '',
                '永顺县', '2116'], [2124, '433130', '', '', '龙山县', '2116']]
        }
    },
    "广东省": {
        "广州市": {
            id: 440100,
            value: [[2127, '440101', '', '', '市辖区', '2126'], [2128, '440103', '', '', '荔湾区', '2126'], [2129,
                    '440104', '', '', '越秀区', '2126'], [2130, '440105', '', '', '海珠区', '2126'], [2131, '440106',
                    '', '', '天河区', '2126'], [2132, '440111', '', '', '白云区', '2126'], [2133, '440112', '', '',
                    '黄埔区', '2126'], [2134, '440113', '', '', '番禺区', '2126'], [2135, '440114', '', '', '花都区',
                    '2126'], [2136, '440115', '', '', '南沙区', '2126'], [2137, '440117', '', '', '从化区', '2126'],
                [2138, '440118', '', '', '增城区', '2126']]
        },
        "韶关市": {
            id: 440200,
            value: [[2140, '440201', '', '', '市辖区', '2139'], [2141, '440203', '', '', '武江区', '2139'], [2142,
                '440204', '', '', '浈江区', '2139'], [2143, '440205', '', '', '曲江区', '2139'], [2144, '440222',
                '', '', '始兴县', '2139'], [2145, '440224', '', '', '仁化县', '2139'], [2146, '440229', '', '',
                '翁源县', '2139'], [2147, '440232', '', '', '乳源瑶族自治县', '2139'], [2148, '440233', '', '', '新丰县',
                '2139'], [2149, '440281', '', '', '乐昌市', '2139'], [2150, '440282', '', '', '南雄市', '2139']]
        },
        "深圳市": {
            id: 440300,
            value: [[2152, '440301', '', '', '市辖区', '2151'], [2153, '440303', '', '', '罗湖区', '2151'], [2154,
                '440304', '', '', '福田区', '2151'], [2155, '440305', '', '', '南山区', '2151'], [2156, '440306',
                '', '', '宝安区', '2151'], [2157, '440307', '', '', '龙岗区', '2151'], [2158, '440308', '', '',
                '盐田区', '2151']]
        },
        "珠海市": {
            id: 440400,
            value: [[2160, '440401', '', '', '市辖区', '2159'], [2161, '440402', '', '', '香洲区', '2159'], [2162,
                '440403', '', '', '斗门区', '2159'], [2163, '440404', '', '', '金湾区', '2159']]
        },
        "汕头市": {
            id: 440500,
            value: [[2165, '440501', '', '', '市辖区', '2164'], [2166, '440507', '', '', '龙湖区', '2164'], [2167,
                '440511', '', '', '金平区', '2164'], [2168, '440512', '', '', '濠江区', '2164'], [2169, '440513',
                '', '', '潮阳区', '2164'], [2170, '440514', '', '', '潮南区', '2164'], [2171, '440515', '', '',
                '澄海区', '2164'], [2172, '440523', '', '', '南澳县', '2164']]
        },
        "佛山市": {
            id: 440600,
            value: [[2174, '440601', '', '', '市辖区', '2173'], [2175, '440604', '', '', '禅城区', '2173'], [2176,
                '440605', '', '', '南海区', '2173'], [2177, '440606', '', '', '顺德区', '2173'], [2178, '440607',
                '', '', '三水区', '2173'], [2179, '440608', '', '', '高明区', '2173']]
        },
        "江门市": {
            id: 440700,
            value: [[2181, '440701', '', '', '市辖区', '2180'], [2182, '440703', '', '', '蓬江区', '2180'], [2183,
                '440704', '', '', '江海区', '2180'], [2184, '440705', '', '', '新会区', '2180'], [2185, '440781',
                '', '', '台山市', '2180'], [2186, '440783', '', '', '开平市', '2180'], [2187, '440784', '', '',
                '鹤山市', '2180'], [2188, '440785', '', '', '恩平市', '2180']]
        },
        "湛江市": {
            id: 440800,
            value: [[2190, '440801', '', '', '市辖区', '2189'], [2191, '440802', '', '', '赤坎区', '2189'], [2192,
                '440803', '', '', '霞山区', '2189'], [2193, '440804', '', '', '坡头区', '2189'], [2194, '440811',
                '', '', '麻章区', '2189'], [2195, '440823', '', '', '遂溪县', '2189'], [2196, '440825', '', '',
                '徐闻县', '2189'], [2197, '440881', '', '', '廉江市', '2189'], [2198, '440882', '', '', '雷州市',
                '2189'], [2199, '440883', '', '', '吴川市', '2189']]
        },
        "茂名市": {
            id: 440900,
            value: [[2201, '440901', '', '', '市辖区', '2200'], [2202, '440902', '', '', '茂南区', '2200'], [2203,
                '440904', '', '', '电白区', '2200'], [2204, '440981', '', '', '高州市', '2200'], [2205, '440982',
                '', '', '化州市', '2200'], [2206, '440983', '', '', '信宜市', '2200']]
        },
        "肇庆市": {
            id: 441200,
            value: [[2208, '441201', '', '', '市辖区', '2207'], [2209, '441202', '', '', '端州区', '2207'], [2210,
                '441203', '', '', '鼎湖区', '2207'], [2211, '441223', '', '', '广宁县', '2207'], [2212, '441224',
                '', '', '怀集县', '2207'], [2213, '441225', '', '', '封开县', '2207'], [2214, '441226', '', '',
                '德庆县', '2207'], [2215, '441283', '', '', '高要市', '2207'], [2216, '441284', '', '', '四会市',
                '2207']]
        },
        "惠州市": {
            id: 441300,
            value: [[2218, '441301', '', '', '市辖区', '2217'], [2219, '441302', '', '', '惠城区', '2217'], [2220,
                '441303', '', '', '惠阳区', '2217'], [2221, '441322', '', '', '博罗县', '2217'], [2222, '441323',
                '', '', '惠东县', '2217'], [2223, '441324', '', '', '龙门县', '2217']]
        },
        "梅州市": {
            id: 441400,
            value: [[2225, '441401', '', '', '市辖区', '2224'], [2226, '441402', '', '', '梅江区', '2224'], [2227,
                '441403', '', '', '梅县区', '2224'], [2228, '441422', '', '', '大埔县', '2224'], [2229, '441423',
                '', '', '丰顺县', '2224'], [2230, '441424', '', '', '五华县', '2224'], [2231, '441426', '', '',
                '平远县', '2224'], [2232, '441427', '', '', '蕉岭县', '2224'], [2233, '441481', '', '', '兴宁市',
                '2224']]
        },
        "汕尾市": {
            id: 441500,
            value: [[2235, '441501', '', '', '市辖区', '2234'], [2236, '441502', '', '', '城区', '2234'], [2237,
                '441521', '', '', '海丰县', '2234'], [2238, '441523', '', '', '陆河县', '2234'], [2239, '441581',
                '', '', '陆丰市', '2234']]
        },
        "河源市": {
            id: 441600,
            value: [[2241, '441601', '', '', '市辖区', '2240'], [2242, '441602', '', '', '源城区', '2240'], [2243,
                '441621', '', '', '紫金县', '2240'], [2244, '441622', '', '', '龙川县', '2240'], [2245, '441623',
                '', '', '连平县', '2240'], [2246, '441624', '', '', '和平县', '2240'], [2247, '441625', '', '',
                '东源县', '2240']]
        },
        "阳江市": {
            id: 441700,
            value: [[2249, '441701', '', '', '市辖区', '2248'], [2250, '441702', '', '', '江城区', '2248'], [2251,
                '441704', '', '', '阳东区', '2248'], [2252, '441721', '', '', '阳西县', '2248'], [2253, '441781',
                '', '', '阳春市', '2248']]
        },
        "清远市": {
            id: 441800,
            value: [[2255, '441801', '', '', '市辖区', '2254'], [2256, '441802', '', '', '清城区', '2254'], [2257,
                '441803', '', '', '清新区', '2254'], [2258, '441821', '', '', '佛冈县', '2254'], [2259, '441823',
                '', '', '阳山县', '2254'], [2260, '441825', '', '', '连山壮族瑶族自治县', '2254'], [2261, '441826', '',
                '', '连南瑶族自治县', '2254'], [2262, '441881', '', '', '英德市', '2254'], [2263, '441882', '', '',
                '连州市', '2254']]
        },
        "东莞市": {
            id: 441900,
            value: [[3924,"441901",'','',"莞城街道",'2264'],[3925,"441902",'','',"南城街道",'2264'],
            [3926,"441903",'','',"东城街道",'2264'],[3927,"441904",'','',"万江街道",'2264'],
            [3928,"441905",'','',"石龙镇",'2264'],[3929,"441906",'','',"石排镇",'2264'],
            [3930,"441907",'','',"茶山镇",'2264'],[3931,"441908",'','',"企石镇",'2264'],
            [3932,"441909",'','',"桥头镇",'2264'],[3933,"441910",'','',"东坑镇",'2264'],
            [3934,"441911",'','',"横沥镇",'2264'],[3935,"441912",'','',"常平镇",'2264'],
            [3936,"441913",'','',"虎门镇",'2264'],[3937,"441914",'','',"长安镇",'2264'],
            [3938,"441915",'','',"沙田镇",'2264'],[3939,"441916",'','',"厚街镇",'2264'],
            [3940,"441917",'','',"寮步镇",'2264'],[3941,"441918",'','',"大岭山镇",'2264'],
            [3942,"441919",'','',"大朗镇",'2264'],[3943,"441920",'','',"黄江镇",'2264'],
            [3944,"441921",'','',"樟木头镇",'2264'],[3945,"441922",'','',"谢岗镇",'2264'],
            [3946,"441923",'','',"塘厦镇",'2264'],[3947,"441924",'','',"清溪镇",'2264'],
            [3948,"441925",'','',"凤岗镇",'2264'],[3949,"441926",'','',"麻涌镇",'2264'],
            [3950,"441927",'','',"中堂镇",'2264'],[3951,"441928",'','',"高埗镇",'2264'],
            [3952,"441929",'','',"石碣镇",'2264'],[3953,"441930",'','',"望牛墩镇",'2264'],
            [3954,"441931",'','',"洪梅镇",'2264'],[3955,"441932",'','',"道滘镇",'2264']]
        },
        "中山市": {
            id: 442000,
            value: [[3956,'442001','','','石岐街道','2265'],[3957,'442002','','','东区街道','2265'],
            [3958,'442003','','','西区街道','2265'],[3959,'442004','','','南区街道','2265'],
            [3960,'442005','','','五桂山街道','2265'],[3961,'442006','','','中山港街道','2265'],
            [3962,'442007','','','黄圃镇','2265'],[3963,'442008','','','南头镇','2265'],
            [3964,'442009','','','东凤镇','2265'],[3965,'442010','','','阜沙镇','2265'],
            [3966,'442011','','','小榄镇','2265'],[3967,'442012','','','东升镇','2265'],
            [3968,'442013','','','古镇镇','2265'],[3969,'442014','','','横栏镇','2265'],
            [3970,'442015','','','三角镇','2265'],[3971,'442016','','','民众镇','2265'],
            [3972,'442017','','','南朗镇','2265'],[3973,'442018','','','港口镇','2265'],
            [3974,'442019','','','大涌镇','2265'],[3975,'442020','','','沙溪镇','2265'],
            [3976,'442021','','','三乡镇','2265'],[3977,'442022','','','板芙镇','2265'],
            [3978,'442023','','','神湾镇','2265'],[3979,'442024','','','坦洲镇','2265']]
        },
        "潮州市": {
            id: 445100,
            value: [[2267, '445101', '', '', '市辖区', '2266'], [2268, '445102', '', '', '湘桥区', '2266'], [2269,
                '445103', '', '', '潮安区', '2266'], [2270, '445122', '', '', '饶平县', '2266']]
        },
        "揭阳市": {
            id: 445200,
            value: [[2272, '445201', '', '', '市辖区', '2271'], [2273, '445202', '', '', '榕城区', '2271'], [2274,
                '445203', '', '', '揭东区', '2271'], [2275, '445222', '', '', '揭西县', '2271'], [2276, '445224',
                '', '', '惠来县', '2271'], [2277, '445281', '', '', '普宁市', '2271']]
        },
        "云浮市": {
            id: 445300,
            value: [[2279, '445301', '', '', '市辖区', '2278'], [2280, '445302', '', '', '云城区', '2278'], [2281,
                '445303', '', '', '云安区', '2278'], [2282, '445321', '', '', '新兴县', '2278'], [2283, '445322',
                '', '', '郁南县', '2278'], [2284, '445381', '', '', '罗定市', '2278']]
        }
    },
    "广西壮族自治区": {
        "南宁市": {
            id: 450100,
            value: [[2287, '450101', '', '', '市辖区', '2286'], [2288, '450102', '', '', '兴宁区', '2286'], [2289,
                    '450103', '', '', '青秀区', '2286'], [2290, '450105', '', '', '江南区', '2286'], [2291, '450107',
                    '', '', '西乡塘区', '2286'], [2292, '450108', '', '', '良庆区', '2286'], [2293, '450109', '', '',
                    '邕宁区', '2286'], [2294, '450122', '', '', '武鸣县', '2286'], [2295, '450123', '', '', '隆安县',
                    '2286'], [2296, '450124', '', '', '马山县', '2286'], [2297, '450125', '', '', '上林县', '2286'],
                [2298, '450126', '', '', '宾阳县', '2286'], [2299, '450127', '', '', '横县', '2286']]
        },
        "柳州市": {
            id: 450200,
            value: [[2301, '450201', '', '', '市辖区', '2300'], [2302, '450202', '', '', '城中区', '2300'], [2303,
                '450203', '', '', '鱼峰区', '2300'], [2304, '450204', '', '', '柳南区', '2300'], [2305, '450205',
                '', '', '柳北区', '2300'], [2306, '450221', '', '', '柳江县', '2300'], [2307, '450222', '', '',
                '柳城县', '2300'], [2308, '450223', '', '', '鹿寨县', '2300'], [2309, '450224', '', '', '融安县',
                '2300'], [2310, '450225', '', '', '融水苗族自治县', '2300'], [2311, '450226', '', '', '三江侗族自治县',
                '2300']]
        },
        "桂林市": {
            id: 450300,
            value: [[2313, '450301', '', '', '市辖区', '2312'], [2314, '450302', '', '', '秀峰区', '2312'], [2315,
                    '450303', '', '', '叠彩区', '2312'], [2316, '450304', '', '', '象山区', '2312'], [2317, '450305',
                    '', '', '七星区', '2312'], [2318, '450311', '', '', '雁山区', '2312'], [2319, '450312', '', '',
                    '临桂区', '2312'], [2320, '450321', '', '', '阳朔县', '2312'], [2321, '450323', '', '', '灵川县',
                    '2312'], [2322, '450324', '', '', '全州县', '2312'], [2323, '450325', '', '', '兴安县', '2312'],
                [2324, '450326', '', '', '永福县', '2312'], [2325, '450327', '', '', '灌阳县', '2312'], [2326,
                    '450328', '', '', '龙胜各族自治县', '2312'], [2327, '450329', '', '', '资源县', '2312'], [2328,
                    '450330', '', '', '平乐县', '2312'], [2329, '450331', '', '', '荔浦县', '2312'], [2330, '450332',
                    '', '', '恭城瑶族自治县', '2312']]
        },
        "梧州市": {
            id: 450400,
            value: [[2332, '450401', '', '', '市辖区', '2331'], [2333, '450403', '', '', '万秀区', '2331'], [2334,
                '450405', '', '', '长洲区', '2331'], [2335, '450406', '', '', '龙圩区', '2331'], [2336, '450421',
                '', '', '苍梧县', '2331'], [2337, '450422', '', '', '藤县', '2331'], [2338, '450423', '', '',
                '蒙山县', '2331'], [2339, '450481', '', '', '岑溪市', '2331']]
        },
        "北海市": {
            id: 450500,
            value: [[2341, '450501', '', '', '市辖区', '2340'], [2342, '450502', '', '', '海城区', '2340'], [2343,
                '450503', '', '', '银海区', '2340'], [2344, '450512', '', '', '铁山港区', '2340'], [2345, '450521',
                '', '', '合浦县', '2340']]
        },
        "防城港市": {
            id: 450600,
            value: [[2347, '450601', '', '', '市辖区', '2346'], [2348, '450602', '', '', '港口区', '2346'], [2349,
                '450603', '', '', '防城区', '2346'], [2350, '450621', '', '', '上思县', '2346'], [2351, '450681',
                '', '', '东兴市', '2346']]
        },
        "钦州市": {
            id: 450700,
            value: [[2353, '450701', '', '', '市辖区', '2352'], [2354, '450702', '', '', '钦南区', '2352'], [2355,
                '450703', '', '', '钦北区', '2352'], [2356, '450721', '', '', '灵山县', '2352'], [2357, '450722',
                '', '', '浦北县', '2352']]
        },
        "贵港市": {
            id: 450800,
            value: [[2359, '450801', '', '', '市辖区', '2358'], [2360, '450802', '', '', '港北区', '2358'], [2361,
                '450803', '', '', '港南区', '2358'], [2362, '450804', '', '', '覃塘区', '2358'], [2363, '450821',
                '', '', '平南县', '2358'], [2364, '450881', '', '', '桂平市', '2358']]
        },
        "玉林市": {
            id: 450900,
            value: [[2366, '450901', '', '', '市辖区', '2365'], [2367, '450902', '', '', '玉州区', '2365'], [2368,
                '450903', '', '', '福绵区', '2365'], [2369, '450921', '', '', '容县', '2365'], [2370, '450922',
                '', '', '陆川县', '2365'], [2371, '450923', '', '', '博白县', '2365'], [2372, '450924', '', '',
                '兴业县', '2365'], [2373, '450981', '', '', '北流市', '2365']]
        },
        "百色市": {
            id: 451000,
            value: [[2375, '451001', '', '', '市辖区', '2374'], [2376, '451002', '', '', '右江区', '2374'], [2377,
                    '451021', '', '', '田阳县', '2374'], [2378, '451022', '', '', '田东县', '2374'], [2379, '451023',
                    '', '', '平果县', '2374'], [2380, '451024', '', '', '德保县', '2374'], [2381, '451025', '', '',
                    '靖西县', '2374'], [2382, '451026', '', '', '那坡县', '2374'], [2383, '451027', '', '', '凌云县',
                    '2374'], [2384, '451028', '', '', '乐业县', '2374'], [2385, '451029', '', '', '田林县', '2374'],
                [2386, '451030', '', '', '西林县', '2374'], [2387, '451031', '', '', '隆林各族自治县', '2374']]
        },
        "贺州市": {
            id: 451100,
            value: [[2389, '451101', '', '', '市辖区', '2388'], [2390, '451102', '', '', '八步区', '2388'], [2391,
                '451119', '', '', '平桂管理区', '2388'], [2392, '451121', '', '', '昭平县', '2388'], [2393,
                '451122', '', '', '钟山县', '2388'], [2394, '451123', '', '', '富川瑶族自治县', '2388']]
        },
        "河池市": {
            id: 451200,
            value: [[2396, '451201', '', '', '市辖区', '2395'], [2397, '451202', '', '', '金城江区', '2395'], [2398,
                '451221', '', '', '南丹县', '2395'], [2399, '451222', '', '', '天峨县', '2395'], [2400, '451223',
                '', '', '凤山县', '2395'], [2401, '451224', '', '', '东兰县', '2395'], [2402, '451225', '', '',
                '罗城仫佬族自治县', '2395'], [2403, '451226', '', '', '环江毛南族自治县', '2395'], [2404, '451227', '', '',
                '巴马瑶族自治县', '2395'], [2405, '451228', '', '', '都安瑶族自治县', '2395'], [2406, '451229', '', '',
                '大化瑶族自治县', '2395'], [2407, '451281', '', '', '宜州市', '2395']]
        },
        "来宾市": {
            id: 451300,
            value: [[2409, '451301', '', '', '市辖区', '2408'], [2410, '451302', '', '', '兴宾区', '2408'], [2411,
                '451321', '', '', '忻城县', '2408'], [2412, '451322', '', '', '象州县', '2408'], [2413, '451323',
                '', '', '武宣县', '2408'], [2414, '451324', '', '', '金秀瑶族自治县', '2408'], [2415, '451381', '',
                '', '合山市', '2408']]
        },
        "崇左市": {
            id: 451400,
            value: [[2417, '451401', '', '', '市辖区', '2416'], [2418, '451402', '', '', '江州区', '2416'], [2419,
                '451421', '', '', '扶绥县', '2416'], [2420, '451422', '', '', '宁明县', '2416'], [2421, '451423',
                '', '', '龙州县', '2416'], [2422, '451424', '', '', '大新县', '2416'], [2423, '451425', '', '',
                '天等县', '2416'], [2424, '451481', '', '', '凭祥市', '2416']]
        }
    },
    "海南省": {
        "海口市": {
            id: 460100,
            value: [[2427, '460101', '', '', '市辖区', '2426'], [2428, '460105', '', '', '秀英区', '2426'], [2429,
                '460106', '', '', '龙华区', '2426'], [2430, '460107', '', '', '琼山区', '2426'], [2431, '460108',
                '', '', '美兰区', '2426']]
        },
        "三亚市": {
            id: 460200,
            value: [[2433, '460201', '', '', '市辖区', '2432'], [2434, '460202', '', '', '海棠区', '2432'], [2435,
                '460203', '', '', '吉阳区', '2432'], [2436, '460204', '', '', '天涯区', '2432'], [2437, '460205',
                '', '', '崖州区', '2432']]
        },
        "三沙市": {
            id: 460300,
            value: [[2439, '460321', '', '', '西沙群岛', '2438'], [2440, '460322', '', '', '南沙群岛', '2438'], [2441,
                '460323', '', '', '中沙群岛的岛礁及其海域', '2438']]
        },
        "省直辖县级行政区划": {
            id: 469000,
            value: [[2443, '469001', '', '', '五指山市', '2442'], [2444, '469002', '', '', '琼海市', '2442'], [2445,
                '469003', '', '', '儋州市', '2442'], [2446, '469005', '', '', '文昌市', '2442'], [2447, '469006',
                '', '', '万宁市', '2442'], [2448, '469007', '', '', '东方市', '2442'], [2449, '469021', '', '',
                '定安县', '2442'], [2450, '469022', '', '', '屯昌县', '2442'], [2451, '469023', '', '', '澄迈县',
                '2442'], [2452, '469024', '', '', '临高县', '2442'], [2453, '469025', '', '', '白沙黎族自治县',
                '2442'], [2454, '469026', '', '', '昌江黎族自治县', '2442'], [2455, '469027', '', '', '乐东黎族自治县',
                '2442'], [2456, '469028', '', '', '陵水黎族自治县', '2442'], [2457, '469029', '', '', '保亭黎族苗族自治县',
                '2442'], [2458, '469030', '', '', '琼中黎族苗族自治县', '2442']]
        }
    },
    "重庆市": {
        "重庆市": {
            id: 500000,
            value: [[2460, '500101', '', '', '万州区', '2459'], [2461, '500102', '', '', '涪陵区', '2459'], [2462,
                    '500103', '', '', '渝中区', '2459'], [2463, '500104', '', '', '大渡口区', '2459'], [2464, '500105',
                    '', '', '江北区', '2459'], [2465, '500106', '', '', '沙坪坝区', '2459'], [2466, '500107', '', '',
                    '九龙坡区', '2459'], [2467, '500108', '', '', '南岸区', '2459'], [2468, '500109', '', '', '北碚区',
                    '2459'], [2469, '500110', '', '', '綦江区', '2459'], [2470, '500111', '', '', '大足区', '2459'],
                [2471, '500112', '', '', '渝北区', '2459'], [2472, '500113', '', '', '巴南区', '2459'], [2473,
                    '500114', '', '', '黔江区', '2459'], [2474, '500115', '', '', '长寿区', '2459'], [2475, '500116',
                    '', '', '江津区', '2459'], [2476, '500117', '', '', '合川区', '2459'], [2477, '500118', '', '',
                    '永川区', '2459'], [2478, '500119', '', '', '南川区', '2459'], [2479, '500120', '', '', '璧山区',
                    '2459'], [2480, '500151', '', '', '铜梁区', '2459'], [2481, '500223', '', '', '潼南县', '2459'],
                [2482, '500226', '', '', '荣昌县', '2459'], [2483, '500228', '', '', '梁平县', '2459'], [2484,
                    '500229', '', '', '城口县', '2459'], [2485, '500230', '', '', '丰都县', '2459'], [2486, '500231',
                    '', '', '垫江县', '2459'], [2487, '500232', '', '', '武隆县', '2459'], [2488, '500233', '', '',
                    '忠县', '2459'], [2489, '500234', '', '', '开县', '2459'], [2490, '500235', '', '', '云阳县',
                    '2459'], [2491, '500236', '', '', '奉节县', '2459'], [2492, '500237', '', '', '巫山县', '2459'],
                [2493, '500238', '', '', '巫溪县', '2459'], [2494, '500240', '', '', '石柱土家族自治县', '2459'], [2495,
                    '500241', '', '', '秀山土家族苗族自治县', '2459'], [2496, '500242', '', '', '酉阳土家族苗族自治县', '2459'], [
                    2497, '500243', '', '', '彭水苗族土家族自治县', '2459']]
        }
    },
    "四川省": {
        "成都市": {
            id: 510100,
            value: [[2500, '510101', '', '', '市辖区', '2499'], [2501, '510104', '', '', '锦江区', '2499'], [2502,
                    '510105', '', '', '青羊区', '2499'], [2503, '510106', '', '', '金牛区', '2499'], [2504, '510107',
                    '', '', '武侯区', '2499'], [2505, '510108', '', '', '成华区', '2499'], [2506, '510112', '', '',
                    '龙泉驿区', '2499'], [2507, '510113', '', '', '青白江区', '2499'], [2508, '510114', '', '', '新都区',
                    '2499'], [2509, '510115', '', '', '温江区', '2499'], [2510, '510121', '', '', '金堂县', '2499'],
                [2511, '510122', '', '', '双流县', '2499'], [2512, '510124', '', '', '郫县', '2499'], [2513,
                    '510129', '', '', '大邑县', '2499'], [2514, '510131', '', '', '蒲江县', '2499'], [2515, '510132',
                    '', '', '新津县', '2499'], [2516, '510181', '', '', '都江堰市', '2499'], [2517, '510182', '', '',
                    '彭州市', '2499'], [2518, '510183', '', '', '邛崃市', '2499'], [2519, '510184', '', '', '崇州市',
                    '2499']]
        },
        "自贡市": {
            id: 510300,
            value: [[2521, '510301', '', '', '市辖区', '2520'], [2522, '510302', '', '', '自流井区', '2520'], [2523,
                '510303', '', '', '贡井区', '2520'], [2524, '510304', '', '', '大安区', '2520'], [2525, '510311',
                '', '', '沿滩区', '2520'], [2526, '510321', '', '', '荣县', '2520'], [2527, '510322', '', '',
                '富顺县', '2520']]
        },
        "攀枝花市": {
            id: 510400,
            value: [[2529, '510401', '', '', '市辖区', '2528'], [2530, '510402', '', '', '东区', '2528'], [2531,
                '510403', '', '', '西区', '2528'], [2532, '510411', '', '', '仁和区', '2528'], [2533, '510421',
                '', '', '米易县', '2528'], [2534, '510422', '', '', '盐边县', '2528']]
        },
        "泸州市": {
            id: 510500,
            value: [[2536, '510501', '', '', '市辖区', '2535'], [2537, '510502', '', '', '江阳区', '2535'], [2538,
                '510503', '', '', '纳溪区', '2535'], [2539, '510504', '', '', '龙马潭区', '2535'], [2540, '510521',
                '', '', '泸县', '2535'], [2541, '510522', '', '', '合江县', '2535'], [2542, '510524', '', '',
                '叙永县', '2535'], [2543, '510525', '', '', '古蔺县', '2535']]
        },
        "德阳市": {
            id: 510600,
            value: [[2545, '510601', '', '', '市辖区', '2544'], [2546, '510603', '', '', '旌阳区', '2544'], [2547,
                '510623', '', '', '中江县', '2544'], [2548, '510626', '', '', '罗江县', '2544'], [2549, '510681',
                '', '', '广汉市', '2544'], [2550, '510682', '', '', '什邡市', '2544'], [2551, '510683', '', '',
                '绵竹市', '2544']]
        },
        "绵阳市": {
            id: 510700,
            value: [[2553, '510701', '', '', '市辖区', '2552'], [2554, '510703', '', '', '涪城区', '2552'], [2555,
                '510704', '', '', '游仙区', '2552'], [2556, '510722', '', '', '三台县', '2552'], [2557, '510723',
                '', '', '盐亭县', '2552'], [2558, '510724', '', '', '安县', '2552'], [2559, '510725', '', '',
                '梓潼县', '2552'], [2560, '510726', '', '', '北川羌族自治县', '2552'], [2561, '510727', '', '', '平武县',
                '2552'], [2562, '510781', '', '', '江油市', '2552']]
        },
        "广元市": {
            id: 510800,
            value: [[2564, '510801', '', '', '市辖区', '2563'], [2565, '510802', '', '', '利州区', '2563'], [2566,
                '510811', '', '', '昭化区', '2563'], [2567, '510812', '', '', '朝天区', '2563'], [2568, '510821',
                '', '', '旺苍县', '2563'], [2569, '510822', '', '', '青川县', '2563'], [2570, '510823', '', '',
                '剑阁县', '2563'], [2571, '510824', '', '', '苍溪县', '2563']]
        },
        "遂宁市": {
            id: 510900,
            value: [[2573, '510901', '', '', '市辖区', '2572'], [2574, '510903', '', '', '船山区', '2572'], [2575,
                '510904', '', '', '安居区', '2572'], [2576, '510921', '', '', '蓬溪县', '2572'], [2577, '510922',
                '', '', '射洪县', '2572'], [2578, '510923', '', '', '大英县', '2572']]
        },
        "内江市": {
            id: 511000,
            value: [[2580, '511001', '', '', '市辖区', '2579'], [2581, '511002', '', '', '市中区', '2579'], [2582,
                '511011', '', '', '东兴区', '2579'], [2583, '511024', '', '', '威远县', '2579'], [2584, '511025',
                '', '', '资中县', '2579'], [2585, '511028', '', '', '隆昌县', '2579']]
        },
        "乐山市": {
            id: 511100,
            value: [[2587, '511101', '', '', '市辖区', '2586'], [2588, '511102', '', '', '市中区', '2586'], [2589,
                '511111', '', '', '沙湾区', '2586'], [2590, '511112', '', '', '五通桥区', '2586'], [2591, '511113',
                '', '', '金口河区', '2586'], [2592, '511123', '', '', '犍为县', '2586'], [2593, '511124', '', '',
                '井研县', '2586'], [2594, '511126', '', '', '夹江县', '2586'], [2595, '511129', '', '', '沐川县',
                '2586'], [2596, '511132', '', '', '峨边彝族自治县', '2586'], [2597, '511133', '', '', '马边彝族自治县',
                '2586'], [2598, '511181', '', '', '峨眉山市', '2586']]
        },
        "南充市": {
            id: 511300,
            value: [[2600, '511301', '', '', '市辖区', '2599'], [2601, '511302', '', '', '顺庆区', '2599'], [2602,
                '511303', '', '', '高坪区', '2599'], [2603, '511304', '', '', '嘉陵区', '2599'], [2604, '511321',
                '', '', '南部县', '2599'], [2605, '511322', '', '', '营山县', '2599'], [2606, '511323', '', '',
                '蓬安县', '2599'], [2607, '511324', '', '', '仪陇县', '2599'], [2608, '511325', '', '', '西充县',
                '2599'], [2609, '511381', '', '', '阆中市', '2599']]
        },
        "眉山市": {
            id: 511400,
            value: [[2611, '511401', '', '', '市辖区', '2610'], [2612, '511402', '', '', '东坡区', '2610'], [2613,
                '511403', '', '', '彭山区', '2610'], [2614, '511421', '', '', '仁寿县', '2610'], [2615, '511423',
                '', '', '洪雅县', '2610'], [2616, '511424', '', '', '丹棱县', '2610'], [2617, '511425', '', '',
                '青神县', '2610']]
        },
        "宜宾市": {
            id: 511500,
            value: [[2619, '511501', '', '', '市辖区', '2618'], [2620, '511502', '', '', '翠屏区', '2618'], [2621,
                '511503', '', '', '南溪区', '2618'], [2622, '511521', '', '', '宜宾县', '2618'], [2623, '511523',
                '', '', '江安县', '2618'], [2624, '511524', '', '', '长宁县', '2618'], [2625, '511525', '', '',
                '高县', '2618'], [2626, '511526', '', '', '珙县', '2618'], [2627, '511527', '', '', '筠连县',
                '2618'], [2628, '511528', '', '', '兴文县', '2618'], [2629, '511529', '', '', '屏山县', '2618']]
        },
        "广安市": {
            id: 511600,
            value: [[2631, '511601', '', '', '市辖区', '2630'], [2632, '511602', '', '', '广安区', '2630'], [2633,
                '511603', '', '', '前锋区', '2630'], [2634, '511621', '', '', '岳池县', '2630'], [2635, '511622',
                '', '', '武胜县', '2630'], [2636, '511623', '', '', '邻水县', '2630'], [2637, '511681', '', '',
                '华蓥市', '2630']]
        },
        "达州市": {
            id: 511700,
            value: [[2639, '511701', '', '', '市辖区', '2638'], [2640, '511702', '', '', '通川区', '2638'], [2641,
                '511703', '', '', '达川区', '2638'], [2642, '511722', '', '', '宣汉县', '2638'], [2643, '511723',
                '', '', '开江县', '2638'], [2644, '511724', '', '', '大竹县', '2638'], [2645, '511725', '', '',
                '渠县', '2638'], [2646, '511781', '', '', '万源市', '2638']]
        },
        "雅安市": {
            id: 511800,
            value: [[2648, '511801', '', '', '市辖区', '2647'], [2649, '511802', '', '', '雨城区', '2647'], [2650,
                '511803', '', '', '名山区', '2647'], [2651, '511822', '', '', '荥经县', '2647'], [2652, '511823',
                '', '', '汉源县', '2647'], [2653, '511824', '', '', '石棉县', '2647'], [2654, '511825', '', '',
                '天全县', '2647'], [2655, '511826', '', '', '芦山县', '2647'], [2656, '511827', '', '', '宝兴县',
                '2647']]
        },
        "巴中市": {
            id: 511900,
            value: [[2658, '511901', '', '', '市辖区', '2657'], [2659, '511902', '', '', '巴州区', '2657'], [2660,
                '511903', '', '', '恩阳区', '2657'], [2661, '511921', '', '', '通江县', '2657'], [2662, '511922',
                '', '', '南江县', '2657'], [2663, '511923', '', '', '平昌县', '2657']]
        },
        "资阳市": {
            id: 512000,
            value: [[2665, '512001', '', '', '市辖区', '2664'], [2666, '512002', '', '', '雁江区', '2664'], [2667,
                '512021', '', '', '安岳县', '2664'], [2668, '512022', '', '', '乐至县', '2664'], [2669, '512081',
                '', '', '简阳市', '2664']]
        },
        "阿坝藏族羌族自治州": {
            id: 513200,
            value: [[2671, '513221', '', '', '汶川县', '2670'], [2672, '513222', '', '', '理县', '2670'], [2673,
                    '513223', '', '', '茂县', '2670'], [2674, '513224', '', '', '松潘县', '2670'], [2675, '513225',
                    '', '', '九寨沟县', '2670'], [2676, '513226', '', '', '金川县', '2670'], [2677, '513227', '', '',
                    '小金县', '2670'], [2678, '513228', '', '', '黑水县', '2670'], [2679, '513229', '', '', '马尔康县',
                    '2670'], [2680, '513230', '', '', '壤塘县', '2670'], [2681, '513231', '', '', '阿坝县', '2670'],
                [2682, '513232', '', '', '若尔盖县', '2670'], [2683, '513233', '', '', '红原县', '2670']]
        },
        "甘孜藏族自治州": {
            id: 513300,
            value: [[2685, '513321', '', '', '康定县', '2684'], [2686, '513322', '', '', '泸定县', '2684'], [2687,
                    '513323', '', '', '丹巴县', '2684'], [2688, '513324', '', '', '九龙县', '2684'], [2689, '513325',
                    '', '', '雅江县', '2684'], [2690, '513326', '', '', '道孚县', '2684'], [2691, '513327', '', '',
                    '炉霍县', '2684'], [2692, '513328', '', '', '甘孜县', '2684'], [2693, '513329', '', '', '新龙县',
                    '2684'], [2694, '513330', '', '', '德格县', '2684'], [2695, '513331', '', '', '白玉县', '2684'],
                [2696, '513332', '', '', '石渠县', '2684'], [2697, '513333', '', '', '色达县', '2684'], [2698,
                    '513334', '', '', '理塘县', '2684'], [2699, '513335', '', '', '巴塘县', '2684'], [2700, '513336',
                    '', '', '乡城县', '2684'], [2701, '513337', '', '', '稻城县', '2684'], [2702, '513338', '', '',
                    '得荣县', '2684']]
        },
        "凉山彝族自治州": {
            id: 513400,
            value: [[2704, '513401', '', '', '西昌市', '2703'], [2705, '513422', '', '', '木里藏族自治县', '2703'], [2706,
                    '513423', '', '', '盐源县', '2703'], [2707, '513424', '', '', '德昌县', '2703'], [2708, '513425',
                    '', '', '会理县', '2703'], [2709, '513426', '', '', '会东县', '2703'], [2710, '513427', '', '',
                    '宁南县', '2703'], [2711, '513428', '', '', '普格县', '2703'], [2712, '513429', '', '', '布拖县',
                    '2703'], [2713, '513430', '', '', '金阳县', '2703'], [2714, '513431', '', '', '昭觉县', '2703'],
                [2715, '513432', '', '', '喜德县', '2703'], [2716, '513433', '', '', '冕宁县', '2703'], [2717,
                    '513434', '', '', '越西县', '2703'], [2718, '513435', '', '', '甘洛县', '2703'], [2719, '513436',
                    '', '', '美姑县', '2703'], [2720, '513437', '', '', '雷波县', '2703']]
        }
    },
    "贵州省": {
        "贵阳市": {
            id: 520100,
            value: [[2723, '520101', '', '', '市辖区', '2722'], [2724, '520102', '', '', '南明区', '2722'], [2725,
                '520103', '', '', '云岩区', '2722'], [2726, '520111', '', '', '花溪区', '2722'], [2727, '520112',
                '', '', '乌当区', '2722'], [2728, '520113', '', '', '白云区', '2722'], [2729, '520115', '', '',
                '观山湖区', '2722'], [2730, '520121', '', '', '开阳县', '2722'], [2731, '520122', '', '', '息烽县',
                '2722'], [2732, '520123', '', '', '修文县', '2722'], [2733, '520181', '', '', '清镇市', '2722']]
        },
        "六盘水市": {
            id: 520200,
            value: [[2735, '520201', '', '', '钟山区', '2734'], [2736, '520203', '', '', '六枝特区', '2734'], [2737,
                '520221', '', '', '水城县', '2734'], [2738, '520222', '', '', '盘县', '2734']]
        },
        "遵义市": {
            id: 520300,
            value: [[2740, '520301', '', '', '市辖区', '2739'], [2741, '520302', '', '', '红花岗区', '2739'], [2742,
                '520303', '', '', '汇川区', '2739'], [2743, '520321', '', '', '遵义县', '2739'], [2744, '520322',
                '', '', '桐梓县', '2739'], [2745, '520323', '', '', '绥阳县', '2739'], [2746, '520324', '', '',
                '正安县', '2739'], [2747, '520325', '', '', '道真仡佬族苗族自治县', '2739'], [2748, '520326', '', '',
                '务川仡佬族苗族自治县', '2739'], [2749, '520327', '', '', '凤冈县', '2739'], [2750, '520328', '', '',
                '湄潭县', '2739'], [2751, '520329', '', '', '余庆县', '2739'], [2752, '520330', '', '', '习水县',
                '2739'], [2753, '520381', '', '', '赤水市', '2739'], [2754, '520382', '', '', '仁怀市', '2739']]
        },
        "安顺市": {
            id: 520400,
            value: [[2756, '520401', '', '', '市辖区', '2755'], [2757, '520402', '', '', '西秀区', '2755'], [2758,
                '520403', '', '', '平坝区', '2755'], [2759, '520422', '', '', '普定县', '2755'], [2760, '520423',
                '', '', '镇宁布依族苗族自治县', '2755'], [2761, '520424', '', '', '关岭布依族苗族自治县', '2755'], [2762,
                '520425', '', '', '紫云苗族布依族自治县', '2755']]
        },
        "毕节市": {
            id: 520500,
            value: [[2764, '520501', '', '', '市辖区', '2763'], [2765, '520502', '', '', '七星关区', '2763'], [2766,
                '520521', '', '', '大方县', '2763'], [2767, '520522', '', '', '黔西县', '2763'], [2768, '520523',
                '', '', '金沙县', '2763'], [2769, '520524', '', '', '织金县', '2763'], [2770, '520525', '', '',
                '纳雍县', '2763'], [2771, '520526', '', '', '威宁彝族回族苗族自治县', '2763'], [2772, '520527', '', '',
                '赫章县', '2763']]
        },
        "铜仁市": {
            id: 520600,
            value: [[2774, '520601', '', '', '市辖区', '2773'], [2775, '520602', '', '', '碧江区', '2773'], [2776,
                '520603', '', '', '万山区', '2773'], [2777, '520621', '', '', '江口县', '2773'], [2778, '520622',
                '', '', '玉屏侗族自治县', '2773'], [2779, '520623', '', '', '石阡县', '2773'], [2780, '520624', '',
                '', '思南县', '2773'], [2781, '520625', '', '', '印江土家族苗族自治县', '2773'], [2782, '520626', '', '',
                '德江县', '2773'], [2783, '520627', '', '', '沿河土家族自治县', '2773'], [2784, '520628', '', '',
                '松桃苗族自治县', '2773']]
        },
        "黔西南布依族苗族自治州": {
            id: 522300,
            value: [[2786, '522301', '', '', '兴义市', '2785'], [2787, '522322', '', '', '兴仁县', '2785'], [2788,
                '522323', '', '', '普安县', '2785'], [2789, '522324', '', '', '晴隆县', '2785'], [2790, '522325',
                '', '', '贞丰县', '2785'], [2791, '522326', '', '', '望谟县', '2785'], [2792, '522327', '', '',
                '册亨县', '2785'], [2793, '522328', '', '', '安龙县', '2785']]
        },
        "黔东南苗族侗族自治州": {
            id: 522600,
            value: [[2795, '522601', '', '', '凯里市', '2794'], [2796, '522622', '', '', '黄平县', '2794'], [2797,
                    '522623', '', '', '施秉县', '2794'], [2798, '522624', '', '', '三穗县', '2794'], [2799, '522625',
                    '', '', '镇远县', '2794'], [2800, '522626', '', '', '岑巩县', '2794'], [2801, '522627', '', '',
                    '天柱县', '2794'], [2802, '522628', '', '', '锦屏县', '2794'], [2803, '522629', '', '', '剑河县',
                    '2794'], [2804, '522630', '', '', '台江县', '2794'], [2805, '522631', '', '', '黎平县', '2794'],
                [2806, '522632', '', '', '榕江县', '2794'], [2807, '522633', '', '', '从江县', '2794'], [2808,
                    '522634', '', '', '雷山县', '2794'], [2809, '522635', '', '', '麻江县', '2794'], [2810, '522636',
                    '', '', '丹寨县', '2794']]
        },
        "黔南布依族苗族自治州": {
            id: 522700,
            value: [[2812, '522701', '', '', '都匀市', '2811'], [2813, '522702', '', '', '福泉市', '2811'], [2814,
                    '522722', '', '', '荔波县', '2811'], [2815, '522723', '', '', '贵定县', '2811'], [2816, '522725',
                    '', '', '瓮安县', '2811'], [2817, '522726', '', '', '独山县', '2811'], [2818, '522727', '', '',
                    '平塘县', '2811'], [2819, '522728', '', '', '罗甸县', '2811'], [2820, '522729', '', '', '长顺县',
                    '2811'], [2821, '522730', '', '', '龙里县', '2811'], [2822, '522731', '', '', '惠水县', '2811'],
                [2823, '522732', '', '', '三都水族自治县', '2811']]
        }
    },
    "云南省": {
        "昆明市": {
            id: 530100,
            value: [[2826, '530101', '', '', '市辖区', '2825'], [2827, '530102', '', '', '五华区', '2825'], [2828,
                '530103', '', '', '盘龙区', '2825'], [2829, '530111', '', '', '官渡区', '2825'], [2830, '530112',
                '', '', '西山区', '2825'], [2831, '530113', '', '', '东川区', '2825'], [2832, '530114', '', '',
                '呈贡区', '2825'], [2833, '530122', '', '', '晋宁县', '2825'], [2834, '530124', '', '', '富民县',
                '2825'], [2835, '530125', '', '', '宜良县', '2825'], [2836, '530126', '', '', '石林彝族自治县',
                '2825'], [2837, '530127', '', '', '嵩明县', '2825'], [2838, '530128', '', '', '禄劝彝族苗族自治县',
                '2825'], [2839, '530129', '', '', '寻甸回族彝族自治县', '2825'], [2840, '530181', '', '', '安宁市',
                '2825']]
        },
        "曲靖市": {
            id: 530300,
            value: [[2842, '530301', '', '', '市辖区', '2841'], [2843, '530302', '', '', '麒麟区', '2841'], [2844,
                '530321', '', '', '马龙县', '2841'], [2845, '530322', '', '', '陆良县', '2841'], [2846, '530323',
                '', '', '师宗县', '2841'], [2847, '530324', '', '', '罗平县', '2841'], [2848, '530325', '', '',
                '富源县', '2841'], [2849, '530326', '', '', '会泽县', '2841'], [2850, '530328', '', '', '沾益县',
                '2841'], [2851, '530381', '', '', '宣威市', '2841']]
        },
        "玉溪市": {
            id: 530400,
            value: [[2853, '530401', '', '', '市辖区', '2852'], [2854, '530402', '', '', '红塔区', '2852'], [2855,
                '530421', '', '', '江川县', '2852'], [2856, '530422', '', '', '澄江县', '2852'], [2857, '530423',
                '', '', '通海县', '2852'], [2858, '530424', '', '', '华宁县', '2852'], [2859, '530425', '', '',
                '易门县', '2852'], [2860, '530426', '', '', '峨山彝族自治县', '2852'], [2861, '530427', '', '',
                '新平彝族傣族自治县', '2852'], [2862, '530428', '', '', '元江哈尼族彝族傣族自治县', '2852']]
        },
        "保山市": {
            id: 530500,
            value: [[2864, '530501', '', '', '市辖区', '2863'], [2865, '530502', '', '', '隆阳区', '2863'], [2866,
                '530521', '', '', '施甸县', '2863'], [2867, '530522', '', '', '腾冲县', '2863'], [2868, '530523',
                '', '', '龙陵县', '2863'], [2869, '530524', '', '', '昌宁县', '2863']]
        },
        "昭通市": {
            id: 530600,
            value: [[2871, '530601', '', '', '市辖区', '2870'], [2872, '530602', '', '', '昭阳区', '2870'], [2873,
                    '530621', '', '', '鲁甸县', '2870'], [2874, '530622', '', '', '巧家县', '2870'], [2875, '530623',
                    '', '', '盐津县', '2870'], [2876, '530624', '', '', '大关县', '2870'], [2877, '530625', '', '',
                    '永善县', '2870'], [2878, '530626', '', '', '绥江县', '2870'], [2879, '530627', '', '', '镇雄县',
                    '2870'], [2880, '530628', '', '', '彝良县', '2870'], [2881, '530629', '', '', '威信县', '2870'],
                [2882, '530630', '', '', '水富县', '2870']]
        },
        "丽江市": {
            id: 530700,
            value: [[2884, '530701', '', '', '市辖区', '2883'], [2885, '530702', '', '', '古城区', '2883'], [2886,
                '530721', '', '', '玉龙纳西族自治县', '2883'], [2887, '530722', '', '', '永胜县', '2883'], [2888,
                '530723', '', '', '华坪县', '2883'], [2889, '530724', '', '', '宁蒗彝族自治县', '2883']]
        },
        "普洱市": {
            id: 530800,
            value: [[2891, '530801', '', '', '市辖区', '2890'], [2892, '530802', '', '', '思茅区', '2890'], [2893,
                    '530821', '', '', '宁洱哈尼族彝族自治县', '2890'], [2894, '530822', '', '', '墨江哈尼族自治县', '2890'], [
                    2895, '530823', '', '', '景东彝族自治县', '2890'], [2896, '530824', '', '', '景谷傣族彝族自治县', '2890'],
                [2897, '530825', '', '', '镇沅彝族哈尼族拉祜族自治县', '2890'], [2898, '530826', '', '', '江城哈尼族彝族自治县',
                    '2890'], [2899, '530827', '', '', '孟连傣族拉祜族佤族自治县', '2890'], [2900, '530828', '', '',
                    '澜沧拉祜族自治县', '2890'], [2901, '530829', '', '', '西盟佤族自治县', '2890']]
        },
        "临沧市": {
            id: 530900,
            value: [[2903, '530901', '', '', '市辖区', '2902'], [2904, '530902', '', '', '临翔区', '2902'], [2905,
                '530921', '', '', '凤庆县', '2902'], [2906, '530922', '', '', '云县', '2902'], [2907, '530923',
                '', '', '永德县', '2902'], [2908, '530924', '', '', '镇康县', '2902'], [2909, '530925', '', '',
                '双江拉祜族佤族布朗族傣族自治县', '2902'], [2910, '530926', '', '', '耿马傣族佤族自治县', '2902'], [2911, '530927',
                '', '', '沧源佤族自治县', '2902']]
        },
        "楚雄彝族自治州": {
            id: 532300,
            value: [[2913, '532301', '', '', '楚雄市', '2912'], [2914, '532322', '', '', '双柏县', '2912'], [2915,
                '532323', '', '', '牟定县', '2912'], [2916, '532324', '', '', '南华县', '2912'], [2917, '532325',
                '', '', '姚安县', '2912'], [2918, '532326', '', '', '大姚县', '2912'], [2919, '532327', '', '',
                '永仁县', '2912'], [2920, '532328', '', '', '元谋县', '2912'], [2921, '532329', '', '', '武定县',
                '2912'], [2922, '532331', '', '', '禄丰县', '2912']]
        },
        "红河哈尼族彝族自治州": {
            id: 532500,
            value: [[2924, '532501', '', '', '个旧市', '2923'], [2925, '532502', '', '', '开远市', '2923'], [2926,
                '532503', '', '', '蒙自市', '2923'], [2927, '532504', '', '', '弥勒市', '2923'], [2928, '532523',
                '', '', '屏边苗族自治县', '2923'], [2929, '532524', '', '', '建水县', '2923'], [2930, '532525', '',
                '', '石屏县', '2923'], [2931, '532527', '', '', '泸西县', '2923'], [2932, '532528', '', '', '元阳县',
                '2923'], [2933, '532529', '', '', '红河县', '2923'], [2934, '532530', '', '', '金平苗族瑶族傣族自治县',
                '2923'], [2935, '532531', '', '', '绿春县', '2923'], [2936, '532532', '', '', '河口瑶族自治县',
                '2923']]
        },
        "文山壮族苗族自治州": {
            id: 532600,
            value: [[2938, '532601', '', '', '文山市', '2937'], [2939, '532622', '', '', '砚山县', '2937'], [2940,
                '532623', '', '', '西畴县', '2937'], [2941, '532624', '', '', '麻栗坡县', '2937'], [2942, '532625',
                '', '', '马关县', '2937'], [2943, '532626', '', '', '丘北县', '2937'], [2944, '532627', '', '',
                '广南县', '2937'], [2945, '532628', '', '', '富宁县', '2937']]
        },
        "西双版纳傣族自治州": {
            id: 532800,
            value: [[2947, '532801', '', '', '景洪市', '2946'], [2948, '532822', '', '', '勐海县', '2946'], [2949,
                '532823', '', '', '勐腊县', '2946']]
        },
        "大理白族自治州": {
            id: 532900,
            value: [[2951, '532901', '', '', '大理市', '2950'], [2952, '532922', '', '', '漾濞彝族自治县', '2950'], [2953,
                '532923', '', '', '祥云县', '2950'], [2954, '532924', '', '', '宾川县', '2950'], [2955, '532925',
                '', '', '弥渡县', '2950'], [2956, '532926', '', '', '南涧彝族自治县', '2950'], [2957, '532927', '',
                '', '巍山彝族回族自治县', '2950'], [2958, '532928', '', '', '永平县', '2950'], [2959, '532929', '', '',
                '云龙县', '2950'], [2960, '532930', '', '', '洱源县', '2950'], [2961, '532931', '', '', '剑川县',
                '2950'], [2962, '532932', '', '', '鹤庆县', '2950']]
        },
        "德宏傣族景颇族自治州": {
            id: 533100,
            value: [[2964, '533102', '', '', '瑞丽市', '2963'], [2965, '533103', '', '', '芒市', '2963'], [2966,
                '533122', '', '', '梁河县', '2963'], [2967, '533123', '', '', '盈江县', '2963'], [2968, '533124',
                '', '', '陇川县', '2963']]
        },
        "怒江傈僳族自治州": {
            id: 533300,
            value: [[2970, '533321', '', '', '泸水县', '2969'], [2971, '533323', '', '', '福贡县', '2969'], [2972,
                '533324', '', '', '贡山独龙族怒族自治县', '2969'], [2973, '533325', '', '', '兰坪白族普米族自治县', '2969']]
        },
        "迪庆藏族自治州": {
            id: 533400,
            value: [[2975, '533401', '', '', '香格里拉市', '2974'], [2976, '533422', '', '', '德钦县', '2974'], [2977,
                '533423', '', '', '维西傈僳族自治县', '2974']]
        }
    },
    "西藏自治区": {
        "拉萨市": {
            id: 540100,
            value: [[2980, '540101', '', '', '市辖区', '2979'], [2981, '540102', '', '', '城关区', '2979'], [2982,
                '540121', '', '', '林周县', '2979'], [2983, '540122', '', '', '当雄县', '2979'], [2984, '540123',
                '', '', '尼木县', '2979'], [2985, '540124', '', '', '曲水县', '2979'], [2986, '540125', '', '',
                '堆龙德庆县', '2979'], [2987, '540126', '', '', '达孜县', '2979'], [2988, '540127', '', '', '墨竹工卡县',
                '2979']]
        },
        "日喀则市": {
            id: 540200,
            value: [[2990, '540201', '', '', '市辖区', '2989'], [2991, '540202', '', '', '桑珠孜区', '2989'], [2992,
                    '540221', '', '', '南木林县', '2989'], [2993, '540222', '', '', '江孜县', '2989'], [2994, '540223',
                    '', '', '定日县', '2989'], [2995, '540224', '', '', '萨迦县', '2989'], [2996, '540225', '', '',
                    '拉孜县', '2989'], [2997, '540226', '', '', '昂仁县', '2989'], [2998, '540227', '', '', '谢通门县',
                    '2989'], [2999, '540228', '', '', '白朗县', '2989'], [3000, '540229', '', '', '仁布县', '2989'],
                [3001, '540230', '', '', '康马县', '2989'], [3002, '540231', '', '', '定结县', '2989'], [3003,
                    '540232', '', '', '仲巴县', '2989'], [3004, '540233', '', '', '亚东县', '2989'], [3005, '540234',
                    '', '', '吉隆县', '2989'], [3006, '540235', '', '', '聂拉木县', '2989'], [3007, '540236', '', '',
                    '萨嘎县', '2989'], [3008, '540237', '', '', '岗巴县', '2989']]
        },
        "昌都市": {
            id: 540300,
            value: [[3010, '540301', '', '', '市辖区', '3009'], [3011, '540302', '', '', '卡若区', '3009'], [3012,
                    '540321', '', '', '江达县', '3009'], [3013, '540322', '', '', '贡觉县', '3009'], [3014, '540323',
                    '', '', '类乌齐县', '3009'], [3015, '540324', '', '', '丁青县', '3009'], [3016, '540325', '', '',
                    '察雅县', '3009'], [3017, '540326', '', '', '八宿县', '3009'], [3018, '540327', '', '', '左贡县',
                    '3009'], [3019, '540328', '', '', '芒康县', '3009'], [3020, '540329', '', '', '洛隆县', '3009'],
                [3021, '540330', '', '', '边坝县', '3009']]
        },
        "山南地区": {
            id: 542200,
            value: [[3023, '542221', '', '', '乃东县', '3022'], [3024, '542222', '', '', '扎囊县', '3022'], [3025,
                    '542223', '', '', '贡嘎县', '3022'], [3026, '542224', '', '', '桑日县', '3022'], [3027, '542225',
                    '', '', '琼结县', '3022'], [3028, '542226', '', '', '曲松县', '3022'], [3029, '542227', '', '',
                    '措美县', '3022'], [3030, '542228', '', '', '洛扎县', '3022'], [3031, '542229', '', '', '加查县',
                    '3022'], [3032, '542231', '', '', '隆子县', '3022'], [3033, '542232', '', '', '错那县', '3022'],
                [3034, '542233', '', '', '浪卡子县', '3022']]
        },
        "那曲地区": {
            id: 542400,
            value: [[3036, '542421', '', '', '那曲县', '3035'], [3037, '542422', '', '', '嘉黎县', '3035'], [3038,
                '542423', '', '', '比如县', '3035'], [3039, '542424', '', '', '聂荣县', '3035'], [3040, '542425',
                '', '', '安多县', '3035'], [3041, '542426', '', '', '申扎县', '3035'], [3042, '542427', '', '',
                '索县', '3035'], [3043, '542428', '', '', '班戈县', '3035'], [3044, '542429', '', '', '巴青县',
                '3035'], [3045, '542430', '', '', '尼玛县', '3035'], [3046, '542431', '', '', '双湖县', '3035']]
        },
        "阿里地区": {
            id: 542500,
            value: [[3048, '542521', '', '', '普兰县', '3047'], [3049, '542522', '', '', '札达县', '3047'], [3050,
                '542523', '', '', '噶尔县', '3047'], [3051, '542524', '', '', '日土县', '3047'], [3052, '542525',
                '', '', '革吉县', '3047'], [3053, '542526', '', '', '改则县', '3047'], [3054, '542527', '', '',
                '措勤县', '3047']]
        },
        "林芝地区": {
            id: 542600,
            value: [[3056, '542621', '', '', '林芝县', '3055'], [3057, '542622', '', '', '工布江达县', '3055'], [3058,
                '542623', '', '', '米林县', '3055'], [3059, '542624', '', '', '墨脱县', '3055'], [3060, '542625',
                '', '', '波密县', '3055'], [3061, '542626', '', '', '察隅县', '3055'], [3062, '542627', '', '',
                '朗县', '3055']]
        }
    },
    "陕西省": {
        "西安市": {
            id: 610100,
            value: [[3065, '610101', '', '', '市辖区', '3064'], [3066, '610102', '', '', '新城区', '3064'], [3067,
                    '610103', '', '', '碑林区', '3064'], [3068, '610104', '', '', '莲湖区', '3064'], [3069, '610111',
                    '', '', '灞桥区', '3064'], [3070, '610112', '', '', '未央区', '3064'], [3071, '610113', '', '',
                    '雁塔区', '3064'], [3072, '610114', '', '', '阎良区', '3064'], [3073, '610115', '', '', '临潼区',
                    '3064'], [3074, '610116', '', '', '长安区', '3064'], [3075, '610117', '', '', '高陵区', '3064'],
                [3076, '610122', '', '', '蓝田县', '3064'], [3077, '610124', '', '', '周至县', '3064'], [3078,
                    '610125', '', '', '户县', '3064']]
        },
        "铜川市": {
            id: 610200,
            value: [[3080, '610201', '', '', '市辖区', '3079'], [3081, '610202', '', '', '王益区', '3079'], [3082,
                '610203', '', '', '印台区', '3079'], [3083, '610204', '', '', '耀州区', '3079'], [3084, '610222',
                '', '', '宜君县', '3079']]
        },
        "宝鸡市": {
            id: 610300,
            value: [[3086, '610301', '', '', '市辖区', '3085'], [3087, '610302', '', '', '渭滨区', '3085'], [3088,
                    '610303', '', '', '金台区', '3085'], [3089, '610304', '', '', '陈仓区', '3085'], [3090, '610322',
                    '', '', '凤翔县', '3085'], [3091, '610323', '', '', '岐山县', '3085'], [3092, '610324', '', '',
                    '扶风县', '3085'], [3093, '610326', '', '', '眉县', '3085'], [3094, '610327', '', '', '陇县',
                    '3085'], [3095, '610328', '', '', '千阳县', '3085'], [3096, '610329', '', '', '麟游县', '3085'],
                [3097, '610330', '', '', '凤县', '3085'], [3098, '610331', '', '', '太白县', '3085']]
        },
        "咸阳市": {
            id: 610400,
            value: [[3100, '610401', '', '', '市辖区', '3099'], [3101, '610402', '', '', '秦都区', '3099'], [3102,
                '610403', '', '', '杨陵区', '3099'], [3103, '610404', '', '', '渭城区', '3099'], [3104, '610422',
                '', '', '三原县', '3099'], [3105, '610423', '', '', '泾阳县', '3099'], [3106, '610424', '', '',
                '乾县', '3099'], [3107, '610425', '', '', '礼泉县', '3099'], [3108, '610426', '', '', '永寿县',
                '3099'], [3109, '610427', '', '', '彬县', '3099'], [3110, '610428', '', '', '长武县', '3099'], [
                3111, '610429', '', '', '旬邑县', '3099'], [3112, '610430', '', '', '淳化县', '3099'], [3113,
                '610431', '', '', '武功县', '3099'], [3114, '610481', '', '', '兴平市', '3099']]
        },
        "渭南市": {
            id: 610500,
            value: [[3116, '610501', '', '', '市辖区', '3115'], [3117, '610502', '', '', '临渭区', '3115'], [3118,
                    '610521', '', '', '华县', '3115'], [3119, '610522', '', '', '潼关县', '3115'], [3120, '610523',
                    '', '', '大荔县', '3115'], [3121, '610524', '', '', '合阳县', '3115'], [3122, '610525', '', '',
                    '澄城县', '3115'], [3123, '610526', '', '', '蒲城县', '3115'], [3124, '610527', '', '', '白水县',
                    '3115'], [3125, '610528', '', '', '富平县', '3115'], [3126, '610581', '', '', '韩城市', '3115'],
                [3127, '610582', '', '', '华阴市', '3115']]
        },
        "延安市": {
            id: 610600,
            value: [[3129, '610601', '', '', '市辖区', '3128'], [3130, '610602', '', '', '宝塔区', '3128'], [3131,
                '610621', '', '', '延长县', '3128'], [3132, '610622', '', '', '延川县', '3128'], [3133, '610623',
                '', '', '子长县', '3128'], [3134, '610624', '', '', '安塞县', '3128'], [3135, '610625', '', '',
                '志丹县', '3128'], [3136, '610626', '', '', '吴起县', '3128'], [3137, '610627', '', '', '甘泉县',
                '3128'], [3138, '610628', '', '', '富县', '3128'], [3139, '610629', '', '', '洛川县', '3128'], [
                3140, '610630', '', '', '宜川县', '3128'], [3141, '610631', '', '', '黄龙县', '3128'], [3142,
                '610632', '', '', '黄陵县', '3128']]
        },
        "汉中市": {
            id: 610700,
            value: [[3144, '610701', '', '', '市辖区', '3143'], [3145, '610702', '', '', '汉台区', '3143'], [3146,
                    '610721', '', '', '南郑县', '3143'], [3147, '610722', '', '', '城固县', '3143'], [3148, '610723',
                    '', '', '洋县', '3143'], [3149, '610724', '', '', '西乡县', '3143'], [3150, '610725', '', '',
                    '勉县', '3143'], [3151, '610726', '', '', '宁强县', '3143'], [3152, '610727', '', '', '略阳县',
                    '3143'], [3153, '610728', '', '', '镇巴县', '3143'], [3154, '610729', '', '', '留坝县', '3143'],
                [3155, '610730', '', '', '佛坪县', '3143']]
        },
        "榆林市": {
            id: 610800,
            value: [[3157, '610801', '', '', '市辖区', '3156'], [3158, '610802', '', '', '榆阳区', '3156'], [3159,
                '610821', '', '', '神木县', '3156'], [3160, '610822', '', '', '府谷县', '3156'], [3161, '610823',
                '', '', '横山县', '3156'], [3162, '610824', '', '', '靖边县', '3156'], [3163, '610825', '', '',
                '定边县', '3156'], [3164, '610826', '', '', '绥德县', '3156'], [3165, '610827', '', '', '米脂县',
                '3156'], [3166, '610828', '', '', '佳县', '3156'], [3167, '610829', '', '', '吴堡县', '3156'], [
                3168, '610830', '', '', '清涧县', '3156'], [3169, '610831', '', '', '子洲县', '3156']]
        },
        "安康市": {
            id: 610900,
            value: [[3171, '610901', '', '', '市辖区', '3170'], [3172, '610920', '', '', '汉滨区', '3170'], [3173,
                '610921', '', '', '汉阴县', '3170'], [3174, '610922', '', '', '石泉县', '3170'], [3175, '610923',
                '', '', '宁陕县', '3170'], [3176, '610924', '', '', '紫阳县', '3170'], [3177, '610925', '', '',
                '岚皋县', '3170'], [3178, '610926', '', '', '平利县', '3170'], [3179, '610927', '', '', '镇坪县',
                '3170'], [3180, '610928', '', '', '旬阳县', '3170'], [3181, '610929', '', '', '白河县', '3170']]
        },
        "商洛市": {
            id: 611000,
            value: [[3183, '611001', '', '', '市辖区', '3182'], [3184, '611002', '', '', '商州区', '3182'], [3185,
                '611021', '', '', '洛南县', '3182'], [3186, '611022', '', '', '丹凤县', '3182'], [3187, '611023',
                '', '', '商南县', '3182'], [3188, '611024', '', '', '山阳县', '3182'], [3189, '611025', '', '',
                '镇安县', '3182'], [3190, '611026', '', '', '柞水县', '3182']]
        }
    },
    "甘肃省": {
        "兰州市": {
            id: 620100,
            value: [[3193, '620101', '', '', '市辖区', '3192'], [3194, '620102', '', '', '城关区', '3192'], [3195,
                '620103', '', '', '七里河区', '3192'], [3196, '620104', '', '', '西固区', '3192'], [3197, '620105',
                '', '', '安宁区', '3192'], [3198, '620111', '', '', '红古区', '3192'], [3199, '620121', '', '',
                '永登县', '3192'], [3200, '620122', '', '', '皋兰县', '3192'], [3201, '620123', '', '', '榆中县',
                '3192']]
        },
        "嘉峪关市": {
            id: 620200,
            value: [[3203, '620201', '', '', '市辖区', '3202']]
        },
        "金昌市": {
            id: 620300,
            value: [[3205, '620301', '', '', '市辖区', '3204'], [3206, '620302', '', '', '金川区', '3204'], [3207,
                '620321', '', '', '永昌县', '3204']]
        },
        "白银市": {
            id: 620400,
            value: [[3209, '620401', '', '', '市辖区', '3208'], [3210, '620402', '', '', '白银区', '3208'], [3211,
                '620403', '', '', '平川区', '3208'], [3212, '620421', '', '', '靖远县', '3208'], [3213, '620422',
                '', '', '会宁县', '3208'], [3214, '620423', '', '', '景泰县', '3208']]
        },
        "天水市": {
            id: 620500,
            value: [[3216, '620501', '', '', '市辖区', '3215'], [3217, '620502', '', '', '秦州区', '3215'], [3218,
                '620503', '', '', '麦积区', '3215'], [3219, '620521', '', '', '清水县', '3215'], [3220, '620522',
                '', '', '秦安县', '3215'], [3221, '620523', '', '', '甘谷县', '3215'], [3222, '620524', '', '',
                '武山县', '3215'], [3223, '620525', '', '', '张家川回族自治县', '3215']]
        },
        "武威市": {
            id: 620600,
            value: [[3225, '620601', '', '', '市辖区', '3224'], [3226, '620602', '', '', '凉州区', '3224'], [3227,
                '620621', '', '', '民勤县', '3224'], [3228, '620622', '', '', '古浪县', '3224'], [3229, '620623',
                '', '', '天祝藏族自治县', '3224']]
        },
        "张掖市": {
            id: 620700,
            value: [[3231, '620701', '', '', '市辖区', '3230'], [3232, '620702', '', '', '甘州区', '3230'], [3233,
                '620721', '', '', '肃南裕固族自治县', '3230'], [3234, '620722', '', '', '民乐县', '3230'], [3235,
                '620723', '', '', '临泽县', '3230'], [3236, '620724', '', '', '高台县', '3230'], [3237, '620725',
                '', '', '山丹县', '3230']]
        },
        "平凉市": {
            id: 620800,
            value: [[3239, '620801', '', '', '市辖区', '3238'], [3240, '620802', '', '', '崆峒区', '3238'], [3241,
                '620821', '', '', '泾川县', '3238'], [3242, '620822', '', '', '灵台县', '3238'], [3243, '620823',
                '', '', '崇信县', '3238'], [3244, '620824', '', '', '华亭县', '3238'], [3245, '620825', '', '',
                '庄浪县', '3238'], [3246, '620826', '', '', '静宁县', '3238']]
        },
        "酒泉市": {
            id: 620900,
            value: [[3248, '620901', '', '', '市辖区', '3247'], [3249, '620902', '', '', '肃州区', '3247'], [3250,
                '620921', '', '', '金塔县', '3247'], [3251, '620922', '', '', '瓜州县', '3247'], [3252, '620923',
                '', '', '肃北蒙古族自治县', '3247'], [3253, '620924', '', '', '阿克塞哈萨克族自治县', '3247'], [3254,
                '620981', '', '', '玉门市', '3247'], [3255, '620982', '', '', '敦煌市', '3247']]
        },
        "庆阳市": {
            id: 621000,
            value: [[3257, '621001', '', '', '市辖区', '3256'], [3258, '621002', '', '', '西峰区', '3256'], [3259,
                '621021', '', '', '庆城县', '3256'], [3260, '621022', '', '', '环县', '3256'], [3261, '621023',
                '', '', '华池县', '3256'], [3262, '621024', '', '', '合水县', '3256'], [3263, '621025', '', '',
                '正宁县', '3256'], [3264, '621026', '', '', '宁县', '3256'], [3265, '621027', '', '', '镇原县',
                '3256']]
        },
        "定西市": {
            id: 621100,
            value: [[3267, '621101', '', '', '市辖区', '3266'], [3268, '621102', '', '', '安定区', '3266'], [3269,
                '621121', '', '', '通渭县', '3266'], [3270, '621122', '', '', '陇西县', '3266'], [3271, '621123',
                '', '', '渭源县', '3266'], [3272, '621124', '', '', '临洮县', '3266'], [3273, '621125', '', '',
                '漳县', '3266'], [3274, '621126', '', '', '岷县', '3266']]
        },
        "陇南市": {
            id: 621200,
            value: [[3276, '621201', '', '', '市辖区', '3275'], [3277, '621202', '', '', '武都区', '3275'], [3278,
                '621221', '', '', '成县', '3275'], [3279, '621222', '', '', '文县', '3275'], [3280, '621223',
                '', '', '宕昌县', '3275'], [3281, '621224', '', '', '康县', '3275'], [3282, '621225', '', '',
                '西和县', '3275'], [3283, '621226', '', '', '礼县', '3275'], [3284, '621227', '', '', '徽县',
                '3275'], [3285, '621228', '', '', '两当县', '3275']]
        },
        "临夏回族自治州": {
            id: 622900,
            value: [[3287, '622901', '', '', '临夏市', '3286'], [3288, '622921', '', '', '临夏县', '3286'], [3289,
                '622922', '', '', '康乐县', '3286'], [3290, '622923', '', '', '永靖县', '3286'], [3291, '622924',
                '', '', '广河县', '3286'], [3292, '622925', '', '', '和政县', '3286'], [3293, '622926', '', '',
                '东乡族自治县', '3286'], [3294, '622927', '', '', '积石山保安族东乡族撒拉族自治县', '3286']]
        },
        "甘南藏族自治州": {
            id: 623000,
            value: [[3296, '623001', '', '', '合作市', '3295'], [3297, '623021', '', '', '临潭县', '3295'], [3298,
                '623022', '', '', '卓尼县', '3295'], [3299, '623023', '', '', '舟曲县', '3295'], [3300, '623024',
                '', '', '迭部县', '3295'], [3301, '623025', '', '', '玛曲县', '3295'], [3302, '623026', '', '',
                '碌曲县', '3295'], [3303, '623027', '', '', '夏河县', '3295']]
        }
    },
    "青海省": {
        "西宁市": {
            id: 630100,
            value: [[3306, '630101', '', '', '市辖区', '3305'], [3307, '630102', '', '', '城东区', '3305'], [3308,
                '630103', '', '', '城中区', '3305'], [3309, '630104', '', '', '城西区', '3305'], [3310, '630105',
                '', '', '城北区', '3305'], [3311, '630121', '', '', '大通回族土族自治县', '3305'], [3312, '630122', '',
                '', '湟中县', '3305'], [3313, '630123', '', '', '湟源县', '3305']]
        },
        "海东市": {
            id: 630200,
            value: [[3315, '630201', '', '', '市辖区', '3314'], [3316, '630202', '', '', '乐都区', '3314'], [3317,
                '630221', '', '', '平安县', '3314'], [3318, '630222', '', '', '民和回族土族自治县', '3314'], [3319,
                '630223', '', '', '互助土族自治县', '3314'], [3320, '630224', '', '', '化隆回族自治县', '3314'], [3321,
                '630225', '', '', '循化撒拉族自治县', '3314']]
        },
        "海北藏族自治州": {
            id: 632200,
            value: [[3323, '632221', '', '', '门源回族自治县', '3322'], [3324, '632222', '', '', '祁连县', '3322'], [3325,
                '632223', '', '', '海晏县', '3322'], [3326, '632224', '', '', '刚察县', '3322']]
        },
        "黄南藏族自治州": {
            id: 632300,
            value: [[3328, '632321', '', '', '同仁县', '3327'], [3329, '632322', '', '', '尖扎县', '3327'], [3330,
                '632323', '', '', '泽库县', '3327'], [3331, '632324', '', '', '河南蒙古族自治县', '3327']]
        },
        "海南藏族自治州": {
            id: 632500,
            value: [[3333, '632521', '', '', '共和县', '3332'], [3334, '632522', '', '', '同德县', '3332'], [3335,
                '632523', '', '', '贵德县', '3332'], [3336, '632524', '', '', '兴海县', '3332'], [3337, '632525',
                '', '', '贵南县', '3332']]
        },
        "果洛藏族自治州": {
            id: 632600,
            value: [[3339, '632621', '', '', '玛沁县', '3338'], [3340, '632622', '', '', '班玛县', '3338'], [3341,
                '632623', '', '', '甘德县', '3338'], [3342, '632624', '', '', '达日县', '3338'], [3343, '632625',
                '', '', '久治县', '3338'], [3344, '632626', '', '', '玛多县', '3338']]
        },
        "玉树藏族自治州": {
            id: 632700,
            value: [[3346, '632701', '', '', '玉树市', '3345'], [3347, '632722', '', '', '杂多县', '3345'], [3348,
                '632723', '', '', '称多县', '3345'], [3349, '632724', '', '', '治多县', '3345'], [3350, '632725',
                '', '', '囊谦县', '3345'], [3351, '632726', '', '', '曲麻莱县', '3345']]
        },
        "海西蒙古族藏族自治州": {
            id: 632800,
            value: [[3353, '632801', '', '', '格尔木市', '3352'], [3354, '632802', '', '', '德令哈市', '3352'], [3355,
                '632821', '', '', '乌兰县', '3352'], [3356, '632822', '', '', '都兰县', '3352'], [3357, '632823',
                '', '', '天峻县', '3352']]
        }
    },
    "宁夏回族自治区": {
        "银川市": {
            id: 640100,
            value: [[3360, '640101', '', '', '市辖区', '3359'], [3361, '640104', '', '', '兴庆区', '3359'], [3362,
                '640105', '', '', '西夏区', '3359'], [3363, '640106', '', '', '金凤区', '3359'], [3364, '640121',
                '', '', '永宁县', '3359'], [3365, '640122', '', '', '贺兰县', '3359'], [3366, '640181', '', '',
                '灵武市', '3359']]
        },
        "石嘴山市": {
            id: 640200,
            value: [[3368, '640201', '', '', '市辖区', '3367'], [3369, '640202', '', '', '大武口区', '3367'], [3370,
                '640205', '', '', '惠农区', '3367'], [3371, '640221', '', '', '平罗县', '3367']]
        },
        "吴忠市": {
            id: 640300,
            value: [[3373, '640301', '', '', '市辖区', '3372'], [3374, '640302', '', '', '利通区', '3372'], [3375,
                '640303', '', '', '红寺堡区', '3372'], [3376, '640323', '', '', '盐池县', '3372'], [3377, '640324',
                '', '', '同心县', '3372'], [3378, '640381', '', '', '青铜峡市', '3372']]
        },
        "固原市": {
            id: 640400,
            value: [[3380, '640401', '', '', '市辖区', '3379'], [3381, '640402', '', '', '原州区', '3379'], [3382,
                '640422', '', '', '西吉县', '3379'], [3383, '640423', '', '', '隆德县', '3379'], [3384, '640424',
                '', '', '泾源县', '3379'], [3385, '640425', '', '', '彭阳县', '3379']]
        },
        "中卫市": {
            id: 640500,
            value: [[3387, '640501', '', '', '市辖区', '3386'], [3388, '640502', '', '', '沙坡头区', '3386'], [3389,
                '640521', '', '', '中宁县', '3386'], [3390, '640522', '', '', '海原县', '3386']]
        }
    },
    "新疆维吾尔自治区": {
        "乌鲁木齐市": {
            id: 650100,
            value: [[3393, '650101', '', '', '市辖区', '3392'], [3394, '650102', '', '', '天山区', '3392'], [3395,
                '650103', '', '', '沙依巴克区', '3392'], [3396, '650104', '', '', '新市区', '3392'], [3397,
                '650105', '', '', '水磨沟区', '3392'], [3398, '650106', '', '', '头屯河区', '3392'], [3399,
                '650107', '', '', '达坂城区', '3392'], [3400, '650109', '', '', '米东区', '3392'], [3401, '650121',
                '', '', '乌鲁木齐县', '3392']]
        },
        "克拉玛依市": {
            id: 650200,
            value: [[3403, '650201', '', '', '市辖区', '3402'], [3404, '650202', '', '', '独山子区', '3402'], [3405,
                '650203', '', '', '克拉玛依区', '3402'], [3406, '650204', '', '', '白碱滩区', '3402'], [3407,
                '650205', '', '', '乌尔禾区', '3402']]
        },
        "吐鲁番地区": {
            id: 652100,
            value: [[3409, '652101', '', '', '吐鲁番市', '3408'], [3410, '652122', '', '', '鄯善县', '3408'], [3411,
                '652123', '', '', '托克逊县', '3408']]
        },
        "哈密地区": {
            id: 652200,
            value: [[3413, '652201', '', '', '哈密市', '3412'], [3414, '652222', '', '', '巴里坤哈萨克自治县', '3412'], [3415,
                '652223', '', '', '伊吾县', '3412']]
        },
        "昌吉回族自治州": {
            id: 652300,
            value: [[3417, '652301', '', '', '昌吉市', '3416'], [3418, '652302', '', '', '阜康市', '3416'], [3419,
                '652323', '', '', '呼图壁县', '3416'], [3420, '652324', '', '', '玛纳斯县', '3416'], [3421,
                '652325', '', '', '奇台县', '3416'], [3422, '652327', '', '', '吉木萨尔县', '3416'], [3423,
                '652328', '', '', '木垒哈萨克自治县', '3416']]
        },
        "博尔塔拉蒙古自治州": {
            id: 652700,
            value: [[3425, '652701', '', '', '博乐市', '3424'], [3426, '652702', '', '', '阿拉山口市', '3424'], [3427,
                '652722', '', '', '精河县', '3424'], [3428, '652723', '', '', '温泉县', '3424']]
        },
        "巴音郭楞蒙古自治州": {
            id: 652800,
            value: [[3430, '652801', '', '', '库尔勒市', '3429'], [3431, '652822', '', '', '轮台县', '3429'], [3432,
                '652823', '', '', '尉犁县', '3429'], [3433, '652824', '', '', '若羌县', '3429'], [3434, '652825',
                '', '', '且末县', '3429'], [3435, '652826', '', '', '焉耆回族自治县', '3429'], [3436, '652827', '',
                '', '和静县', '3429'], [3437, '652828', '', '', '和硕县', '3429'], [3438, '652829', '', '', '博湖县',
                '3429']]
        },
        "阿克苏地区": {
            id: 652900,
            value: [[3440, '652901', '', '', '阿克苏市', '3439'], [3441, '652922', '', '', '温宿县', '3439'], [3442,
                '652923', '', '', '库车县', '3439'], [3443, '652924', '', '', '沙雅县', '3439'], [3444, '652925',
                '', '', '新和县', '3439'], [3445, '652926', '', '', '拜城县', '3439'], [3446, '652927', '', '',
                '乌什县', '3439'], [3447, '652928', '', '', '阿瓦提县', '3439'], [3448, '652929', '', '', '柯坪县',
                '3439']]
        },
        "克孜勒苏柯尔克孜自治州": {
            id: 653000,
            value: [[3450, '653001', '', '', '阿图什市', '3449'], [3451, '653022', '', '', '阿克陶县', '3449'], [3452,
                '653023', '', '', '阿合奇县', '3449'], [3453, '653024', '', '', '乌恰县', '3449']]
        },
        "喀什地区": {
            id: 653100,
            value: [[3455, '653101', '', '', '喀什市', '3454'], [3456, '653121', '', '', '疏附县', '3454'], [3457,
                    '653122', '', '', '疏勒县', '3454'], [3458, '653123', '', '', '英吉沙县', '3454'], [3459, '653124',
                    '', '', '泽普县', '3454'], [3460, '653125', '', '', '莎车县', '3454'], [3461, '653126', '', '',
                    '叶城县', '3454'], [3462, '653127', '', '', '麦盖提县', '3454'], [3463, '653128', '', '', '岳普湖县',
                    '3454'], [3464, '653129', '', '', '伽师县', '3454'], [3465, '653130', '', '', '巴楚县', '3454'],
                [3466, '653131', '', '', '塔什库尔干塔吉克自治县', '3454']]
        },
        "和田地区": {
            id: 653200,
            value: [[3468, '653201', '', '', '和田市', '3467'], [3469, '653221', '', '', '和田县', '3467'], [3470,
                '653222', '', '', '墨玉县', '3467'], [3471, '653223', '', '', '皮山县', '3467'], [3472, '653224',
                '', '', '洛浦县', '3467'], [3473, '653225', '', '', '策勒县', '3467'], [3474, '653226', '', '',
                '于田县', '3467'], [3475, '653227', '', '', '民丰县', '3467']]
        },
        "伊犁哈萨克自治州": {
            id: 654000,
            value: [[3477, '654002', '', '', '伊宁市', '3476'], [3478, '654003', '', '', '奎屯市', '3476'], [3479,
                    '654004', '', '', '霍尔果斯市', '3476'], [3480, '654021', '', '', '伊宁县', '3476'], [3481,
                    '654022', '', '', '察布查尔锡伯自治县', '3476'], [3482, '654023', '', '', '霍城县', '3476'], [3483,
                    '654024', '', '', '巩留县', '3476'], [3484, '654025', '', '', '新源县', '3476'], [3485, '654026',
                    '', '', '昭苏县', '3476'], [3486, '654027', '', '', '特克斯县', '3476'], [3487, '654028', '', '',
                    '尼勒克县', '3476'], [3488, '654200', '', '', '塔城地区', '3476'], [3489, '654201', '', '', '塔城市',
                    '3476'], [3490, '654202', '', '', '乌苏市', '3476'], [3491, '654221', '', '', '额敏县', '3476'],
                [3492, '654223', '', '', '沙湾县', '3476'], [3493, '654224', '', '', '托里县', '3476'], [3494,
                    '654225', '', '', '裕民县', '3476'], [3495, '654226', '', '', '和布克赛尔蒙古自治县', '3476'], [3496,
                    '654300', '', '', '阿勒泰地区', '3476'], [3497, '654301', '', '', '阿勒泰市', '3476'], [3498,
                    '654321', '', '', '布尔津县', '3476'], [3499, '654322', '', '', '富蕴县', '3476'], [3500, '654323',
                    '', '', '福海县', '3476'], [3501, '654324', '', '', '哈巴河县', '3476'], [3502, '654325', '', '',
                    '青河县', '3476'], [3503, '654326', '', '', '吉木乃县', '3476']]
        },
        "自治区直辖县级行政区划": {
            id: 659000,
            value: [[3505, '659001', '', '', '石河子市', '3504'], [3506, '659002', '', '', '阿拉尔市', '3504'], [3507,
                '659003', '', '', '图木舒克市', '3504'], [3508, '659004', '', '', '五家渠市', '3504'], [3509,
                '659005', '', '', '北屯市', '3504'], [3510, '659006', '', '', '铁门关市', '3504'], [3511, '659007',
                '', '', '双河市', '3504']]
        }
    },
    "台湾省": {
        "台北市": {
            id: 710100,
            value: [[3514, '710101', '', '', '松山区', '3513'], [3515, '710102', '', '', '信义区', '3513'], [3516,
                    '710103', '', '', '大安区', '3513'], [3517, '710104', '', '', '中山区', '3513'], [3518, '710105',
                    '', '', '中正区', '3513'], [3519, '710106', '', '', '大同区', '3513'], [3520, '710107', '', '',
                    '万华区', '3513'], [3521, '710108', '', '', '文山区', '3513'], [3522, '710109', '', '', '南港区',
                    '3513'], [3523, '710110', '', '', '内湖区', '3513'], [3524, '710111', '', '', '士林区', '3513'],
                [3525, '710112', '', '', '北投区', '3513']]
        },
        "高雄市": {
            id: 710200,
            value: [[3527, '710201', '', '', '盐埕区', '3526'], [3528, '710202', '', '', '鼓山区', '3526'], [3529,
                    '710203', '', '', '左营区', '3526'], [3530, '710204', '', '', '楠梓区', '3526'], [3531, '710205',
                    '', '', '三民区', '3526'], [3532, '710206', '', '', '新兴区', '3526'], [3533, '710207', '', '',
                    '前金区', '3526'], [3534, '710208', '', '', '苓雅区', '3526'], [3535, '710209', '', '', '前镇区',
                    '3526'], [3536, '710210', '', '', '旗津区', '3526'], [3537, '710211', '', '', '小港区', '3526'],
                [3538, '710212', '', '', '凤山区', '3526'], [3539, '710213', '', '', '林园区', '3526'], [3540,
                    '710214', '', '', '大寮区', '3526'], [3541, '710215', '', '', '大树区', '3526'], [3542, '710216',
                    '', '', '大社区', '3526'], [3543, '710217', '', '', '仁武区', '3526'], [3544, '710218', '', '',
                    '鸟松区', '3526'], [3545, '710219', '', '', '冈山区', '3526'], [3546, '710220', '', '', '桥头区',
                    '3526'], [3547, '710221', '', '', '燕巢区', '3526'], [3548, '710222', '', '', '田寮区', '3526'],
                [3549, '710223', '', '', '阿莲区', '3526'], [3550, '710224', '', '', '路竹区', '3526'], [3551,
                    '710225', '', '', '湖内区', '3526'], [3552, '710226', '', '', '茄萣区', '3526'], [3553, '710227',
                    '', '', '永安区', '3526'], [3554, '710228', '', '', '弥陀区', '3526'], [3555, '710229', '', '',
                    '梓官区', '3526'], [3556, '710230', '', '', '旗山区', '3526'], [3557, '710231', '', '', '美浓区',
                    '3526'], [3558, '710232', '', '', '六龟区', '3526'], [3559, '710233', '', '', '甲仙区', '3526'],
                [3560, '710234', '', '', '杉林区', '3526'], [3561, '710235', '', '', '内门区', '3526'], [3562,
                    '710236', '', '', '茂林区', '3526'], [3563, '710237', '', '', '桃源区', '3526'], [3564, '710238',
                    '', '', '那玛夏区', '3526']]
        },
        "基隆市": {
            id: 710300,
            value: [[3566, '710301', '', '', '中正区', '3565'], [3567, '710302', '', '', '七堵区', '3565'], [3568,
                '710303', '', '', '暖暖区', '3565'], [3569, '710304', '', '', '仁爱区', '3565'], [3570, '710305',
                '', '', '中山区', '3565'], [3571, '710306', '', '', '安乐区', '3565'], [3572, '710307', '', '',
                '信义区', '3565']]
        },
        "台中市": {
            id: 710400,
            value: [[3574, '710401', '', '', '中区', '3573'], [3575, '710402', '', '', '东区', '3573'], [3576, '710403',
                    '', '', '南区', '3573'], [3577, '710404', '', '', '西区', '3573'], [3578, '710405', '', '',
                    '北区', '3573'], [3579, '710406', '', '', '西屯区', '3573'], [3580, '710407', '', '', '南屯区',
                    '3573'], [3581, '710408', '', '', '北屯区', '3573'], [3582, '710409', '', '', '丰原区', '3573'],
                [3583, '710410', '', '', '东势区', '3573'], [3584, '710411', '', '', '大甲区', '3573'], [3585,
                    '710412', '', '', '清水区', '3573'], [3586, '710413', '', '', '沙鹿区', '3573'], [3587, '710414',
                    '', '', '梧栖区', '3573'], [3588, '710415', '', '', '后里区', '3573'], [3589, '710416', '', '',
                    '神冈区', '3573'], [3590, '710417', '', '', '潭子区', '3573'], [3591, '710418', '', '', '大雅区',
                    '3573'], [3592, '710419', '', '', '新社区', '3573'], [3593, '710420', '', '', '石冈区', '3573'],
                [3594, '710421', '', '', '外埔区', '3573'], [3595, '710422', '', '', '大安区', '3573'], [3596,
                    '710423', '', '', '乌日区', '3573'], [3597, '710424', '', '', '大肚区', '3573'], [3598, '710425',
                    '', '', '龙井区', '3573'], [3599, '710426', '', '', '雾峰区', '3573'], [3600, '710427', '', '',
                    '太平区', '3573'], [3601, '710428', '', '', '大里区', '3573'], [3602, '710429', '', '', '和平区',
                    '3573']]
        },
        "台南市": {
            id: 710500,
            value: [[3604, '710501', '', '', '东区', '3603'], [3605, '710502', '', '', '南区', '3603'], [3606, '710504',
                    '', '', '北区', '3603'], [3607, '710506', '', '', '安南区', '3603'], [3608, '710507', '', '',
                    '安平区', '3603'], [3609, '710508', '', '', '中西区', '3603'], [3610, '710509', '', '', '新营区',
                    '3603'], [3611, '710510', '', '', '盐水区', '3603'], [3612, '710511', '', '', '白河区', '3603'],
                [3613, '710512', '', '', '柳营区', '3603'], [3614, '710513', '', '', '后壁区', '3603'], [3615,
                    '710514', '', '', '东山区', '3603'], [3616, '710515', '', '', '麻豆区', '3603'], [3617, '710516',
                    '', '', '下营区', '3603'], [3618, '710517', '', '', '六甲区', '3603'], [3619, '710518', '', '',
                    '官田区', '3603'], [3620, '710519', '', '', '大内区', '3603'], [3621, '710520', '', '', '佳里区',
                    '3603'], [3622, '710521', '', '', '学甲区', '3603'], [3623, '710522', '', '', '西港区', '3603'],
                [3624, '710523', '', '', '七股区', '3603'], [3625, '710524', '', '', '将军区', '3603'], [3626,
                    '710525', '', '', '北门区', '3603'], [3627, '710526', '', '', '新化区', '3603'], [3628, '710527',
                    '', '', '善化区', '3603'], [3629, '710528', '', '', '新市区', '3603'], [3630, '710529', '', '',
                    '安定区', '3603'], [3631, '710530', '', '', '山上区', '3603'], [3632, '710531', '', '', '玉井区',
                    '3603'], [3633, '710532', '', '', '楠西区', '3603'], [3634, '710533', '', '', '南化区', '3603'],
                [3635, '710534', '', '', '左镇区', '3603'], [3636, '710535', '', '', '仁德区', '3603'], [3637,
                    '710536', '', '', '归仁区', '3603'], [3638, '710537', '', '', '关庙区', '3603'], [3639, '710538',
                    '', '', '龙崎区', '3603'], [3640, '710539', '', '', '永康区', '3603']]
        },
        "新竹市": {
            id: 710600,
            value: [[3642, '710601', '', '', '东区', '3641'], [3643, '710602', '', '', '北区', '3641'], [3644, '710603',
                '', '', '香山区', '3641']]
        },
        "嘉义市": {
            id: 710700,
            value: [[3646, '710701', '', '', '东区', '3645'], [3647, '710702', '', '', '西区', '3645']]
        },
        "新北市": {
            id: 710800,
            value: [[3649, '710801', '', '', '板桥区', '3648'], [3650, '710802', '', '', '三重区', '3648'], [3651,
                    '710803', '', '', '中和区', '3648'], [3652, '710804', '', '', '永和区', '3648'], [3653, '710805',
                    '', '', '新庄区', '3648'], [3654, '710806', '', '', '新店区', '3648'], [3655, '710807', '', '',
                    '树林区', '3648'], [3656, '710808', '', '', '莺歌区', '3648'], [3657, '710809', '', '', '三峡区',
                    '3648'], [3658, '710810', '', '', '淡水区', '3648'], [3659, '710811', '', '', '汐止区', '3648'],
                [3660, '710812', '', '', '瑞芳区', '3648'], [3661, '710813', '', '', '土城区', '3648'], [3662,
                    '710814', '', '', '芦洲区', '3648'], [3663, '710815', '', '', '五股区', '3648'], [3664, '710816',
                    '', '', '泰山区', '3648'], [3665, '710817', '', '', '林口区', '3648'], [3666, '710818', '', '',
                    '深坑区', '3648'], [3667, '710819', '', '', '石碇区', '3648'], [3668, '710820', '', '', '坪林区',
                    '3648'], [3669, '710821', '', '', '三芝区', '3648'], [3670, '710822', '', '', '石门区', '3648'],
                [3671, '710823', '', '', '八里区', '3648'], [3672, '710824', '', '', '平溪区', '3648'], [3673,
                    '710825', '', '', '双溪区', '3648'], [3674, '710826', '', '', '贡寮区', '3648'], [3675, '710827',
                    '', '', '金山区', '3648'], [3676, '710828', '', '', '万里区', '3648'], [3677, '710829', '', '',
                    '乌来区', '3648']]
        },
        "宜兰县": {
            id: 712200,
            value: [[3679, '712201', '', '', '宜兰市', '3678'], [3680, '712221', '', '', '罗东镇', '3678'], [3681,
                    '712222', '', '', '苏澳镇', '3678'], [3682, '712223', '', '', '头城镇', '3678'], [3683, '712224',
                    '', '', '礁溪乡', '3678'], [3684, '712225', '', '', '壮围乡', '3678'], [3685, '712226', '', '',
                    '员山乡', '3678'], [3686, '712227', '', '', '冬山乡', '3678'], [3687, '712228', '', '', '五结乡',
                    '3678'], [3688, '712229', '', '', '三星乡', '3678'], [3689, '712230', '', '', '大同乡', '3678'],
                [3690, '712231', '', '', '南澳乡', '3678']]
        },
        "桃园县": {
            id: 712300,
            value: [[3692, '712301', '', '', '桃园市', '3691'], [3693, '712302', '', '', '中坜市', '3691'], [3694,
                    '712303', '', '', '平镇市', '3691'], [3695, '712304', '', '', '八德市', '3691'], [3696, '712305',
                    '', '', '杨梅市', '3691'], [3697, '712321', '', '', '大溪镇', '3691'], [3698, '712323', '', '',
                    '芦竹乡', '3691'], [3699, '712324', '', '', '大园乡', '3691'], [3700, '712325', '', '', '龟山乡',
                    '3691'], [3701, '712327', '', '', '龙潭乡', '3691'], [3702, '712329', '', '', '新屋乡', '3691'],
                [3703, '712330', '', '', '观音乡', '3691'], [3704, '712331', '', '', '复兴乡', '3691']]
        },
        "新竹县": {
            id: 712400,
            value: [[3706, '712401', '', '', '竹北市', '3705'], [3707, '712421', '', '', '竹东镇', '3705'], [3708,
                    '712422', '', '', '新埔镇', '3705'], [3709, '712423', '', '', '关西镇', '3705'], [3710, '712424',
                    '', '', '湖口乡', '3705'], [3711, '712425', '', '', '新丰乡', '3705'], [3712, '712426', '', '',
                    '芎林乡', '3705'], [3713, '712427', '', '', '橫山乡', '3705'], [3714, '712428', '', '', '北埔乡',
                    '3705'], [3715, '712429', '', '', '宝山乡', '3705'], [3716, '712430', '', '', '峨眉乡', '3705'],
                [3717, '712431', '', '', '尖石乡', '3705'], [3718, '712432', '', '', '五峰乡', '3705']]
        },
        "苗栗县": {
            id: 712500,
            value: [[3720, '712501', '', '', '苗栗市', '3719'], [3721, '712521', '', '', '苑里镇', '3719'], [3722,
                    '712522', '', '', '通霄镇', '3719'], [3723, '712523', '', '', '竹南镇', '3719'], [3724, '712524',
                    '', '', '头份镇', '3719'], [3725, '712525', '', '', '后龙镇', '3719'], [3726, '712526', '', '',
                    '卓兰镇', '3719'], [3727, '712527', '', '', '大湖乡', '3719'], [3728, '712528', '', '', '公馆乡',
                    '3719'], [3729, '712529', '', '', '铜锣乡', '3719'], [3730, '712530', '', '', '南庄乡', '3719'],
                [3731, '712531', '', '', '头屋乡', '3719'], [3732, '712532', '', '', '三义乡', '3719'], [3733,
                    '712533', '', '', '西湖乡', '3719'], [3734, '712534', '', '', '造桥乡', '3719'], [3735, '712535',
                    '', '', '三湾乡', '3719'], [3736, '712536', '', '', '狮潭乡', '3719'], [3737, '712537', '', '',
                    '泰安乡', '3719']]
        },
        "彰化县": {
            id: 712700,
            value: [[3739, '712701', '', '', '彰化市', '3738'], [3740, '712721', '', '', '鹿港镇', '3738'], [3741,
                    '712722', '', '', '和美镇', '3738'], [3742, '712723', '', '', '线西乡', '3738'], [3743, '712724',
                    '', '', '伸港乡', '3738'], [3744, '712725', '', '', '福兴乡', '3738'], [3745, '712726', '', '',
                    '秀水乡', '3738'], [3746, '712727', '', '', '花坛乡', '3738'], [3747, '712728', '', '', '芬园乡',
                    '3738'], [3748, '712729', '', '', '员林镇', '3738'], [3749, '712730', '', '', '溪湖镇', '3738'],
                [3750, '712731', '', '', '田中镇', '3738'], [3751, '712732', '', '', '大村乡', '3738'], [3752,
                    '712733', '', '', '埔盐乡', '3738'], [3753, '712734', '', '', '埔心乡', '3738'], [3754, '712735',
                    '', '', '永靖乡', '3738'], [3755, '712736', '', '', '社头乡', '3738'], [3756, '712737', '', '',
                    '二水乡', '3738'], [3757, '712738', '', '', '北斗镇', '3738'], [3758, '712739', '', '', '二林镇',
                    '3738'], [3759, '712740', '', '', '田尾乡', '3738'], [3760, '712741', '', '', '埤头乡', '3738'],
                [3761, '712742', '', '', '芳苑乡', '3738'], [3762, '712743', '', '', '大城乡', '3738'], [3763,
                    '712744', '', '', '竹塘乡', '3738'], [3764, '712745', '', '', '溪州乡', '3738']]
        },
        "南投县": {
            id: 712800,
            value: [[3766, '712801', '', '', '南投市', '3765'], [3767, '712821', '', '', '埔里镇', '3765'], [3768,
                    '712822', '', '', '草屯镇', '3765'], [3769, '712823', '', '', '竹山镇', '3765'], [3770, '712824',
                    '', '', '集集镇', '3765'], [3771, '712825', '', '', '名间乡', '3765'], [3772, '712826', '', '',
                    '鹿谷乡', '3765'], [3773, '712827', '', '', '中寮乡', '3765'], [3774, '712828', '', '', '鱼池乡',
                    '3765'], [3775, '712829', '', '', '国姓乡', '3765'], [3776, '712830', '', '', '水里乡', '3765'],
                [3777, '712831', '', '', '信义乡', '3765'], [3778, '712832', '', '', '仁爱乡', '3765']]
        },
        "云林县": {
            id: 712900,
            value: [[3780, '712901', '', '', '斗六市', '3779'], [3781, '712921', '', '', '斗南镇', '3779'], [3782,
                    '712922', '', '', '虎尾镇', '3779'], [3783, '712923', '', '', '西螺镇', '3779'], [3784, '712924',
                    '', '', '土库镇', '3779'], [3785, '712925', '', '', '北港镇', '3779'], [3786, '712926', '', '',
                    '古坑乡', '3779'], [3787, '712927', '', '', '大埤乡', '3779'], [3788, '712928', '', '', '莿桐乡',
                    '3779'], [3789, '712929', '', '', '林内乡', '3779'], [3790, '712930', '', '', '二仑乡', '3779'],
                [3791, '712931', '', '', '仑背乡', '3779'], [3792, '712932', '', '', '麦寮乡', '3779'], [3793,
                    '712933', '', '', '东势乡', '3779'], [3794, '712934', '', '', '褒忠乡', '3779'], [3795, '712935',
                    '', '', '台西乡', '3779'], [3796, '712936', '', '', '元长乡', '3779'], [3797, '712937', '', '',
                    '四湖乡', '3779'], [3798, '712938', '', '', '口湖乡', '3779'], [3799, '712939', '', '', '水林乡',
                    '3779']]
        },
        "嘉义县": {
            id: 713000,
            value: [[3801, '713001', '', '', '太保市', '3800'], [3802, '713002', '', '', '朴子市', '3800'], [3803,
                    '713023', '', '', '布袋镇', '3800'], [3804, '713024', '', '', '大林镇', '3800'], [3805, '713025',
                    '', '', '民雄乡', '3800'], [3806, '713026', '', '', '溪口乡', '3800'], [3807, '713027', '', '',
                    '新港乡', '3800'], [3808, '713028', '', '', '六脚乡', '3800'], [3809, '713029', '', '', '东石乡',
                    '3800'], [3810, '713030', '', '', '义竹乡', '3800'], [3811, '713031', '', '', '鹿草乡', '3800'],
                [3812, '713032', '', '', '水上乡', '3800'], [3813, '713033', '', '', '中埔乡', '3800'], [3814,
                    '713034', '', '', '竹崎乡', '3800'], [3815, '713035', '', '', '梅山乡', '3800'], [3816, '713036',
                    '', '', '番路乡', '3800'], [3817, '713037', '', '', '大埔乡', '3800'], [3818, '713038', '', '',
                    '阿里山乡', '3800']]
        },
        "屏东县": {
            id: 713300,
            value: [[3820, '713301', '', '', '屏东市', '3819'], [3821, '713321', '', '', '潮州镇', '3819'], [3822,
                    '713322', '', '', '东港镇', '3819'], [3823, '713323', '', '', '恒春镇', '3819'], [3824, '713324',
                    '', '', '万丹乡', '3819'], [3825, '713325', '', '', '长治乡', '3819'], [3826, '713326', '', '',
                    '麟洛乡', '3819'], [3827, '713327', '', '', '九如乡', '3819'], [3828, '713328', '', '', '里港乡',
                    '3819'], [3829, '713329', '', '', '盐埔乡', '3819'], [3830, '713330', '', '', '高树乡', '3819'],
                [3831, '713331', '', '', '万峦乡', '3819'], [3832, '713332', '', '', '内埔乡', '3819'], [3833,
                    '713333', '', '', '竹田乡', '3819'], [3834, '713334', '', '', '新埤乡', '3819'], [3835, '713335',
                    '', '', '枋寮乡', '3819'], [3836, '713336', '', '', '新园乡', '3819'], [3837, '713337', '', '',
                    '崁顶乡', '3819'], [3838, '713338', '', '', '林边乡', '3819'], [3839, '713339', '', '', '南州乡',
                    '3819'], [3840, '713340', '', '', '佳冬乡', '3819'], [3841, '713341', '', '', '琉球乡', '3819'],
                [3842, '713342', '', '', '车城乡', '3819'], [3843, '713343', '', '', '满州乡', '3819'], [3844,
                    '713344', '', '', '枋山乡', '3819'], [3845, '713345', '', '', '三地门乡', '3819'], [3846, '713346',
                    '', '', '雾台乡', '3819'], [3847, '713347', '', '', '玛家乡', '3819'], [3848, '713348', '', '',
                    '泰武乡', '3819'], [3849, '713349', '', '', '来义乡', '3819'], [3850, '713350', '', '', '春日乡',
                    '3819'], [3851, '713351', '', '', '狮子乡', '3819'], [3852, '713352', '', '', '牡丹乡', '3819']]
        },
        "台东县": {
            id: 713400,
            value: [[3854, '713401', '', '', '台东市', '3853'], [3855, '713421', '', '', '成功镇', '3853'], [3856,
                    '713422', '', '', '关山镇', '3853'], [3857, '713423', '', '', '卑南乡', '3853'], [3858, '713424',
                    '', '', '鹿野乡', '3853'], [3859, '713425', '', '', '池上乡', '3853'], [3860, '713426', '', '',
                    '东河乡', '3853'], [3861, '713427', '', '', '长滨乡', '3853'], [3862, '713428', '', '', '太麻里乡',
                    '3853'], [3863, '713429', '', '', '大武乡', '3853'], [3864, '713430', '', '', '绿岛乡', '3853'],
                [3865, '713431', '', '', '海端乡', '3853'], [3866, '713432', '', '', '延平乡', '3853'], [3867,
                    '713433', '', '', '金峰乡', '3853'], [3868, '713434', '', '', '达仁乡', '3853'], [3869, '713435',
                    '', '', '兰屿乡', '3853']]
        },
        "花莲县": {
            id: 713500,
            value: [[3871, '713501', '', '', '花莲市', '3870'], [3872, '713521', '', '', '凤林镇', '3870'], [3873,
                    '713522', '', '', '玉里镇', '3870'], [3874, '713523', '', '', '新城乡', '3870'], [3875, '713524',
                    '', '', '吉安乡', '3870'], [3876, '713525', '', '', '寿丰乡', '3870'], [3877, '713526', '', '',
                    '光复乡', '3870'], [3878, '713527', '', '', '丰滨乡', '3870'], [3879, '713528', '', '', '瑞穗乡',
                    '3870'], [3880, '713529', '', '', '富里乡', '3870'], [3881, '713530', '', '', '秀林乡', '3870'],
                [3882, '713531', '', '', '万荣乡', '3870'], [3883, '713532', '', '', '卓溪乡', '3870']]
        },
        "澎湖县": {
            id: 713600,
            value: [[3885, '713601', '', '', '马公市', '3884'], [3886, '713621', '', '', '湖西乡', '3884'], [3887,
                '713622', '', '', '白沙乡', '3884'], [3888, '713623', '', '', '西屿乡', '3884'], [3889, '713624',
                '', '', '望安乡', '3884'], [3890, '713625', '', '', '七美乡', '3884']]
        }
    },
    "香港特别行政区": {
        "香港岛": {
            id: 810100,
            value: [[3893, '810101', '', '', '中西区', '3892'], [3894, '810102', '', '', '湾仔区', '3892'], [3895,
                '810103', '', '', '东区', '3892'], [3896, '810104', '', '', '南区', '3892']]
        },
        "九龙": {
            id: 810200,
            value: [[3898, '810201', '', '', '油尖旺区', '3897'], [3899, '810202', '', '', '深水埗区', '3897'], [3900,
                '810203', '', '', '九龙城区', '3897'], [3901, '810204', '', '', '黄大仙区', '3897'], [3902,
                '810205', '', '', '观塘区', '3897']]
        },
        "新界": {
            id: 810300,
            value: [[3904, '810301', '', '', '荃湾区', '3903'], [3905, '810302', '', '', '屯门区', '3903'], [3906,
                '810303', '', '', '元朗区', '3903'], [3907, '810304', '', '', '北区', '3903'], [3908, '810305',
                '', '', '大埔区', '3903'], [3909, '810306', '', '', '西贡区', '3903'], [3910, '810307', '', '',
                '沙田区', '3903'], [3911, '810308', '', '', '葵青区', '3903'], [3912, '810309', '', '', '离岛区',
                '3903']]
        }
    },
    "澳门特别行政区": {
        "澳门半岛": {
            id: 820100,
            value: [[3915, '820101', '', '', '花地玛堂区', '3914'], [3916, '820102', '', '', '圣安多尼堂区', '3914'], [3917,
                '820103', '', '', '大堂区', '3914'], [3918, '820104', '', '', '望德堂区', '3914'], [3919, '820105',
                '', '', '风顺堂区', '3914']]
        },
        "氹仔岛": {
            id: 820200,
            value: [[3921, '820201', '', '', '嘉模堂区', '3920']]
        },
        "路环岛": {
            id: 820300,
            value: [[3923, '820301', '', '', '圣方济各堂区', '3922']]
        }
    }
};