var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "cube-btn",
      class: _vm.btnClass,
      attrs: { type: _vm.type },
      on: { click: _vm.handleClick }
    },
    [
      _vm.icon ? _c("i", { class: _vm.icon }) : _vm._e(),
      _vm.disabled
        ? _c("div", { staticClass: "c-loading" }, [
            _c(
              "span",
              { staticClass: "c-loading-spinners" },
              _vm._l(_vm.balde, function(item) {
                return _c("i", { staticClass: "c-loading-spinner" })
              }),
              0
            )
          ])
        : _vm._e(),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }