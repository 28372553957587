<template>
  <div class="container">
    <md-notice-bar>请上传以下照片，多张图片可一次性上传</md-notice-bar>

    <div class="upload-section">
      <div v-for="(item, key) in uploadList" :key="key" class="upload-box">
        <div class="upload-title">{{ item.title }}</div>
        <UploadBox
          ref="upload1"
          :files.sync="item.files"
          :multiple="item.multiple"
          :editType="editType"
        ></UploadBox>
      </div>
      <!-- <div class="upload-box">
        <div class="upload-title">合作协议（4页）</div>
        <UploadBox ref="upload1" :files.sync="files1" :multiple="true"></UploadBox>
      </div>-->
    </div>
    <md-notice-bar>拍照提醒：注意光线明亮，照片清晰，显示完整</md-notice-bar>

    <div v-if="editType" class="pd20">
      <md-button
        class="mgt30"
        type="primary"
        :loading="committing"
        @click="handleSubmit"
        >下一步</md-button
      >
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { NoticeBar, Button } from "mand-mobile";
import {
  getSelectOption,
  reSupplementOrder,
  supplementOrder,
} from "@/api/carFinance";

import { resetImageBlob } from "@/utils/getImageBlobUrl";

import UploadBox from "./components/UploadBox";
export default {
  name: "",
  components: {
    [NoticeBar.name]: NoticeBar,
    [Button.name]: Button,
    UploadBox,
  },
  data() {
    return {
      uploadList: {
        // CooperationAgreement: {
        //   files: [],
        //   title: "合作协议（4页）",
        //   multiple: true,
        //   max: 4
        // },
        CarLoanServiceContract: {
          files: [],
          title: "购车分期付款服务合同（4页）",
          multiple: true,
          max: 4,
        },
        // BusinessLicense: {
        //   files: [],
        //   title: "营业执照"
        // },
        CarPurchaseContract: {
          files: [],
          title: "购车合同",
          multiple: true,
        },
        OrderInfo: {
          files: [],
          title: "订单信息",
          multiple: true,
        },
        OtherMaterials: {
          files: [],
          title: "其他材料",
          multiple: true,
        },
      },
      committing: false,
      type: "",
    };
  },
  computed: {
    viewType() {
      return this.type === "view";
    },
    editType() {
      return !this.viewType;
    },
  },
  methods: {
    reSupplementOrder() {
      reSupplementOrder({ id: this.$route.query.orderCarId })
        .then((res) => {
          if (res.success) {
            const { fileMap } = res.data;

            if (fileMap) {
              const entriesObj = Object.entries(fileMap);

              if (entriesObj.length === 0) return;

              for (let [key, value] of entriesObj) {
                if (Array.isArray(value)) {
                  const blobList = value.map((item, index) => {
                    return resetImageBlob(item.url);
                  });

                  Promise.all(blobList).then((res) => {
                    this.uploadList[key].files = res;
                  });
                }
              }
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    vailOKStatus(files) {
      return files.some(
        (file) => !file || !file.response || file.response.success === false
      );
    },
    handleSubmit() {
      const vailOKStatus = this.vailOKStatus;

      // eslint-disable-next-line no-unused-vars
      for (let [key, value] of Object.entries(this.uploadList)) {
        if (value.files.length === 0) {
          this.$createToast({
            type: "warn",
            txt: `请上传${value.title}图片`,
            time: 1500,
          }).show();
          return false;
        }

        if (vailOKStatus(value.files)) {
          this.$createToast({
            type: "warn",
            txt: `${value.title}有上传失败图片，请确认后重新提交`,
            time: 1500,
          }).show();
          return false;
        }
      }

      this.committing = true;
      const resetBtnStatus = () => {
        setTimeout(() => {
          this.committing = false;
        }, 200);
      };

      const { orderCarId } = this.$route.query;

      const params = { orderCarId, dataList: [] };

      // eslint-disable-next-line no-unused-vars
      for (let [key, value] of Object.entries(this.uploadList)) {
        value.files.map((item, index) => {
          params.dataList.push({
            number: index + 1,
            url: item.response.data.url,
            typeId: value.typeId,
          });
        });
      }

      supplementOrder(params)
        .then((res) => {
          if (res.success) {
            this.$router.replace({
              name: "commitComplete",
            });
          } else {
            resetBtnStatus();
          }
        })
        .catch((err) => {
          resetBtnStatus();
          console.log("err", err);
        });
    },
  },
  mounted() {
    const { type } = this.$route.query;
    this.type = type;

    getSelectOption({ name: "OrderCarData" })
      .then((res) => {
        if (res.success) {
          const { OrderCarData } = res.data;
          const uploadList = this.uploadList;
          // 可根据返回确立upList
          OrderCarData.map((item) => {
            if (uploadList[item.value_]) {
              uploadList[item.value_].typeId = item.id;
            }
          });

          // 回显订单合同
          this.reSupplementOrder();
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  },
};
</script>

<style scoped lang="less">
.container {
  padding-bottom: 60px;

  .upload-section {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 8px 10px 10px;
    background: rgb(249, 250, 251);

    .upload-box {
      width: 50%;
      padding: 10px 10px;

      .upload-title {
        margin-bottom: 10px;
        white-space: nowrap;
      }
    }
  }
}
</style>
