<template>
  <div class="container">
    <img class="logo" src="@/assets/images/logo.png" alt />

    <div class="flex border-bottom-1px field-item">
      <div>服务热线</div>
      <a href="tel:020-38686775" class="tel-num">020-38686775</a>
    </div>

    <div class="flex border-bottom-1px field-item">
      <div>官方微信</div>

      <CopySpan class="tel-num" text="爱保数科"></CopySpan>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import CopySpan from "@/components/CopySpan";
export default {
  name: "",
  components: {
    CopySpan,
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style scoped lang="less">
.container {
  padding-top: 50px;
  .logo {
    display: block;
    width: 80px;
    height: 80px;
    margin: 0 auto 50px;
  }

  .field-item {
    height: 50px;
    padding: 0 16px;
  }

  .tel-num {
    color: #579af1;
    font-weight: 600;
  }
}
</style>
