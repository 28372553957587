import fixedInput from './fixedInput'

const install = function(Vue) {
  Vue.directive('fixedInput', fixedInput)
}

if (window.Vue) {
  window['fixedInput'] = fixedInput
  Vue.use(install); // eslint-disable-line
}

fixedInput.install = install
export default fixedInput
