<template>
  <div class="container">
    <tips-top>目前仅支持以下银行</tips-top>

    <div class="white-space"></div>
    <!-- <div class="bank-area">
      <ul class="list-wrapper">
        <li class="bank-list-item" v-for="(item,idx) in bankList" :key="idx">
          <div class="bank-icon">
            <img :src="item.logo">
          </div>
          <div class="bank-name">{{item.name}}</div>
        </li>
      </ul>
    </div>-->

    <div class="bank-card-area">
      <img :src="supportBanks" alt="支持银行" />
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import logo1 from "@assets/images/banklogo/工商银行.png";
import logo2 from "@assets/images/banklogo/光大银行.png";
import logo3 from "@assets/images/banklogo/广发银行.png";
import logo4 from "@assets/images/banklogo/华夏银行.png";
import logo5 from "@assets/images/banklogo/建设银行.png";
import logo6 from "@assets/images/banklogo/交通银行.png";
import logo7 from "@assets/images/banklogo/民生银行.png";
import logo8 from "@assets/images/banklogo/农业银行.png";
import logo9 from "@assets/images/banklogo/平安银行.png";
import logo10 from "@assets/images/banklogo/浦发银行.png";
import logo11 from "@assets/images/banklogo/兴业银行.png";
import logo12 from "@assets/images/banklogo/邮政银行.png";
import logo13 from "@assets/images/banklogo/招商银行.png";
import logo14 from "@assets/images/banklogo/中国银行.png";
import logo15 from "@assets/images/banklogo/中信银行.png";
import supportBanks from "@assets/images/banklogo/support-banks.png";

import TipsTop from "@comp/TipsTop/TipsTop";

export default {
  name: "",
  data() {
    return {
      bankList: [
        { name: "工商银行", logo: logo1, id: "" },
        { name: "光大银行", logo: logo2, id: "" },
        { name: "广发银行", logo: logo3, id: "" },
        // { name: "华夏银行", logo: logo4, id: "" },
        { name: "建设银行", logo: logo5, id: "" },
        // { name: "交通银行", logo: logo6, id: "" },
        { name: "民生银行", logo: logo7, id: "" },
        // { name: "农业银行", logo: logo8, id: "" },
        { name: "平安银行", logo: logo9, id: "" },
        { name: "浦发银行", logo: logo10, id: "" },
        // { name: "兴业银行", logo: logo11, id: "" },
        { name: "邮政银行", logo: logo12, id: "" },
        // { name: "招商银行", logo: logo13, id: "" },
        { name: "中国银行", logo: logo14, id: "" },
        { name: "中信银行", logo: logo15, id: "" }
      ],
      supportBanks
    };
  },
  components: {
    TipsTop
  }
};
</script>

<style scoped lang="less">
.container {
  .white-space {
    height: 10px;
    background: #eef1f6;
  }

  .bank-card-area {
    padding: 20px;
    img {
      width: 100%;
    }
  }

  .bank-area {
    height: calc(100vh - 54px);
    padding-top: 20px;
    overflow-y: scroll;
    font-size: 14px;
    font-weight: 500;
    color: #333;

    .list-wrapper {
      display: flex;
      flex-wrap: wrap;

      .bank-list-item {
        width: 25%;
        margin-bottom: 44px;
      }

      .bank-icon {
        width: 32px;
        height: 32px;
        margin: 0 auto 13px;
        line-height: 32px;
        img {
          height: auto;
          width: 32px;
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
