<template>
  <LoanRepaymentHistory process="application"></LoanRepaymentHistory>
</template>

<script type="text/ecmascript-6">
import LoanRepaymentHistory from "@/views/repayment/LoanRepaymentHistory";
export default {
  name: "",
  components: {
    LoanRepaymentHistory
  },
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>

<style scoped lang="less">
</style>
