const getters = {
    token: state => state.user.token,
    avatar: state => state.user.avatar,
    nickname: state => state.user.name,
    welcome: state => state.user.welcome,
    userInfo: state => state.user.info,
    cacheView: state => state.cacheView.cacheView,
  }
  
  export default getters
  