export default {
  data() {
    return {
      submitting: false
    };
  },
  methods: {
    regainStatus() {
      setTimeout(() => {
        this.submitting = false;
      }, 300);
    }
  }
};
