export const bankNameList = {
    '01020000': '工商银行',
    '01030000':'农业银行',
    '03010000':'交通银行',
    '01050000':'建设银行',
    '01050001':'建设银行',
    '01053930':'建设银行',
    '01040000':'中国银行',
    '03080000':'招商银行',
    '03020000':'中信银行',
    '03030000':'光大银行',
    '03070010':'平安银行',
    '04100000':'平安银行',
    '03180000':'平安银行',
    '04105840':'平安银行',
    '06105840':'平安银行',
    '03090010':'兴业银行',
    '01000000':'邮政银行',
    '01004900':'邮政银行',
    '01009999':'邮政银行',
    '03050000':'民生银行',
  }