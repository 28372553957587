<template>
  <div class="container">
    <tips-top>请使用本人身份信息，勿使用他人身份信息尝试</tips-top>

    <cube-popup type="ORCPopup" ref="ORCPopup">
      <div class="orc-result">
        <img class="close-icon" @click="closePopup" src="../../assets/images/icon-close.png" alt />
        <div class="orc-title">信息确认</div>

        <div class="orc-tips border-bottom-1px">*请确认以下信息是否正确，如有误请重新识别</div>

        <div class="orc-info">
          <div class="orc-item">
            <div class="orc-item-left">姓ㅤㅤ名</div>
            <div class="orc-item-right">{{OCRResult.name}}</div>
          </div>
          <div class="orc-item">
            <div class="orc-item-left">身份证号</div>
            <div class="orc-item-right">{{OCRResult.idCard}}</div>
          </div>

          <div class="orc-item">
            <div class="orc-item-left">签发机关</div>
            <div class="orc-item-right">{{OCRResult.issue}}</div>
          </div>

          <div class="orc-item">
            <div class="orc-item-left">有效期限</div>
            <div class="orc-item-right">{{OCRResult.valid_date_start}}—{{OCRResult.valid_date_end}}</div>
          </div>
        </div>

        <div class="handle-section flex border-top-1px">
          <div @click="closePopup" class="back flex-1">返回修改</div>

          <div @click="confirmSubmit" class="confirm flex-1">确认无误</div>
        </div>
      </div>
    </cube-popup>

    <div id="upload-section">
      <cube-upload
        ref="uploadface"
        class="upload-box"
        v-model="idcardface"
        :action="action"
        :process-file="processFile"
        @files-added="addedHandlerFace"
        @file-error="errHandler"
      >
        <div class="clear-fix">
          <cube-upload-file
            class="upload-file-idcard"
            v-for="(file, i) in idcardface"
            :file="file"
            :key="i"
          ></cube-upload-file>
          <cube-upload-btn
            class="upload-btn-idcard idcard-face"
            :capture="capture"
            :multiple="false"
          >
            <div>
              <i>＋</i>
            </div>
          </cube-upload-btn>
        </div>
      </cube-upload>

      <div class="upload-tips">
        点击开始识别正面
        <!-- <span>人像面</span> -->
      </div>

      <cube-upload
        ref="uploadback"
        class="upload-box"
        v-model="idcardback"
        :action="action"
        :process-file="processFile"
        @files-added="addedHandlerBack"
        @file-error="errHandler"
      >
        <div class="clear-fix">
          <cube-upload-file
            class="upload-file-idcard"
            v-for="(file, i) in idcardback"
            :file="file"
            :key="i"
          ></cube-upload-file>
          <cube-upload-btn
            class="upload-btn-idcard idcard-back"
            :capture="capture"
            :multiple="false"
          >
            <div>
              <i>＋</i>
            </div>
          </cube-upload-btn>
        </div>
      </cube-upload>

      <div class="upload-tips">
        点击开始识别背面
        <!-- <span>国徽面</span> -->
      </div>
    </div>

    <div class="btn-section">
      <div class="fs14 c-999">*拍摄时请确保身份证边框完整、字迹清晰、亮度均衡</div>
      <cube-button @click="OERCDeal" class="sumit-btn" :class="{'disabled':loading}">
        <carousel v-if="loading"></carousel>
        <span v-else>下一步</span>
      </cube-button>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import TipsTop from "@comp/TipsTop/TipsTop";
import Carousel from "@comp/Carousel/Carousel";
import compress from "@/utils/compress";
import store from "@/store";
import { getToken } from "@/utils/auth";
import { faceRedirectUrl } from "@/config/config";
const BASEURL = process.env.VUE_APP_BASE_API;

import { ocrIdCheck, getFaceToken, doVerification } from "@/api/application";

export default {
  name: "",
  data() {
    return {
      action: {
        target: `${BASEURL}/auth/uploadFile.do`,
        fileName: "file",
        headers: {
          Authorization: `xsjy_${store.getters.token || getToken()}`
        },
        data: {},
        // 用于检验请求成功与否
        checkSuccess(res, file) {
          console.log("上传返回数据", res);
          if (res.success) {
            return true;
          } else {
            return false;
          }
        }
      },
      // 生产环境只能调用 摄像机 camera
      // 此处生产环境要更改 node_modules\cube-ui\src\components\upload
      //  文件 btn-mixin
      //  capture: {
      //   type: String,
      // }
      //  btn upload props 增加 :capture="capture"

      capture: "camera",
      idcardface: [],
      idcardback: [],
      loading: false,
      OCRResult: {},
      comfirmStatus: true
    };
  },
  components: {
    TipsTop,
    Carousel
  },
  methods: {
    processFile(file, next) {
      console.log("ios 10.1 图片上传 == processFile", file);

      //console.log(navigator.userAgent, "navigator.userAgent");
      if (!(navigator.userAgent.indexOf("CPU iPhone OS 10_") > -1)) {
        console.log("已压缩");
        compress(
          file,
          {
            compress: {
              width: 1600,
              height: 1600,
              quality: 0.5
            },
            type: "image"
          },
          next
        );
      } else {
        console.log("未压缩");
        next(file);
      }
    },
    addedHandlerFace() {
      const file = this.idcardface[0];
      file && this.$refs.uploadface.removeFile(file);
    },
    addedHandlerBack() {
      const file = this.idcardback[0];
      file && this.$refs.uploadback.removeFile(file);
    },
    errHandler(file) {
      // const msg = file.response.message
      console.log('errHandler', file)
      if (file._xhr && file._xhr && file._xhr.status === 401) {
        this.$createToast({
          type: "warn",
          txt: "Unauthorized,请重新扫码进入流程",
          time: 3000
        }).show();
      } else {
        this.$createToast({
          type: "warn",
          txt: "Upload fail",
          time: 1500
        }).show();
      }
    },

    vail(files, Tips) {
      let file = files[0];
      if (!file || !file.response || file.response.success === false) {
        this.$createToast({
          txt: Tips,
          time: 1800,
          type: "txt"
        }).show();
        return true;
      }
    },

    hideLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    OERCDeal() {
      const { vail, idcardface, idcardback } = this;

      if (
        vail(idcardface, "请上传身份证人像面") ||
        vail(idcardback, "请上传身份证国徽面")
      ) {
        return false;
      }

      this.loading = true;

      // 上传ocr识别
      ocrIdCheck({
        frontUrl: idcardface[0].response.data,
        backUrl: idcardback[0].response.data
      })
        .then(res => {
          if (res.success) {
            this.OCRResult = res.data;
            this.showPopup();
          }
          this.hideLoading();
        })
        .catch(err => {
          this.hideLoading();
        });
    },

    comfirmStatusStop() {
      setTimeout(() => {
        this.comfirmStatus = true;
        this.getFaceTokenToast.hide();
      }, 3500);
    },

    confirmSubmit() {
      //   this.$router.push({
      //     name: "improveInformation"
      //   });
      const { name: idcard_name, idCard: idcard_number } = this.OCRResult;

      if (!this.comfirmStatus) {
        return false;
      }
      this.comfirmStatus = false;

      this.getFaceTokenToast = this.$createToast({
        txt: "处理中",
        time: 0
      }).show();

      getFaceToken({
        idcard_name,
        idcard_number
      })
        .then(res => {
          if (res.success) {
            const token = res.data;

            window.location.href = `${faceRedirectUrl}${token}`;
            this.comfirmStatusStop();
          } else {
            this.comfirmStatusStop();
          }
        })
        .catch(err => {
          this.comfirmStatusStop();
        });
    },

    closePopup() {
      this.$refs.ORCPopup.hide();
    },
    showPopup() {
      this.$refs.ORCPopup.show();
    }
  },
  mounted() {}
};
</script>


<style scoped lang="less">
#upload-section {
  height: 396px;
  padding-top: 14px;
  background: #eef1f6;

  .upload-box {
    .upload-file-idcard,
    .upload-btn-idcard {
      margin: 26px auto 0;
      height: 120px;
      width: 200px;
    }

    .idcard-back {
      background: url("../../assets/images/idcard-back.png") no-repeat;
      background-size: 200px 120px;
    }

    .idcard-face {
      background: url("../../assets/images/idcard-face.png") no-repeat;
      background-size: 200px 120px;
    }
  }

  .upload-tips {
    margin-top: 10px;
    font-size: 14px;
    color: #999;
    span {
      margin-left: 6px;
      color: #579af1;
    }
  }
}

.btn-section {
  padding: 20px 16px 0px;
  text-align: center;

  .sumit-btn {
    margin-top: 40px;
    border-radius: 4px;
  }

  .disabled {
    pointer-events: none;
    border-color: transparent;
    cursor: not-allowed;
    opacity: 0.8;
  }
}

.orc-result {
  position: relative;
  width: 310px;
  //   height: 320px;
  height: auto;
  background: #fff;
  border-radius: 8px;
  animation: pop 0.4s;
  @keyframes pop {
    0% {
      transform: scale(0);
      opacity: 0;
    }

    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }

  .close-icon {
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 9;
    width: 20px;
    height: 20px;
  }

  .orc-title {
    padding: 24px 0 10px;
    font-size: 18px;
    color: #333;
  }

  .orc-tips {
    padding: 10px 0;
    margin: 0 10px;
    font-size: 14px;
    color: #999;
  }

  .orc-info {
    padding: 18px 0 20px 20px;
    .orc-item {
      display: flex;
      justify-content: space-around;
      align-items: center;
      line-height: 36px;

      .orc-item-left {
        color: rgba(51, 51, 51, 1);
        font-size: 16px;
        min-width: 62px;
        margin-right: 30px;
      }
      .orc-item-right {
        flex: 1;
        color: rgba(102, 102, 102, 1);
        line-height: 28px;
        text-align: left;
      }
    }
  }

  .handle-section {
    height: 50px;
    line-height: 50px;
    color: #666;

    .back {
      &:active {
        background: rgba(0, 0, 0, 0.04);
      }
    }
    .confirm {
      border-bottom-right-radius: 8px;
      background: #579af1;
      color: #fff;
      &:active {
        background: #a2c5f3;
      }
    }
  }
}
</style>

<style lang="less">
#upload-section {
  .cube-upload-file {
    margin: 26px auto 0;

    + .cube-upload-btn {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      margin: 0 auto !important;
      background-size: cover;
    }
  }

  .cube-upload-file-def {
    width: 100%;
    height: 100%;

    .cubeic-wrong {
      display: none;
    }
  }

  .cube-upload-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      text-align: center;
    }

    i {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 34px;
      font-size: 16px;
      line-height: 1;
      font-style: normal;
      color: #fff;
      background-color: #ccc;
      border-radius: 50%;
      opacity: 0;
    }
  }
}
</style>


