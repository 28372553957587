<template>
  <div class="container">
    <div class="user-section">
      <img class="avatar" src="@/assets/images/avatar-default.png" alt="头像" />
      <div class="user-name">{{ userName | nameFilter }}</div>
      <div class="user-mobile">{{ mobile | mobileFilter }}</div>
    </div>

    <div class="item-section flex">
      <div @click="toMyBill" class="flex-1 left-item">
        <div class="item-number">{{ nearMoney }}</div>
        <div class="item-name">近期待付(元)</div>
      </div>

      <div @click="toLoanRepaymentHistory" class="flex-1">
        <div class="item-number">{{ receiptNum }}</div>
        <div class="item-name">分期记录</div>
      </div>
    </div>

    <CWhiteSpace></CWhiteSpace>

    <div class="field-section">
      <CFieldItem
        @click.native="toMyBill"
        iconName="icon-zhangdan"
        title="我的账单"
      ></CFieldItem>

      <CFieldItem
        @click.native="toMyBankCard"
        iconName="icon-yinhangqia"
        title="我的银行卡"
      ></CFieldItem>
      <CWhiteSpace></CWhiteSpace>

      <CFieldItem
        @click.native="toCustomerService"
        iconName="icon-kefu"
        title="我的客服"
      ></CFieldItem>

      <CFieldItem
        @click.native="toSecuritySetting"
        iconName="icon-dunpai"
        title="安全设置"
      ></CFieldItem>

      <CFieldItem
        @click.native="toAboutUs"
        iconName="icon-guanyu"
        title="关于我们"
      ></CFieldItem>

      <CFieldItem
        @click.native="toLogout"
        iconName="icon-tuichudenglu"
        title="退出登录"
      ></CFieldItem>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import Cookies from "js-cookie";
import { TOKEN_REPAYMENT_NAME } from "@/config/config";

import { userCenter } from "@/api/repayment";
import { logout, getUser } from "@/api/user";

export default {
  name: "mine",
  components: {},
  data() {
    return {
      mobile: "",
      userName: "",
      nearMoney: "--",
      receiptNum: "--",
    };
  },
  computed: {},
  methods: {
    toMyBill() {
      this.$router.push({
        name: "myBill",
      });
    },
    toLoanRepaymentHistory() {
      this.$router.push({
        name: "loanRepaymentHistory",
      });
    },
    toMyBankCard() {
      this.$router.push({
        name: "myBankCard",
      });
    },

    toCustomerService() {
      this.$router.push({
        name: "customerService",
      });
    },

    toSecuritySetting() {
      this.$router.push({
        name: "securitySetting",
      });
    },

    toAboutUs() {
      this.$router.push({
        name: "aboutUs",
      });
    },
    toLogout() {
      this.$createDialog({
        type: "confirm",
        icon: "cubeic-alert",
        content: "退出登录",
        confirmBtn: {
          text: "确定",
          active: true,
          disabled: false,
          href: "javascript:;",
        },
        cancelBtn: {
          text: "取消",
          active: false,
          disabled: false,
          href: "javascript:;",
        },
        onConfirm: () => {
          logout()
            .then((res) => {
              if (res.success) {
                this.$router.push({
                  name: "login",
                });
              }
            })
            .catch((error) => {
              console.log("error", error);
            });
        },
      }).show();
    },
  },
  mounted() {
    getUser()
      .then((res) => {
        if (res.success) {
          const userInfo = res.data;
          this.mobile = userInfo.mobile;
          this.userName = userInfo.userName;
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  },

  activated() {
    userCenter({})
      .then((res) => {
        if (res.success) {
          const { nearMoney, receiptNum } = res.data;
          this.nearMoney = nearMoney;
          this.receiptNum = receiptNum;
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  },
  beforeRouteEnter(to, from, next) {
    if (Cookies.get(TOKEN_REPAYMENT_NAME)) {
      next();
    } else {
      next({
        name: "login",
      });
    }
  },
};
</script>

<style scoped lang="less">
.container {
  .user-section {
    height: 180px;
    background: #579af1 url("../../assets/images/mine-bg.png");
    background-size: cover;
    color: #fff;
    overflow: hidden;

    .avatar {
      display: block;
      height: 64px;
      width: 64px;
      margin: 40px auto 10px;
      // background: #ccc;
    }

    .user-name {
      margin-bottom: 10px;
    }

    .user-mobile {
    }
  }

  .item-section {
    position: relative;
    height: 80px;

    .left-item {
      border-right: 1px #eef1f6 solid;
    }

    .item-number {
      font-size: 26px;
      color: #579af1;
      margin-bottom: 10px;
    }

    .item-name {
      font-size: 14px;
      color: #333;
      // font-family:PingFang-SC-Medium;
    }
  }

  .field-section {
    background: #fff;
  }
}
</style>
