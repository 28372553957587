var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm.showResultType === "redirecting"
      ? _c(
          "div",
          [
            _c("c-spin", {
              staticStyle: { "margin-top": "30vh" },
              attrs: { tip: "页面跳转中...", size: "large" }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.showResultType === "faceError"
      ? _c(
          "div",
          [
            _c("img", {
              staticClass: "resutl-icon mgt20",
              attrs: { src: require("@assets/images/error.png") }
            }),
            _c("div", { staticClass: "result-title" }, [_vm._v("认证失败")]),
            _c("div", { staticClass: "result-tips" }, [
              _vm._v(_vm._s(_vm.faceErrorResult))
            ]),
            _c(
              "cube-button",
              {
                staticClass: "handle-btn",
                on: { click: _vm.retryFaceRecognition }
              },
              [_vm._v("重试")]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }