<template>
  <div class="fill-item border-bottom-1px">
    <div class="fill-name">{{title}}</div>
    <input
      ref="input"
      v-fixedInput
      v-model="inputValue"
      class="fill-input"
      :pattern="pattern"
      :style="styleObject"
      :class="classObject"
      :maxlength="maxlength"
      :placeholder="placeholder"
      :type="type"
     
    />
    <span v-if="unit" class="fill-unit">{{unit}}</span>
  </div>
</template>

<script type="text/ecmascript-6">
import fixedInput from "@/directive/fixedInput";

export default {
  name: "",
  props: {
    title: {
      type: String,
      default: ""
    },
    value: [String, Number],
    unit: [String],
    type: {
      type: String,
      default: ""
    },
    pattern: {
      type: String,
      default: ""
    },
    align: {
      type: String,
      default: "left"
    },
    placeholder: {
      type: String,
      default: ""
    },
    maxlength: {
      type: String,
      default: ""
    }
  },
  components: {},
  directives: {
    fixedInput
  },
  data() {
    return {};
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    classObject() {
      const classObject = {};

      return classObject;
    },
    styleObject() {
      const styleObject = {
        textAlign: this.align
      };
      // styleObject.textAlign = this.align;

      return styleObject;
    }
  },
  methods: {}
};
</script>

<style scoped lang="less">
.fill-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;

  .fill-name {
    width: 80px !important;
    margin-right: 5px;
    text-align: left;
    color: rgba(51, 51, 51, 1);
    line-height: 40px;
  }
  .fill-input {
    height: 40px;
    width: 150px;
    flex: 1;
    background: none;
    outline: none;
    border: 0px;
    color: #666;
    &::-webkit-input-placeholder {
      color: #ccc;
      line-height: 19px;
    }
  }

  .fill-unit {
    margin-left: 5px;
  }
}
</style>
