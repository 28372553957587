var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm.loanInfo.receiptId
        ? [
            _vm.repapmentProcess
              ? _c("div", { staticClass: "amount-section flex" }, [
                  _c("div", { staticClass: "flex-1" }, [
                    _c("div", { staticClass: "fs20 bold" }, [
                      _vm._v(_vm._s(_vm.loanInfo.thisReceiptAmount))
                    ]),
                    _c("div", { staticClass: "fs14 mgt14" }, [
                      _vm._v("本期应付( 元)")
                    ])
                  ]),
                  _c("div", { staticClass: "flex-1" }, [
                    _c("div", { staticClass: "fs20 bold" }, [
                      _vm._v(_vm._s(_vm.loanInfo.receiptBalance))
                    ]),
                    _c("div", { staticClass: "fs14 mgt14" }, [
                      _vm._v("未付总额(元)")
                    ])
                  ])
                ])
              : _vm._e(),
            _vm.repapmentProcess
              ? _c("div", { staticClass: "white-sapce" })
              : _vm._e(),
            _c(
              "div",
              { staticClass: "repayment-info-list" },
              [
                _c(
                  "div",
                  { staticClass: "flex space-between border-bottom-1px" },
                  [
                    _c("div", { staticClass: "c-333" }, [_vm._v("分期金额：")]),
                    _c("div", { staticClass: "c-666" }, [
                      _vm._v(_vm._s(_vm.loanInfo.loanAmount) + "元")
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "flex space-between border-bottom-1px" },
                  [
                    _c("div", { staticClass: "c-333" }, [_vm._v("分期期数：")]),
                    _c("div", { staticClass: "c-666" }, [
                      _vm._v(_vm._s(_vm.loanInfo.termNumber) + "期")
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "flex space-between border-bottom-1px" },
                  [
                    _c("div", { staticClass: "c-333" }, [_vm._v("分期用途：")]),
                    _c("div", { staticClass: "c-666" }, [
                      _vm._v(_vm._s(_vm.loanInfo.loanPurposeCn))
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "flex space-between border-bottom-1px" },
                  [
                    _c("div", { staticClass: "c-333" }, [_vm._v("付款日：")]),
                    _c("div", { staticClass: "c-666" }, [
                      _vm._v(_vm._s(_vm.loanInfo.receiptDate))
                    ])
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "flex space-between border-bottom-1px" },
                  [
                    _c("div", { staticClass: "c-333" }, [
                      _vm._v("首次付款日：")
                    ]),
                    _c("div", { staticClass: "c-666" }, [
                      _vm._v(_vm._s(_vm.loanInfo.firstReceiptDate))
                    ])
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "flex space-between border-bottom-1px",
                    on: { click: _vm.viewAgreement }
                  },
                  [
                    _c("div", { staticClass: "c-333" }, [_vm._v("分期合同：")]),
                    _c("div", { staticClass: "c-666 flex-1 tr" }, [
                      _vm._v("查看")
                    ]),
                    _c("i", { staticClass: "cubeic-arrow c-666" })
                  ]
                ),
                _vm.repapmentProcess
                  ? _c(
                      "div",
                      {
                        staticClass: "flex space-between border-bottom-1px",
                        on: { click: _vm.viewRepaymentRecord }
                      },
                      [
                        _c("div", { staticClass: "c-333" }, [
                          _vm._v("付款记录：")
                        ]),
                        _c("div", { staticClass: "c-666 flex-1 tr" }, [
                          _vm._v("查看")
                        ]),
                        _c("i", { staticClass: "cubeic-arrow c-666" })
                      ]
                    )
                  : _vm._e(),
                _c(
                  "div",
                  { staticClass: "flex space-between border-bottom-1px" },
                  [
                    _c("div", { staticClass: "c-333" }, [_vm._v("订单状态：")]),
                    _c(
                      "div",
                      {
                        staticClass: "c-666",
                        style: _vm.loanInfo.needRepayment ? "color:red" : ""
                      },
                      [_vm._v(_vm._s(_vm.loanInfo.orderStatus))]
                    )
                  ]
                ),
                _vm.loanInfo.needRepayment && _vm.repapmentProcess
                  ? _c(
                      "cube-button",
                      {
                        staticClass: "submit-btn",
                        attrs: { type: "submit" },
                        on: { click: _vm.handleSubmit }
                      },
                      [_vm._v("立即付款")]
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        : _c("c-spin", {
            staticStyle: { "margin-top": "40vh" },
            attrs: { tip: "Loading...", size: "large" }
          })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }