<template>
  <div class="white-space"></div>
</template>

<script type="text/ecmascript-6">
export default {
  name: "",
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {},
};
</script>

<style scoped lang="less">
.white-space {
  height: 10px;
  background: #eef1f6;
}
</style>
