
<template>
  <div class="container">
    <!-- 订单已失效 -->
    <div>
      <img class="resutl-icon mgt20" src="@assets/images/error.png" />
      <div class="result-title">{{ title }}</div>
      <div class="result-tips" v-for="(item, index) in message" :key="index">{{item}}</div>
      <cube-button v-if="returnOrderUrl" @click="checkOrder" class="handle-btn"
        >查看订单</cube-button
      >
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { stringify } from "qs";


export default {
  name: "",
  data() {
    return {
      showResultType: "",
      returnOrderUrl: "",
      title: '订单已失效',
      message: ['当前订单已失效，请重新下单申请。']
    };
  },
  components: {},
  watch: {
    // $route: {
    //   handler(to, from) {
    //     console.log("监听路由 $route to, from", to, from, this);
    //     this.$mounted();
    //   },
    //   immediate: true
    // }
  },
  methods: {
    checkOrder() {
      window.location.href = this.returnOrderUrl;
    },
  },
  mounted() {
    // console.log(`http://192.168.0.200:8081/application/interceptOrderResult?title=${encodeURIComponent("订单已失效")}&message=${encodeURIComponent("当前订单已失效，请重新下单;好的！")}`)
    console.log("result页面=== router", this.$route);
    const { title, returnUrl, message } = this.$route.query;
    if (title) {
      this.title = decodeURIComponent(title);
    }
    if (message) {
      this.message = decodeURIComponent(message).split(";");
    }
    if (returnUrl) {
      this.returnOrderUrl = returnUrl;
    }
  },
  
  created() {},

  destroyed() {
    this.errorToast && this.errorToast.hide();
  },
};
</script>

<style scoped lang="less">
.container {
  padding: 90px 16px 0;
  text-align: center;

  .resutl-icon {
    //   display: block;
    width: 64px;
    height: 64px;
  }

  .resutl-gif {
    width: 272px;
    height: 213px;
  }

  .result-title {
    margin: 20px 0 16px;
    font-size: 20px;
    // line-height: 28px;
  }

  .result-tips {
    font-size: 14px;
    line-height: 1.3;
    color: #666;
  }

  .handle-btn {
    border-radius: 4px;
    margin-top: 92px;
  }

  .gonghzhonghao-poster {
    display: block;
    width: 375px;
    // height: 100px;
    margin-top: 60px;
    margin-left: -16px;
  }
}
</style>
