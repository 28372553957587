/*
 * @Autor: CPB
 * @Date: 2020-11-06 18:15:37
 * @LastEditors: CZK
 * @LastEditTime: 2023-12-27 11:56:15
 * @Description:
 */
import { axios } from "@/utils/request";
import { requestCarFinance } from "@/utils/requestCarFinance";

/**
 * 获取社会关系
 */
export function queryDataDict(params) {
  return axios({
    url: "/common/queryDataDict.do",
    method: "get",
    params,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
}

// ====================== 7033 api Start=================
/**
 * 上传图片识别
 * @param {*} data
 */
export function discernPicWord(data = {}) {
  return requestCarFinance({
    url: "/common/discernPicWord.do",
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
}

import baseRequest from "axios";
/**
 * ali oss 获取签名
 * @param {*} params
 */
export async function osspolicy(params) {
  return baseRequest({
    url: "/oss/getUploadProof.do",
    method: "get",
    params,
    headers: {},
  });
}
