/*
 * @Author: cpb 
 * @Date: 2020-11-06 17:56:33 
 * @Last Modified by: cpb
 * @Last Modified time: 2020-11-09 11:25:29
 * @Desc: 未实现功能：文字说明（未添加延迟动画）， 滑动时按钮增加透明效果
 */

<template>
  <div>
    <div
      v-show="!popMaskVisible"
      @click="handleShowPopMask"
      class="icon-button guide-button-block"
      ref="dragBox"
      @touchstart="touchstartHandle('dragBox', $event)"
      @touchmove="touchmoveHandle('dragBox', $event)"
      @touchend="touchendHandle('dragBox', $event)"
      :style="positionObject"
    ></div>
    <transition name="rotate">
      <div
        v-show="popMaskVisible"
        @click="handleHidePopMask"
        class="icon-button close-guide-button"
        :style="positionObject"
      ></div>
    </transition>
    <transition name="slide">
      <div
        v-show="popMaskVisible"
        @click="handleBack"
        class="icon-button back-guide-button"
        :style="positionObject"
      >
        <span class="icon-button-text-desc">返回上页</span>
      </div>
    </transition>
    <transition name="slide">
      <div
        v-show="popMaskVisible"
        @click="handleBackHome"
        class="icon-button home-guide-button"
        :style="positionObject"
      >
      <span class="icon-button-text-desc">返回首页</span>
      </div>
    </transition>
    <div
      @click="handleHidePopMask"
      v-show="popMaskVisible"
      class="pop-mask"
    ></div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: "GuideButton",
  components: {},
  data() {
    return {
      popMaskVisible: false,
      positionObject: {
        left: "18px",
        top: "547px",
      },
      coordinate: {
        client: {},
        elePosition: {},
      },
    };
  },
  computed: {},
  methods: {
    handleShowPopMask() {
      this.popMaskVisible = true;
    },
    handleHidePopMask() {
      this.popMaskVisible = false;
    },
    handleBack() {
      this.$router.back();
      this.handleHidePopMask();
    },
    handleBackHome() {
      this.$router.push({
        name: "mine",
      });
      this.handleHidePopMask();
    },

    touchstartHandle(refName, e) {
      let element = e.targetTouches[0];
      // 记录点击的坐标
      this.coordinate.client = {
        x: element.clientX,
        y: element.clientY,
      };
      // 记录需要移动的元素坐标
      this.coordinate.elePosition.left = this.$refs[refName].offsetLeft;
      this.coordinate.elePosition.top = this.$refs[refName].offsetTop;
    },
    touchmoveHandle(refName, e) {
      let element = e.targetTouches[0];
      // 根据初始 client 位置计算移动距离(元素移动位置=元素初始位置+光标移动后的位置-光标点击时的初始位置)
      let x =
        this.coordinate.elePosition.left +
        (element.clientX - this.coordinate.client.x);
      let y =
        this.coordinate.elePosition.top +
        (element.clientY - this.coordinate.client.y);
      // 限制可移动距离，不超出可视区域
      x =
        x <= 0
          ? 0
          : x >= innerWidth - this.$refs[refName].offsetWidth
          ? innerWidth - this.$refs[refName].offsetWidth
          : x;
      y =
        y <= 0
          ? 0
          : y >= innerHeight - this.$refs[refName].offsetHeight
          ? innerHeight - this.$refs[refName].offsetHeight
          : y;
      // 移动当前元素
      this.$refs[refName].style.left = x + "px";
      this.$refs[refName].style.top = y + "px";

      e.preventDefault()
    },
    touchendHandle(refName, e) {
      const { left, top } = this.$refs[refName].style;
      // 可试用transform: transition
      this.positionObject = {
        left,
        top,
      };
    },
  },
  created() {
    window.addEventListener("scroll", () => {
      if (this.popMaskVisible) {
        //  fixme 不知道是否耗费性能了
        this.handleHidePopMask();
      }
    });
  },
};
</script>

<style scoped lang="less">
.pop-mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 800;
  background: #25262d;
  opacity: 0.4;
}

.icon-button {
  position: fixed;
  //   positionObject 控制
  //   top: 550px;
  //   left: 18px;
  z-index: 900;

  width: 36px;
  height: 36px;
  border-radius: 50%;
  opacity: 0.85;
  background-size: 22px;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #fff;

  .icon-button-text-desc {
      position: absolute;
      left: 50px;
      top: 0px;
      margin-top: 2px;
      line-height: 30px;
      z-index: 9;
      color: #fff;
      font-size: 13px;
      
      white-space: nowrap;
  }
}

.guide-button-block {

  background-image: url("../../assets/images/icons/options-icon.png");
  background-color: #579af1;
  background-size: 18px;
  box-shadow: 2px 2px 10px #999;
}

.close-guide-button {
  background-image: url("../../assets/images/icons/cancel.png");
  background-size: 18px;
}

.back-guide-button {
  transform: translateY(-55px);
  background-image: url("../../assets/images/icons/back.png");
}

.home-guide-button {
  transform: translateY(-105px);
  background-image: url("../../assets/images/icons/home.png");
  background-color: #579af1;
}

// 切换动画
.rotate-enter {
  transform: rotate(-30deg);
  opacity: 0.2;
}

.rotate-enter-active {
  transition: all 0.6s;
}

.rotate-leave-to {
  transform: rotate(-30deg);
  opacity: 0.2;
}

.rotate-leave-active {
  transition: all ease 0.2s;
}

// 画出动画
.slide-enter {
  transform: translateY(0);
}

.slide-enter-active {
  transition: all 0.2s;
}

.slide-leave-to {
  transform: translateY(0);
  opacity: 0.2;
}

.slide-leave-active {
  transition: all ease 0.2s;
}
</style>
