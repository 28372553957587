var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.info
    ? _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "loan-main" }, [
          _c("p", { staticClass: "_tip" }, [
            _vm._v("产品信息可能已变更，请再次确认订单信息")
          ]),
          _c("div", { staticClass: "product-info" }, [
            _c("div", { staticClass: "product-title" }, [_vm._v("订单信息")]),
            _c(
              "div",
              { staticClass: "flex border-bottom-1px product-info-section" },
              [
                _c("div", { staticClass: "item-left" }, [_vm._v("交易商户:")]),
                _c("div", { staticClass: "item-right item-right-select" }, [
                  _c("div", { staticClass: "flex-1" }, [
                    _vm._v(_vm._s(_vm.goodsInfo.merchantName))
                  ])
                ])
              ]
            ),
            _c(
              "div",
              { staticClass: "flex border-bottom-1px product-info-section" },
              [
                _c("div", { staticClass: "item-left" }, [_vm._v("课程名称:")]),
                _c("div", { staticClass: "item-right item-right-select" }, [
                  _c("div", { staticClass: "flex-1" }, [
                    _vm._v(_vm._s(_vm.otherInfo.goodsName))
                  ])
                ])
              ]
            ),
            _c(
              "div",
              { staticClass: "flex border-bottom-1px product-info-section" },
              [
                _c("div", { staticClass: "item-left" }, [_vm._v("订单金额:")]),
                _c("div", { staticClass: "item-right" }, [
                  _c("div", { staticClass: "flex-1" }, [
                    _vm._v(_vm._s(_vm._f("yuan")(_vm.otherInfo.amount)))
                  ])
                ])
              ]
            ),
            _c(
              "div",
              { staticClass: "flex border-bottom-1px product-info-section" },
              [
                _c("div", { staticClass: "item-left" }, [_vm._v("付款方式:")]),
                _c("div", { staticClass: "item-right item-right-select" }, [
                  _c("div", { staticClass: "flex-1" }, [
                    _vm._v(_vm._s(_vm._f("stage")(_vm.selectStage)))
                  ])
                ])
              ]
            ),
            _c(
              "div",
              { staticClass: "flex border-bottom-1px product-info-section" },
              [
                _c("div", { staticClass: "item-left" }, [_vm._v("付款计划:")]),
                _c("div", { staticClass: "item-right item-right-select" }, [
                  _c("div", { staticClass: "flex-1" }, [
                    _vm._v(_vm._s(_vm.downPaymentAmount))
                  ])
                ])
              ]
            )
          ]),
          _c(
            "div",
            { staticClass: "flex agreement-read-section" },
            [
              _c(
                "cube-checkbox",
                {
                  staticClass: "with-click",
                  attrs: { shape: "square", "hollow-style": false },
                  model: {
                    value: _vm.checked,
                    callback: function($$v) {
                      _vm.checked = $$v
                    },
                    expression: "checked"
                  }
                },
                [_c("a", { attrs: { href: "javascript:void()" } })]
              ),
              _c("div", { staticClass: "flex-1 agreement-name-section" }, [
                _vm._v("\n        本人已阅读并同意\n        "),
                _c(
                  "a",
                  {
                    staticClass: "agreement-link",
                    on: {
                      click: function($event) {
                        return _vm.toAgreementView(
                          "《豆乐派服务协议》",
                          "https://huodong.jdz360.cn/agreement/%E4%BF%A1%E7%94%A8%E8%B5%8A%E8%B4%AD%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE-%E6%A0%87%E5%87%86%E7%89%8820201012.html"
                        )
                      }
                    }
                  },
                  [_vm._v("《豆乐派服务协议》")]
                ),
                _c(
                  "a",
                  {
                    staticClass: "agreement-link",
                    on: {
                      click: function($event) {
                        return _vm.toAgreementView(
                          "《应收账款转让同意书》",
                          "https://huodong.jdz360.cn/agreement/%E5%BA%94%E6%94%B6%E8%B4%A6%E6%AC%BE%E8%BD%AC%E8%AE%A9%E5%8D%8F%E8%AE%AE-%E6%95%99%E8%82%B2%E9%A1%B9%E7%9B%AE%E7%89%88%E6%9C%AC20201012.html"
                        )
                      }
                    }
                  },
                  [_vm._v("《应收账款转让同意书》")]
                ),
                _c(
                  "a",
                  {
                    staticClass: "agreement-link",
                    on: {
                      click: function($event) {
                        _vm.toAgreementView(
                          "《隐私保护及授权书》",
                          "https://huodong.jdz360.cn/agreement/%E9%9A%90%E7%A7%81%E4%BF%9D%E6%8A%A4%E5%8F%8A%E6%8E%88%E6%9D%83%E4%B9%A6%2020210129)(1).html"
                        )
                      }
                    }
                  },
                  [_vm._v("《隐私保护及授权书》")]
                )
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "submit-section" },
            [
              _c(
                "cube-button",
                {
                  staticClass: "submit-btn",
                  class: { submitting: _vm.submitting },
                  attrs: { type: "submit" },
                  on: { click: _vm.handleSubmit2 }
                },
                [_c("cube-loading"), _vm._v("确认并继续提交\n      ")],
                1
              )
            ],
            1
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }