var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm.showResultType === "repaymentSuccess"
      ? _c(
          "div",
          [
            _c("img", {
              staticClass: "resutl-icon",
              attrs: { src: require("@assets/images/success.png") }
            }),
            _c("div", { staticClass: "result-title" }, [_vm._v("付款成功")]),
            _c("div", { staticClass: "result-tips" }, [
              _vm._v("恭喜你，本期订单已结清，感谢使用。")
            ]),
            _c(
              "cube-button",
              { staticClass: "handle-btn", on: { click: _vm.handleFinish } },
              [_vm._v("完成")]
            )
          ],
          1
        )
      : _vm.showResultType === "repaymentSubmitSuccess"
      ? _c(
          "div",
          [
            _c("img", {
              staticClass: "resutl-icon",
              attrs: { src: require("@assets/images/success.png") }
            }),
            _c("div", { staticClass: "result-title" }, [
              _vm._v("付款提交成功")
            ]),
            _c("div", { staticClass: "result-tips" }, [
              _vm._v("预计10分钟内处理完成，请留意短信通知。")
            ]),
            _c(
              "cube-button",
              { staticClass: "handle-btn", on: { click: _vm.handleFinish } },
              [_vm._v("完成")]
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }