<template>
  <div class="container">
    <div class="title">请完成支付信息认证</div>

    <div class="page-desc">为了保障您的资金安全，请协助确认 付款支付通道签约信息，避免影响 正常使用</div>
    <div :class="{'hide-line': !state.smsSendBtn}" class="tips mgt10">短信验证码发送至 {{phone || '银行预留手机'}}</div>

    <div class="code-area flex border-bottom-1px">
      <input
        class="code-input flex-1"
        v-model="smsCode"
        type="tel"
        placeholder="请输入验证码"
        maxlength="6"
      />

      <div
        class="code-btn"
        v-bind:class="{'disabled':state.smsSendBtn}"
        @click.stop.prevent="getCaptcha"
      >{{ state.smsSendBtn ? state.time + 's后重发' : '发送验证码'}}</div>
    </div>

    <div class="submit-section">
      <c-button class="submit-btn" :disabled="submitting" @click="handleSubmit">确认</c-button>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { ButtonMixin } from "@comp/Button";

import { queryContractConvergence, querySigning } from "@/api/repayment";

const LIMITTIME = 60;

export default {
  name: "",
  mixins: [ButtonMixin],
  data() {
    return {
      state: {
        time: LIMITTIME,
        smsSendBtn: false
      },
      smsCode: "",
      phone: ""
    };
  },
  computed: {},
  components: {},
  methods: {
    getCaptcha() {
      const { state, phone } = this;

      // 恢复按钮
      const regainCheckBtn = () => {
        state.time = LIMITTIME;
        state.smsSendBtn = false;
      };

      setTimeout(() => {
        if (phone.length > 0) {
          const nowTime = +new Date();
          const prevTime = this.$ls.get("certInfoTimeCode");

          const timeDifference = ((nowTime - prevTime) / 1000) | 0;

          if (timeDifference < LIMITTIME) {
            state.time = LIMITTIME - timeDifference;
            state.smsSendBtn = true;
            const interval = window.setInterval(() => {
              if (state.time-- <= 0) {
                regainCheckBtn();
                window.clearInterval(interval);
              }
            }, 1000);
            return;
          }

          state.smsSendBtn = true;
          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              regainCheckBtn();
              window.clearInterval(interval);
            }
          }, 1000);

          const toastSuccess = this.$createToast({
            txt: "验证码发送中",
            type: "correct",
            time: 0
          });
          toastSuccess.show();

          queryContractConvergence({ phone })
            .then(res => {
              toastSuccess.hide();
              if (res && res.success) {
                this.$createToast({
                  txt: "验证码已发送，请留意",
                  type: "correct"
                }).show();
                // 记录发送时间
                this.$ls.set("certInfoTimeCode", +new Date());
                this.$ls.set("smsId", res.smsId);
              } else {
                clearInterval(interval);
                regainCheckBtn();
              }
            })
            .catch(err => {
              toastSuccess.hide();
              clearInterval(interval);
              regainCheckBtn();
            });
        } else {
          this.$createToast({
            txt: "请确认手机号",
            type: "error"
          }).show();
        }
      }, 0);
    },

    handleSubmit() {
      const { smsCode, phone } = this;

      if (smsCode.length < 4) {
        this.$createToast({
          txt: "请确认手机验证码",
          type: "warn"
        }).show();
        return;
      }

      this.submitting = true;
      const smsId = this.$ls.get("smsId");
      querySigning({ smsCode, smsId })
        .then(res => {
          if (res && res.success) {
            this.$router.replace({
              name: "certifiedResult",
              query: {
                showResultType: "success"
              }
            });
          }
          this.regainStatus();
        })
        .catch(err => {
          this.regainStatus();
        });
    }
  },
  mounted() {
    const { phone } = this.$route.query;
    if (phone) {
      this.phone = phone;
      this.getCaptcha();
    } else {
      this.$createToast({
        txt: "请通过正常途径进入",
        time: 0,
        type: "warn"
      }).show();
    }
  }
};
</script>

<style scoped lang="less">
.container {
  padding: 40px 16px;
  background: #fff;

  .hide-line {
    visibility: hidden;
  }

  .title {
    font-size: 20px;
    font-family: PingFang-SC-Medium;
    font-weight: 500;
    color: #333;
    text-align: left;
  }

  .page-desc {
    width: 224px;
    margin-top: 16px;
    line-height: 1.2;
    font-size: 14px;
    color: #666;
    text-align: left;
  }

  .tips {
    margin-top: 40px;
    color: #666;
    font-size: 14px;
    text-align: left;
  }

  .code-area {
    height: 40px;
    margin-top: 50px;

    .code-input {
      height: 100%;
      //   padding-left: 6px;
      outline: none;
      border: 0px;
      color: #666;
      &::-webkit-input-placeholder {
        color: #ccc;
      }
    }

    .code-btn {
      width: 90px;
      margin-left: 20px;
      padding: 10px 0;
      background: #579af1;
      font-size: 14px;
      color: #fff;
      border-radius: 4px;
      &:active {
        background: #5079f7;
      }
    }
  }

  .submit-section {
    padding: 50px 0 20px;
    font-size: 16px;
    .submit-btn {
      border-radius: 4px;
      font-weight: 400;
    }
  }
}
</style>
