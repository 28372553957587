<template>
  <div class="container">
    <img src="@/assets/images/logo.png" class="logo" alt />
    <div class="logo-title">爱保汽车抵押业务平台</div>

    <div>
      <c-input-item
        v-model.trim="mobile"
        title="手机号码"
        maxlength="11"
        placeholder="请输入手机号码"
        type="number"
        pattern="\d*"
      ></c-input-item>
      <c-input-item
        v-model.trim="password"
        title="登录密码"
        maxlength="11"
        placeholder="请输入登录密码"
        type="password"
      ></c-input-item>
      <div class="fill-item border-bottom-1px">
        <div class="fill-name">验证码</div>
        <input
          v-fixedInput
          v-model="tattedCode"
          class="fill-input"
          maxlength="6"
          placeholder="请输入短信验证码"
          type="text"
        />
        <div
          class="get-code"
          :class="{ disabled: state.smsSendBtn }"
          @click.stop.prevent="getCaptcha"
        >
          {{ state.smsSendBtn ? state.time + "s后重发" : "发送验证码" }}
        </div>
      </div>
      <cube-checkbox
        style="padding-left: 0; font-size: 14px"
        shape="square"
        v-model="rememberAccountAndPassowrd"
      >
        <span>保存账号密码</span>
      </cube-checkbox>
    </div>
    
    <div class="submit-section">
      <c-button
        @click="handleSubmit"
        :disabled="submitting"
        class="submit-btn"
        type="submit"
        >登录</c-button
      >
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { getPublickey, login, loginSMS, getInfo, logout, modifyPassword } from "@/api/carFinance";
import { JSEncrypt } from "jsencrypt";
import { Validator } from "@/utils/validate.js";
import { ButtonMixin } from "@comp/Button";
import store from "@/store";
import fixedInput from "@/directive/fixedInput";

const LIMITTIME = 60;

const LOGIN_ACCOUNT = "loginAccount";

export default {
  name: "",
  mixins: [ButtonMixin],
  components: {},
  directives: {
    fixedInput,
  },
  data() {
    return {
      mobile: "",
      password: "",
      rememberAccountAndPassowrd: true,
      encrypt: null,
      state: {
        time: LIMITTIME,
        smsSendBtn: false,
      },
      // 调用短信验证码返回
      tattedId: "",
      tattedCode: "",
      opId: "",
      eye: {
        open: true,
        reverse: false
      },
      newPassword: "",
      passwordType: "password",
      isHideNewPwdTips: false,
      interval: null
    };
  },
  computed: {},
  methods: {
    getInfo() {
      return new Promise((resolve, reject) => {
        getInfo().then((res) => {
          if (res.success) {
            resolve(res.data);
            
          } else {
            reject("err");
          }
        }).catch((err) =>{
          reject("err");
        })
      });
    },
    getPublickey() {
      return new Promise((resolve, reject) => {
        getPublickey()
          .then((res) => {
            if (res.success) {
              const { data: publicKey } = res;
              const encrypt = new JSEncrypt();
              encrypt.setPublicKey(publicKey);
              this.encrypt = encrypt;
              resolve("ok");
            } else {
              reject("err");
            }
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    rememberAccount({ mobile, password }) {
      if (this.rememberAccountAndPassowrd) {
        const expireTime = 1000 * 60 * 60 * 24 * 31;
        this.$ls.set(LOGIN_ACCOUNT, { mobile, password }, expireTime);
      }else {
        this.$ls.remove(LOGIN_ACCOUNT)
      }
    },
    setAccount() {
      const account = this.$ls.get(LOGIN_ACCOUNT);

      if (Object.prototype.toString.call(account) === "[object Object]") {
        for (let [key, value] of Object.entries(account)) {
          this[key] = value;
        }
      }
    },
    handleSubmit() {
      const { mobile, password, tattedId, tattedCode } = this;

      let validator = new Validator();

      validator.add(mobile, "mobileFormat", "请确认手机号码");
      validator.add(password, "isEmpty", "请确认登录密码");
      validator.add(tattedCode, "isEmpty", "请确认验证码");
      const errMsg = validator.start();

      if (errMsg) {
        this.$createToast({
          txt: errMsg,
          type: "error",
        }).show();
        return;
      }

      this.rememberAccount({ mobile, password });

      if (this.encrypt === null) {
        this.getPublickey()
          .then(() => {
            this.handleSubmit();
          })
          .catch((err) => {
            console.log("err", err);
            this.regainStatus();
            this.$createToast({
              txt: "获取RSA公钥失败，请稍后重试...",
              type: "error",
            }).show();
          });

        return false;
      }

      this.submitting = true;

      const formData = {
        mobile,
        password: this.encrypt.encrypt(this.password),
        where: "Manage",
        type: 'Password' || 'CheckCode',
        authCode: tattedCode,
        authId: tattedId,
      };

      login(formData)
        .then((res) => {
          if (res.success) {
              this.getInfo().then((userRes) => {
                const { opId, firstLogin } = userRes;
                if(firstLogin === "1") {
                  // logout({}).then(res1 => {
                  //   if (res1.success) {
                        this.opId = opId;
                        this.newPassword = '';
                        this.firstLoginDialog();
                  //   }
                  // })
                  // .catch(error => {
                  //   console.log("error", error);
                  // });
                  return false;
                } else {
                  const roles = res.data.roles;

                  if (
                    Array.isArray(roles) &&
                    roles.length > 0 &&
                    roles.find((item) => item.roleCode === "CarH5SystemRole")
                  ) {
                    this.$router.replace({
                      name: "carHome",
                    });
                  } else {
                    this.$createToast({
                      txt: "账户不具有访问该系统权限，请联系管理员",
                      type: "error",
                    }).show();
                  }
                }
              })
              
          }
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          this.regainStatus();
        });
    },
    // 恢复按钮
    regainCheckBtn(state) {
      state.time = LIMITTIME;
      state.smsSendBtn = false;
    },
    getCaptcha() {
      const { state, mobile, password} = this;

      setTimeout(() => {
        if(password.length <= 0 ) {
          this.$createToast({
            txt: "请确认密码",
            type: "error",
          }).show();
          return false;
        };
        if (mobile.length > 0) {
          state.smsSendBtn = true;

          this.interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              this.regainCheckBtn(state);
              window.clearInterval(this.interval);
            }
          }, 1000);

          const toastSuccess = this.$createToast({
            txt: "验证码发送中",
            type: "correct",
            time: 0,
          });
          toastSuccess.show();
          const loginFormData = {
            mobile: mobile,
            password: this.encrypt.encrypt(password),
            where: "Manage",
            type: 'Password' || 'CheckCode',
          };
          loginSMS(loginFormData)
            .then((res) => {
              toastSuccess.hide();
              if (res && res.success && res.data) {
                this.$createToast({
                  txt: "验证码已发送，请留意",
                  type: "correct",
                }).show();
                // 储存tattedId
                const tattedId = res.data.authId;
                this.tattedId = tattedId;
                // 记录信息
                this.$ls.set("loginTattedId", tattedId);
                this.$ls.set("loginMobile", mobile);
                this.$ls.set("startLoginTimeCode", +new Date());
              } else {
                clearInterval(this.interval);
                this.regainCheckBtn(state);
              }
            })
            .catch((err) => {
              console.log("err", err);
              toastSuccess.hide();
              clearInterval(this.interval);
              this.regainCheckBtn(state);
            });
        } else {
          this.$createToast({
            txt: "请确认手机号",
            type: "error",
          }).show();
        }
      }, 0);
    },
    firstLoginDialog() {
      const pwdReg = /^[0-9A-Za-z]{6,20}$/;
      this.$createDialog(
        {
          type: "confirm",
          confirmBtn: {
            text: '确认',
            active: true,
            href: 'javascript:;',
          },
          onConfirm: () => {
            console.log("点击了确认")
            this.resetPassword();
          },
        },
        createElement => {
          return [
            createElement(
              "div",
              {
                class: {
                  "login-dialog-title": true
                },
                slot: "title"
              },
              [
                createElement("p", "这是您首次登录系统，为了您的账号安全，请修改初始登录密码")
              ]
            ),
            createElement(
              "div",
              {
                class: {
                  "login-dialog-content": true
                },
                slot: "content"
              },
              [
                createElement("cube-input", {
                  class: {
                    // "agreement-dialog-my-content-iframe": true
                  },
                  attrs: {
                    value: this.newPassword,
                    placeholder: "请设置6-20位登录密码",
                    type: this.passwordType,
                    maxlength: 20,
                    eye: this.eye,
                    clearable: true
                  },
                  on: {
                    input: (value) => {
                      this.newPassword = value;
                      const isChecked = pwdReg.test(this.newPassword);
                      if(isChecked) {
                        this.isHideNewPwdTips = true;
                      } else {
                        this.isHideNewPwdTips = false;
                      }
                    }
                  }
                }),
                createElement("p", {
                  class: {
                    "login-dialog-input-tips": true,
                    "login-dialog-input-tips-hide": !this.isHideNewPwdTips
                  },
                },""),
                createElement("p", {
                  class: {
                    "login-dialog-input-tips": true,
                    "login-dialog-input-tips-hide": this.isHideNewPwdTips
                  },
                },"6-20位字母和数字")
              ]
            )
          ];
        }
      ).show();
    },
    resetPassword() {
      const { isHideNewPwdTips, newPassword, opId } = this;
      if(!isHideNewPwdTips) {
        this.firstLoginDialog();
        return;
      };
      
      let params = {
        firstLogin: "0",
        password: newPassword,
        opId: opId
      };
      modifyPassword(params).then(res => {
        if (res.success) {
            this.password = "";
            this.tattedCode = "";
            this.regainCheckBtn(this.state);
            this.interval && window.clearInterval(this.interval);
            this.$createDialog({
              type: 'alert',
              title: '修改成功',
              content: '请牢记新密码并注意保密',
              icon: 'cubeic-alert'
            }).show();
          }
      })
    }
  },
  mounted() {
    // 清空页面缓存
    store.commit("CLEAR_CACHE_VIEW");
    this.getPublickey();
    this.setAccount();
  },
};
</script>

<style scoped lang="less">
.container {
  padding: 60px 16px 0;
  font-size: 16px;
  .logo {
    display: block;
    margin: 0 auto 30px;
    width: 80px;
    height: 80px;
  }

  .logo-title {
    margin-bottom: 40px;
    font-size: 18px;
    font-weight: 600;
  }

  .submit-section {
    margin-top: 40px;
  }
  .fill-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;

    .fill-name {
      width: 84px !important;
      //padding-left: 16px;
      text-align: left;
      color: rgba(51, 51, 51, 1);
      line-height: 40px;
    }
    .fill-input {
      height: 40px;
      width: 150px;
      flex: 1;
      background: none;
      outline: none;
      border: 0px;
      color: #666;
      &::-webkit-input-placeholder {
        color: #ccc;
        // line-height: 1.2em;
        line-height: 19px;
      }
    }

    .get-code {
      width: 90px;
      text-align: right;
      font-size: 14px;
      color: #579af1;
      line-height: 40px;
    }
  }
}

</style>
<style lang="less">
  .login-dialog-title {
    padding: 16px 10px 0;
    font-size: 16px;
    line-height: 1.3;
    text-align: left;
  }
  .login-dialog-content {
    padding: 0 10px;
  }
  .login-dialog-input-tips {
    font-size: 12px;
    color: #f56c6c;
    padding-left: 10px;
    height: 20px;
  }
  .login-dialog-input-tips-hide {
    display: none;
  }
</style>
