<template>
  <div class="spinner" :style="style">
    <div class="bounce1"></div>
    <div class="bounce2"></div>
    <div class="bounce3"></div>
  </div>
</template>

<script type="text/ecmascript-6">
const COMPONENT_NAME = "carousel";
export default {
  name: COMPONENT_NAME,
  components: {},
  props: {
    size: {
      type: [String, Number]
    }
  },
  data() {
    return {};
  },
  computed: {
    style() {
      if (!this.size) {
        return;
      }
      const value = `${this.size}px`;
      return {
        width: value,
        height: value
      };
    }
  },
 
};
</script>

<style scoped lang="less">
.spinner {
  margin: 0 auto;
  width: 150px;
  text-align: center;
}

.spinner > div {
  width: 12px;
  height: 12px;
  background-color: #fff;

  border-radius: 100%;
  display: inline-block;

  animation: bouncedelay 1s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.spinner .bounce1 {
  animation-delay: -0.28s;
}

.spinner .bounce2 {
  animation-delay: -0.14s;
}

@keyframes bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0.5);
  }
  40% {
    transform: scale(0.7);
    background-color: #f9f9f9;
  }
}
</style>
