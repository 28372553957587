<!--
 * @Autor: CPB
 * @Date: 2019-08-22 14:04:36
 * @LastEditors: CPB
 * @LastEditTime: 2020-12-30 15:43:11
 * @Description: 
-->
<template>
  <div class="container">
    <!-- <img class="aboutus-pic w340" src="@assets/images/aboutus/aboutus-1.png" />
    <img class="aboutus-pic" src="@assets/images/aboutus/aboutus-2.png" />
    <img class="aboutus-pic w340" src="@assets/images/aboutus/aboutus-3.png" />
    <img class="aboutus-pic w340" src="@assets/images/aboutus/aboutus-4.png" /> -->
    <img class="aboutus-pic" src="@assets/images/aboutus/aboutus-new-20201230.png" />
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: "",
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>

<style scoped lang="less">
.container {
    padding-top: 16px;
    .aboutus-pic {
        display: block;
        width: 100%;
        margin:0 auto 50px;
    }

    .w340 {
        width: 340px;
    }
}
</style>
