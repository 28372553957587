var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "cube-upload-file",
      class: _vm.fileCls,
      on: { click: _vm.clickHandler }
    },
    [
      _vm._t(
        "default",
        [
          _c(
            "div",
            { staticClass: "cube-upload-file-def", style: _vm.fileStyle },
            [
              _c("i", {
                staticClass: "cubeic-wrong",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.removeFile($event)
                  }
                }
              }),
              _c(
                "div",
                {
                  staticClass: "cube-upload-file-state",
                  class: _vm.fileStatusCls
                },
                [
                  _c("i", {
                    staticClass: "cube-upload-file-status",
                    class: _vm.statusCls
                  }),
                  _c("span", { staticClass: "cube-upload-file-progress" }, [
                    _vm._v(_vm._s(_vm.fileProgress))
                  ])
                ]
              )
            ]
          )
        ],
        { imgStyle: _vm.fileStyle, progress: _vm.fileProgress }
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }