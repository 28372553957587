<template>
  <div class="container">
    <template
      v-if="
        repaymentInfo.repaymentBalance || repaymentInfo.repaymentBalance === 0
      "
    >
      <div class="field">
        <div class="field-item border-bottom-1px">
          <div class="field-item-name">应付金额：</div>
          <div class="field-item-content">
            {{ repaymentInfo.principalBalance }}元
          </div>
        </div>

        <div class="field-item border-bottom-1px">
          <div class="field-item-name">应付费用：</div>
          <div class="field-item-content">
            {{ repaymentInfo.interestBalance }}元
          </div>
        </div>

        <!-- <div class="field-item border-bottom-1px">
          <div class="field-item-name">罚ㅤㅤ息：</div>
          <div class="field-item-content">{{repaymentInfo.}}元</div>
        </div>-->

        <div class="field-item border-bottom-1px">
          <div class="field-item-name">费用减免：</div>
          <div class="field-item-content">{{ repaymentInfo.feeWaiver }}元</div>
        </div>

        <div class="field-item border-bottom-1px">
          <div class="field-item-name">应付总额：</div>
          <div class="field-item-content text-orange">
            {{ repaymentInfo.repaymentBalance }}元
          </div>
        </div>

        <div class="field-item border-bottom-1px">
          <div class="field-item-name">本次支付：</div>
          <div class="field-item-content">
            <template v-if="editable">
              <label for="input" class="editable-tag">修改金额</label>
              <input
                id="input"
                v-model="repayMoney"
                class="fill-input"
                maxlength="10"
                placeholder="请输入支付金额"
                type="number"
                pattern="[0-9]*"
                min="0"
                step="0.01"
                inputmode="decimal"
              />
            </template>
            <span v-else>
              {{ repayMoney }}
            </span>
            <span>元</span>
          </div>
        </div>
      </div>

      <div class="white-sapce"></div>

      <div class="bank-card-field">
        <div class="repayment-title border-bottom-1px">请选择付款支付方式</div>

        <div class="bank-card-info flex border-bottom-1px">
          <img
            v-if="repaymentInfo.banklogo"
            class="bank-logo"
            :src="
              require(`@/assets/images/banklogo/${repaymentInfo.banklogo}.png`)
            "
            alt
          />
          <div class="flex-1 tl">
            <div class="bank-card-number">
              {{ repaymentInfo.bankName }}(尾号{{
                repaymentInfo.bankCardNo | bankCardNoFilter
              }})
            </div>
            <!-- <div class="bank-card-limmit">单笔限额5000元</div> -->
          </div>
          <i class="cubeic-right"></i>
        </div>
        <c-alert :title="errorMessages"></c-alert>

        <c-button
          style="margin-top: 10px"
          :disabled="submitting"
          @click="handleSubmit"
          >确认付款</c-button
        >
      </div>
    </template>
  </div>
</template>

<script type="text/ecmascript-6">
import {
  xingShuaiSureRepayment,
  xingShuaiRepayment,
  repaymentInquiry,
} from "@/api/repayment";
import { ButtonMixin } from "@comp/Button";
import CAlert from "@comp/Alert";

import { bankNameList } from "./bankNameList";

const minRepaymentAmount = 200;

export default {
  name: "",
  mixins: [ButtonMixin],
  components: {
    CAlert,
  },
  data() {
    return {
      repaymentInfo: {},
      repayMoney: "",
      whetherXs: true,
      hasIllegitimateData: false,
      errorMessages: "",
    };
  },
  filters: {
    bankCardNoFilter(el) {
      if (!el) {
        return "";
      }
      return el.slice(-4);
    },
  },
  computed: {
    maxRepaymentBalance() {
      return this.repaymentInfo.repaymentBalance;
    },

    editable() {
      return this.whetherXs && this.maxRepaymentBalance >= minRepaymentAmount;
    },
  },
  watch: {
    repayMoney(newValue) {
      if (this.whetherXs) {
        if (this.maxRepaymentBalance >= minRepaymentAmount) {
          this.hasIllegitimateData = this.checkIllegitimateData(
            newValue,
            minRepaymentAmount,
            this.maxRepaymentBalance
          );
        }
      }
    },
  },
  methods: {
    checkIllegitimateData(value, minRepayamentAmount, maxReymentAmout) {
      if (!/^[0-9]+\.{0,1}[0-9]{0,2}$/.test(value)) {
        return (this.errorMessages = "本次支付金额请输入最多两位小数的数字");
      }
      if (value > maxReymentAmout) {
        return (this.errorMessages = `本次支付金额应不大于应付总额${maxReymentAmout}元`);
      }
      if (value < minRepayamentAmount) {
        return (this.errorMessages = `本次支付金额应不小于${minRepayamentAmount}元`);
      }

      this.errorMessages = "";
    },
    handleSubmit() {
      const {
        isAgreement,
        bankMobile,
        bankCardNo: bankCardNumber,
        bankcardId
      } = this.repaymentInfo;
      const { receiptId } = this.$route.query;

      if (isAgreement === "1") {
        return this.submitRepaymentRequest();
      }

      this.$createMyDialog({
        phone: bankMobile,
        bankCardNumber,
        bankcardId,
        receiptId,
        onConfirm: (e) => {
          this.repaymentInfo.isAgreement = "1";
          this.submitRepaymentRequest();
        },
      }).show();
    },

    submitRepaymentRequest() {
      const { receiptId, isAll, planId, productId } = this.$route.query;
      // 本次支付
      const { repayMoney } = this;

      if (this.hasIllegitimateData) {
        return;
      }

      if (this.editable && repayMoney < minRepaymentAmount) {
        this.repayMoney = minRepaymentAmount;
        setTimeout(() => {
          this.resetErrorMsg();
        }, 2000);
        return (this.errorMessages = `本次支付金额不能少于${minRepaymentAmount}元`);
      }

      this.submitting = true;

      xingShuaiRepayment({
        receiptId,
        isAll,
        planIds: planId,
        productId,
        repayMoney,
      })
        .then((res) => {
          if (res.success) {
            this.handleSubmitSuccess(res.data);
          } else {
            this.showErrorMessage(res);
            this.regainStatus();
          }
        })
        .catch((err) => {
          this.regainStatus();
          console.log("err", err);
        });
    },

    handleSubmitSuccess(batchId) {
      let continueStatus = false;

      return repaymentInquiry({ batchId })
        .then((res) => {
          if (res.success) {
            const { errorMsg, errorCode, responseStatus, status } = res.data;

            if (status === "1") {
              this.$router.replace({
                name: "resultRepayment",
                query: {
                  showResultType: "repaymentSuccess",
                },
              });
            } else {
              if (responseStatus === "1" && status === "0") {
                this.showErrorMessage({
                  errCode: errorCode || "XS10008",
                  errMsg: `${errorMsg}`,
                });
              } else if (responseStatus === "0" && status === "0") {
                setTimeout(() => {
                  this.handleSubmitSuccess(batchId);
                }, 2000);
                continueStatus = true;
              } else {
                this.showErrorMessage({
                  errCode: "XS10009",
                  errMsg: "程序处理异常，请联系客服人员",
                });
              }
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          if (!continueStatus) {
            this.regainStatus();
          }
        });
    },
    resetErrorMsg() {
      this.errorMessages = "";
    },
    showErrorMessage({ errCode, errMsg }) {
      if (errCode) {
        this.errorMessages = `错误码：${errCode}； 错误信息：${errMsg}`;
      } else {
        this.resetErrorMsg();
      }
    },
  },

  mounted() {
    const {
      receiptId,
      isAll,
      planId,
      productId,
      whetherXs,
    } = this.$route.query;

    xingShuaiSureRepayment({
      receiptId,
      isAll,
      planIds: planId,
      productId,
    }).then((res) => {
      if (res.success) {
        const repaymentInfo = res.data;
        const bankNo = repaymentInfo.bankNo;
        repaymentInfo.banklogo = bankNameList[bankNo] || "";
        this.repaymentInfo = repaymentInfo;

        this.repayMoney = this.repaymentInfo.repaymentBalance;
        // todo 判断类别，可编辑状态 刷新页面 whetherXs为字符串
        this.whetherXs = String(whetherXs) === "true";
      }
    });
  },
};
</script>

<style scoped lang="less">
.container {
  color: #333;
  .field {
    padding: 0 16px;

    .field-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 48px;

      .field-item-name {
        color: #333;
      }

      .field-item-contnet {
      }

      .text-orange {
        color: #f47636;
        font-size: 18px;
      }
    }
  }

  .editable-tag {
    color: #579af1;
    font-size: 12px;
  }

  .fill-input {
    width: 100px;
    text-align: right;
  }

  .white-sapce {
    height: 10px;
    background: #eef1f6;
  }

  .bank-card-field {
    padding: 0 16px;
    .repayment-title {
      height: 50px;
      text-align: left;
      line-height: 50px;
    }

    .bank-card-info {
      height: 66px;
      // margin-bottom: 20px;

      .bank-logo {
        width: 24px;
        height: 24px;
        margin-right: 10px;
      }
      .bank-card-number {
      }

      .bank-card-limmit {
        margin-top: 8px;
        color: #666;
        font-size: 14px;
      }

      .cubeic-right {
        color: #08c261;
        font-size: 20px;
      }
    }
  }
}
</style>
