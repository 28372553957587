import Button from "./Button";
import BaseButtonMixin from "./ButtonMixin";

export const CButton = {
  install: function(Vue) {
    Vue.component("CButton", Button);
  }
};

export const ButtonMixin = BaseButtonMixin;
export default Button;
