<template>
  <div class="container">
    <div class="icon-404">404</div>
    <div>Not Found</div>
    <div>
      <!-- <img class="not-found-img" src="@/assets/images/404-notfond.png" alt> -->
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: "",
  data() {
    return {};
  },
  components: {}
};
</script>

<style scoped lang="less">
.container {
  text-align: center;
}

.icon-404 {
  position: relative;
  letter-spacing: -1px;
  line-height: 120px;
  font-size: 60px;
  font-weight: 100;
  margin: 0px 0 50px 0;
  text-shadow: 0 1px 0 #fff;
}

.not-found-img {
  width: 300px;
  height: auto;
}
</style>
