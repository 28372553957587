<template>
  <div class="container">
    <template v-if="loanInfo.receiptId">
      <div v-if="repapmentProcess" class="amount-section flex">
        <div class="flex-1">
          <div class="fs20 bold">{{loanInfo.thisReceiptAmount}}</div>
          <div class="fs14 mgt14">本期应付( 元)</div>
        </div>
        <div class="flex-1">
          <div class="fs20 bold">{{loanInfo.receiptBalance}}</div>
          <div class="fs14 mgt14">未付总额(元)</div>
        </div>
      </div>
      <div v-if="repapmentProcess" class="white-sapce"></div>

      <div class="repayment-info-list">
        <div class="flex space-between border-bottom-1px">
          <div class="c-333">分期金额：</div>
          <div class="c-666">{{loanInfo.loanAmount}}元</div>
        </div>

        <div class="flex space-between border-bottom-1px">
          <div class="c-333">分期期数：</div>
          <div class="c-666">{{loanInfo.termNumber}}期</div>
        </div>
        <!-- 隐藏 需求226 20200415-->
        <!-- <div class="flex space-between border-bottom-1px">
          <div class="c-333">付款方式：</div>
          <div class="c-666">{{loanInfo.repaymentTypeCn}}</div>
        </div>-->

        <div class="flex space-between border-bottom-1px">
          <div class="c-333">分期用途：</div>
          <div class="c-666">{{loanInfo.loanPurposeCn}}</div>
        </div>
        <!-- 
        <div class="flex space-between border-bottom-1px">
          <div class="c-333">绑定卡号：</div>
          <div class="c-666">{{loanInfo.repaymentCardName}} ( 尾号 {{loanInfo.repaymentCardNo}} )</div>
        </div>-->

        <div class="flex space-between border-bottom-1px">
          <div class="c-333">付款日：</div>
          <div class="c-666">{{loanInfo.receiptDate}}</div>
        </div>

        <div class="flex space-between border-bottom-1px">
          <div class="c-333">首次付款日：</div>
          <div class="c-666">{{loanInfo.firstReceiptDate}}</div>
        </div>

        <!-- <div class="flex space-between border-bottom-1px">
        <div class="c-333">分期费率：</div>
        <div class="c-666">1.25%</div>
        </div>-->

        <div @click="viewAgreement" class="flex space-between border-bottom-1px">
          <div class="c-333">分期合同：</div>
          <div class="c-666 flex-1 tr">查看</div>
          <i class="cubeic-arrow c-666"></i>
        </div>

        <div @click="viewRepaymentRecord" v-if="repapmentProcess" class="flex space-between border-bottom-1px">
          <div class="c-333">付款记录：</div>
          <div class="c-666 flex-1 tr">查看</div>
          <i class="cubeic-arrow c-666"></i>
        </div>

        <div class="flex space-between border-bottom-1px">
          <div class="c-333">订单状态：</div>
          <div
            class="c-666"
            :style="loanInfo.needRepayment ?'color:red':''"
          >{{loanInfo.orderStatus}}</div>
        </div>

        <cube-button
          v-if="loanInfo.needRepayment && repapmentProcess"
          @click="handleSubmit"
          class="submit-btn"
          type="submit"
        >立即付款</cube-button>
      </div>
    </template>
    <c-spin style="margin-top:40vh;" tip="Loading..." size="large" v-else></c-spin>
  </div>
</template>

<script type="text/ecmascript-6">
import { queryReceiptInfo, qeuryContract } from "@/api/repayment";
import { queryUserReceiptInfo } from "@/api/application";

export default {
  name: "",
  props: {
    process: {
      type: String,
      default: "repayment"
    }
  },
  data() {
    return {
      basicFlow: true,
      loanInfo: {}
    };
  },
  computed: {
    applicationProcess() {
      return this.process === "application";
    },
    repapmentProcess() {
      return this.process === "repayment";
    }
  },
  components: {},
  methods: {
    viewAgreement() {
      const { receiptId } = this.$route.query;
      qeuryContract({ receiptId }).then(res => {
        if (res.success) {
          this.showPickerStyle(res.data);
        }
      });
    },
    showPickerStyle(list = []) {
      this.$createActionSheet({
        title: "分期合同",
        pickerStyle: true,
        data: list.map(item => {
          item.content = item.depositFilename;
          return item;
        }),
        onSelect: (item, index) => {
          this.$router.push({
            name: "agreementView",
            query: {
              url: item.keepUrl
            }
          });
        },
        onCancel: () => {}
      }).show();
    },
    viewRepaymentRecord() {
      // 有页面返回，没有跳转

      if (this.basicFlow) {
        this.$router.back();
        return;
      }
      const { receiptId } = this.$route.query;
      this.$router.push({
        name: "billDetails",
        query: {
          receiptId
        }
      });
    },
    handleSubmit() {
      // 有页面返回，没有跳转

      if (this.basicFlow) {
        this.$router.back();
        return;
      }
      const { receiptId } = this.$route.query;
      this.$router.push({
        name: "billDetails",
        query: {
          receiptId
        }
      });
    }
  },
  mounted() {
    const { receiptId } = this.$route.query;

    const handleCallBack = res => {
    
      if (res.success) {
        const loanInfo = res.data;

        loanInfo.repaymentCardNo =
          loanInfo.repaymentCardNo && loanInfo.repaymentCardNo.slice(-4);
        
        loanInfo.needRepayment = ['付款中', "逾期中","逾期部分还款"].includes(loanInfo.orderStatus)
        
        this.loanInfo = loanInfo;
      }
    };

    if (this.applicationProcess) {
      queryUserReceiptInfo({ receiptId }).then(handleCallBack);
    } else {
      queryReceiptInfo({
        receiptId
      }).then(handleCallBack);
    }
  },
  beforeRouteEnter(to, from, next) {
    if (from.name === "billDetails") {
      next();
    } else {
      next(vm => (vm.basicFlow = false));
    }
  }
};
</script>

<style scoped lang="less">
.amount-section {
  position: relative;
  height: 100px;
  color: #fff;
  background: #579af1;

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    transform: translate(-50%, -50%) scale(0.5);
    width: 1px;
    height: 80px;
    background: #eef1f6;
  }

  .mgt14 {
    margin-top: 14px;
  }
}

.white-sapce {
  height: 10px;
  background: #eef1f6;
}

.repayment-info-list {
  padding: 0 16px;
  font-size: 14px;
  line-height: 46px;
}

.submit-btn {
  margin: 40px 0 36px;
  border-radius: 4px;
}
</style>
