<template>
  <div class="container">
    <md-result-page  :img-url="require('@/assets/images/commited.png')"  text="资料已提交" subtext="请稍后留意审核结果"></md-result-page>
    <div class="pd20">
      <md-button class="mgt30" type="primary" @click="handleBackHome">返回首页</md-button>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { ResultPage, Button } from "mand-mobile";
export default {
  name: "",
  components: {
    [ResultPage.name]: ResultPage,
    [Button.name]: Button
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    handleBackHome() {
        this.$router.replace({
            name: 'carHome'
        })
    }
  }
};
</script>

<style scoped lang="less">
</style>
