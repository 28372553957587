var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("cube-popup", { ref: "popup", attrs: { type: "extend-popup" } }, [
    _c(
      "div",
      {
        staticClass: "extend-popup-content",
        style: { width: _vm.contentWidth }
      },
      [
        _c(
          "div",
          { staticClass: "title-block" },
          [
            _c("span", { staticClass: "title-text" }, [
              _vm._v(_vm._s(_vm.title))
            ]),
            _c("md-icon", {
              staticClass: "close-icon",
              attrs: { name: "close", size: "lg" },
              on: { click: _vm.hide }
            })
          ],
          1
        ),
        _vm._t("default"),
        _vm._t("footer")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }