import Vue from "vue";

/**
 * 借款token
 */

import { ACCESS_TOKEN } from "@/store/mutation-types";

export function getToken() {
  return Vue.ls.get(ACCESS_TOKEN);
}

export function setToken(token, maxAge = null) {
  return Vue.ls.set(ACCESS_TOKEN, token, maxAge);
}

export function removeToken() {
  return Vue.ls.remove(ACCESS_TOKEN);
}

/**
 * 付款登录
 */

const REPAYMENT_TOKEN = "Repayment-Access-Token";

export function getRepaymentToken() {
  return Vue.ls.get(REPAYMENT_TOKEN);
}

export function setRepaymentToken(token, maxAge = null) {
  return Vue.ls.set(REPAYMENT_TOKEN, token, maxAge);
}

export function removeRepaymentToken() {
  return Vue.ls.remove(REPAYMENT_TOKEN);
}

/**签约登录 */

const CERT_TOKEN = "cert-Access-Token";

export function getCertToken() {
  return Vue.ls.get(CERT_TOKEN);
}

export function setCertToken(token, maxAge = null) {
  return Vue.ls.set(CERT_TOKEN, token, maxAge);
}

export function removeCertToken() {
  return Vue.ls.remove(CERT_TOKEN);
}
