<template>
  <LoanDetails process="application"></LoanDetails>
</template>

<script type="text/ecmascript-6">
import LoanDetails from "@/views/repayment/LoanDetails";
export default {
  name: "",
  components: {
    LoanDetails
  },
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>

<style scoped lang="less">
</style>
