import Spin from "./Spin";


export const CSpin = {
  install: function(Vue) {
    Vue.component("CSpin", Spin);
  }
};

export default Spin;
