/**
 *  20200303 最新状态
     * Created(0, "首次创建"),
    Apply(1, "申请分期"),
    UploadPic(2, "上传身份证"),
    ConfirmId(3, "确认身份"),
    FaceAuth(4, "活体认证"),
    BasicInfo(5, "基本信息"),
    AllowFail(6,"准入失败"),
    AllowOk(7,"准入成功"),
    BindCard(8, "绑卡"),
    BindCardFail(9, "P2p绑卡失败"),
    OpenAccount(10, "开户"),
    OpenAccountFail(11, "开户失败"),
    AgreePay(12, "协议支付"),
    AgreePayFail(13, "协议支付失败"),
    TradePass(14, "交易规则通过"),
    TradeFail(15, "交易规则失败"),
    WaitApprove(16,"待复审"),
    RuleApprove(17,"复审通过"),
    ApproveReject(18,"人审拒绝"),
    BidOver(19, "上标成功"),
    BidFail(20, "上标失败"),
 */

/** 20200306

Created(-2, "法大大待复审"),
Created(0, "首次创建"),
Apply(1, "申请分期"),
UploadPic(2, "上传身份证"),
ConfirmId(3, "确认身份"),
FaceAuth(4, "活体认证"),
BasicInfo(5, "基本信息"),
AllowFail(6,"准入失败"),
AllowOk(7,"准入成功"),
BindCard(8, "绑卡"),
BindCardFail(9, "P2p绑卡失败"),
OpenAccount(10, "开户"),
OpenAccountFail(11, "开户失败"),
AgreePay(12, "绑卡加协议支付"),
AgreePayFail(13, "协议支付失败"),
TradePass(14, "交易规则通过"),
TradeFail(15, "交易规则失败"),
WaitApprove(16,"待复审"),
RuleApprove(17,"复审通过"),
ApproveReject(18,"人审拒绝"),
BidOver(19, "上标成功"),r
BidFail(20, "上标失败"),
 */
import Vue from "vue";
import router from "@/router";
import { Toast } from "cube-ui";
import { faceRedirectUrl } from "@/config/config";
import { fadadaAuthentication, fadadaStayAuthAutoSign, findAttachProducts,getFaceToken,applyStage } from "../../api/application";
Vue.use(Toast);

export const action = (otherInfo, data) => {
  // 法大大认证
  const toRealNameAuthentication = () => {
    if (otherInfo.faceType === "Faceplusplus") {
      //face ++ 认证
      // router.push({
      //   name: "realNameAuthentication",
      // });
      fadadaAuthentication({}).then((res) => {
        if (res.success) {
          window.location.href = res.data.verifyUrl;
        }
      });
      return;
    } else if (true || otherInfo.faceType === "Fadada") {
      fadadaAuthentication({}).then((res) => {
        if (res.success) {
          window.location.href = res.data.verifyUrl;
        }
      });
      return;
    } else {
      alert("otherIndo.faceType未空，跳转异常");
    }
  };

  //法大大授权
  const fadadaAuthorize = ()=>{
    fadadaStayAuthAutoSign({}).then(res=>{
      console.log(res,'法大大授权接口')
      if (res.success) {
        if(res.data.url){
          window.location.href = res.data.url;
        }else{
          if(res.data.autoSignStatus===1||res.data.autoSignStatus==='1'){
            //已授权跳转法大大结果页
            router.push({
              name: "faaResult",
            });
          }
        }   
      }
    })
  }

  const toImproveInformation = () => {
    router.push({
      name: "improveInformation",
    });
  };
  const toAddBankCard = () => {
    router.push({
      name: "addBankCard",
    });
  };
  const toAgreementPayment = () => {
    router.push({
      name: "agreementPayment",
      query: {
        phone: otherInfo.mobile,
        bank_phone: otherInfo.phone,
      },
    });
  };

  const toResult = (resultType, returnUrl) => {
    console.log('进入结果页=》',resultType)
    const query = {
      resultType,
    };
    // 分期成功页设置returnUrl
    if (returnUrl) {
      query.returnUrl = returnUrl;
    }
    console.log('进入跳转前')
    return () => {
      
      router.push({
        name: "result",
        query,
      });
      console.log('进入跳转后')
    };
  };

  // 状态为6时处理
  const status6Func = () => {
    console.log('进来状态6')
    findAttachProducts().then(res=>{
      const {attachProductStutus, term} = res.data
      console.log(res,'状态6处理结果')
      switch(attachProductStutus){
        case 'NULL_ATTACH':  //没有附产品，进入结果页
          console.log('没有附产品进入结果页')
          router.push({
            name: "result",
            query:{resultType:'applyReject'}
          });
          break;
        case 'NEXT_STAGE':  //有附产品，直接进入下一步
          // 处理附产品提交
          console.log('有附产品，直接进入下一步')
          applyStage1(term)
          break;
        case 'CONFIRM_PAGE':  // 有附产品，进入确认页
         console.log('有附产品，进入确认页')
          router.push({name: "nextApply"});
          break;
        default:
          console.log('默认进入结果页')
          router.push({
            name: "result",
            query:{resultType:'applyReject'}
          });
          break;
      }
    })
  }

  // 处理附产品提交
  const applyStage1 = (term) => {
    applyStage({
      term,
    })
      .then((res) => {
        if (res.success) {
          const {
            data: { status, params },
            other: otherInfo,
          } = res;
          if (status === "SimpleContractNo" || status === "NotYetAgree") {
            nextProcess(otherInfo, { params });
          } else if (status === "NoThreeMonth") {
            this.dialog = this.$createDialog({
              type: "confirm",
              icon: "cubeic-alert",
              title: "是否确认提交申请",
              content: "确认则开放签约放款",
              confirmBtn: {
                text: "确定",
                active: true,
                disabled: false,
                href: "javascript:;",
              },
              cancelBtn: {
                text: "取消",
                active: false,
                disabled: false,
                href: "javascript:;",
              },
              onConfirm: () => {
                nextProcess(otherInfo, { params });
              },
              onCancel: () => {},
            });
            this.dialog.show();
          } else if (status === "ThreeMonthOver") {
            // 做 认证入口
            if (otherInfo.faceType === "Fadada") {
              fadadaAuthentication({}).then((res) => {
                if (res.success) {
                  window.location.href = res.data.verifyUrl;
                }
              });
              return;
            } else if (otherInfo.faceType === "Faceplusplus") {
              //face ++ 认证
              getFaceToken({
                status: otherInfo.status,
              })
                .then((res) => {
                  if (res.success) {
                    const token = res.data;

                    window.location.href = `${faceRedirectUrl}${token}`;
                  }
                })
                .catch((err) => {
                  console.log("err", err);
                });
            }
          }
        }
        // setTimeout(() => {
        //   this.submitting = false;
        // }, 300);
      })
      .catch((err) => {
        // setTimeout(() => {
        //   this.submitting = false;
        // }, 300);
      });
  }
  return {
    "step-3": toResult("fadadaReviewReject"),
    "step-2": toResult("fadadaToBeVerified"),
    step0: toRealNameAuthentication,
    step1: toRealNameAuthentication,
    step2: toRealNameAuthentication,
    step3: toRealNameAuthentication,
    step4: toImproveInformation,
    step5: toAddBankCard,
    step6: status6Func,
    step7: toAddBankCard,

    step8: toAddBankCard,
    step9: toAddBankCard,
    step10: toAddBankCard,
    step11: toAddBankCard,
    step12: fadadaAuthorize,
    step13: toAddBankCard,
    step14: toResult("bidHandle"),
    step15: fadadaAuthorize,
    step16: toResult("bidHandle"),
    step17: toResult("bidHandle"),
    step18: toResult("infoSubmitted"),
    step19: toResult("applyReject"),
    step20: toResult("applyReject"),
    step21: toResult("stagedSuccess", `${otherInfo.returnUrl}${data.params}`),
    step22: toResult("bidHandle"),
   
    
  };
};

export const nextProcess = (otherInfo = {}, data = {}) => {
  const stepNum = `step${otherInfo.status}`;
  console.log("%c otherInfo", "color:red;", otherInfo,'---',stepNum);
  action(otherInfo, data)[stepNum]();
};
