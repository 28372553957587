var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tips-top" },
    [
      _c("span", { staticClass: "iconfont icon-dunpai mgr10 fs22" }),
      _c("div", { staticClass: "flex-1 tl" }, [_vm._t("default")], 2),
      _vm._t("extra")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }