<template>
  <div class="field">
    <header class="field-header" v-if="title">
      <div class="field-header-heading">
        <div class="field-header-title">{{title}}</div>
        <p class="field-header-brief">{{brief}}</p>
      </div>

      <div class="field-header-aciton">
        <slot name="action"></slot>
      </div>
    </header>
    <slot ></slot>
    <slot name="footer"></slot>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: "",
  props: {
    title: String,
    brief: String
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>

<style scoped lang="less">
.field {
  padding: 20px;
  background: #fff;
  border: none;
  text-align: left;

  .field-header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  .field-header-heading {
    flex: 1;
  }

  .field-header-title {
    color: #111a34;
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
  }

  .field-header-brief {
    margin-top: 4px;
    color: #858b9c;
    font-size: 12px;
    line-height: 1.4;
  }

  .field-header-aciton {
    align-items: flex-end;
    height: 18px;
    line-height: 18px;
    display: inline-flex;
    flex-shrink: 0;
    -webkit-box-align: center;
    justify-content: flex-end;
    margin-left: 6px;
    color: #858b9c;
    font-size: 12px;
    align-self: flex-start;
    -webkit-box-pack: end;
  }
}
</style>
