/*
 * @Author: cpb 
 * @Date: 2020-07-28 09:49:52 
 * @Last Modified by: cpb
 * @Last Modified time: 2020-11-24 10:36:32
 * @Desc 优化考虑按需引入图标
 */

<template>
  <div>
    <div v-if="title" class="chart-title">{{title}}</div>
    <canvas :id="elementId" class="canvas"></canvas>
  </div>
</template>

<script type="text/ecmascript-6">

// const Core = require('@antv/f2/lib/core');
// require('@antv/f2/lib/geom/line'); // 只加载折线图

const F2 = require("@antv/f2");

// https://f2.antv.vision/zh/examples/component/legend#marker

export default {
  name: "",
  props: {
    title: String,
    data: {
      type: Array,
      default() {
        return [
          {
            month: "一月",
            name: "核批",
            value: 7
          },
          {
            month: "一月",
            name: "全部",
            value: 44
          }
        ];
      }
    },
    elementId: {
      type: String,
      default: `${Math.random()}`
    }
  },
  components: {},
  data() {
    return {
      chartElement: null
    };
  },
  computed: {},
  watch: {
    data() {
      const chart = this.chartElement;
      // Step 2: 载入数据源
      chart.source(this.data);
      chart.tooltip({
        showCrosshairs: true
      });

      chart.legend({
        marker: function marker(x, y, r, ctx) {
          ctx.lineWidth = 1;
          ctx.strokeStyle = ctx.fillStyle;
          ctx.moveTo(x - r - 3, y);
          ctx.lineTo(x + r + 3, y);
          ctx.stroke();
          ctx.arc(x, y, r, 0, Math.PI * 2, false);
          ctx.fill();
        }
      });
      chart
        .line()
        .position("month*value")
        
        .color("name");
      chart
        .point()
        .position("month*value")
        .color("name")
        .style({
          lineWidth: 1,
          stroke: "#fff"
        });

      chart.render();
    }
  },
  methods: {},
  mounted() {
    // Step 1: 创建 Chart 对象
    const id = this.elementId;
    const chart = new F2.Chart({
      id,
      pixelRatio: window.devicePixelRatio // 指定分辨率
    });

    this.chartElement = chart;
  }
};
</script>

<style scoped lang="less">
.canvas {
  width: 100%;
  height: auto;
}

.chart-title {
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 500;
}
</style>
