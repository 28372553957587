var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "field" },
    [
      _vm.title
        ? _c("header", { staticClass: "field-header" }, [
            _c("div", { staticClass: "field-header-heading" }, [
              _c("div", { staticClass: "field-header-title" }, [
                _vm._v(_vm._s(_vm.title))
              ]),
              _c("p", { staticClass: "field-header-brief" }, [
                _vm._v(_vm._s(_vm.brief))
              ])
            ]),
            _c(
              "div",
              { staticClass: "field-header-aciton" },
              [_vm._t("action")],
              2
            )
          ])
        : _vm._e(),
      _vm._t("default"),
      _vm._t("footer")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }