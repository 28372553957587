var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.containerClass }, [
    _c("div", {}, [
      _c("div", { staticClass: "ant-spin", class: _vm.rootClass }, [
        _vm._m(0),
        !!_vm.tip
          ? _c("div", { staticClass: "ant-spin-text" }, [
              _vm._v(_vm._s(_vm.tip))
            ])
          : _vm._e()
      ])
    ]),
    _c("div", { class: _vm.spinningClass }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "ant-spin-dot ant-spin-dot-spin" }, [
      _c("i"),
      _c("i"),
      _c("i"),
      _c("i")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }