var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("tips-top", [_vm._v("目前仅支持以下银行")]),
      _c("div", { staticClass: "white-space" }),
      _c("div", { staticClass: "bank-card-area" }, [
        _c("img", { attrs: { src: _vm.supportBanks, alt: "支持银行" } })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }