var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c("img", {
      staticClass: "logo",
      attrs: { src: require("@/assets/images/logo.png"), alt: "" }
    }),
    _vm._m(0),
    _c("div", { staticClass: "flex border-bottom-1px field-item" }, [
      _c("div", [_vm._v("客服QQ-1")]),
      _c(
        "span",
        {
          staticClass: "tel-num",
          on: {
            click: function($event) {
              return _vm.handleCopy("3459581176")
            }
          }
        },
        [_vm._v("3459581176")]
      )
    ]),
    _c("div", { staticClass: "flex border-bottom-1px field-item" }, [
      _c("div", [_vm._v("客服QQ-2")]),
      _c(
        "span",
        {
          staticClass: "tel-num",
          on: {
            click: function($event) {
              return _vm.handleCopy("3473847431")
            }
          }
        },
        [_vm._v("3473847431")]
      )
    ]),
    _c(
      "div",
      { staticClass: "flex border-bottom-1px field-item" },
      [
        _c("div", [_vm._v("官方微信")]),
        _c("CopySpan", {
          staticClass: "tel-num",
          attrs: { text: "金豆子微服务" }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex border-bottom-1px field-item" }, [
      _c("div", [_vm._v("服务热线")]),
      _c("a", { staticClass: "tel-num", attrs: { href: "tel:020-38686775" } }, [
        _vm._v("020-38686775")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }