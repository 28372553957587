<template>
  <div class="container">
    <div class="card-title">统计数据</div>
    <div class="statistics-data-section">
      <div v-for="(item, index) in list" :key="index" class="statistics-data-item">
        <div class="statistics-count">{{item.count}}</div>
        <div class="statistics-desc">{{item.desc}}</div>
      </div>
    </div>

    <div class="card-title">数据明细</div>

    <LineChart title="销售额" elementId="ammount" :data="amounListChart"></LineChart>

    <LineChart title="客户数" elementId="account" :data="peopleListChart"></LineChart>
  </div>
</template>

<script type="text/ecmascript-6">
import { statistics } from "@/api/carFinance";

import LineChart from "./components/LineChart";

export default {
  name: "",
  components: {
    LineChart
  },
  data() {
    return {
      list: [
        { count: "", desc: "今日进件数", key: "todayInto" },
        { count: "", desc: "今日通过数", key: "todayPass" },
        { count: "", desc: "今日通过金额(元)", key: "todayAmount" },
        { count: "", desc: "累计进件数", key: "allInto" },
        { count: "", desc: "累计通过数", key: "allPass" },
        { count: "", desc: "累计通过金额(元)", key: "allPassAmount" }
      ],
      amounListChart: [],
      peopleListChart: []
    };
  },
  computed: {},
  methods: {
    init() {
      statistics()
        .then(res => {
          if (res.success) {
            const { amountList, peopleList, xlist } = res.data;
            this.list = this.list.map(item => {
              item.count = res.data[item.key];
              return item;
            });

            const amounListChart = [];
            const peopleListChart = [];
            xlist.map((item, index) => {
              amounListChart.push({
                month: item,
                name: "申请金额",
                value: amountList[index] && amountList[index].allAmount
              });
              amounListChart.push({
                month: item,
                name: "核批金额",
                value: amountList[index] && amountList[index].passAmount
              });
              peopleListChart.push({
                month: item,
                name: "申请人数",
                value: peopleList[index] && peopleList[index].allPeople
              });
              peopleListChart.push({
                month: item,
                name: "核批人数",
                value: peopleList[index] && peopleList[index].passPeople
              });
            });

            this.amounListChart = amounListChart;
            this.peopleListChart = peopleListChart;
          }
        })
        .catch(err => {
          console.log("err", err);
        });
    },
    format(val) {
      return val + "%";
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style scoped lang="less">
.container {
  padding: 10px 0 20px;
  text-align: left;

  .card-title {
    position: relative;
    line-height: 22px;
    padding-left: 10px;
    margin-top: 6px;
    margin-left: 10px;
    border-left: 3px solid cornflowerblue;
  }

  .statistics-data-section {
    padding: 10px 4px;
    display: flex;
    align-items: center;
    // justify-content: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;

    .statistics-data-item {
      padding: 10px;
      margin-bottom: 10px;
      border-radius: 3px;
      width: 30%;
      border: 1px solid #ccc;
      text-align: center;

      .statistics-count {
        margin-bottom: 15px;
        font-weight: 600;
        white-space: nowrap;
      }

      .statistics-desc {
        white-space: nowrap;
        font-size: 12px;
      }
    }
  }
}
</style>
