
<!--
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2022-06-17 15:16:01
 * @LastEditors: CZK
 * @LastEditTime: 2022-12-23 11:54:07
-->
<template>
  <div class="container" v-if="info">
    <div class="loan-main">
      <p class="_tip">产品信息可能已变更，请再次确认订单信息</p>
      
      <div class="product-info">
      <div class="product-title">订单信息</div>
        <div class="flex border-bottom-1px product-info-section">
          <div class="item-left">交易商户:</div>
          <div  class="item-right item-right-select">
            <div class="flex-1">{{goodsInfo.merchantName}}</div>
            <!-- <span :class="['iconfont', 'item-right-select-icon','icon-jiantou']"></span> -->
          </div>
        </div>
        <div class="flex border-bottom-1px product-info-section">
          <div class="item-left">课程名称:</div>
          <div  class="item-right item-right-select">
            <div class="flex-1">{{ otherInfo.goodsName }}</div>
            <!-- <span :class="['iconfont', 'item-right-select-icon','icon-jiantou']"></span> -->
          </div>
        </div>
         <div class="flex border-bottom-1px product-info-section">
          <div class="item-left">订单金额:</div>
          <div class="item-right">
            <div class="flex-1">{{ otherInfo.amount | yuan }}</div>
          </div>
        </div>
        <div class="flex border-bottom-1px product-info-section" >
          <div class="item-left">付款方式:</div>
          <div  class="item-right item-right-select">
            <div class="flex-1">{{ selectStage | stage }}</div>
            </div>
        </div>
       <div class="flex border-bottom-1px product-info-section">
          <div class="item-left">付款计划:</div>
          <div  class="item-right item-right-select">
            <div class="flex-1">{{ downPaymentAmount }}</div>
            
          </div>
        </div>
      </div>

      <div class="flex agreement-read-section">
        <cube-checkbox class="with-click" shape="square" :hollow-style="false" v-model="checked">
          <a href="javascript:void()"></a>
        </cube-checkbox>

        <div class="flex-1 agreement-name-section">
          本人已阅读并同意
          <a @click="toAgreementView('《豆乐派服务协议》', 'https://huodong.jdz360.cn/agreement/%E4%BF%A1%E7%94%A8%E8%B5%8A%E8%B4%AD%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE-%E6%A0%87%E5%87%86%E7%89%8820201012.html')" class="agreement-link">《豆乐派服务协议》</a>
          <a @click="toAgreementView('《应收账款转让同意书》', 'https://huodong.jdz360.cn/agreement/%E5%BA%94%E6%94%B6%E8%B4%A6%E6%AC%BE%E8%BD%AC%E8%AE%A9%E5%8D%8F%E8%AE%AE-%E6%95%99%E8%82%B2%E9%A1%B9%E7%9B%AE%E7%89%88%E6%9C%AC20201012.html')" class="agreement-link">《应收账款转让同意书》</a>
          <a @click="toAgreementView('《隐私保护及授权书》', 'https://huodong.jdz360.cn/agreement/%E9%9A%90%E7%A7%81%E4%BF%9D%E6%8A%A4%E5%8F%8A%E6%8E%88%E6%9D%83%E4%B9%A6%2020210129)(1).html')" class="agreement-link">《隐私保护及授权书》</a>
        </div>
      </div>

      <div class="submit-section">
        <cube-button
          @click="handleSubmit2"
          :class="{'submitting':submitting}"
          class="submit-btn"
          type="submit"
        >
          <cube-loading></cube-loading>确认并继续提交
        </cube-button>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import store from "@/store";
import { ButtonMixin } from "@comp/Button"
import filters from "@views/carFinance/mixins/filters"
import {
  queryGoods,
  queryUserReceipt,
  getRepaymentData,
  applyStage,
  creditAgain,
  applyPass,
  getPersonVerifyUrl,
  getFaceToken,
  fadadaAuthentication,
} from "@/api/application";
import { faceRedirectUrl } from "@/config/config";
import { nextProcess } from "./redirectAction";

//好一个定时器
var timer;

export default {
  name: "loanApplyP",
  mixins: [ButtonMixin, filters],
  directives: {
    
  },
  data() {
    return {
      isShowDetail: false,
      inviteCode: "",
      leagueProductList: [],    //返回的产品列表
      leagueProduct: null,    //当前选择的产品
      productNameVal: [],    //当前商品id数组
      productNameText: [],    //当前商品名称数组
      remark: "",    //商品备注
      amount: "",    //商品总价
      dpAmount: NaN,    //商品首付金額
      dpAmountTip: "",
      stageNumberList: [],    //订单期数数组
      stageActive: 0,    //当前订单期数数组脚标
      isVisibleRpMask: false,
      repaymentPlan: NaN,
      rDate: "",
      rAmount: 0,
      active:0,
      info:{},
      goodsInfo:null,
      repaymentPlanList:[],
      otherInfo:{},
      checked: true,
    };
  },
  filters: {
    yuan(value) {
      if (!value) return "";
      return `${value}元`;
    },
    stage(value) {
      if (!value) return "";
      // return `${value}期`;
      return `按月支付 ( ${value}期 ) `;
    },
  },
  computed: {
    selectStage() {
      let { active, stageNumberList } = this;
      return stageNumberList[active];
    },
    downPaymentAmount() {
      console.log(this.repaymentPlanList,'付款计划')
      const [downPaymentItem = {}] = this.repaymentPlanList;
      let text1 = '', text2 = '';
      if(downPaymentItem.repayDate) {
        text1 = downPaymentItem.repayDate + ' | ';
      }
      if(downPaymentItem.repaymentAmount) {
        text2 = downPaymentItem.repaymentAmount + '元';
      }
      return (`${text1}${text2}`)
      // return (
      //   (downPaymentItem.totalAmount && `${downPaymentItem.totalAmount}元`) ||
      //   ""
      // );
    },

  },
  watch: {
    '$route':{
      handler(v){
        console.log('监听')
        this.getInfo() // 获取订单信息

      },
      immediate:true
    }
  },
  methods: {
    saveLoan() {
      const { checked, selectStage } = this;
      applyStage({
        term: selectStage,
      })
        .then((res) => {
          if (res.success) {
            /**
             * SimpleContractNo("SimpleContractNo","单个手机情况"),
              NotYetAgree("NotYetAgree","对应订单还未开通协议"),
              NoThreeMonth("NoThreeMonth","上次申请还未超过3个月");
              ThreeMonthOver("ThreeMonthOver","该手机距上次申请超过3个月"),
              
             */
            const {
              data: { status, params },
              other: otherInfo,
            } = res;
            if (status === "SimpleContractNo" || status === "NotYetAgree") {
              nextProcess(otherInfo, { params });
            } else if (status === "NoThreeMonth") {
              this.dialog = this.$createDialog({
                type: "confirm",
                icon: "cubeic-alert",
                title: "是否确认提交申请",
                content: "确认则开放签约放款",
                confirmBtn: {
                  text: "确定",
                  active: true,
                  disabled: false,
                  href: "javascript:;",
                },
                cancelBtn: {
                  text: "取消",
                  active: false,
                  disabled: false,
                  href: "javascript:;",
                },
                onConfirm: () => {
                  nextProcess(otherInfo, { params });
                },
                onCancel: () => {},
              });
              this.dialog.show();
            } else if (status === "ThreeMonthOver") {
              // 做 认证入口
              if (otherInfo.faceType === "Fadada") {
                fadadaAuthentication({}).then((res) => {
                  if (res.success) {
                    window.location.href = res.data.verifyUrl;
                  }
                });
                return;
              } else if (otherInfo.faceType === "Faceplusplus") {
                //face ++ 认证
                getFaceToken({
                  status: otherInfo.status,
                })
                  .then((res) => {
                    if (res.success) {
                      const token = res.data;

                      window.location.href = `${faceRedirectUrl}${token}`;
                    }
                  })
                  .catch((err) => {
                    console.log("err", err);
                  });
              }
            }
          }
          setTimeout(() => {
            this.submitting = false;
          }, 300);
        })
        .catch((err) => {
          setTimeout(() => {
            this.submitting = false;
          }, 300);
        });
    },
    handleSubmit2() {
      const { checked } = this;
      if (!checked) {
        this.$createToast({
          type: "warn",
          txt: "请阅读并同意服务协议"
        }).show();
        return;
      }
      if(!this.submitting){
        this.submitting = true;
        //保存订单
        this.saveLoan();
      }else{
        this.$createToast({
          type: "warn",
          txt: "请勿频繁提交"
        }).show();
        return;
      }
     
    },
    toAgreementView(protocolName, url) {
      this.$router.push({
        name: "agreementView",
        query: {
          protocolName,
          url
        }
      });
    },
    getInfo(){
      queryGoods()
        .then((res) => {
          if (res.success) {
            const [goodsInfo] = res.data;
            const { other = {} } = res;
            const { stageNumberList } = goodsInfo;
            
            this.goodsInfo = goodsInfo;
            
            this.otherInfo = other || {};
            if (other.term && other.term !== 0) {
              this.active = stageNumberList.indexOf(other.term.toString());
            } else {
              this.active = stageNumberList.length - 1;
            }

            this.stageNumberList = stageNumberList;

            if (stageNumberList.length > 0) {
              this.getRepaymentData();
            }

            console.log(this.goodsInfo,'信息',this.otherInfo )
            // this.queryUserReceipt();
          } else {
            this.$$createToast({
              txt: res.msg || (res.data && res.data.msg),
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    // queryUserReceipt() {
    //   queryUserReceipt().then((res) => {
    //     if (res.success) {
    //       this.userReceiptNum = res.data;
    //     }
    //   });
    // },
    getRepaymentData() {
      const {
        // goodsInfo: { goodPrice: amount },
        otherInfo: { amount, aliasDictId },
        selectStage: term,
      } = this;

      const resetPlanData = () => {
        this.repaymentPlanList = [];
        this.rateTotal = "--";
        this.totalAmount = "--";
      };

      getRepaymentData({
        amount,
        term,
        aliasDictId,
      })
        .then((res) => {
          if (res.success) {
            const { loanPlanList, rateTotal, repaymentTotal } = res.data;

            this.repaymentPlanList = loanPlanList;
            this.totalAmount = repaymentTotal;
            this.rateTotal = rateTotal;
          } else {
            resetPlanData();
          }
        })
        .catch((err) => {
          console.log("err", err);
          resetPlanData();
        });
    },
  },
  mounted() {
    console.log('来')
  },
  activated() {
  }
};
</script>

<style scoped lang="less">
.container {
  background: #fff;
  ._tip{
    font-size: 14px;
    line-height: 60px;
    text-align: left;
    text-indent: 20px;
  }
  .bg {
    position: absolute;
    display: block;
    width: 100%;
    // height: 100%;
  }

  .invest-code {
    position: relative;
    height: auto;
    padding: 0 16px;

    .logo {
      width: 80px;
      height: 80px;
      margin: 44px auto 80px;
    }

    .fill-item {
      // margin: 0 40px;
      .fill-input {
        height: 40px;
        width: 200px;
        flex: 1;
        background: none;
        text-align: center;
        outline: none;
        border: 0px;
        color: #fff;
        &::-webkit-input-placeholder {
          color: #fff;
          // line-height: 1.2em;
          line-height: 19px;
          text-align: center;
        }
      }
    }

    .submit-section {
      margin-top: 40px;
    }

    .submit-btn {
      background-color: azure;
      color: #579AF1;
    }
  }

  .loan-main {
    position: relative;
    height: auto;
    
    .just-between {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .product-fun {
      width: auto;
      box-sizing: border-box;
      // color: #579AF1;
      color: #FFFFFF;
      min-width: 60px;
    }

    .loan-header {
      position: relative;
      min-height: 152px;
      height: auto;
      // background-color: #3271f3;
      // background-image: linear-gradient(225deg , #3271f3 , #4ac8ff);/* 30度位置开始渐变 */
      background-image: linear-gradient(90deg, #4bc7ff, #3372f6);
      // border: 1px solid #F2F2F2;
      // border-radius: 16px;
      box-sizing: border-box;
      padding: 16px;
      // margin: 0 16px 16px;
      display: flex;
      flex-direction: column;
      .product-name {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .product-title {
      line-height: 38px;
      color: #333;
      // text-indent: 16px;
      text-align: left;
      font-weight: 600;
      font-size: 14px;
      border-bottom: 1px solid #f3f3f3;
    }

    .fill-input-remark {
      height: 40px;
      width: 200px;
      flex: 1;
      background: none;
      text-align: right;
      outline: none;
      border: 0px;
      color: rgba(51, 51, 51, 1);
      &::-webkit-input-placeholder {
        color: #999;
        // line-height: 1.2em;
        line-height: 19px;
        text-align: right;
      }
    }

    .product-info {
      padding: 0 16px;
      background: #fff;
      font-weight: 500;
      font-size: 15px;
      .product-info-section {
        height: 50px;
        .item-left {
          color: rgba(102, 102, 102, 1);
        }
        .item-right {
          flex: 1;
          text-align: right;
          color: rgba(51, 51, 51, 1);
          &.item-right-select {
            display: flex;
            align-items: center;
            .item-right-select-icon {
              margin-right: -6px;
              margin-left: 4px;
              color: #cdcdd1;
            }
          }
          .item-right-desc {
            margin-top: 4px;
            font-weight: 400;
            font-size: 12px;
            font-family: PingFang-SC-Regular;
          }
        }
        .item-right-tip {
          flex: 1;
          text-align: right;
          color: rgba(99, 99, 99, 1);
        }
      }
    }

    .agreement-read-section {
      padding: 26px 16px 16px 0px;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular;
      font-weight: 400;
      color: rgba(102, 102, 102, 1);
      line-height: 1.2;
      .with-click {
        font-size: 16px;
        &.cube-checkbox {
          padding: 0 2px 0 16px;
        }
      }
      .agreement-name-section {
        text-align: left;
        .agreement-link {
          color: #579af1;
          &:active {
            opacity: 0.6;
          }
        }
      }
    }

    .submit-section {
      padding: 0 16px;
      margin: 30px 0 24px;
      font-size: 16px;
      .submit-btn {
        border-radius: 4px;
        font-weight: 400;
        position: relative;
      }
      .cube-loading {
        display: none;
        position: absolute;
        left: 34%;
        top: 50%;
        z-index: 9;
        transform: translate(-50%, -50%);
      }
      .submitting {
        pointer-events: none;
        border-color: transparent;
        cursor: not-allowed;
        opacity: 0.8;
        .cube-loading {
          display: block;
        }
      }
    }

  }
}

.cube-action-sheet-fade-enter,
.cube-action-sheet-fade-leave-active {
  opacity: 0;
}

.cube-action-sheet-fade-enter-active,
.cube-action-sheet-fade-leave-active {
  transition: all 0.3s ease-in-out;
}

.cube-action-sheet-move-enter,
.cube-action-sheet-move-leave-active {
  transform: translate3d(0, 100%, 0);
}

.cube-action-sheet-move-enter-active,
.cube-action-sheet-move-leave-active {
  transition: all 0.3s ease-in-out;
}

.cube-action-sheet-cancel span,
.cube-action-sheet-item {
  &:active {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.plan-content {
  background: #fff;
}

.cube-action-sheet-title {
  padding-top: 16px;
  padding-bottom: 16px;
  color: #666;
  font-size: 16px;
}

.cube-action-sheet-space {
  height: 1px;
  background-color: rgba(37, 38, 45, 0.4);
}

.scroll-list-wrap {
  height: 60vh;
}

.plan-list-section {
  padding: 18px 0 6px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(102, 102, 102, 1);

  .total-price {
  }

  .total-amount {
    margin: 10px 0 18px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    font-size: 30px;
  }

  .total-tate {
    font-size: 12px;
    margin-bottom: 20px;
  }

  .pan-tipes {
    margin: 8px 0 20px;
    font-size: 12px;
  }

  // .plan-item-title {
  //   background: #eef1f6;
  // }

  .plan-item {
    height: 40px;
    margin: 0 16px;
    line-height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.c-fff {
  color: #fff;
}

.c-f8f2f2 {
  color: #F8F2F2;
}



</style>
