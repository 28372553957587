<template>
  <div class="container">
    <tips-top>
      请添加本人名下的储蓄卡
      <template slot="extra">
        <div @click="supportBankPage" class="support-bank">支持银行</div>
      </template>
    </tips-top>

    <div class="fill-area">
      <div class="fill-item border-bottom-1px">
        <div class="fill-name">姓ㅤㅤ名</div>
        <input
          v-model="name"
          readonly
          class="fill-input no-modify-input"
          type="text"
          maxlength="8"
          placeholder="请输入姓名"
        />
      </div>

      <div class="fill-item border-bottom-1px">
        <div class="fill-name">预留手机</div>
        <input
          v-model.trim="mobile"
          class="fill-input"
          type="tel"
          maxlength="11"
          placeholder="请输入预留手机号"
        />
      </div>

      <div class="fill-item border-bottom-1px">
        <div class="fill-name">银行卡号</div>
        <input
          v-model="cardNo"
          class="fill-input"
          type="tel"
          maxlength="19"
          placeholder="请输入银行卡号"
        />
      </div>

      <div class="fill-item border-bottom-1px">
        <div class="fill-name">验证码</div>
        <div class="code-area flex">
          <input
            class="fill-input flex-1"
            v-model="smsCode"
            type="tel"
            placeholder="请输入验证码"
            maxlength="6"
          />

          <div
            class="code-btn"
            v-bind:class="{ disabled: state.smsSendBtn }"
            @click.stop.prevent="getCaptcha"
          >
            {{ state.smsSendBtn ? state.time + "s后重发" : "发送验证码" }}
          </div>
        </div>
      </div>
    </div>

    <div class="flex agreement-read-section">
      <cube-checkbox class="with-click" shape="square" :hollow-style="false" v-model="hasRead">
        <a href="javascript:void()"></a>
      </cube-checkbox>

      <div class="flex-1 agreement-name-section">
        本人已阅读并同意
        <a @click="showAgreementDialog" class="agreement-link">《支付开通及扣款授权确认书》</a>
      </div>
    </div>

    <c-alert :title="errorMessages"></c-alert>

    <div class="submit-section">
      <c-button
        @click="handleSubmit"
        :disabled="submitting"
        class="submit-btn"
        type="submit"
        >下一步</c-button
      >
    </div>

    <div hidden v-html="target"></div>
  </div>
</template>

<script type="text/ecmascript-6">
import TipsTop from "@comp/TipsTop/TipsTop";
import CAlert from "@comp/Alert";

import {
  getBindRecord,
  bindBankCard,
  // executeSynUserInfo,
  // 协议支付
  getCheckCodeByMobile,
  // openPayProtocol
} from "@/api/application";
import { Validator } from "@/utils/validate";

import { ButtonMixin } from "@comp/Button";

export const SALT = "salt";

import { nextProcess } from "./redirectAction";

const LIMITTIME = 60;

const COUNT_DOWN_SECONDS = 10;
const originConfig = {
  text: "同意并继续(9s)",
  active: true,
  disabled: true,
  href: "javascript:;",
};

const AGREEMENT_URL = "https://static.app.jdz360.cn/yibaoProtocol.html";
const REQUEST_NO = "smsAgreementPaymentId";
const START_TIME_CODE = "startTimeCode";

export default {
  name: "",
  mixins: [ButtonMixin],
  components: {
    TipsTop,
    CAlert,
  },
  data() {
    return {
      state: {
        time: LIMITTIME,
        smsSendBtn: false,
      },
      smsCode: "",

      confirmBtn: {
        ...originConfig,
      },
      hasRead: false,
      name: "",
      cardNo: "",
      mobile: "",
      target: "",
      errorMessages: "",
    };
  },
  methods: {
    showAgreementDialog() {
      let time = COUNT_DOWN_SECONDS,
        timer;
      this.$createDialog(
        {
          type: "confirm",
          icon: "cubeic-alert",

          confirmBtn: this.confirmBtn,
          cancelBtn: {
            text: "取消",
            active: false,
            disabled: false,
            href: "javascript:;",
          },
          onConfirm: () => {
            this.hasRead = true;
          },
          onCancel: () => {
            clearInterval(timer);
            this.confirmBtn = { ...originConfig };
          },
        },
        (createElement) => {
          return [
            createElement(
              "div",
              {
                class: {
                  "agreement-dialog-my-title": true,
                },
                slot: "title",
              },
              [createElement("p", "支付服务开通及授权扣款确认")]
            ),
            createElement(
              "div",
              {
                class: {
                  "agreement-dialog-my-content": true,
                },
                slot: "content",
              },
              [
                createElement("iframe", {
                  class: {
                    "agreement-dialog-my-content-iframe": true,
                  },
                  attrs: {
                    src: AGREEMENT_URL,
                  },
                }),
              ]
            ),
          ];
        }
      ).show();

      timer = setInterval(() => {
        time--;
        this.confirmBtn.text = `同意并继续(${time}s)`;

        if (time === 0) {
          clearInterval(timer);
          this.confirmBtn.text = "同意并继续";
          this.confirmBtn.disabled = false;
        }
      }, 1000);
    },

    regainCheckBtn() {
      this.state.time = LIMITTIME;
      this.state.smsSendBtn = false;
    },
    regainCheckBtn1() {
      this.state.time = LIMITTIME;
      this.state.smsSendBtn = true;
      
      const interval = window.setInterval(() => {
        if (this.state.time-- <= 0) {
          this.regainCheckBtn();
          window.clearInterval(interval);
        }
      }, 1000);
    },
    continueCountDown() {
      const nowTime = +new Date();
      const prevTime = this.$ls.get(START_TIME_CODE);
    
      if (!prevTime) {
        return;
      }

      const timeDifference = ((nowTime - prevTime) / 1000) | 0;
 
      if (timeDifference < LIMITTIME) {
        const { state } = this;

        state.time = LIMITTIME - timeDifference;
        state.smsSendBtn = true;

        const interval = window.setInterval(() => {
          if (state.time-- <= 0) {
            this.regainCheckBtn();
            window.clearInterval(interval);
          }
        }, 1000);
      }
    },

    getCaptcha() {
      const { state, mobile, cardNo, regainCheckBtn, regainCheckBtn1 } = this;

      // if (!this.hasRead) {
      //   this.showAgreementDialog();
      //   return;
      // }

      let validator = new Validator();

      validator.add(mobile, "mobileFormat", "请确认手机号");

      validator.add(
        cardNo,
        "isEmpty",
        "银行卡格式不对，请按正确格式填写银行卡号"
      );

      let errorMsg = validator.start();

      if (errorMsg) {
        this.$createToast({
          txt: errorMsg,
          type: "error",
        }).show();
        return;
      }

      // 倒计时中不可请求
      if(state.smsSendBtn) return
      
      setTimeout(() => {
        state.smsSendBtn = true;
        const interval = window.setInterval(() => {
          if (state.time-- <= 0) {
            regainCheckBtn();
            window.clearInterval(interval);
          }
        }, 1000);

        const toastSuccess = this.$createToast({
          txt: "验证码发送中",
          type: "correct",
          time: 0,
        });

        toastSuccess.show();

        getCheckCodeByMobile({ mobile, bankNo: cardNo })
          .then((res) => {
            toastSuccess.hide();

            if (res.success) {
              this.$createToast({
                txt: "验证码已发送，请留意",
                type: "correct",
              }).show();

              // 记录发送时间
              this.$ls.set(START_TIME_CODE, +new Date());
              this.$ls.set(REQUEST_NO, res.data.requestNo);
            } else {
              clearInterval(interval);

              this.showErrorMessage(res);
              regainCheckBtn1();
            }
          })
          .catch((err) => {
            console.log("err", err);
            toastSuccess.hide();
            clearInterval(interval);
            regainCheckBtn();
          });
      }, 0);
    },
    getBindRecord() {
      return getBindRecord({}).then((res) => {
        if (res.success) {
          const { data, other } = res;
          if (data) {
            const {
              user: { name, mobile },
              bank,
            } = data;
            this.name = name;
            this.mobile = mobile;

            bank && bank.cardNo && (this.cardNo = bank.cardNo);
          }
        }
      });
    },
    supportBankPage() {
      this.$router.push({
        path: "/application/supportBank",
      });
    },
    handleSubmit() {
      const { name, cardNo, mobile, smsCode } = this;

      if (!this.hasRead) {
        this.showAgreementDialog();
        return;
      }

      let validator = new Validator();

      validator.add(name, "isEmpty", "请确认持卡人");
      validator.add(
        cardNo,
        "isEmpty",
        "银行卡格式不对，请按正确格式填写银行卡号"
      );
      validator.add(mobile, "mobileFormat", "请确认手机信息");
      validator.add(smsCode, "isEmpty", "请填写验证码");

      let errorMsg = validator.start();

      if (errorMsg) {
        this.$createToast({
          txt: errorMsg,
          type: "error",
        }).show();
        return;
      }

      this.submitting = true;
      const requestNo = this.$ls.get(REQUEST_NO);

      bindBankCard({
        name,
        bankCardNo: cardNo,
        mobile,
        smsCode,
        requestNo,
      })
        .then((res) => {
          if (res.success) {
            const { other } = res;
            nextProcess(other);
          } else {
            this.showErrorMessage(res);
            this.regainStatus();
          }
        })
        .catch((err) => {
          console.log("err", err);
          this.regainStatus();
        });
    },
    showErrorMessage({ errCode, errMsg }) {
      if (errCode) {
        this.errorMessages = `错误码：${errCode}； 错误信息：${errMsg}`;
      } else {
        this.errorMessages = "";
      }
    },
  },
  mounted() {
    this.getBindRecord();
    this.continueCountDown();
    this.showAgreementDialog();
  },
};
</script>

<style scoped lang="less">
.container {
  background: #eef1f6;

  .support-bank {
    color: #579af1;
    padding: 10px 16px;
    &:active {
      opacity: 0.7;
    }
  }

  .fill-area {
    margin-top: 10px;
    background: #fff;

    .fill-item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      height: 50px;
      .fill-name {
        width: 120px;
        padding-left: 16px;
        text-align: left;
        //   font-weight: 500;
        color: rgba(51, 51, 51, 1);
        line-height: 28px;
      }
      .fill-input {
        height: 100%;
        flex: 1;
        background: none;
        outline: none;
        border: 0px;
        color: #666;
        &::-webkit-input-placeholder {
          color: #ccc;
        }
      }

      .no-modify-input {
        color: #999;
      }
    }
  }

  .code-area {
    height: 50px;
    flex: 1;

    .fill-input {
      width: 100px;
    }
    .code-btn {
      width: 90px;
      margin-left: 10px;
      margin-right: 10px;
      padding: 10px 0;
      background: #579af1;
      font-size: 14px;
      color: #fff;
      border-radius: 4px;
      &:active {
        background: #5079f7;
      }
    }
  }
  
  .agreement-read-section {
    padding: 16px 16px 16px 0px;
    font-size: 14px;
    font-family: SourceHanSansCN-Regular;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    line-height: 1.2;
    .with-click {
      font-size: 16px;
      &.cube-checkbox {
        padding: 0 2px 0 16px;
      }
    }
    .agreement-name-section {
      text-align: left;
      .agreement-link {
        color: #579af1;
        &:active {
          opacity: 0.6;
        }
      }
    }
  }

  .submit-section {
    margin-top: 20px;
    padding: 0 16px;
    .submit-btn {
      border-radius: 4px;
      font-weight: 400;
    }
  }
}
</style>


<style lang="less">
// dialog 样式
.agreement-dialog-my-title {
  font-size: 14px;
}

.agreement-dialog-my-content {
  height: 350px;
  .agreement-dialog-my-content-iframe {
    height: 100%;
    width: 100%;
  }
}
</style>
