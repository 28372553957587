import { RouteView } from "@/components/layouts";

const carFinanceRouter = {
  path: "/carFinance",
  name: "carFinance",
  redirect: "/carFinance/home",
  component: RouteView,
  meta: { title: "爱保汽车抵押业务平台" },
  children: [
    {
      path: "login",
      name: "carLogin",
      component: () => import("@/views/carFinance/Login"),
      meta: {
        // -爱保汽车抵押业务平台
        title: "用户登录",
      },
    },
    {
      path: "dev",
      name: "dev",
      component: () => import("@/views/carFinance/DevPage"),
      meta: {
        title: "开发",
      },
    },
    {
      path: "home",
      name: "carHome",
      component: () => import("@/views/carFinance/Home"),
      meta: {
        //爱保汽车抵押业务平台
        title: "首页",
        
      },
    },
    {
      path: "orderForm",
      name: "orderForm",
      component: () => import("@/views/carFinance/OrderForm"),
      meta: {
        title: "提交订单",
      },
    },
    {
      path: "supplementInfo",
      name: "supplementInfo",
      component: () => import("@/views/carFinance/SupplementInfo"),
      meta: {
        title: "上传补充资料",
      },
    },
    {
      path: "commitComplete",
      name: "commitComplete",
      component: () => import("@/views/carFinance/CommitComplete"),
      meta: {
        title: "提交完成",
      },
    },
    {
      path: "allOrders",
      name: "allOrders",
      component: () => import("@/views/carFinance/AllOrders"),
      meta: {
        title: "全部订单",
        keepAlive: true
      },
    },
    {
      path: "orderDetails/:id",
      name: "orderDetails",
      component: () => import("@/views/carFinance/OrderDetails"),
      meta: {
        title: "订单详情",
      },
    },
    {
      path: "statistics",
      name: "statistics",
      component: () => import("@/views/carFinance/Statistics"),
      meta: {
        title: "数据统计",
      },
    },
    {
      path: "personalInfo",
      name: "personalInfo",
      component: () => import("@/views/carFinance/PersonalInfo"),
      meta: {
        title: "个人信息",
      },
    },
    {
      path: "changePassword",
      name: "changePassword",
      component: () => import("@/views/carFinance/ChangePassword"),
      meta: {
        title: "修改密码",
      },
    },
    {
      path: "customerService",
      name: "carCustomerService",
      component: () => import("@views/carFinance/CustomerService"),
      meta: {
        title: "我的客服",
      },
    },
  ],
};

export default carFinanceRouter;
