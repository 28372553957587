var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.goodsInfo
    ? _c(
        "div",
        { staticClass: "container" },
        [
          _vm.userReceiptNum
            ? _c("tips-top", [
                _c(
                  "div",
                  {
                    staticClass: "flex flex-start mgr10 h30",
                    on: { click: _vm.goLoanHistory }
                  },
                  [
                    _c(
                      "span",
                      { staticStyle: { color: "rgba(87, 154, 241, 1)" } },
                      [
                        _vm._v(
                          "\n        你在当前商户有" +
                            _vm._s(_vm.userReceiptNum) +
                            "个已完成订单\n        "
                        ),
                        _c("span", [_vm._v("点击查看")]),
                        _c("span", {
                          staticClass: "iconfont icon-shuangjiantou fs14 mgr10"
                        })
                      ]
                    )
                  ]
                )
              ])
            : _vm._e(),
          _c("div", { staticClass: "product-title" }, [_vm._v("商品信息")]),
          _c("div", { staticClass: "product-info" }, [
            _c(
              "div",
              { staticClass: "flex border-bottom-1px product-info-section" },
              [
                _c("div", { staticClass: "item-left" }, [_vm._v("商户名称：")]),
                _c("div", { staticClass: "item-right" }, [
                  _vm._v(_vm._s(_vm.goodsInfo.merchantName))
                ])
              ]
            ),
            _c("div", { staticClass: "flex product-info-section" }, [
              _c("div", { staticClass: "item-left" }, [_vm._v("商品信息：")]),
              _c("div", { staticClass: "item-right" }, [
                _vm._v(_vm._s(_vm.otherInfo.goodsName))
              ])
            ])
          ]),
          _c("div", { staticClass: "product-title" }, [_vm._v("订单信息")]),
          _c("div", { staticClass: "product-info" }, [
            _c(
              "div",
              { staticClass: "flex border-bottom-1px product-info-section" },
              [
                _c("div", { staticClass: "item-left" }, [_vm._v("订单金额：")]),
                _c("div", { staticClass: "item-right" }, [
                  _vm._v(_vm._s(_vm._f("yuan")(_vm.otherInfo.amount)))
                ])
              ]
            ),
            _c(
              "div",
              {
                staticClass: "flex border-bottom-1px product-info-section",
                class: { active: _vm.canSelectStage }
              },
              [
                _c("div", { staticClass: "item-left" }, [_vm._v("付款方式：")]),
                _c(
                  "div",
                  {
                    staticClass: "item-right item-right-select",
                    on: { click: _vm.showActive }
                  },
                  [
                    _c("div", { staticClass: "flex-1" }, [
                      _vm._v(_vm._s(_vm._f("stage")(_vm.selectStage)))
                    ]),
                    _vm.canSelectStage
                      ? _c("span", {
                          class: [
                            "iconfont",
                            "item-right-select-icon",
                            "icon-jiantou"
                          ]
                        })
                      : _vm._e()
                  ]
                )
              ]
            ),
            _c("div", { staticClass: "flex product-info-section active" }, [
              _c("div", { staticClass: "item-left" }, [_vm._v("付款计划：")]),
              _c(
                "div",
                {
                  staticClass: "item-right item-right-select",
                  on: { click: _vm.viewRepaymentPlan }
                },
                [
                  _c("div", { staticClass: "flex-1" }, [
                    _c("div", [_vm._v(_vm._s(_vm.downPaymentAmount))])
                  ]),
                  _c("span", {
                    staticClass: "iconfont icon-jiantou item-right-select-icon"
                  })
                ]
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "flex agreement-read-section" },
            [
              _c(
                "cube-checkbox",
                {
                  staticClass: "with-click",
                  attrs: { shape: "square", "hollow-style": false },
                  model: {
                    value: _vm.checked,
                    callback: function($$v) {
                      _vm.checked = $$v
                    },
                    expression: "checked"
                  }
                },
                [_c("a", { attrs: { href: "javascript:void()" } })]
              ),
              _vm._m(0),
              _vm._m(1)
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "submit-section" },
            [
              _c(
                "cube-button",
                {
                  staticClass: "submit-btn",
                  class: { submitting: _vm.submitting },
                  attrs: { type: "submit" },
                  on: { click: _vm.handleSubmit }
                },
                [_c("cube-loading"), _vm._v("提交申请\n    ")],
                1
              )
            ],
            1
          ),
          _c("div", { staticClass: "guide-section" }, [
            _c("div", { staticClass: "guide-title" }, [
              _c("span", { on: { click: _vm.goGuidePage } }, [
                _vm._v("新手指引")
              ]),
              _c("span", { staticClass: "line-between" }, [_vm._v("|")]),
              _c("span", { on: { click: _vm.goAboutUsPage } }, [
                _vm._v("关于我们")
              ])
            ]),
            _vm._m(2)
          ]),
          _c(
            "transition",
            { attrs: { name: "cube-action-sheet-fade" } },
            [
              _c(
                "cube-popup",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isVisibleMask,
                      expression: "isVisibleMask"
                    }
                  ],
                  attrs: { type: "repayment-plan", center: false },
                  on: { "mask-click": _vm.hideRepaymentPlan }
                },
                [
                  _c(
                    "transition",
                    { attrs: { name: "cube-action-sheet-move" } },
                    [
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isVisibleMask,
                              expression: "isVisibleMask"
                            }
                          ],
                          staticClass: "plan-content",
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                            }
                          }
                        },
                        [
                          _c(
                            "h1",
                            {
                              staticClass:
                                "cube-action-sheet-title border-bottom-1px"
                            },
                            [_vm._v("付款计划")]
                          ),
                          _c(
                            "div",
                            { staticClass: "scroll-list-wrap" },
                            [
                              _c(
                                "cube-scroll",
                                { ref: "scroll", attrs: { data: [] } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "plan-list-section" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "total-price" },
                                        [_vm._v("应付总额(元)")]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "total-amount" },
                                        [_vm._v(_vm._s(_vm.totalAmount))]
                                      ),
                                      false
                                        ? _c(
                                            "div",
                                            { staticClass: "pan-tipes" },
                                            [
                                              _vm._v(
                                                "\n                  如需提前付款，需一次性偿付全部期数的息费\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        {},
                                        _vm._l(_vm.repaymentPlanList, function(
                                          item,
                                          index
                                        ) {
                                          return _c("div", { key: index }, [
                                            _c(
                                              "div",
                                              { staticClass: "plan-item" },
                                              [
                                                _c("div", [
                                                  _vm._v(_vm._s(item.repayDate))
                                                ]),
                                                _c("div", [
                                                  _vm._v(
                                                    "¥" +
                                                      _vm._s(
                                                        item.repaymentAmount
                                                      )
                                                  )
                                                ])
                                              ]
                                            )
                                          ])
                                        }),
                                        0
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "cube-action-sheet-space" }),
                          _c(
                            "div",
                            {
                              staticClass: "cube-action-sheet-cancel",
                              on: { click: _vm.hideRepaymentPlan }
                            },
                            [_c("span", [_vm._v("关闭")])]
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex-1 agreement-name-section" }, [
      _vm._v("\n      本人已阅读并同意\n      "),
      _c(
        "a",
        {
          staticClass: "agreement-link",
          attrs: {
            href:
              "https://huodong.jdz360.cn/agreement/1-豆乐派服务协议20230518.html"
          }
        },
        [_vm._v("《服务协议》")]
      ),
      _c(
        "a",
        {
          staticClass: "agreement-link",
          attrs: {
            href:
              "https://huodong.jdz360.cn/agreement/隐私保护及授权书 20210129)(1).html"
          }
        },
        [_vm._v("《隐私保护及信息授权书》")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "tips" }, [
      _vm._v("\n      温馨提示："),
      _c("br"),
      _vm._v("\n      提交申请后将进行实名认证环节，需上传"),
      _c("span", [_vm._v("有效期大于3个月")]),
      _vm._v("的身份证正反面照片\n    ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "guide-company" }, [
      _c("div", [_vm._v("广东金豆子数字科技有限公司")]),
      _c("div", [_vm._v("Copyright © 2019 All Rights Reserved.")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }