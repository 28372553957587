/*
 * @Autor: CPB
 * @Date: 2020-11-25 10:25:13
 * @LastEditors: CPB
 * @LastEditTime: 2020-11-25 10:29:33
 * @Description:
 */
import Upload from "./upload";
import UploadBtn from "./btn";
import UploadFile from "./file";

export { Upload, UploadBtn, UploadFile };
