import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

import applicationRouter from "./modules/application";
import repaymentRouter from "./modules/repayment";
import certifiedPaymentInfoRouter from './modules/certifiedPaymentInfo';
import carFinanceRouter from './modules/carFinance';



const NotFoundPage = resolve => {
  import("@views/NotFoundPage").then(module => {
    resolve(module);
  });
};

const TokenExpirePage = resolve => {
  import("@views/TokenExpirePage").then(module => {
    resolve(module);
  });
};

import Blank from "@views/application/BlankPage";

export default new Router({
  mode: "history",
  base: process.env.VUE_APP_BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/repayment"
    },
    applicationRouter,
    repaymentRouter,
    certifiedPaymentInfoRouter,
    carFinanceRouter,
    {
      path: "/expire",
      name: "expire",
      component: TokenExpirePage,
      meta: {
        title: "登录失效"
      }
    },
    {
      path: "/404",
      name: "404",
      component: NotFoundPage,
      meta: {
        title: "页面不存在"
      }
    },
    {
      path: "/blank",
      name: "blank",
      meta: { title: "空白页" },
      component: Blank
    },
    { path: "*", redirect: "/404" }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});
