<template>
  <div class="container all-order-container">
    <!-- <input type="date" name="" id=""> -->
    <div class="search-section">
      <md-date-picker
        v-model="isDatePickerShow"
        ref="datePicker"
        today-text="今天"
        type="custom"
        :custom-types="['yyyy', 'MM']"
        :min-date="minDate"
        :max-date="maxDate"
        :default-date="currentDate"
        :is-view="false"
        @initialed="onDatePickerInitialed"
        @confirm="onDatePickerConfirm"
      ></md-date-picker>

      <div class="pd10">
        <md-input-item
          ref="input4"
          v-model="search"
          clearable
          type="search"
          placeholder="请输入客户姓名或手机号查询"
          align="right"
        >
          <span @click="handleDateSearch" class="flex search-date-block" slot="left">
            <span>{{searchDate || '搜索日期'}}</span>
            <md-icon name="calendar" size="lg"></md-icon>
          </span>
          <md-icon @click="handleSearch" name="search" size="lg" class="pdl6 mgl10" slot="right"></md-icon>
        </md-input-item>
      </div>

      <div class="order-status-list">
        <div
          v-for="(item ,index) in orderStatusList"
          :key="index"
          class="order-status-item active"
          :class="index === selectedIndex ? 'selected' : ''"
          @click="handleSelectOrderStatus(index)"
        >
          <div class="order-item-count">{{item.count}}</div>
          <div class="order-item-title">{{item.title}}</div>
        </div>
      </div>
    </div>
    <div class="list-section">
      <md-scroll-view
        ref="scrollView"
        :scrolling-x="false"
        @refreshing="$_onRefresh"
        @end-reached="$_onEndReached"
      >
        <md-scroll-view-refresh
          slot="refresh"
          slot-scope="{ scrollTop, isRefreshActive, isRefreshing }"
          :scroll-top="scrollTop"
          :is-refreshing="isRefreshing"
          :is-refresh-active="isRefreshActive"
        ></md-scroll-view-refresh>

        <md-cell-item
          @click="handleViewOrderDetail(item)"
          v-for="(item,index) in list"
          :key="index"
          :title="item.userName"
          :brief="item.createDate"
          arrow
          class="hover pdl16 pdr16"
        >
          <span class="holder" slot="left">{{item.userName | firstNameFilter}}</span>
          <span class="tr" slot="right">
            <div class="order-amount">{{item.loanAmount}}元</div>
            <div class="order-status">
              <md-tag
                v-if="filterTagShow(item)"
                size="large"
                shape="fillet"
                type="fill"
                fill-color="rgba(255, 91, 96, .1)"
                font-weight="normal"
                font-color="#FF5B60"
              >{{item.orderStatusCn}}</md-tag>
              <span v-else>{{item.orderStatusCn}}</span>
            </div>
          </span>
        </md-cell-item>
        <md-result-page
          v-if="list.length === 0 && hasLoaded"
          :img-url="require('@/assets/images/empty.png')"
        ></md-result-page>
        <div
          v-else-if="!hasLoaded"
          class="tc"
          style="position:absolute;left:0;top:0;z-index:10;width:100%;padding-top:10vh;"
        >
          <c-spin stip="Loading..." size="large"></c-spin>
        </div>

        <md-scroll-view-more slot="more" :is-finished="isFinished"></md-scroll-view-more>
      </md-scroll-view>
    </div>
  </div>
</template>

<script>
import {
  DatePicker,
  ScrollView,
  ScrollViewRefresh,
  ScrollViewMore,
  CellItem,
  Field,
  InputItem,
  Icon,
  Tag,
  ResultPage,
} from "mand-mobile";

import { allOrders } from "@/api/carFinance";
import filters from "./mixins/filters";

export default {
  name: "allOrders",
  mixins: [filters],
  components: {
    [DatePicker.name]: DatePicker,
    [ScrollView.name]: ScrollView,
    [ScrollViewRefresh.name]: ScrollViewRefresh,
    [ScrollViewMore.name]: ScrollViewMore,
    [Field.name]: Field,
    [CellItem.name]: CellItem,
    [InputItem.name]: InputItem,
    [Icon.name]: Icon,
    [Tag.name]: Tag,
    [ResultPage.name]: ResultPage,
  },
  data() {
    return {
      minDate: new Date("2018/1/1"),
      maxDate: new Date(),
      currentDate: new Date(),
      search: "",
      searchDate: "",
      isDatePickerShow: false,
      params: {
        pageNum: 1,
        pageSize: 10,
      },

      orderStatusList: [
        { title: "全部订单", count: "...", key: "allOrderNum" },
        { title: "待审核", count: "...", key: "toAuditNum" },
        { title: "待放款", count: "...", key: "toPayOutNum" },
        { title: "已拒绝", count: "...", key: "audit1FailNum" },
      ],
      selectedIndex: 0,
      list: [],
      hasLoaded: false,
      isFinished: false,
    };
  },
  methods: {
    handleDateSearch() {
      this.isDatePickerShow = true;
    },
    onDatePickerInitialed() {},
    onDatePickerConfirm() {
      this.searchDate = this.$refs.datePicker.getFormatDate("yyyy-MM");
      this.handleSearch();
    },
    handleSearch() {
      const { searchDate, search } = this;
      return this.handleSelectOrderStatus(0, { date: searchDate, search });
    },
    handleSelectOrderStatus(statusType, searchObj) {
      const params = {
        statusType,
        pageNum: 1,
      };
      // 处理搜素框
      if (searchObj) {
        Object.assign(params, searchObj);
      } else {
        this.search = "";
        this.searchDate = "";
      }
      this.selectedIndex = statusType;
      this.hasLoaded = false;

      this.params = { pageSize: 10, ...params };

      this.getOrderList(true);
    },

    getOrderList(chekStatus = false) {
      return allOrders({
        ...this.params,
      })
        .then((res) => {
          if (res.success) {
            // eslint-disable-next-line no-unused-vars
            const { userdata, rows, page, pageSize } = res;

            this.orderStatusList = this.orderStatusList.map((item) => {
              item.count = userdata[item.key];
              return item;
            });

            if (chekStatus) {
              this.list = rows;
            } else {
              this.list = [...this.list, ...rows];
            }
            // TODO 重新计算高度
            this.$nextTick(() => {
              this.$refs.scrollView.reflowScroller();
            });

            this.hasLoaded = true;
            return rows;
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    filterTagShow(item) {
      return (
        item.orderStatus === "CommitApply" || item.orderStatus === "AuditFail"
      );
    },
    handleViewOrderDetail({ id, orderStatus }) {
      if (orderStatus === "CommitApply") {
        return this.$router.push({
          name: "supplementInfo",
          query: {
            orderCarId: id,
          },
        });
      }
      this.$router.push({
        name: "orderDetails",
        params: {
          id,
        },
      });
    },

    $_onRefresh() {
      this.params.pageNum = 1;
      this.getOrderList(true).then(() => {
        setTimeout(() => {
          this.$refs.scrollView.finishRefresh();
        }, 500);
      });
    },
    $_onEndReached() {
      // if (this.isFinished) {
      //   return;
      // }

      this.params.pageNum = this.params.pageNum + 1;
      // eslint-disable-next-line no-unused-vars
      this.getOrderList(false).then((rows) => {
        setTimeout(() => {
          // if (Array.isArray(rows) && rows.length === 0) {
          //   this.isFinished = true;
          // }

          this.isFinished = true;
          this.$refs.scrollView.finishLoadMore();
        }, 300);
      });
    },
  },

  mounted() {
    window.ScrollViewTrigger1 = () => {
      console.log("ScrollViewTrigger1", "ScrollViewTrigger1");
      this.$refs.scrollView.triggerRefresh();
    };
    console.log("mounted", "mounted");

    this.getOrderList(true);
  },

  activated() {},
  beforeRouteEnter(to, from, next) {
    if (from.name === "carHome") {
      // FixMe 首次由首页进入回和mounted 触发共两次 ，待解决
      next((vm) => {
        vm.params = { ...vm.params, pageNum: 1 };
        vm.getOrderList(true);
      });
    } else {
      next();
    }
  },
};
</script>

<style lang="less">
.all-order-container {
  text-align: left;

  .search-date-block {
    > span {
      width: 70px;
      text-align: center;
      font-size: 14px;
    }
  }

  .search-section {
    height: 160px;
    background: rgb(249, 250, 251);

    .order-status-list {
      padding-top: 20px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .order-status-item {
        border-radius: 4px;
        border: 1px #ccc solid;
        padding: 10px;
        width: 22%;
        white-space: nowrap;
        text-align: center;
        box-shadow: 0 3px 10px rgba(197, 202, 213, 0.25);

        .order-item-count {
        }

        .order-item-title {
          margin-top: 6px;
          font-size: 14px;
        }

        &.selected {
          border-color: red;
          color: red;
        }
      }
    }
  }

  .list-section {
    height: calc(100vh - 160px);
    // padding: 0 20px;
    border: none;
    background-color: #fff;

    .holder {
      display: block;
      width: 44px;
      height: 44px;
      text-align: center;
      line-height: 44px;
      font-size: 20px;
      font-weight: 500;
      color: #fff;
      border-radius: 22px;
      background-color: #2f86f6;
    }

    .order-status {
      margin-top: 8px;
    }

    .continue-order {
      font-weight: 500;
      color: red;
    }
  }
}
</style>

