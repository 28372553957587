var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "card-title" }, [_vm._v("统计数据")]),
      _c(
        "div",
        { staticClass: "statistics-data-section" },
        _vm._l(_vm.list, function(item, index) {
          return _c(
            "div",
            { key: index, staticClass: "statistics-data-item" },
            [
              _c("div", { staticClass: "statistics-count" }, [
                _vm._v(_vm._s(item.count))
              ]),
              _c("div", { staticClass: "statistics-desc" }, [
                _vm._v(_vm._s(item.desc))
              ])
            ]
          )
        }),
        0
      ),
      _c("div", { staticClass: "card-title" }, [_vm._v("数据明细")]),
      _c("LineChart", {
        attrs: {
          title: "销售额",
          elementId: "ammount",
          data: _vm.amounListChart
        }
      }),
      _c("LineChart", {
        attrs: {
          title: "客户数",
          elementId: "account",
          data: _vm.peopleListChart
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }