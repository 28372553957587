var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("md-selector", {
        attrs: {
          data: _vm.CooperationDealerList,
          "default-value": _vm.selectDefalutValue,
          title: "选择车商",
          "is-check": "",
          iconSize: "lg",
          "max-height": "520px"
        },
        on: { choose: _vm.onSelectorChoose },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var option = ref.option
              var index = ref.index
              var selected = ref.selected
              return [
                _c(
                  "div",
                  {
                    staticClass: "selector-item-body",
                    class: { disabled: option.disabled, selected: selected }
                  },
                  [
                    _c("div", { staticClass: "selector-item-left" }, [
                      _c("span", { staticClass: "holder" }, [
                        _vm._v(_vm._s(_vm._f("firstNameFilter")(option.text)))
                      ])
                    ]),
                    _c("div", { staticClass: "selector-item-content" }, [
                      _c("p", {
                        staticClass: "selector-item-title",
                        domProps: { textContent: _vm._s(option.text) }
                      }),
                      _c("p", {
                        staticClass: "selector-item-brief",
                        domProps: {
                          textContent: _vm._s("编号：" + option.value)
                        }
                      })
                    ])
                  ]
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.isSelectorShow,
          callback: function($$v) {
            _vm.isSelectorShow = $$v
          },
          expression: "isSelectorShow"
        }
      }),
      _c(
        "ExtendPopup",
        { ref: "CreateCooperationDealer", attrs: { title: "添加车商" } },
        [
          _c(
            "md-field",
            [
              _c("md-input-item", {
                attrs: {
                  title: "合作车商",
                  placeholder: "请输入合作车商名称",
                  clearable: ""
                }
              }),
              _c("md-input-item", {
                attrs: {
                  title: "ㅤ联系人",
                  placeholder: "请输入联系人姓名",
                  clearable: ""
                }
              }),
              _c("md-input-item", {
                attrs: {
                  title: "手机号码",
                  placeholder: "请输入联系人手机号码",
                  clearable: ""
                }
              }),
              _c(
                "div",
                { staticClass: "action-section" },
                [
                  _c(
                    "md-button",
                    {
                      attrs: {
                        type: "primary",
                        inline: "",
                        plain: "",
                        size: "small"
                      },
                      on: { click: _vm.handleCloseCreateCooperationDealer }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "md-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "right",
                        inline: "",
                        size: "small"
                      }
                    },
                    [_vm._v("确认")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("md-selector", {
        attrs: {
          data: _vm.bankDealerList,
          "default-value": _vm.selectBankDefalutValue,
          title: "选择合作银行",
          "is-check": "",
          iconSize: "lg",
          "max-height": "520px"
        },
        on: { choose: _vm.onBankSelectorChoose },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var option = ref.option
              var index = ref.index
              var selected = ref.selected
              return [
                _c(
                  "div",
                  {
                    staticClass: "selector-item-body",
                    class: { disabled: option.disabled, selected: selected }
                  },
                  [
                    _c("div", { staticClass: "selector-item-left" }, [
                      _c("span", { staticClass: "holder" }, [
                        _vm._v(_vm._s(_vm._f("firstNameFilter")(option.text)))
                      ])
                    ]),
                    _c("div", { staticClass: "selector-item-content" }, [
                      _c("p", {
                        staticClass: "selector-item-title",
                        domProps: { textContent: _vm._s(option.text) }
                      }),
                      _c("p", {
                        staticClass: "selector-item-brief",
                        domProps: {
                          textContent: _vm._s("编号：" + option.value)
                        }
                      })
                    ])
                  ]
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.isSelectorShowBankDealers,
          callback: function($$v) {
            _vm.isSelectorShowBankDealers = $$v
          },
          expression: "isSelectorShowBankDealers"
        }
      }),
      _c(
        "div",
        { ref: "orderFormView", staticClass: "order-form-container" },
        [
          _c(
            "md-field",
            [
              _vm.internal
                ? _c("md-field-item", {
                    attrs: {
                      title: "合作银行",
                      content: _vm.bankDealerName || "请选择",
                      arrow: ""
                    },
                    on: { click: _vm.showBankSelector }
                  })
                : _vm._e(),
              _c("md-field-item", {
                attrs: {
                  title: "合作车商",
                  content: _vm.carDealerName || "请选择",
                  arrow: ""
                },
                on: { click: _vm.showSelector }
              }),
              _c(
                "md-input-item",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  attrs: {
                    title: "银行订单",
                    solid: false,
                    type: "text",
                    align: "right",
                    clearable: "",
                    "data-vv-as": "银行订单号后六位",
                    name: "contractNo",
                    error: _vm.errors.first("contractNo"),
                    placeholder: "请输入银行订单号后六位"
                  },
                  model: {
                    value: _vm.form.contractNo,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.form,
                        "contractNo",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.contractNo"
                  }
                },
                [
                  _c("span", { attrs: { slot: "right" }, slot: "right" }, [
                    _vm._v("ㅤ")
                  ])
                ]
              ),
              _c(
                "md-input-item",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  attrs: {
                    title: "贷款总额",
                    type: "money",
                    align: "right",
                    "data-vv-as": "贷款总金额",
                    name: "loanTotal",
                    error: _vm.errors.first("loanTotal"),
                    "is-virtual-keyboard": "",
                    placeholder: "请输入贷款总金额"
                  },
                  model: {
                    value: _vm.form.loanTotal,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.form,
                        "loanTotal",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.loanTotal"
                  }
                },
                [
                  _c("span", { attrs: { slot: "right" }, slot: "right" }, [
                    _vm._v("元")
                  ])
                ]
              ),
              _c(
                "md-input-item",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  attrs: {
                    title: "垫资金额",
                    type: "money",
                    align: "right",
                    maxlength: "13",
                    "data-vv-as": "垫资金额(车款金额)",
                    name: "loanAmount",
                    error: _vm.errors.first("loanAmount"),
                    "is-virtual-keyboard": "",
                    placeholder: "请输入垫资金额(车款金额)"
                  },
                  model: {
                    value: _vm.form.loanAmount,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.form,
                        "loanAmount",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.loanAmount"
                  }
                },
                [
                  _c("span", { attrs: { slot: "right" }, slot: "right" }, [
                    _vm._v("元")
                  ])
                ]
              ),
              _c(
                "md-input-item",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|percentage|validateDecimal4",
                      expression: "'required|percentage|validateDecimal4'"
                    }
                  ],
                  attrs: {
                    title: "客户利率",
                    align: "right",
                    "data-vv-as": "客户合同约定利率",
                    name: "realInterest",
                    error: _vm.errors.first("realInterest"),
                    "is-virtual-keyboard": "",
                    placeholder: "请输入客户合同约定利率"
                  },
                  model: {
                    value: _vm.form.realInterest,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.form,
                        "realInterest",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.realInterest"
                  }
                },
                [
                  _c("span", { attrs: { slot: "right" }, slot: "right" }, [
                    _vm._v("%")
                  ])
                ]
              ),
              _c(
                "md-input-item",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|percentage|validateDecimal4",
                      expression: "'required|percentage|validateDecimal4'"
                    }
                  ],
                  attrs: {
                    title: "返佣利率",
                    align: "right",
                    "data-vv-as": "下游返佣利率",
                    name: "downCommissionPriceRate",
                    error: _vm.errors.first("downCommissionPriceRate"),
                    "is-virtual-keyboard": "",
                    placeholder: "请输入下游返佣利率"
                  },
                  model: {
                    value: _vm.form.downCommissionPriceRate,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.form,
                        "downCommissionPriceRate",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.downCommissionPriceRate"
                  }
                },
                [
                  _c("span", { attrs: { slot: "right" }, slot: "right" }, [
                    _vm._v("%")
                  ])
                ]
              ),
              _c(
                "md-input-item",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  attrs: {
                    title: "车架号",
                    align: "right",
                    "data-vv-as": "车辆车架号",
                    name: "carFrameNo",
                    error: _vm.errors.first("carFrameNo"),
                    placeholder: "请输入车辆车架号"
                  },
                  model: {
                    value: _vm.form.carFrameNo,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.form,
                        "carFrameNo",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.carFrameNo"
                  }
                },
                [
                  _c("span", { attrs: { slot: "right" }, slot: "right" }, [
                    _vm._v("ㅤ")
                  ])
                ]
              ),
              _c("div", { staticClass: "mgt20" }, [_vm._v("客户信息")]),
              _c("div", { attrs: { id: "upload-section" } }, [
                _c(
                  "div",
                  [
                    _c(
                      "cube-upload",
                      {
                        ref: "uploadface",
                        staticClass: "upload-box",
                        attrs: {
                          action: _vm.action,
                          "process-file": _vm.processFile
                        },
                        on: {
                          "files-added": _vm.addedHandlerFace,
                          "file-error": _vm.errHandler
                        },
                        model: {
                          value: _vm.idcardface,
                          callback: function($$v) {
                            _vm.idcardface = $$v
                          },
                          expression: "idcardface"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "clear-fix" },
                          [
                            _vm._l(_vm.idcardface, function(file, i) {
                              return _c("cube-upload-file", {
                                key: i,
                                staticClass: "upload-file-idcard",
                                attrs: { file: file }
                              })
                            }),
                            _c(
                              "cube-upload-btn",
                              {
                                staticClass: "upload-btn-idcard idcard-face",
                                attrs: { multiple: false }
                              },
                              [_c("div", [_c("i", [_vm._v("＋")])])]
                            )
                          ],
                          2
                        )
                      ]
                    ),
                    _c("div", { staticClass: "upload-tips" }, [
                      _vm._v("\n            点击识别正面\n            "),
                      _c("span", [_vm._v("人像面")])
                    ])
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "cube-upload",
                      {
                        ref: "uploadback",
                        staticClass: "upload-box",
                        attrs: {
                          action: _vm.action,
                          "process-file": _vm.processFile
                        },
                        on: {
                          "files-added": _vm.addedHandlerBack,
                          "file-error": _vm.errHandler
                        },
                        model: {
                          value: _vm.idcardback,
                          callback: function($$v) {
                            _vm.idcardback = $$v
                          },
                          expression: "idcardback"
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "clear-fix" },
                          [
                            _vm._l(_vm.idcardback, function(file, i) {
                              return _c("cube-upload-file", {
                                key: i,
                                staticClass: "upload-file-idcard",
                                attrs: { file: file }
                              })
                            }),
                            _c(
                              "cube-upload-btn",
                              {
                                staticClass: "upload-btn-idcard idcard-back",
                                attrs: { multiple: false }
                              },
                              [_c("div", [_c("i", [_vm._v("＋")])])]
                            )
                          ],
                          2
                        )
                      ]
                    ),
                    _c("div", { staticClass: "upload-tips" }, [
                      _vm._v("\n            点击识别背面\n            "),
                      _c("span", [_vm._v("国徽面")])
                    ])
                  ],
                  1
                )
              ]),
              _c(
                "md-input-item",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  attrs: {
                    title: "客户姓名",
                    solid: false,
                    type: "text",
                    align: "right",
                    clearable: "",
                    "data-vv-as": "客户姓名",
                    name: "userName",
                    error: _vm.errors.first("userName"),
                    placeholder: "请输入客户姓名"
                  },
                  model: {
                    value: _vm.form.userName,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.form,
                        "userName",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.userName"
                  }
                },
                [
                  _c("span", { attrs: { slot: "right" }, slot: "right" }, [
                    _vm._v("ㅤ")
                  ])
                ]
              ),
              _c(
                "md-input-item",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  attrs: {
                    title: "身份证号",
                    solid: false,
                    type: "text",
                    maxlength: "18",
                    align: "right",
                    clearable: "",
                    "data-vv-as": "身份证号",
                    name: "idNo",
                    error: _vm.errors.first("idNo"),
                    "is-virtual-keyboard": "",
                    "virtual-keyboard-vm": "idCardKeyboard",
                    placeholder: "请输入身份证号"
                  },
                  on: {
                    focus: _vm.handleIDCardFocus,
                    blur: _vm.handleIDCardBlur
                  },
                  model: {
                    value: _vm.form.idNo,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.form,
                        "idNo",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.idNo"
                  }
                },
                [
                  _c("span", { attrs: { slot: "right" }, slot: "right" }, [
                    _vm._v("ㅤ")
                  ])
                ]
              ),
              _c("md-number-keyboard", {
                ref: "idCardKeyboard",
                attrs: { "text-render": _vm.keyFormatter }
              }),
              _c(
                "md-input-item",
                {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|phone",
                      expression: "'required|phone'"
                    }
                  ],
                  attrs: {
                    title: "ㅤ手机号",
                    solid: false,
                    type: "phone",
                    align: "right",
                    maxlength: "11",
                    clearable: "",
                    name: "mobile",
                    "data-vv-as": "手机号",
                    error: _vm.errors.first("mobile"),
                    placeholder: "请输入客户手机号",
                    disabled: _vm.continueOrder
                  },
                  model: {
                    value: _vm.form.mobile,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.form,
                        "mobile",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "form.mobile"
                  }
                },
                [
                  _c("span", { attrs: { slot: "right" }, slot: "right" }, [
                    _vm._v("ㅤ")
                  ])
                ]
              ),
              _c(
                "md-button",
                {
                  staticClass: "mgt50",
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handleSubmit }
                },
                [_vm._v("下一步")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }