var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container agreement-payment-container" }, [
    _c("div", { staticClass: "title" }, [_vm._v("请输入验证码")]),
    _c(
      "div",
      {
        staticClass: "tips mgt10",
        class: { "hide-line": !_vm.state.smsSendBtn }
      },
      [_vm._v("短信验证码已发送至 " + _vm._s(_vm.phone || "银行预留手机"))]
    ),
    _c("div", { staticClass: "code-area flex border-bottom-1px" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.smsCode,
            expression: "smsCode"
          }
        ],
        staticClass: "code-input flex-1",
        attrs: { type: "tel", placeholder: "请输入验证码", maxlength: "6" },
        domProps: { value: _vm.smsCode },
        on: {
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.smsCode = $event.target.value
          }
        }
      }),
      _c(
        "div",
        {
          staticClass: "code-btn",
          class: { disabled: _vm.state.smsSendBtn },
          on: {
            click: function($event) {
              $event.stopPropagation()
              $event.preventDefault()
              return _vm.getCaptcha($event)
            }
          }
        },
        [
          _vm._v(
            _vm._s(
              _vm.state.smsSendBtn ? _vm.state.time + "s后重发" : "发送验证码"
            )
          )
        ]
      )
    ]),
    _c(
      "div",
      { staticClass: "submit-section" },
      [
        _c(
          "c-button",
          {
            staticClass: "submit-btn",
            attrs: { disabled: _vm.submitting },
            on: { click: _vm.handleSubmit }
          },
          [_vm._v("确认开通")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }