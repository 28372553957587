<template>
  <div class="container">
    <div v-if="loanList.length !== 0 && loanListLoaded" class="bill-list">
      <cube-scroll
        ref="scroll"
        @pulling-down="onPullingDown"
        @pulling-up="onPullingUp"
        :options="options"
      >
        <div
          v-for="(item,index) in loanList"
          :key="index"
          class="history-bill-item border-bottom-1px"
          @click="viewLoanDetail(item.receiptId)"
        >
          <div class="flex-1 flex">
            <div class="flex-1 bill-item-info">
              <div class="bill-item-name">{{item.productName}}</div>
              <div class="bill-item-time">{{item.createTime}}</div>
            </div>
            <div class="bill-item-right">
              <div class="bill-item-amount">{{item.loanAmount}}元</div>
              <div
                class="bill-item-status"
                :style="item.biddingStatus ==  3 ?'color:F47636': ''"
              >{{item.orderStatus}}</div>
            </div>
            <span class="iconfont icon-jiantou item-right-select-icon"></span>
          </div>
        </div>
      </cube-scroll>
    </div>
    <div v-else-if="loanList.length === 0 && loanListLoaded">
      <img class="empty-list" src="@/assets/images/empty.png" alt />
      <div class="c-666 mgt10">暂无记录</div>
    </div>
    <c-spin v-else style="margin-top:40vh;" tip="Loading..." size="large"></c-spin>
  </div>
</template>

<script type="text/ecmascript-6">
import { queryPreLoanApplyPagred } from "@/api/repayment";
import { queryUserPreLoanApplyPagred } from "@/api/application";

export default {
  name: "",
  props: {
    process: {
      type: String,
      default: "repayment"
    }
  },
  data() {
    return {
      options: {
        pullDownRefresh: {
          threshold: 60,
          stop: 40,
          txt: "更新成功"
        },
        pullUpLoad: {
          threshold: 0,
          visible: true,
          txt: { more: "", noMore: "已加载完毕" }
        }
      },
      pageInfo: {
        rows: 30,
        page: 1
      },
      loanListLoaded: false,
      loanList: []
    };
  },
  computed: {
    applicationProcess() {
      return this.process === "application";
    }
  },
  components: {},
  methods: {
    viewLoanDetail(receiptId) {
      if (this.applicationProcess) {
        this.$router.push({
          name: "applicationLoanDetails",
          query: {
            receiptId
          }
        });
      } else {
        this.$router.push({
          name: "loanDetails",
          query: {
            receiptId
          }
        });
      }
    },
    getLoanApplyList(init) {
      init ? (this.pageInfo.page = 1) : this.pageInfo.page++;
      
      return new Promise((resolve, reject) => {
        const handleCallback = res => {
          if (res.success) {
            const loanList = res.rows;

            init
              ? (this.loanList = loanList)
              : (this.loanList = this.loanList.concat(loanList));

            this.loanListLoaded = true;

            this.$refs.scroll && this.$refs.scroll.forceUpdate(true);

            resolve(res);
          } else {
            reject();
          }
        };

        if (this.applicationProcess) {
          queryUserPreLoanApplyPagred(this.pageInfo).then(handleCallback);
        } else {
          queryPreLoanApplyPagred(this.pageInfo).then(handleCallback);
        }
      });
    },
    onPullingDown() {
      this.getLoanApplyList(true);
    },
    onPullingUp() {
      console.log("onPullingUp", 2222222);
      this.getLoanApplyList(false);
    }
  },
  created() {
    this.getLoanApplyList(true);
  }
};
</script>

<style scoped lang="less">
.container {
  .bill-list {
    padding: 0 16px;
    height: 100vh;
    overflow-y: scroll;

    .history-bill-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 74px;
      font-size: 12px;
      color: #666;
    }

    .bill-item-info {
      margin-left: 4px;
      text-align: left;
    }

    .bill-item-name {
      font-size: 16px;

      color: rgba(51, 51, 51, 1);
    }

    .bill-item-time {
      margin-top: 12px;
      font-size: 14px;
      color: #666;
    }

    .bill-item-right {
      //line-height: 74px;
      min-width: 60px;
      padding-right: 10px;
      text-align: right;
      font-size: 15px;
      // background: cornflowerblue;

      div {
        //display: inline-block;

        &.bill-item-amount {
        }

        &.bill-item-status {
          margin-top: 10px;
          color: #666;
        }
      }
    }

    .item-right-select-icon {
      font-size: 12px;
      margin-right: -4px;
      color: #cdcdd1;
    }
  }

  .empty-list {
    width: 248px;
    height: 184px;
    margin-top: 50px;
  }
}
</style>
