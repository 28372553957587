var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("md-notice-bar", [_vm._v("请上传以下照片，多张图片可一次性上传")]),
      _c(
        "div",
        { staticClass: "upload-section" },
        _vm._l(_vm.uploadList, function(item, key) {
          return _c(
            "div",
            { key: key, staticClass: "upload-box" },
            [
              _c("div", { staticClass: "upload-title" }, [
                _vm._v(_vm._s(item.title))
              ]),
              _c("UploadBox", {
                ref: "upload1",
                refInFor: true,
                attrs: {
                  files: item.files,
                  multiple: item.multiple,
                  editType: _vm.editType
                },
                on: {
                  "update:files": function($event) {
                    return _vm.$set(item, "files", $event)
                  }
                }
              })
            ],
            1
          )
        }),
        0
      ),
      _c("md-notice-bar", [
        _vm._v("拍照提醒：注意光线明亮，照片清晰，显示完整")
      ]),
      _vm.editType
        ? _c(
            "div",
            { staticClass: "pd20" },
            [
              _c(
                "md-button",
                {
                  staticClass: "mgt30",
                  attrs: { type: "primary", loading: _vm.committing },
                  on: { click: _vm.handleSubmit }
                },
                [_vm._v("下一步")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }