<template>
  <div class="container">
    <div v-if="bankList.length === 0 && hasLoaded" class="clear-fix">
      <img class="bank-card-none" src="@/assets/images/banklogo/bank-card-none.png" alt />
      <div class="bank-card-none-desc">暂无添加银行卡</div>
    </div>

    <div v-else-if="bankList.length !== 0 && hasLoaded" class="clear-fix bank-card-list">
      <div
        v-for=" (item , index ) in bankList"
        :key="index"
        class="bank-card-item"
        :style="`background-image:url(` + require(`@/assets/images/banklogo/${item.banklogo}背景.png`) + `)`"
      >
        <div v-if="item.banklogo" class="bank-card-item-logo">
          <img :src="require(`@/assets/images/banklogo/${item.banklogo}图标.png`)" alt />
        </div>
        <div class="flex-1 tl">
          <div class="bank-card-item-name">{{item.bankName}}</div>
          <div class="bank-card-item-user">{{item.name | nameFilter}}</div>
          <div class="bank-card-item-number">**** **** **** {{item.cardNo | bankNoFilter}}</div>
        </div>
      </div>
    </div>
    <c-spin style="margin-top:40vh;" tip="Loading..." size="large" v-else></c-spin>
  </div>
</template>

<script type="text/ecmascript-6">
import { querybankCard } from "@/api/repayment";
import { bankNameList } from "./bankNameList";

export default {
  name: "",
  components: {},
  data() {
    return {
      bankList: [],
      hasLoaded: false
    };
  },
  computed: {},
  methods: {},
  filters: {
    nameFilter(el) {
      let charArr = el.split("");

      let result = charArr.reduce((arr, next, index) => {
        index === 0 ? arr.push(next) : arr.push(" *");
        return arr;
      }, []);

      return result.join("");
    },

    bankNoFilter(el) {
      if (!el) {
        return "";
      }
      return el.slice(-4);
    }
  },
  mounted() {
    querybankCard().then(res => {
      if (res.success) {
        const bankList = res.data;

        bankList.forEach(item => {
          item.banklogo = bankNameList[item.bankNo];
        });

        this.bankList = bankList;
        this.hasLoaded = true;
        
      }
    }).catch(err=>{
      console.log(`err`, err)
    });
  }
};
</script>

<style scoped lang="less">
.container {
  .bank-card-list {
    .bank-card-item {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 343px;
      height: 110px;
      padding: 16px 20px 0;
      margin: 10px auto 0;
      //background: url(../../assets/images/banklogo/工商银行背景.png) no-repeat;
      background-repeat: no-repeat;
      background-size: cover;
      color: #fff;

      .bank-card-item-logo {
        width: 44px;
        height: 44px;
        margin-right: 10px;

        img {
          width: 44px;
          height: auto;
          line-height: 44px;
        }
      }

      .bank-card-item-name {
        margin-bottom: 10px;
        font-size: 17px;
      }

      .bank-card-item-user {
        font-size: 14px;
      }

      .bank-card-item-number {
        margin-top: 24px;
        text-align: left;
      }
    }
  }

  .bank-card-none {
    width: 273px;
    height: 203px;
    margin-top: 66px;
  }

  .bank-card-none-desc {
    margin-top: 33px;
    color: #999;
  }
}
</style>
