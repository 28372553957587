var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm.bankList.length === 0 && _vm.hasLoaded
        ? _c("div", { staticClass: "clear-fix" }, [
            _c("img", {
              staticClass: "bank-card-none",
              attrs: {
                src: require("@/assets/images/banklogo/bank-card-none.png"),
                alt: ""
              }
            }),
            _c("div", { staticClass: "bank-card-none-desc" }, [
              _vm._v("暂无添加银行卡")
            ])
          ])
        : _vm.bankList.length !== 0 && _vm.hasLoaded
        ? _c(
            "div",
            { staticClass: "clear-fix bank-card-list" },
            _vm._l(_vm.bankList, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "bank-card-item",
                  style:
                    "background-image:url(" +
                    require("@/assets/images/banklogo/" +
                      item.banklogo +
                      "背景.png") +
                    ")"
                },
                [
                  item.banklogo
                    ? _c("div", { staticClass: "bank-card-item-logo" }, [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/banklogo/" +
                              item.banklogo +
                              "图标.png"),
                            alt: ""
                          }
                        })
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "flex-1 tl" }, [
                    _c("div", { staticClass: "bank-card-item-name" }, [
                      _vm._v(_vm._s(item.bankName))
                    ]),
                    _c("div", { staticClass: "bank-card-item-user" }, [
                      _vm._v(_vm._s(_vm._f("nameFilter")(item.name)))
                    ]),
                    _c("div", { staticClass: "bank-card-item-number" }, [
                      _vm._v(
                        "**** **** **** " +
                          _vm._s(_vm._f("bankNoFilter")(item.cardNo))
                      )
                    ])
                  ])
                ]
              )
            }),
            0
          )
        : _c("c-spin", {
            staticStyle: { "margin-top": "40vh" },
            attrs: { tip: "Loading...", size: "large" }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }