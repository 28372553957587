import { axios } from "@/utils/request";

/**
 * 查询产品
 * @param {*}
 */

export function queryGoods() {
  return axios({
    url: "/user/queryGoods.do",
    method: "get",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
}

// ====== 申请流程获取历史记录 =====
/**
 * 查询列表
 * @param {*} query 
 */
export function queryUserPreLoanApplyPagred(query) {
  return axios({
    url: "/user/queryUserPreLoanApplyPagred.do",
    method: "get",
    params: query,
  });
}

/**
 * 详情
 * @param {*} data 
 */
export function queryUserReceiptInfo(data) {
  return axios({
    url: "/user/queryUserReceiptInfo.do",
    method: "post",
    data,
  });
}


/**
 * 查询首页完成订单数量
 * @param {*} query 
 */
export function queryUserReceipt(query) {
  return axios({
    url: "/user/queryUserReceipt.do",
    method: "get",
    params: query,
  });
}
//  ====== End =======

export function getRepaymentData(query) {
  return axios({
    url: "/user/getRepaymentData.do",
    method: "get",
    params: query,
  });
}

export function applyStage(data = {}) {
  return axios({
    url: "/user/applyStage.do",
    method: "post",
    data,
  });
}

// 法大大认证
export function fadadaAuthentication(data = {}) {
  return axios({
    url: "/user/fadadaAuthentication.do",
    method: "post",
    data,
  });
}

// 点击申请 跳转法大大
export function getPersonVerifyUrl(data = {}) {
  return axios({
    url: "/fadada/getPersonVerifyUrl.do",
    method: "post",
    data,
  });
}

// 法大大授权
export function fadadaStayAuthAutoSign(data = {}) {
  return axios({
    url: "/user/fadadaStayAuthAutoSign.do",
    method: "post",
    data,
  });
}

// 法大大授权结果
export function fadadaAuthAutoSignStatus(data = {}) {
  return axios({
    url: "/user/fadadaAuthAutoSignStatus.do",
    method: "post",
    data,
  });
}


/**
 * 跳转
 * @param {*} data
 */
export function fadadaRzStatus(data = {}) {
  return axios({
    url: "/user/fadadaRzStatus.do",
    method: "post",
    data,
  });
}

export function creditAgain(data = {}) {
  return axios({
    url: "/credit/creditAgain.do",
    method: "post",
    data,
  });
}

export function applyPass(data = {}) {
  return axios({
    url: "/credit/applyPass.do",
    method: "post",
    data,
  });
}

/**
 * 上传身份证
 * @param {*} data
 */

export function ocrIdCheck(data = {}) {
  return axios({
    url: "/face/ocrIdCheck.do",
    method: "post",
    data,
  });
}

/**
 * 跳转face ++ 活体验证
 * @param {*} data
 */
export function getFaceToken(data = {}) {
  return axios({
    url: "/face/getToken.do",
    method: "post",
    data,
  });
}

/**
 *  上传校验结果
 * @param {*} biz_id
 */
export function obtainOciResult(data = {}) {
  return axios({
    url: "/face/obtainOciResult.do",
    method: "post",
    data,
  });
}

/**
 * 获取省市区
 * @param {*} query
 */

export function getAreaCode(query) {
  return axios({
    url: "/common/getAreaCode.do",
    method: "get",
    params: query,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });
}

/**
 * 获取填写数据记录
 * @param {*} query
 */
export function getUserBorrow(query) {
  return axios({
    url: "/user/getUserBorrow.do",
    method: "get",
    params: query,
  });
}

/**
 * 提交填写数据
 * @param {*} data
 */

export function handlerUserInfo(data = {}) {
  return axios({
    url: "/user/handlerUserInfo.do",
    method: "post",
    data,
  });
}

/**
 * 获取填写记录
 * @param {*} params
 */

export function getBindRecord(params = {}) {
  return axios({
    url: "/bank/getBindRecord.do",
    method: "get",
    params,
  });
}

/**
 * 上传三要素
 */

export function bindBankCard(data = {}) {
  return axios({
    url: "/bank/bindBankCard.do",
    method: "post",
    data,
  });
}

/**
 * 绑卡第二个接口
 * @param {*} data
 */

export function executeSynUserInfo(data = {}) {
  return axios({
    url: "/bank/executeSynUserInfo.do",
    method: "post",
    data,
  });
}

/**
 * 发送协议支付验证码
 * @param {Object} phone
 */

export function getCheckCodeByMobile(data = {}) {
  return axios({
    url: "/bank/queryContractConvergenceApply.do",
    method: "post",
    data,
  });
}

/**
 *  开通协议支付
 * @param {*} data  String smsCode, String smsId
 */
export function openPayProtocol(data = {}) {
  return axios({
    url: "/bank/querySigningApply.do",
    method: "post",
    data,
  });
}

/**
 * 自动上标
 * @param {*} data
 */
export function autoBid(data = {}) {
  return axios({
    url: "/bank/insertRepayment.do",
    method: "post",
    data,
  });
}


/**
 * 查询附产品
 * @param {*} data
 */

 export function findAttachProducts(data = {}) {
  return axios({
    url: "/user/findAttachProducts.do",
    method: "post",
    data,
  });
}

