var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container" }, [
      _c("h2", [_vm._v("登录超时，请重新扫码进入")]),
      _c("div", [
        _c("img", {
          staticClass: "tips-img",
          attrs: { src: require("@/assets/images/expire.gif"), alt: "" }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }