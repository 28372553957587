<template>
  <div class="alert-block">
    <div v-show="visible" class="alert-contnet">
      <span class="alert-title">{{title}}</span>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
const COMPONENT_NAME = "CAlert";

export default {
  name: COMPONENT_NAME,
  components: {},
  props: {
    title: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      default: "error"
    }
  },
  data() {
    return {};
  },
  computed: {
    visible() {
      return !!this.title;
    }
  },
  methods: {}
};
</script>

<style scoped lang="less">
.alert-block {
  // height: 30px;
  // line-height: 30px;
  min-height: 52px;
  padding: 8px;
  font-size: 12px;
  line-height: 16px;

  .alert-contnet {
    text-align: left;
    font-size: 12px;
    padding:2px 0 2px 20px;
    background: #fef0f0;
  }

  .alert-title {
    color: #f56c6c;
  }
}
</style>
