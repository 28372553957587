<template>
  <button class="cube-btn" :type="type" :class="btnClass" @click="handleClick">
    <i :class="icon" v-if="icon"></i>
    <div v-if="disabled" class="c-loading">
      <span class="c-loading-spinners">
        <i class="c-loading-spinner" v-for="item in balde"></i>
      </span>
    </div>
    <slot></slot>
  </button>
</template>

<script>
const COMPONENT_NAME = "c-button";
export default {
  name: COMPONENT_NAME,
  components: {},
  props: {
    icon: {
      type: String,
      default: ""
    },
    active: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    inline: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    },
    light: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: "button"
    }
  },
  data() {
    return {
      balde: 12
    };
  },
  computed: {
    btnClass() {
      return {
        "cube-btn_active": this.active,
        "cube-btn_disabled": this.disabled,
        "cube-btn-inline": this.inline,
        "cube-btn-primary": this.primary,
        "cube-btn-outline": this.outline,
        "cube-btn-outline-primary": this.outline && this.primary,
        "cube-btn-light": this.light
      };
    }
  },
  methods: {
    handleClick(event) {
      if (this.disabled) {
        event.preventDefault();
        event.stopPropagation();
        return;
      }
      this.$emit("click", event);
    }
  }
};
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
@require '~cube-ui/src/common/stylus/variable.styl';
@require '~cube-ui/src/common/stylus/mixin.styl';

btn-active($bg, $border = null) {
  &.cube-btn_active, &:active {
    background: $bg;

    if ($border != null) {
      border-1px($border);
    }
  }
}

.cube-btn {
  position: relative;
  display: block;
  margin: 0;
  padding: 17px 16px;
  width: 100%;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  font-size: $fontsize-large;
  line-height: 1;
  color: $btn-color;
  background: $btn-bgc;
  outline: none;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  btn-active($btn-active-bgc);

  > i {
    display: inline-block;
    margin-right: 4px;
    font-size: 100%;
    transform: scale(1.13);
    transform-origin: right center;
  }
}

.cube-btn-inline {
  width: auto;
  display: inline-block;
  vertical-align: middle;
  padding: 9px 10px;
  font-size: $fontsize-small;

  > i {
    margin-right: 2px;
    transform: scale(1.14);
  }
}

.cube-btn-primary {
  color: $btn-primary-color;
  background: $btn-primary-bgc;
  btn-active($btn-primary-active-bgc);
}

.cube-btn-light {
  color: $btn-light-color;
  background: $btn-light-bgc;
  box-shadow: $box-shadow-content;
  btn-active($btn-light-active-bgc);
}

.cube-btn-outline {
  color: $btn-outline-color;
  background: $btn-outline-bgc;
  border-1px($btn-outline-bdc);
  btn-active($btn-outline-active-bgc, $btn-outline-active-bdc);
}

.cube-btn-outline-primary {
  color: $btn-outline-primary-color;
  background: $btn-outline-primary-bgc;
  border-1px($btn-outline-primary-bdc);
  btn-active($btn-outline-primary-active-bgc, $btn-outline-primary-active-bdc);
}

.cube-btn_disabled {
//   color: $btn-disabled-color;
//   background: $btn-disabled-bgc;
//   border-1px($btn-disabled-bdc);
//   btn-active($btn-disabled-bgc, $btn-disabled-bdc);
  pointer-events: none;
  border-color: transparent;
  cursor: not-allowed;
  opacity: 0.8;
}

.c-loading {
  // font-size: $fontsize-large-xxx
  position: absolute;
  left: 34%;
  top: 50%;
  z-index: 9;
  transform: translate(-50%, -50%);
}

.c-loading-spinners {
  position: relative;
  display: block;
  width: 24px;
  height: 24px;
}

.c-loading-spinner {
  position: absolute;
  left: 44.5%;
  top: 37%;
  width: 2PX; // for rem
  height: 25%;
  border-radius: 50% / 20%;
  opacity: 0.25;
  background-color: currentColor;
  animation: spinner-fade 1s linear infinite;

  for num in (1 .. 12) {
    &:nth-child({num}) {
      animation-delay: ((num - 1) / 12)s;
      transform: rotate(30deg * (num - 6)) translateY(-150%);
    }
  }
}

@keyframes spinner-fade {
  0% {
    opacity: 0.85;
  }

  50% {
    opacity: 0.25;
  }

  100% {
    opacity: 0.25;
  }
}
</style>
