<!--
 * @Descripttion: 法大大授权结果页
 * @version: 1.0
 * @Author: CZK
 * @Date: 2021-12-02 11:01:11
 * @LastEditors: CZK
 * @LastEditTime: 2021-12-06 16:59:57
-->

<template>
  <div class="container">
    <!-- 授权中 -->
    <div v-if="showResultType === 'bidHandle'">
      <div class="result-title">请不要关闭该页面!</div>
      <div class="result-tips">正在处理中，请稍等...</div>
      <img class="resutl-icon mgt40" src="@assets/images/spin.gif" />
    </div>

    <!-- 授权失败 -->
    <div v-if="showResultType === 'faaFail'">
      <img class="resutl-icon mgt20" src="@assets/images/error.png" />
      <div class="result-title">授权失败</div>
      <div class="result-tips">系统处理中遇到点问题，请联系客服或稍后重试</div>
    </div>

    <!-- 授权成功 -->
    <div v-if="showResultType === 'faaSuccess'">
      <img class="resutl-icon mgt20" src="@assets/images/success.png" />
      <div class="result-title">授权成功</div>
      <div class="result-tips">您的授权已成功，即将进入下个流程</div>
    </div>

  </div>
</template>

<script type="text/ecmascript-6">
import { obtainOciResult, autoBid,fadadaAuthAutoSignStatus } from "@/api/application";
import { nextProcess } from "./redirectAction";
import { stringify } from "qs";

export default {
  name: "",
  data() {
    return {
      showResultType: "bidHandle",
      returnOrderUrl: "",
    };
  },
  components: {},
  watch: {},
  methods: {
    
  },
  mounted() {
    console.log("result页面=== router", this.$route);
    fadadaAuthAutoSignStatus({}).then(res=>{
      console.log(res,"法大大授权结果页")
      if(res.success){
        const { other, data } = res;
        if(other.status===14) this.showResultType='faaSuccess'
        else if(other.status===15) this.showResultType='faaFail'
        nextProcess(other, data && data.dto);
      }
    })
  },
  
  created() {},
  destroyed() {}
};
</script>

<style scoped lang="less">   
.container {
  padding: 90px 16px 0;
  text-align: center;

  .resutl-icon {
    //   display: block;
    width: 64px;
    height: 64px;
  }

  .resutl-gif {
    width: 272px;
    height: 213px;
  }

  .result-title {
    margin: 20px 0 16px;
    font-size: 20px;
    // line-height: 28px;
  }

  .result-tips {
    font-size: 14px;
    line-height: 1.3;
    color: #666;
  }

  .handle-btn {
    border-radius: 4px;
    margin-top: 92px;
  }

  .gonghzhonghao-poster {
    display: block;
    width: 375px;
    // height: 100px;
    margin-top: 60px;
    margin-left: -16px;
  }
}
</style>
