var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm.showResultType === "bidHandle"
      ? _c("div", [
          _c("div", { staticClass: "result-title" }, [
            _vm._v("请不要关闭该页面!")
          ]),
          _c("div", { staticClass: "result-tips" }, [
            _vm._v("正在处理中，请稍等...")
          ]),
          _c("img", {
            staticClass: "resutl-icon mgt40",
            attrs: { src: require("@assets/images/spin.gif") }
          })
        ])
      : _vm._e(),
    _vm.showResultType === "faaFail"
      ? _c("div", [
          _c("img", {
            staticClass: "resutl-icon mgt20",
            attrs: { src: require("@assets/images/error.png") }
          }),
          _c("div", { staticClass: "result-title" }, [_vm._v("授权失败")]),
          _c("div", { staticClass: "result-tips" }, [
            _vm._v("系统处理中遇到点问题，请联系客服或稍后重试")
          ])
        ])
      : _vm._e(),
    _vm.showResultType === "faaSuccess"
      ? _c("div", [
          _c("img", {
            staticClass: "resutl-icon mgt20",
            attrs: { src: require("@assets/images/success.png") }
          }),
          _c("div", { staticClass: "result-title" }, [_vm._v("授权成功")]),
          _c("div", { staticClass: "result-tips" }, [
            _vm._v("您的授权已成功，即将进入下个流程")
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }