/*
 * @Autor: CPB
 * @Date: 2019-05-20 17:54:33
 * @LastEditors: CPB
 * @LastEditTime: 2021-01-26 17:00:24
 * @Description: 
 */
import Vue from "vue";

import VueLazyload from "vue-lazyload";

Vue.use(VueLazyload, {
  loading: require("@/assets/logo.png"),
  preLoad: 0.1,
});

// 动态修改标题
import VueWechatTitle from "vue-wechat-title";
Vue.use(VueWechatTitle);

import VueStorage from "vue-ls";
Vue.use(VueStorage, {
  namespace: "pro__", // key prefix
  name: "ls", // name variable Vue.[ls] or this.[$ls],
  storage: "local", // storage name session, local, memory
});

// 自定义组件
import { CButton } from "@/components/Button";
import { CSpin } from "@/components/Spin";
import { CField, CFieldItem, CInputItem, CWhiteSpace } from "@/components/Field";

Vue.use(CButton);
Vue.use(CSpin);
Vue.use(CField);
Vue.use(CFieldItem);
Vue.use(CInputItem);
Vue.use(CWhiteSpace);

// 验证组件
import VeeValidate, { Validator as VeeValidator } from "vee-validate";
Vue.use(VeeValidate, {
  // events: 'blur'
});
import zh_CN from "vee-validate/dist/locale/zh_CN";
VeeValidator.localize("zh_CN", zh_CN);

// cube-ui 组件
import {
  /* eslint-disable no-unused-vars */
  Style,
  Popup,
  Toast,
  Button,
  Dialog,
  TabBar,
  Input,
  Slide,
  Scroll,
  Switch,
  Checkbox,
  Form,
  Validator,
  Picker,
  CascadePicker,
  ImagePreview,
  Upload,
  ActionSheet,
  createAPI,
} from "cube-ui";
// import { Button } from '@comp/Button/Button'
Vue.use(Button);
Vue.use(Dialog);
Vue.use(TabBar);
Vue.use(Input);
Vue.use(Slide);
Vue.use(Scroll);
Vue.use(Switch);
Vue.use(Checkbox);
Vue.use(Form);
Vue.use(Validator);
Vue.use(Picker);
Vue.use(Upload);
Vue.use(ActionSheet);
Vue.use(Popup);

createAPI(Vue, Dialog, ["confirm", "cancel"], true);
createAPI(Vue, Toast, ["click", "timeout"], true);
createAPI(Vue, CascadePicker, ["select"], true);
createAPI(Vue, ImagePreview);

import MyDialog from "../views/repayment/components/MyDialog.vue";

createAPI(Vue, MyDialog, ["confirm", "cancel"], true);
