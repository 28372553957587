var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      [
        _c("img", {
          staticClass: "resutl-icon mgt20",
          attrs: { src: require("@assets/images/error.png") }
        }),
        _c("div", { staticClass: "result-title" }, [_vm._v(_vm._s(_vm.title))]),
        _vm._l(_vm.message, function(item, index) {
          return _c("div", { key: index, staticClass: "result-tips" }, [
            _vm._v(_vm._s(item))
          ])
        }),
        _vm.returnOrderUrl
          ? _c(
              "cube-button",
              { staticClass: "handle-btn", on: { click: _vm.checkOrder } },
              [_vm._v("查看订单")]
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }