<template>
  <div class="container">
    <img src="@/assets/images/logo.png" class="logo" alt />

    <div>
      <div class="fill-item border-bottom-1px">
        <div class="fill-name">手机号</div>
        <input
          v-fixedInput
          v-model="mobile"
          class="fill-input"
          maxlength="11"
          placeholder="请输入手机号码"
          type="text"
        />
      </div>

      <div class="fill-item border-bottom-1px">
        <div class="fill-name">验证码</div>
        <input
          v-fixedInput
          v-model="tattedCode"
          class="fill-input"
          maxlength="6"
          placeholder="请输入短信验证码"
          type="text"
        />
        <div
          class="get-code"
          :class="{'disabled':state.smsSendBtn}"
          @click.stop.prevent="getCaptcha"
        >{{ state.smsSendBtn ? state.time + 's后重发' : '发送验证码'}}</div>
      </div>
    </div>

    <div class="submit-section">
      <c-button @click="handleSubmit" :disabled="submitting" class="submit-btn" type="submit">登录</c-button>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { certLogin, verificationCode } from "@/api/user";
import { Validator } from "@/utils/validate.js";
import { setCertToken } from "@/utils/auth";
import { ButtonMixin } from "@comp/Button";

import fixedInput from "@/directive/fixedInput";

const LIMITTIME = 60;

export default {
  name: "",
  mixins: [ButtonMixin],
  components: {},
  directives: {
    fixedInput
  },
  data() {
    return {
      state: {
        time: LIMITTIME,
        smsSendBtn: false
      },
      mobile: "",
      // 调用短信验证码返回
      tattedId: "",
      tattedCode: ""
    };
  },
  computed: {},
  methods: {
    // 恢复按钮
    regainCheckBtn(state) {
      state.time = LIMITTIME;
      state.smsSendBtn = false;
    },
    getCaptcha() {
      const { state, mobile } = this;

      setTimeout(() => {
        if (mobile.length > 0) {
          state.smsSendBtn = true;

          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              this.regainCheckBtn(state);
              window.clearInterval(interval);
            }
          }, 1000);

          const toastSuccess = this.$createToast({
            txt: "验证码发送中",
            type: "correct",
            time: 0
          });
          toastSuccess.show();

          verificationCode({ mobile })
            .then(res => {
              toastSuccess.hide();
              if (
                res &&
                res.success &&
                res.tattedId
              ) {
                this.$createToast({
                  txt: "验证码已发送，请留意",
                  type: "correct"
                }).show();
                // 储存tattedId
                const tattedId = res.tattedId;
                this.tattedId = tattedId;
                // 记录信息
                this.$ls.set("certTattedId", tattedId);
                this.$ls.set("certMobile", mobile);
                this.$ls.set("certLoginTimeCode", +new Date());
              } else {
                clearInterval(interval);
                this.regainCheckBtn(state);
              }
            })
            .catch(err => {
              toastSuccess.hide();
              clearInterval(interval);
              this.regainCheckBtn(state);
            });
        } else {
          this.$createToast({
            txt: "请确认手机号",
            type: "error"
          }).show();
        }
      }, 0);
    },
    handleSubmit() {
      const { mobile, tattedId, tattedCode } = this;

      let validator = new Validator();

      validator.add(mobile, "mobileFormat", "请确认手机号码");
      validator.add(tattedCode, "isEmpty", "请确认短信验证码");
      const errMsg = validator.start();
      if (errMsg) {
        this.$createToast({
          txt: errMsg,
          type: "error"
        }).show();
        return;
      }
      this.submitting = true;
      certLogin({ mobile, tattedId, tattedCode })
        .then(res => {
          if (res.success) {
            const { token, signStatus, regMobile } = res.data;
            setCertToken(token);

            if (signStatus === "2") {
              this.$router.replace({
                name: "certifiedResult",
                query: {
                  showResultType: 'successed'
                }
              });
              return false;
            }

            this.$router.replace({
              name: "certifiedHome",
              query: {
                phone: regMobile
              }
            });
          }
          this.regainStatus();
        })
        .catch(err => {
          this.regainStatus();
        });
    }
  },
  mounted() {
    this.$ls.get("certTattedId") &&
      (this.tattedId = this.$ls.get("certTattedId"));

    this.$ls.get("certMobile") && (this.mobile = this.$ls.get("certMobile"));

    const nowTime = +new Date();
    const prevTime = this.$ls.get("certLoginTimeCode") || 0;

    const timeDifference = ((nowTime - prevTime) / 1000) | 0;

    if (timeDifference < LIMITTIME) {
      const { state } = this;
      state.time = LIMITTIME - timeDifference;
      state.smsSendBtn = true;
      const interval = window.setInterval(() => {
        if (state.time-- <= 0) {
          this.regainCheckBtn(state);
          window.clearInterval(interval);
        }
      }, 1000);
    }
  }
};
</script>

<style scoped lang="less">
.container {
  padding: 60px 16px 0;
  font-size: 16px;
  .logo {
    display: block;
    margin: 0 auto 50px;

    width: 80px;
    height: 80px;
  }

  .fill-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;

    .fill-name {
      width: 70px !important;
      //padding-left: 16px;
      text-align: left;
      color: rgba(51, 51, 51, 1);
      line-height: 40px;
    }
    .fill-input {
      height: 40px;
      width: 150px;
      flex: 1;
      background: none;
      outline: none;
      border: 0px;
      color: #666;
      &::-webkit-input-placeholder {
        color: #ccc;
        // line-height: 1.2em;
        line-height: 19px;
      }
    }

    .get-code {
      width: 90px;
      text-align: right;
      font-size: 14px;
      color: #579af1;
      line-height: 40px;
    }
  }

  .submit-section {
    margin-top: 40px;
  }
}
</style>
