var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _vm.showResultType === "bidHandle"
      ? _c("div", [
          _c("div", { staticClass: "result-title" }, [
            _vm._v("请不要关闭该页面!")
          ]),
          _c("div", { staticClass: "result-tips" }, [
            _vm._v("正在处理中，请稍等...")
          ]),
          _c("img", {
            staticClass: "resutl-icon mgt40",
            attrs: { src: require("@assets/images/spin.gif") }
          })
        ])
      : _vm._e(),
    _vm.showResultType === "loanFail"
      ? _c("div", [
          _c("img", {
            staticClass: "resutl-icon mgt20",
            attrs: { src: require("@assets/images/error.png") }
          }),
          _c("div", { staticClass: "result-title" }, [_vm._v("处理失败")]),
          _c("div", { staticClass: "result-tips" }, [
            _vm._v("系统处理中遇到点问题，请联系客服或稍后重试")
          ])
        ])
      : _vm._e(),
    _vm.showResultType === "loanWaitting"
      ? _c("div", [
          _c("img", {
            staticClass: "resutl-icon mgt20",
            attrs: { src: require("@assets/images/waitting.png") }
          }),
          _c("div", { staticClass: "result-title" }, [_vm._v("审批放款中")]),
          _c("div", { staticClass: "result-tips" }, [
            _vm._v(
              "\n      您的资料已提交，正在审批放款，请留意短信通知。\n    "
            )
          ])
        ])
      : _vm._e(),
    _vm.showResultType === "stagedSuccess"
      ? _c(
          "div",
          [
            _c("img", {
              staticClass: "resutl-icon",
              attrs: { src: require("@assets/images/success.png") }
            }),
            _c("div", { staticClass: "result-title" }, [_vm._v("申请成功")]),
            _c("div", { staticClass: "result-tips" }, [
              _vm._v("恭喜您，分期申请成功！请返回商户页面")
            ]),
            _c("div", { staticClass: "result-tips" }, [
              _vm._v("进行后续操作或与商户确认。")
            ]),
            _vm.returnOrderUrl
              ? _c(
                  "cube-button",
                  { staticClass: "handle-btn", on: { click: _vm.checkOrder } },
                  [_vm._v("查看订单")]
                )
              : _vm._e(),
            _c("img", {
              ref: "gonghzhonghao-poster",
              staticClass: "gonghzhonghao-poster",
              attrs: {
                src: require("@assets/images/gongzhonghao.png"),
                alt: ""
              }
            })
          ],
          1
        )
      : _vm._e(),
    _vm.showResultType === "applyReject"
      ? _c("div", [
          _c("img", {
            staticClass: "resutl-icon mgt20",
            attrs: { src: require("@assets/images/error.png") }
          }),
          _c("div", { staticClass: "result-title" }, [_vm._v("审核不通过")]),
          _c("div", { staticClass: "result-tips" }, [
            _vm._v("很遗憾！您本次申请未获得通过，请选择")
          ]),
          _c("div", { staticClass: "result-tips" }, [
            _vm._v("其他支付方式进行付款。")
          ])
        ])
      : _vm._e(),
    _vm.showResultType === "infoSubmitted"
      ? _c("div", [
          _c("img", {
            staticClass: "resutl-gif",
            attrs: { src: require("@assets/images/waitting.gif") }
          }),
          _c("div", { staticClass: "result-title" }, [_vm._v("资料已提交")]),
          _c(
            "div",
            { staticClass: "company-box" },
            [
              _c("p", { staticClass: "c_title" }, [
                _vm._v("请务必添加官方企业微信")
              ]),
              _c("p", { staticClass: "result-tips" }, [
                _vm._v("以便核实申请信息以及获取专属服务等")
              ]),
              _c("img", {
                staticClass: "c_img mgt20",
                attrs: { src: require("@assets/images/companyWC.jpg") }
              }),
              _c("cube-button", { staticClass: "c-btn" }, [
                _vm._v("点击添加 豆乐派专属客服")
              ])
            ],
            1
          )
        ])
      : _vm._e(),
    _vm.showResultType === "fadadaToBeVerified"
      ? _c("div", [
          _c("img", {
            staticClass: "resutl-gif mgt20",
            attrs: { src: require("@assets/images/waitting.gif") }
          }),
          _c("div", { staticClass: "result-title" }, [_vm._v("资料已提交")]),
          _c(
            "div",
            { staticClass: "company-box" },
            [
              _c("p", { staticClass: "c_title" }, [
                _vm._v("请务必添加官方企业微信")
              ]),
              _c("p", { staticClass: "result-tips" }, [
                _vm._v("以便核实申请信息以及获取专属服务等")
              ]),
              _c("img", {
                staticClass: "c_img mgt20",
                attrs: { src: require("@assets/images/companyWC.jpg") }
              }),
              _c("cube-button", { staticClass: "c-btn" }, [
                _vm._v("点击添加 豆乐派专属客服")
              ])
            ],
            1
          )
        ])
      : _vm._e(),
    _vm.showResultType === "fadadaReviewReject"
      ? _c("div", [
          _c("img", {
            staticClass: "resutl-icon mgt20",
            attrs: { src: require("@assets/images/error.png") }
          }),
          _c("div", { staticClass: "result-title" }, [_vm._v("复审拒绝")]),
          _c("div", { staticClass: "result-tips" }, [
            _vm._v(
              "\n      很遗憾，您的认证资料审核不通过，请确保使用本人真实资料进行认证。\n    "
            )
          ])
        ])
      : _vm._e(),
    _vm.showResultType === "repaymentSuccess"
      ? _c("div", [
          _c("img", {
            staticClass: "resutl-icon",
            attrs: { src: require("@assets/images/success.png") }
          }),
          _c("div", { staticClass: "result-title" }, [_vm._v("付款提交成功")]),
          _c("div", { staticClass: "result-tips" }, [
            _vm._v("预计10分钟内处理完成，请留意短信通知。")
          ])
        ])
      : _vm._e(),
    _vm.showResultType === "redirecting"
      ? _c(
          "div",
          [
            _c("c-spin", {
              staticStyle: { "margin-top": "30vh" },
              attrs: { tip: "页面跳转中...", size: "large" }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }