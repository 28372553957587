<template>
  <div class="container">
    <tips-top> 检测到您已绑定以下银行卡，请重新完成绑卡鉴权 </tips-top>
    <md-field>
      <md-input-item v-model="userName" title="持卡人" readonly>
      </md-input-item>
      <md-input-item v-model="bankCardNo" title="银行卡号" readonly>
      </md-input-item>
      <md-input-item v-model="bankMobile" title="预留手机号" readonly>
      </md-input-item>

      <md-input-item
        v-model.trim="smsCode"
        maxlength="6"
        placeholder="请输入短信验证码"
        clearable
        title="验证码"
      >
        <SmsButton
          :params="params"
          :requestFn="sendChangeMobile"
          :storageModel="false"
          @response="getResponseData"
          slot="right"
        ></SmsButton>
      </md-input-item>
      <md-button
        @click="handleSubmit"
        :loading="loading"
        class="mgt20"
        type="primary"
        >确认</md-button
      >
    </md-field>
  </div>
</template>

<script type="text/ecmascript-6">
import { Field, FieldItem, InputItem, Button } from "mand-mobile";

import TipsTop from "@comp/TipsTop/TipsTop";

import SmsButton from "./components/SmsButton";

import { changeMobilePayCode, changeMobile } from "@/api/repayment";

export default {
  name: "",
  components: {
    [Field.name]: Field,
    [FieldItem.name]: FieldItem,
    [InputItem.name]: InputItem,

    [Button.name]: Button,
    TipsTop,
    SmsButton,
  },
  data() {
    return {
      loading: false,
      userName: "",
      historyId: "",
      bankCardNo: "",
      bankMobile: "",
      smsCode: "",
      requestNo: "",
    };
  },
  computed: {
    params() {
      const { bankCardNo, historyId } = this;
      return {
        bankCardNo,
        historyId,
      };
    },
  },
  methods: {
    sendChangeMobile: changeMobilePayCode,
    getResponseData(value) {
      console.log("value", value);
      const { requestNo } = value;
      this.requestNo = requestNo;
    },
    handleSubmit() {
      const { requestNo, smsCode, historyId, bankCardNo } = this;
      const params = {
        requestNo,
        smsCode,
        historyId,
        bankCardNo,
      };

      if (this.loading) {
        return;
      }

      this.loading = true;

      const resetBtnStatus = () => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
      };

      changeMobile({ ...params })
        .then((res) => {
          if (res.success) {
            this.$createToast({
              txt: "操作成功",
              type: "correct",
            }).show();
            this.$router.replace({
              name: "mine",
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          resetBtnStatus();
        });
    },
  },
  created() {
    const { bankCardNo, bankMobile, userName, historyId } = this.$route.query;

    this.bankCardNo = bankCardNo;
    this.bankMobile = bankMobile;
    this.userName = userName;
    this.historyId = historyId;
  },
};
</script>

<style scoped lang="less">
/deep/.md-field-item-title {
  text-align: left;
}
</style>
