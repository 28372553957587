import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "@/assets/styles/index.less";

import "@/core/use";

import { VueAxios } from "@/utils/request";

import bootstrap from "@/core/bootstrap";
import "@/permission.js";

// import * as fundebug from "fundebug-javascript";
// import fundebugVue from "fundebug-vue";
// import "@/config/fundebugConfig";

// // bug监控
// if (process.env.NODE_ENV === "development") {
//   fundebugVue(fundebug, Vue);
// }
// // 视频bug回放
// require("fundebug-revideo");

import * as filters from "@/filters"; // global filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

console.log("当前运行环境", process.env);
/* eslint-disable no-unused-vars */
import VConsole from "vconsole";
/* eslint-disable */
if (process.env.NODE_ENV === "test" || process.env.NODE_ENV === "development") {
  var vConsole = new VConsole();
}

Vue.config.productionTip = false;

Vue.config.errorHandler = function(err, vm, info, a) {
  // todo,如果需要，可简单上报数据
  // const errorInfo = { err, vm, info, url: window.location.href };
  // console.error(err, info);
};

Vue.use(VueAxios, router);

new Vue({
  router,
  store,
  created() {
    console.log("app create");
    bootstrap();
  },
  render: (h) => h(App),
}).$mount("#app");
