<template>
  <span @click="handleCopy()" class="tel-num">{{ text }}</span>
</template>

<script type="text/ecmascript-6">
export default {
  name: "CopySpan",
  props: {
    text: {
      type: String,
      default: "默认",
    },
  },
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    handleCopy() {
      let text = this.text;
      console.log("text", text);
      // fixme 待优化
      const input = document.createElement("input");
      input.value = text;
      document.body.appendChild(input);
      input.select();
      if (typeof document.execCommand === "function") {
        document.execCommand("Copy"); // 执行浏览器复制命令

        this.$createToast({
          txt: "复制成功",
          type: "success",
          time: 800,
        }).show();

        input.remove();
      }
    },
  },
};
</script>

<style scoped lang="less">
</style>
