/* eslint-disable no-unused-vars */
<template>
  <div class="container">
    <md-selector
      v-model="isSelectorShow"
      :data="CooperationDealerList"
      :default-value="selectDefalutValue"
      title="选择车商"
      is-check
      iconSize="lg"
      max-height="520px"
      @choose="onSelectorChoose"
    >
      <!-- <div class="selector-header" slot="header">
        选择车商
      </div>-->

      <template slot-scope="{ option, index, selected }">
        <!-- <div class="md-selector-custom-brief">{{ option.text }}使用slot-scope</div> -->
        <div
          class="selector-item-body"
          :class="{ disabled: option.disabled, selected }"
        >
          <div class="selector-item-left">
            <span class="holder">{{ option.text | firstNameFilter }}</span>
          </div>
          <div class="selector-item-content">
            <p class="selector-item-title" v-text="option.text"></p>
            <p class="selector-item-brief" v-text="`编号：${option.value}`"></p>
          </div>
        </div>
      </template>

      <!-- <div class="selector-footer" slot="footer">
        <span @click="handleCreateCooperationDealer" class="selector-footer-help">没有找到想要的车商？点击添加</span>
      </div>-->
    </md-selector>

    <ExtendPopup ref="CreateCooperationDealer" title="添加车商">
      <md-field>
        <md-input-item
          title="合作车商"
          placeholder="请输入合作车商名称"
          clearable
        ></md-input-item>
        <md-input-item
          title="ㅤ联系人"
          placeholder="请输入联系人姓名"
          clearable
        ></md-input-item>
        <md-input-item
          title="手机号码"
          placeholder="请输入联系人手机号码"
          clearable
        ></md-input-item>

        <div class="action-section">
          <md-button
            @click="handleCloseCreateCooperationDealer"
            type="primary"
            inline
            plain
            size="small"
            >取消</md-button
          >
          <md-button type="primary" icon="right" inline size="small"
            >确认</md-button
          >
        </div>
      </md-field>
    </ExtendPopup>

    <!-- 合作银行 -->

    <md-selector
      v-model="isSelectorShowBankDealers"
      :data="bankDealerList"
      :default-value="selectBankDefalutValue"
      title="选择合作银行"
      is-check
      iconSize="lg"
      max-height="520px"
      @choose="onBankSelectorChoose"
    >
      <template slot-scope="{ option, index, selected }">
        <div
          class="selector-item-body"
          :class="{ disabled: option.disabled, selected }"
        >
          <div class="selector-item-left">
            <span class="holder">{{ option.text | firstNameFilter }}</span>
          </div>
          <div class="selector-item-content">
            <p class="selector-item-title" v-text="option.text"></p>
            <p class="selector-item-brief" v-text="`编号：${option.value}`"></p>
          </div>
        </div>
      </template>
    </md-selector>

    <div class="order-form-container" ref="orderFormView">
      <md-field>
        <md-field-item
          v-if="internal"
          title="合作银行"
          :content="bankDealerName || '请选择'"
          arrow
          @click="showBankSelector"
        ></md-field-item>

        <!-- title="订单信息" brief="请仔细校验资料" -->
        <md-field-item
          title="合作车商"
          :content="carDealerName || '请选择'"
          arrow
          @click="showSelector"
        ></md-field-item>

        <md-input-item
          title="银行订单"
          :solid="false"
          type="text"
          v-model.trim="form.contractNo"
          align="right"
          clearable
          data-vv-as="银行订单号后六位"
          name="contractNo"
          v-validate="'required'"
          :error="errors.first('contractNo')"
          placeholder="请输入银行订单号后六位"
        >
          <span slot="right">ㅤ</span>
        </md-input-item>

        <md-input-item
          title="贷款总额"
          type="money"
          v-model.trim="form.loanTotal"
          align="right"
          data-vv-as="贷款总金额"
          name="loanTotal"
          v-validate="'required'"
          :error="errors.first('loanTotal')"
          is-virtual-keyboard
          placeholder="请输入贷款总金额"
        >
          <span slot="right">元</span>
        </md-input-item>

        <md-input-item
          title="垫资金额"
          type="money"
          v-model.trim="form.loanAmount"
          align="right"
          maxlength="13"
          data-vv-as="垫资金额(车款金额)"
          name="loanAmount"
          v-validate="'required'"
          :error="errors.first('loanAmount')"
          is-virtual-keyboard
          placeholder="请输入垫资金额(车款金额)"
        >
          <span slot="right">元</span>
        </md-input-item>

        <md-input-item
          title="客户利率"
          v-model.trim="form.realInterest"
          align="right"
          data-vv-as="客户合同约定利率"
          name="realInterest"
          v-validate="'required|percentage|validateDecimal4'"
          :error="errors.first('realInterest')"
          is-virtual-keyboard
          placeholder="请输入客户合同约定利率"
        >
          <span slot="right">%</span>
        </md-input-item>

        <md-input-item
          title="返佣利率"
          v-model.trim="form.downCommissionPriceRate"
          align="right"
          data-vv-as="下游返佣利率"
          name="downCommissionPriceRate"
          v-validate="'required|percentage|validateDecimal4'"
          :error="errors.first('downCommissionPriceRate')"
          is-virtual-keyboard
          placeholder="请输入下游返佣利率"
        >
          <span slot="right">%</span>
        </md-input-item>

        <md-input-item
          title="车架号"
          v-model.trim="form.carFrameNo"
          align="right"
          data-vv-as="车辆车架号"
          name="carFrameNo"
          v-validate="'required'"
          :error="errors.first('carFrameNo')"
          placeholder="请输入车辆车架号"
        >
          <!-- <md-icon
            @click="handleImageRecognition"
            name="camera"
            size="lg"
            class="camera-icon pdl6 mgl10"
            slot="right"
          >
          </md-icon> -->
          <!-- <input slot="right" type="file"/> -->
          <span slot="right">ㅤ</span>
        </md-input-item>

        <div class="mgt20">客户信息</div>

        <div id="upload-section">
          <div>
            <cube-upload
              ref="uploadface"
              class="upload-box"
              v-model="idcardface"
              :action="action"
              :process-file="processFile"
              @files-added="addedHandlerFace"
              @file-error="errHandler"
            >
              <div class="clear-fix">
                <cube-upload-file
                  class="upload-file-idcard"
                  v-for="(file, i) in idcardface"
                  :file="file"
                  :key="i"
                ></cube-upload-file>
                <cube-upload-btn
                  class="upload-btn-idcard idcard-face"
                  :multiple="false"
                >
                  <div>
                    <i>＋</i>
                  </div>
                </cube-upload-btn>
              </div>
            </cube-upload>

            <div class="upload-tips">
              点击识别正面
              <span>人像面</span>
            </div>
          </div>

          <div>
            <cube-upload
              ref="uploadback"
              class="upload-box"
              v-model="idcardback"
              :action="action"
              :process-file="processFile"
              @files-added="addedHandlerBack"
              @file-error="errHandler"
            >
              <div class="clear-fix">
                <cube-upload-file
                  class="upload-file-idcard"
                  v-for="(file, i) in idcardback"
                  :file="file"
                  :key="i"
                ></cube-upload-file>
                <cube-upload-btn
                  class="upload-btn-idcard idcard-back"
                  :multiple="false"
                >
                  <div>
                    <i>＋</i>
                  </div>
                </cube-upload-btn>
              </div>
            </cube-upload>

            <div class="upload-tips">
              点击识别背面
              <span>国徽面</span>
            </div>
          </div>
        </div>

        <md-input-item
          title="客户姓名"
          :solid="false"
          type="text"
          v-model.trim="form.userName"
          align="right"
          clearable
          data-vv-as="客户姓名"
          name="userName"
          v-validate="'required'"
          :error="errors.first('userName')"
          placeholder="请输入客户姓名"
        >
          <!-- 上传身份证后识别 -->
          <span slot="right">ㅤ</span>
        </md-input-item>

        <md-input-item
          title="身份证号"
          :solid="false"
          type="text"
          v-model.trim="form.idNo"
          maxlength="18"
          align="right"
          clearable
          data-vv-as="身份证号"
          name="idNo"
          v-validate="'required'"
          :error="errors.first('idNo')"
          is-virtual-keyboard
          virtual-keyboard-vm="idCardKeyboard"
          placeholder="请输入身份证号"
          @focus="handleIDCardFocus"
          @blur="handleIDCardBlur"
        >
          <span slot="right">ㅤ</span>
        </md-input-item>

        <md-number-keyboard
          ref="idCardKeyboard"
          :text-render="keyFormatter"
        ></md-number-keyboard>

        <md-input-item
          title="ㅤ手机号"
          :solid="false"
          type="phone"
          v-model.trim="form.mobile"
          align="right"
          maxlength="11"
          clearable
          name="mobile"
          data-vv-as="手机号"
          v-validate="'required|phone'"
          :error="errors.first('mobile')"
          placeholder="请输入客户手机号"
          :disabled="continueOrder"
        >
          <!-- <span slot="left">*</span> -->
          <span slot="right">ㅤ</span>
        </md-input-item>

        <md-button
          class="mgt50"
          type="primary"
          :loading="loading"
          @click="handleSubmit"
          >下一步</md-button
        >
      </md-field>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import {
  Field,
  FieldItem,
  InputItem,
  NumberKeyboard,
  Selector,
  ActionBar,
  Button,
  Icon,
  Dialog,
} from "mand-mobile";
import { baseCarFinanceURL } from "@/utils/requestCarFinance";
import { getCarDealers, reSubmitOrder, submitOrder } from "@/api/carFinance";
import { discernPicWord } from "@/api/common";

import { resetImageBlob } from "@/utils/getImageBlobUrl";

import { Validator } from "vee-validate";

import filters from "./mixins/filters";

import ExtendPopup from "./components/ExtendPopup";

import { validateIdCard } from "@/utils/verify";

Validator.extend("phone", {
  getMessage: () => `请确认手机号码`,
  validate: (value) => /^1[0-9]{10}$/.test(value),
});

Validator.extend("percentage", {
  getMessage: () => `请确认输入0-100之间的比率`,
  validate: (value) => {
    if (isNaN(value)) {
      return false;
    }

    if (value >= 0 && value <= 100) {
      return true;
    }
    return false;
  },
});

Validator.extend("validateDecimal4", {
  getMessage: () => `最多保留4位小数`,
  validate: (value) => {
    if (/^[0-9]+\.{0,1}[0-9]{0,4}$/.test(value)) {
      return true;
    }

    return false;
  },
});

Validator.extend("idCard", {
  getMessage: () => `请确认身份证号`,
  validate: (value) =>
    /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/.test(
      value
    ),
});

export default {
  name: "",
  mixins: [filters],
  components: {
    [Field.name]: Field,
    [FieldItem.name]: FieldItem,
    [InputItem.name]: InputItem,
    [NumberKeyboard.name]: NumberKeyboard,
    [Selector.name]: Selector,
    [ActionBar.name]: ActionBar,
    [Button.name]: Button,
    [Icon.name]: Icon,
    ExtendPopup,
  },

  data() {
    return {
      continueOrder: false,
      id: "",
      isSelectorShow: false,
      CooperationDealerList: [
        // example
        // {
        //   value: "A",
        //   text: "选项一",
        //   describe: "使用slot-scope"
        // },
      ],
      selectDefalutValue: "",

      // 合作车商
      leagueId: "",
      // 合作银行
      isSelectorShowBankDealers: false,
      internal: false,
      bankDealerList: [],
      selectBankDefalutValue: "",
      upLeagueId: "",

      // 表单数据
      form: {
        contractNo: "",
        loanAmount: "",
        realInterest: "",
        downCommissionPriceRate: "",
        loanTotal: "",
        carFrameNo: "",
        userName: "",
        idNo: "",
        mobile: "",
      },

      action: {
        target: `${baseCarFinanceURL}/car/operatorCenter/uploadImage.do`,
        fileName: "file",
        headers: {},
        data: {},
        // 用于检验请求成功与否
        // eslint-disable-next-line no-unused-vars
        checkSuccess(res, file) {
          // console.log("上传返回数据", res);
          if (res.success) {
            return true;
          } else {
            return false;
          }
        },
      },

      idcardface: [],
      idcardback: [],
      loading: false,
      Dialog: null,
    };
  },
  computed: {
    carDealerName() {
      const { leagueId } = this;

      const item = this.CooperationDealerList.find((item) => {
        return item.value === leagueId;
      });

      return (item && item.text) || "";
    },
    bankDealerName() {
      const { upLeagueId } = this;

      const item = this.bankDealerList.find((item) => {
        return item.value === upLeagueId;
      });

      return (item && item.text) || "";
    },
  },

  methods: {
    init() {
      this.getCarDealers().then((status) => {
        if (status === "ok") {
          // TODO 用于回显 重新申请
          const { continueOrder, id } = this.$route.query;
          if (continueOrder === "yes" && id) {
            // 记录状态
            this.continueOrder = true;
            this.id = id;
            // TODO ajax 获取blob  记得要在oss 设置同源策略
            this.getOrderInfoBefor(id);
          }
        }
      });
    },
    getOrderInfoBefor(id) {
      reSubmitOrder({ id })
        .then((res) => {
          if (res.success) {
            const {
              carDealerId,
              upLeagueId,
              idCardfrontUrl,
              idCardBackUrl,
            } = res.data;
            this.form = { ...res.data, id };

            // 回显图片上传源图
            resetImageBlob(idCardfrontUrl).then((res) => {
              this.$set(this["idcardface"], "0", res);
            });
            resetImageBlob(idCardBackUrl).then((res) => {
              this.$set(this["idcardback"], "0", res);
            });

            // TODO 设置车商选项
            this.setCarSelectedData(carDealerId);
            // 合作银行回显
            this.setBankSelectedData(upLeagueId);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },

    setCarSelectedData(id) {
      // 设置选项展开默认值
      this.selectDefalutValue = id;
      this.leagueId = id;
    },

    setBankSelectedData(id) {
      // 设置选项展开默认值
      this.selectBankDefalutValue = id;
      this.upLeagueId = id;
    },

    getCarDealers() {
      return getCarDealers({})
        .then((res) => {
          if (res.success && res.data) {
            const { carDealers, bankDealers, internal } = res.data;
            // 合作车上信息
            if (Array.isArray(carDealers)) {
              this.CooperationDealerList = carDealers.map((item) => ({
                text: item.leagueName,
                value: item.leagueId,
              }));
            }

            // 是否为银行内部，是内部就显示
            this.internal = internal;

            // 合作银行信息
            if (Array.isArray(bankDealers)) {
              this.bankDealerList = bankDealers.map((item) => ({
                text: item.leagueName,
                value: item.leagueId,
              }));
            }

            return "ok";
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    showSelector() {
      this.isSelectorShow = true;
    },
    hideSelector() {
      this.isSelectorShow = false;
    },
    onSelectorChoose({ value, text }) {
      this.leagueId = value;
    },
    handleCreateCooperationDealer() {
      this.hideSelector();
      this.$refs.CreateCooperationDealer.show();
    },
    handleCloseCreateCooperationDealer() {
      // 清空表单数据

      this.$refs.CreateCooperationDealer.hide();
    },

    // 合作银行
    showBankSelector() {
      this.isSelectorShowBankDealers = true;
    },
    hideBankSelector() {
      this.isSelectorShowBankDealers = false;
    },

    onBankSelectorChoose({ value, text }) {
      this.upLeagueId = value;
    },

    handleImageRecognition() {
      // const uploadInput = document.createElement("input");

      discernPicWord()
        .then((res) => {
          if (res.succes) {
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },

    processFile(file, next) {
      // console.log("ios 10.1 图片上传 == processFile", file);

      //   if (!(navigator.userAgent.indexOf("CPU iPhone OS 10_") > -1)) {
      //     console.log("已压缩");
      //     compress(
      //       file,
      //       {
      //         compress: {
      //           width: 1600,
      //           height: 1600,
      //           quality: 0.5
      //         },
      //         type: "image"
      //       },
      //       next
      //     );
      //   } else {
      //     console.log("未压缩");
      //     next(file);
      //   }

      next(file);
    },
    addedHandlerFace() {
      const file = this.idcardface[0];
      file && this.$refs.uploadface.removeFile(file);
    },
    addedHandlerBack() {
      const file = this.idcardback[0];
      file && this.$refs.uploadback.removeFile(file);
    },
    errHandler(file) {
      // const msg = file.response.message
      console.log("errHandler", file);
      if (file._xhr && file._xhr && file._xhr.status === 401) {
        this.$createToast({
          type: "warn",
          txt: "Unauthorized,请重新登录",
          time: 3000,
          onTimeout: () => {
            console.log("Timeout");
            this.$router.push({
              name: "carLogin",
            });
          },
        }).show();
      } else {
        this.$createToast({
          type: "warn",
          txt: "Upload fail",
          time: 1500,
        }).show();
      }
    },

    vailIdCard(files, Tips) {
      let file = files[0];
      if (!file || !file.response || file.response.success === false) {
        this.$createToast({
          txt: Tips,
          time: 1800,
          type: "txt",
        }).show();
        return true;
      }
    },
    // 设置身份证x
    keyFormatter(val) {
      if (val === ".") {
        return "x";
      }
    },
    handleIDCardFocus() {
      this.$refs.orderFormView.style.transform = "translateY(-160px)";
    },
    handleIDCardBlur() {
      this.$refs.orderFormView.style.transform = "translateY(0px)";
    },
    handleSubmit() {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          const {
            vailIdCard,
            idcardface,
            idcardback,
            leagueId,
            upLeagueId,
          } = this;

          if (!leagueId) {
            this.$createToast({
              txt: "请选择合作车商",
              time: 1800,
              type: "txt",
            }).show();
            return false;
          }
          if (this.internal && !upLeagueId) {
            this.$createToast({
              txt: "请选择合作银行",
              time: 1800,
              type: "txt",
            }).show();
            return false;
          }

          if (
            vailIdCard(idcardface, "请上传身份证人像面") ||
            vailIdCard(idcardback, "请上传身份证国徽面")
          ) {
            return false;
          }

          if (!validateIdCard(this.form.idNo)) {
            this.$createToast({
              txt: "请确认身份证号",
              time: 1800,
              type: "txt",
            }).show();
            return false;
          }

          if (this.loading) {
            return;
          }

          this.loading = true;

          // 待优化
          const params = {
            ...this.form,
            leagueId,
            upLeagueId,
            idCardfrontUrl: idcardface[0].response.data.url,
            idCardBackUrl: idcardback[0].response.data.url,
          };

          const resetBtnStatus = () => {
            setTimeout(() => {
              this.loading = false;
            }, 200);
          };

          submitOrder(params)
            .then((res) => {
              if (res.success) {
                this.$router.replace({
                  name: "supplementInfo",
                  query: {
                    orderCarId: res.data.orderCarId,
                  },
                });
              } else {
                resetBtnStatus();
              }
            })
            .catch((err) => {
              console.log("err", err);
              resetBtnStatus();
            });
        }
      });
    },
  },
  mounted() {
    this.init();
  },
  beforeRouteLeave(to, from, next) {
    const passable = from.params.passable;

    if (to.name === "carHome" && !passable) {
      Dialog.confirm({
        title: "确认要返回首页吗？",
        content: "当前所填信息将不会得到保存",
        confirmText: "确定",
        onConfirm: () => {
          // todo 设置可通行
          from.params.passable = true;

          next({ name: "carHome" });
        },
        onCancel: () => {},
      });

      return next(false);
    }

    next();
  },
};
</script>

<style scoped lang="less">
.container {
  text-align: left;
}
</style>

<style lang="less">
// 针对contnent 右对齐
.order-form-container {
  transition: transform 0.2s ease-out;
  padding-bottom: 60px;
  .md-field-item-control {
    text-align: right;
  }

  .action-section {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 14px;
  }

  #upload-section {
    display: flex;
    justify-content: space-between;
    padding: 14px 0;
    margin-top: 10px;
    background: #f9fafb;
    text-align: center;

    // fixme 其他style污染了，application\RealNameAuthentication.vue，可用components/Upload 组件 （ 待优化 ）

    .upload-box {
      .upload-file-idcard,
      .upload-btn-idcard {
        margin: 0px auto 0;
        height: 90px;
        width: 150px;
      }

      .idcard-back {
        background: url("../../assets/images/idcard-back.png") no-repeat;
        background-size: 150px 90px;
      }

      .idcard-face {
        background: url("../../assets/images/idcard-face.png") no-repeat;
        background-size: 150px 90px;
      }
    }

    .upload-tips {
      margin-top: 10px;
      font-size: 12px;
      color: #999;
      span {
        margin-left: 3px;
        color: #579af1;
      }
    }
  }
}
</style>

<style scoped lang="less">
.container {
  .selector-header,
  .selector-footer {
    padding: 7px 20px;
    font-size: 8px;
    color: #ccc;
  }

  .selector-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    .selector-footer-help {
      padding: 10px 0 14px;
      color: cornflowerblue;
    }
  }

  .selector-item-body {
    display: flex;
    align-items: center;

    &.selected {
      .selector-item-content {
        color: #2f86f6;
      }
    }

    &.disabled {
      opacity: 0.3;
    }

    .selector-item-left {
      flex-shrink: 0;
      margin-right: 16px;

      .holder {
        display: block;
        width: 44px;
        height: 44px;
        border-radius: 22px;
        background-color: #2f86f6;
        font-size: 20px;
        font-weight: 500;
        color: #fff;
        text-align: center;
        line-height: 44px;
      }
    }

    .selector-item-content {
      flex: 1;
      color: #111a34;
      font-size: 16px;
      line-height: 1.2;

      .selector-item-title {
        line-height: 1.2;
      }

      .selector-item-brief {
        color: #858b9c;
        font-size: 12px;
        line-height: 1.4;
        margin-top: 4px;
      }
    }
  }
}
</style>
