<template>
  <div class="container">
    <div v-if="showResultType === 'redirecting'">
      <c-spin style="margin-top:30vh;" tip="页面跳转中..." size="large"></c-spin>
    </div>

    <!-- 活体认证 -->
    <div v-if="showResultType === 'faceError'">
      <img class="resutl-icon mgt20" src="@assets/images/error.png" />
      <div class="result-title">认证失败</div>
      <div class="result-tips">{{faceErrorResult}}</div>

      <cube-button @click="retryFaceRecognition" class="handle-btn">重试</cube-button>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import { obtainOciResult, autoBid, fadadaRzStatus } from "@/api/application";
import { nextProcess } from "./redirectAction";

export default {
  name: "",
  data() {
    return {
      faceErrorResult: "",
      showResultType: ""
    };
  },
  components: {},

  methods: {
    retryFaceRecognition() {
      this.$router.replace({
        name: "installmentLoan"
      });
    }
  },
  mounted() {
    console.log("result页面 router", this.$route);
    const { biz_id, status, type, transactionNo } = this.$route.query;
    // face ++
    if (biz_id) {
      this.showResultType = "redirecting";

      obtainOciResult({
        biz_id
      })
        .then(res => {
          if (res.success) {
            const { SUCCESS, msg } = res.data;
            if (SUCCESS) {
              if (status) {
                // 跳转页面
                //nextProcess({ status });
                this.$router.push({
                  name: "result",
                  query: {
                    resultType: "bidHandle"
                  }
                });
                return;
              }
              this.$router.push({
                name: "improveInformation"
              });
            } else {
              this.faceErrorResult = msg;
              this.showResultType = "faceError";
            }
          }
        })
        .catch(err => {
          console.log("err", err);
        });
    } else if (transactionNo || type === "fadada") {
      this.showResultType = "redirecting";
      fadadaRzStatus()
        .then(res => {
          console.log("res", res);
          if (res.success) {
            // 判断有没有超过三个月
            // this.$router.push({
            //   name: "improveInformation"
            // });
            nextProcess(res.other);
          }else {
            this.faceErrorResult = res.data.message || '发生了错误';
              this.showResultType = "faceError";
          }
        })
        .catch(err => {
          console.log("err", err);
        });
    }
  },

  destroyed() {}
};
</script>

<style scoped lang="less">
.container {
  padding: 90px 16px 0;
  text-align: center;

  .resutl-icon {
    //   display: block;
    width: 64px;
    height: 64px;
  }

  .resutl-gif {
    width: 272px;
    height: 213px;
  }

  .result-title {
    margin: 20px 0 16px;
    font-size: 20px;
    // line-height: 28px;
  }

  .result-tips {
    font-size: 14px;
    line-height: 1.3;
    color: #666;
  }

  .handle-btn {
    border-radius: 4px;
    margin-top: 92px;
  }
}
</style>
