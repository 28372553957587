<template>
  <div class="container">
    <h2>登录超时，请重新扫码进入</h2>

    <div><img class="tips-img" src="@/assets/images/expire.gif" alt=""></div>
  </div>
  
</template>

<script type="text/ecmascript-6">
export default {
  name: "",
  data() {
    return {};
  },
  components: {}
};
</script>

<style scoped lang="less">
.container {
    padding-top: 100px;
    
    text-align: center;

    .tips-img {
      margin-top: 30px;
    }
}
</style>
