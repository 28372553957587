var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-container" },
    [
      _c(
        "Upload",
        {
          ref: "upload",
          staticClass: "upload-box",
          attrs: { action: _vm.action },
          on: { "files-added": _vm.addedHandler, "file-error": _vm.errHandler },
          model: {
            value: _vm.files,
            callback: function($$v) {
              _vm.files = $$v
            },
            expression: "files"
          }
        },
        [
          _c(
            "div",
            { staticClass: "clear-fix upload-box-wrapper" },
            [
              _vm._l(_vm.files, function(file, i) {
                return _c("UploadFile", {
                  key: i,
                  class: [
                    { "multiple-upload-file": _vm.multiple },
                    "upload-box-file"
                  ],
                  attrs: { file: file }
                })
              }),
              _c(
                "UploadBtn",
                {
                  staticClass: "upload-box-btn",
                  style: {
                    "background-image":
                      "url(" +
                      require("@/assets/images/" + _vm.backgroundImgName) +
                      ")"
                  },
                  attrs: {
                    multiple: _vm.multiple,
                    simultaneousUploads: _vm.simultaneousUploads,
                    accept: _vm.accept,
                    capture: _vm.capture
                  }
                },
                [_c("div")]
              )
            ],
            2
          )
        ]
      ),
      _vm.tips
        ? _c("div", { staticClass: "upload-tips" }, [
            _vm.tips.title
              ? _c("div", [_vm._v(_vm._s(_vm.tips.title))])
              : _vm._e(),
            _vm.tips.desc
              ? _c("span", [_vm._v(_vm._s(_vm.tips.desc))])
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }