<template>
  <div class="container">
    <CFieldItem @click.native="modifyMobile" title="修改手机号"></CFieldItem>

    <ExtendPopup contentWidth="90vw" ref="modifyMobile" title="更改手机号">
      <md-field>
        <md-input-item
          v-model.trim="params.userName"
          maxlength="11"
          placeholder="请输入真实姓名"
          clearable
        >
          <i slot="left" class="iconfont icon-geren fs22"></i>
        </md-input-item>

        <md-input-item
          v-model.trim="params.idNo"
          maxlength="18"
          placeholder="请输入身份证号码"
          clearable
        >
          <i slot="left" class="iconfont icon-idcard fs22"></i>
        </md-input-item>

        <md-input-item
          v-model.trim="params.mobileNew"
          maxlength="11"
          placeholder="请输入新的手机号"
          clearable
        >
          <i slot="left" class="iconfont icon-shoujihao fs22"></i>
        </md-input-item>

        <md-input-item
          v-model.trim="params.code"
          maxlength="6"
          placeholder="请输入短信验证码"
          clearable
        >
          <i slot="left" class="iconfont icon-duanxin fs22"></i>
          <!-- <md-button slot="right" type="text" inline size="small"
            >发送验证码</md-button > -->
          <SmsButton
            :params="params"
            :requestFn="sendChangeMobile"
            :verifyHooks="verifyHooks"
            :storageModel="false"
            @response="getResponseData"
            slot="right"
          ></SmsButton>
        </md-input-item>

        <div class="fs12 tl mgt20 mgb30 c-666">注：将同时更改APP中所填写的银行预留手机号</div>

        <div class="action-section">
          <md-button
            @click="handleCloseModifyMobile"
            type="primary"
            inline
            plain
            size="small"
            >取消</md-button
          >

          <md-button @click="handleSubmit" type="primary" inline size="small"
            >确认</md-button
          >
        </div>
      </md-field>
    </ExtendPopup>
  </div>
</template>

<script type="text/ecmascript-6">
import { sendChangeMobile, sendChangeMobileSmsCheck } from "@/api/repayment";
import ExtendPopup from "../carFinance/components/ExtendPopup";
import SmsButton from "./components/SmsButton";
import { Field, FieldItem, InputItem, Button } from "mand-mobile";

export default {
  name: "",
  components: {
    [Field.name]: Field,
    [FieldItem.name]: FieldItem,
    [InputItem.name]: InputItem,

    [Button.name]: Button,
    ExtendPopup,
    SmsButton,
  },
  data() {
    return {
      params: {
        mobileNew: "",
        idNo: "",
        userName: "",
        code: "",
      },
    };
  },
  computed: {},
  methods: {
    sendChangeMobile,
    verifyHooks() {
      if (this.params.mobileNew.length !== 11) {
        this.$createToast({
          txt: "请确认手机号",
          type: "correct",
        }).show();
        return false;
      }

      if (this.params.userName.length <= 1) {
        this.$createToast({
          txt: "请确认真实姓名",
          type: "correct",
        }).show();
        return false;
      }

      if (this.params.idNo.length !== 18) {
        this.$createToast({
          txt: "请确认身份证号",
          type: "correct",
        }).show();
        return false;
      }

      return true;
    },
    getResponseData(value) {
      this.params = { ...this.params, ...value };
    },
    handleSubmit() {
      sendChangeMobileSmsCheck({ ...this.params }).then((res) => {
        if (res.success) {
          if (res.data.isAgreement === "0") {
            this.$createToast({
              txt: "操作成功",
              type: "correct",
            }).show();
          } else if (res.data.isAgreement === "1") {
            // todo 还需要跳转到修改绑卡鉴权确认
            const { bankCardNo, bankMobile, userName } = res.data;
            const { historyId } = this.params;
            this.$router.push({
              name: "authentificationBindCard",
              query: {
                bankCardNo,
                bankMobile,
                userName,
                historyId,
              },
            });
          }
        }
      });
    },
    modifyMobile() {
      this.$refs.modifyMobile.show();
    },
    handleCloseModifyMobile() {
      this.$refs.modifyMobile.hide();
    },
  },
};
</script>

<style scoped lang="less">
.action-section {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 14px;
}
</style>
