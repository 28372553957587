var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.popMaskVisible,
            expression: "!popMaskVisible"
          }
        ],
        ref: "dragBox",
        staticClass: "icon-button guide-button-block",
        style: _vm.positionObject,
        on: {
          click: _vm.handleShowPopMask,
          touchstart: function($event) {
            return _vm.touchstartHandle("dragBox", $event)
          },
          touchmove: function($event) {
            return _vm.touchmoveHandle("dragBox", $event)
          },
          touchend: function($event) {
            return _vm.touchendHandle("dragBox", $event)
          }
        }
      }),
      _c("transition", { attrs: { name: "rotate" } }, [
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.popMaskVisible,
              expression: "popMaskVisible"
            }
          ],
          staticClass: "icon-button close-guide-button",
          style: _vm.positionObject,
          on: { click: _vm.handleHidePopMask }
        })
      ]),
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.popMaskVisible,
                expression: "popMaskVisible"
              }
            ],
            staticClass: "icon-button back-guide-button",
            style: _vm.positionObject,
            on: { click: _vm.handleBack }
          },
          [
            _c("span", { staticClass: "icon-button-text-desc" }, [
              _vm._v("返回上页")
            ])
          ]
        )
      ]),
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.popMaskVisible,
                expression: "popMaskVisible"
              }
            ],
            staticClass: "icon-button home-guide-button",
            style: _vm.positionObject,
            on: { click: _vm.handleBackHome }
          },
          [
            _c("span", { staticClass: "icon-button-text-desc" }, [
              _vm._v("返回首页")
            ])
          ]
        )
      ]),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.popMaskVisible,
            expression: "popMaskVisible"
          }
        ],
        staticClass: "pop-mask",
        on: { click: _vm.handleHidePopMask }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }