/*
 * @Descripttion: your project
 * @version: 1.0
 * @Author: CZK
 * @Date: 2021-09-15 10:24:11
 * @LastEditors: CZK
 * @LastEditTime: 2024-02-23 17:00:33
 */
import router from "@/router";

import NProgress from "nprogress";
import "nprogress/nprogress.css";

import { Dialog } from "cube-ui";

NProgress.inc(0.2);
NProgress.configure({ easing: "ease", speed: 500, showSpinner: false });

import store from "@/store";

const routerAllow = {
  realNameAuthentication: ["installmentLoan"],
  improveInformation: ["installmentLoan", "addBankCard", "result"],
  addBankCard: ["installmentLoan", "supportBank", "result", "faaResult"],
  result: ["installmentLoan", "result"],
};

router.beforeEach((to, from, next) => {
  NProgress.start();
  const routerAllowArray = routerAllow[from.name];
  if (
    !routerAllowArray ||
    (routerAllowArray && routerAllowArray.includes(to.name))
  ) {
    
    if(to.meta && to.meta.keepAlive) {
      store.commit('ADD_CACHE_VIEW', to)
      // store.dispatch('addCachedView', to)
    }
    next();
  } 
  else {
    next();
    NProgress.done();
    // console.log(from.name,'----',to.name)
    // Dialog.$create({
    //   type: "confirm",
    //   icon: "cubeic-alert",
    //   title: "您确定离开申请流程吗？",
    //   content: "离开将返回首页,继续点击申请按钮将恢复申请流程",
    //   confirmBtn: {
    //     text: "确定",
    //     active: true,
    //     disabled: false,
    //     href: "javascript:;",
    //   },
    //   cancelBtn: {
    //     text: "取消",
    //     active: false,
    //     disabled: false,
    //     href: "javascript:;",
    //   },
    //   onConfirm: () => {
    //     next({ name: "installmentLoan" });
    //   },
    //   onCancel: () => {},
    // }).show();
  }
});

router.afterEach(() => {
  NProgress.done();
});
