<template>
  <div v-if="goodsInfo" class="container">
    <!-- <tips-top v-if="tipsShow">
      <div @click="goGuidePage" class="flex flex-start mgr10 h30">
        <span style="color:rgba(87, 154, 241, 1)">
          关于申请操作、账单和付款，请戳这里
          <span class="iconfont icon-shuangjiantou fs14 mgr10"></span>
        </span>
      </div>

      <template slot="extra">
        <div @click="closeTips" class>
          <span class="iconfont icon-guanbi mgr10 fs22"></span>
        </div>
      </template>
    </tips-top>-->
    <tips-top v-if="userReceiptNum">
      <div @click="goLoanHistory" class="flex flex-start mgr10 h30">
        <span style="color: rgba(87, 154, 241, 1)">
          你在当前商户有{{ userReceiptNum }}个已完成订单
          <span>点击查看</span>
          <span class="iconfont icon-shuangjiantou fs14 mgr10"></span>
        </span>
      </div>
    </tips-top>

    <div class="product-title">商品信息</div>
    <div class="product-info">
      <div class="flex border-bottom-1px product-info-section">
        <div class="item-left">商户名称：</div>
        <div class="item-right">{{ goodsInfo.merchantName }}</div>
      </div>
      <div class="flex product-info-section">
        <div class="item-left">商品信息：</div>
        <div class="item-right">{{ otherInfo.goodsName }}</div>
      </div>
    </div>

    <div class="product-title">订单信息</div>
    <div class="product-info">
      <div class="flex border-bottom-1px product-info-section">
        <div class="item-left">订单金额：</div>
        <div class="item-right">{{ otherInfo.amount | yuan }}</div>
      </div>
      <div
        class="flex border-bottom-1px product-info-section"
        :class="{ active: canSelectStage }"
      >
        <div class="item-left">付款方式：</div>
        <div @click="showActive" class="item-right item-right-select">
          <div class="flex-1">{{ selectStage | stage }}</div>
          <span
            v-if="canSelectStage"
            :class="['iconfont', 'item-right-select-icon', 'icon-jiantou']"
          ></span>
        </div>
      </div>
      <!-- <div class="flex border-bottom-1px product-info-section">
        <div class="item-left">付款方式：</div>
        <div class="item-right">{{ typeDto }}</div>
      </div> -->
      <div class="flex product-info-section active">
        <div class="item-left">付款计划：</div>
        <div @click="viewRepaymentPlan" class="item-right item-right-select">
          <div class="flex-1">
            <div>{{ downPaymentAmount }}</div>
            <!-- <div class="item-right-desc">付款计划</div> -->
          </div>
          <span class="iconfont icon-jiantou item-right-select-icon"></span>
        </div>
      </div>
    </div>

    <div class="flex agreement-read-section">
      <cube-checkbox
        class="with-click"
        shape="square"
        :hollow-style="false"
        v-model="checked"
      >
        <a href="javascript:void()"></a>
      </cube-checkbox>

      <div class="flex-1 agreement-name-section">
        本人已阅读并同意
        <a
          class="agreement-link"
          href="https://huodong.jdz360.cn/agreement/1-豆乐派服务协议20230518.html"
          >《服务协议》</a
        >
        <!-- <a
          class="agreement-link"
          href="https://huodong.jdz360.cn/agreement/%E5%BA%94%E6%94%B6%E8%B4%A6%E6%AC%BE%E8%BD%AC%E8%AE%A9%E5%8D%8F%E8%AE%AE-%E6%95%99%E8%82%B2%E9%A1%B9%E7%9B%AE%E7%89%88%E6%9C%AC20201012.html"
          >《应收账款转让同意书》</a
        > -->
        <a
          class="agreement-link"
          href="https://huodong.jdz360.cn/agreement/隐私保护及授权书 20210129)(1).html"
          >《隐私保护及信息授权书》</a
        >
      </div>

      <p class="tips">
        温馨提示：<br/>
        提交申请后将进行实名认证环节，需上传<span>有效期大于3个月</span>的身份证正反面照片
      </p>
    </div>

    <div class="submit-section">
      <!-- handleSubmit -->
      <cube-button
        @click="handleSubmit"
        :class="{ submitting: submitting }"
        class="submit-btn"
        type="submit"
      >
        <cube-loading></cube-loading>提交申请
      </cube-button>
    </div>

    <!-- 指引 -->

    <div class="guide-section">
      <div class="guide-title">
        <span @click="goGuidePage">新手指引</span>
        <span class="line-between">|</span>
        <span @click="goAboutUsPage">关于我们</span>
      </div>
      <div class="guide-company">
        <div>广东金豆子数字科技有限公司</div>
        <div>Copyright &copy; 2019 All Rights Reserved.</div>
      </div>
    </div>

    <!-- 付款计划 -->

    <transition name="cube-action-sheet-fade">
      <cube-popup
        type="repayment-plan"
        :center="false"
        v-show="isVisibleMask"
        @mask-click="hideRepaymentPlan"
      >
        <transition name="cube-action-sheet-move">
          <div class="plan-content" v-show="isVisibleMask" @click.stop>
            <h1 class="cube-action-sheet-title border-bottom-1px">付款计划</h1>

            <div class="scroll-list-wrap">
              <cube-scroll ref="scroll" :data="[]">
                <div class="plan-list-section">
                  <div class="total-price">应付总额(元)</div>
                  <div class="total-amount">{{ totalAmount }}</div>

                  <!-- <div class="total-tate">待付总息费{{rateTotal}}元</div> -->

                  <div v-if="false" class="pan-tipes">
                    如需提前付款，需一次性偿付全部期数的息费
                  </div>

                  <div class>
                    <div
                      v-for="(item, index) in repaymentPlanList"
                      :key="index"
                      class
                    >
                      <div class="plan-item">
                        <div>{{ item.repayDate }}</div>
                        <!-- <div>应付总额：¥{{item.totalAmount}}</div> -->
                        <div>¥{{ item.repaymentAmount }}</div>
                      </div>
                      <!-- <div class="plan-item">
                        <div>应付本金</div>
                        <div>{{item.amount}}</div>
                      </div>

                      <div class="plan-item">
                        <div>应付息费</div>
                        <div>{{item.rateAmount}}</div>
                      </div>-->
                    </div>
                  </div>
                </div>
              </cube-scroll>
            </div>

            <div class="cube-action-sheet-space"></div>
            <div class="cube-action-sheet-cancel" @click="hideRepaymentPlan">
              <span>关闭</span>
            </div>
          </div>
        </transition>
      </cube-popup>
    </transition>
  </div>
</template>

<script type="text/ecmascript-6">
import { setToken } from "@/utils/auth";
import TipsTop from "@comp/TipsTop/TipsTop";
import {
  queryGoods,
  queryUserReceipt,
  getRepaymentData,
  applyStage,
  creditAgain,
  applyPass,
  getPersonVerifyUrl,
  getFaceToken,
  fadadaAuthentication,
} from "@/api/application";
import store from "@/store";

import { faceRedirectUrl } from "@/config/config";
import { nextProcess } from "./redirectAction";

import stopBack from "./mixin/stopBack";

export default {
  name: "",
  mixins: [stopBack],
  components: {
    TipsTop,
  },
  data() {
    return {
      isFirstEnter: true,
      tipsShow: false,
      goodsInfo: null,
      otherInfo: {},
      checked: true,
      active: 0,
      tipDialog:'',
      isVisibleMask: false,
      stageNumberList: [],
      submitting: false,
      repaymentPlanList: [],
      rateTotal: "",
      totalAmount: "",
      userReceiptNum: null,
    };
  },
  computed: {
    // 是否能够点击期数选择
    canSelectStage() {
      return !this.otherInfo.term;
    },
    selectStage() {
      let { active, stageNumberList } = this;
      return stageNumberList[active];
    },

    installmentList() {
      let { stageNumberList } = this;

      return stageNumberList.map((item) => {
        return { content: `${item}个月` };
      });
    },
    typeDto() {
      const {
        goodsInfo: { typeDtoList = [] },
      } = this;
      return (
        (typeDtoList &&
          typeDtoList.length &&
          typeDtoList[0] &&
          typeDtoList[0].dictPrompt) ||
        ""
      );
    },

    downPaymentAmount() {
      const [downPaymentItem = {}] = this.repaymentPlanList;
      let text1 = '', text2 = '';
      if(downPaymentItem.repayDate) {
        text1 = downPaymentItem.repayDate + ' | ';
      }
      if(downPaymentItem.repaymentAmount) {
        text2 = downPaymentItem.repaymentAmount + '元';
      }
      return (`${text1}${text2}`)
      // return (
      //   (downPaymentItem.totalAmount && `${downPaymentItem.totalAmount}元`) ||
      //   ""
      // );
    },
  },
  filters: {
    yuan(value) {
      if (!value) return "";
      return `${value}元`;
    },
    stage(value) {
      if (!value) return "";
      // return `${value}期`;
      return `按月支付 ( ${value}期 ) `;
    },
  },
  methods: {
    initPage() {
      return queryGoods()
        .then((res) => {
          if (res.success) {
            const [goodsInfo] = res.data;
            const { other = {} } = res;
            const { stageNumberList } = goodsInfo;

            this.goodsInfo = goodsInfo;
            this.otherInfo = other || {};
            if (other.term && other.term !== 0) {
              this.active = stageNumberList.indexOf(other.term.toString());
            } else {
              this.active = stageNumberList.length - 1;
            }

            this.stageNumberList = stageNumberList;

            if (stageNumberList.length > 0) {
              this.getRepaymentData();
            }
            this.queryUserReceipt();
          } else {
            this.$$createToast({
              txt: res.msg || (res.data && res.data.msg),
              type: "error",
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    queryUserReceipt() {
      queryUserReceipt().then((res) => {
        if (res.success) {
          this.userReceiptNum = res.data;
        }
      });
    },

    goGuidePage() {
      window.location.href =
        "https://mp.weixin.qq.com/s/iC4NQsbSBny3XASvCZ1m0g";
    },

    goAboutUsPage() {
      this.$router.push({
        name: "aboutUs",
      });
    },

    closeTips() {
      this.tipsShow = false;
    },

    goLoanHistory() {
      this.$router.push({
        name: "applicationLoanRepaymentHistory",
      });
    },
    showActive() {
      let { active, installmentList: data } = this;

      // 有选中期数，就禁止用户选择
      if (!this.canSelectStage) {
        return;
      }

      this.$createActionSheet({
        title: "请选择分期期数",
        active,
        data,
        onSelect: (item, index) => {
          this.active = index;

          this.getRepaymentData();
        },
        onCancel: () => {},
      }).show();
    },
    handleBefor(){
      const { checked } = this;
      if (!checked) {
        this.$createToast({
          type: "warn",
          txt: "请阅读并同意以上协议",
        }).show();
        return;
      }

    this.tipDialog =  this.$createDialog({
        type: 'alert',
        confirmBtn: {
          text: '我知道了',
          active: true
        },
        onConfirm: () => {
          this.handleSubmit()
        },
      }, (createElement) => {
        return [
          createElement('div', {
            'class': {
              'my-title': true
            },
            slot: 'title'
          }, [
            createElement('i', {
              'class': {
                'close-icon': true
              },
              on: {
                click: this.handleCloseIconClick // 点击事件绑定到 handleIconClick 方法
              }
            }),
            createElement('i', {
              'class': {
                'cubeic-alert': true,
                'warn-icon': true
              }
            }),
            createElement('p', '温馨提醒'),
            createElement('p', '在下一步人脸验证过程中，必须保证白色背景，建议背靠墙壁或空旷场地，不能出现宿舍物品等内容')
          ]),
          createElement('p', {
            'class': {
              'my-content': true
            },
            slot: 'content'
          }, '不符合要求需要重新验证。')
        ]
      })
      this.tipDialog.show()
    },
    handleCloseIconClick(){
      this.tipDialog.close()
    },
    handleSubmit() {
      const { checked, selectStage } = this;
      if (!checked) {
        this.$createToast({
          type: "warn",
          txt: "请阅读并同意以上协议",
        }).show();
        return;
      }

      this.submitting = true;

      applyStage({
        term: selectStage,
      })
        .then((res) => {
          if (res.success) {
            /**
             * SimpleContractNo("SimpleContractNo","单个手机情况"),
              NotYetAgree("NotYetAgree","对应订单还未开通协议"),
              NoThreeMonth("NoThreeMonth","上次申请还未超过3个月");
              ThreeMonthOver("ThreeMonthOver","该手机距上次申请超过3个月"),
              
             */
            const {
              data: { status, params },
              other: otherInfo,
            } = res;
            if (status === "SimpleContractNo" || status === "NotYetAgree") {
              nextProcess(otherInfo, { params });
            } else if (status === "NoThreeMonth") {
              this.dialog = this.$createDialog({
                type: "confirm",
                icon: "cubeic-alert",
                title: "是否确认提交申请",
                content: "确认则开放签约放款",
                confirmBtn: {
                  text: "确定",
                  active: true,
                  disabled: false,
                  href: "javascript:;",
                },
                cancelBtn: {
                  text: "取消",
                  active: false,
                  disabled: false,
                  href: "javascript:;",
                },
                onConfirm: () => {
                  nextProcess(otherInfo, { params });
                },
                onCancel: () => {},
              });
              this.dialog.show();
            } else if (status === "ThreeMonthOver") {
              // 做 认证入口
              if (otherInfo.faceType === "Fadada") {
                fadadaAuthentication({}).then((res) => {
                  if (res.success) {
                    window.location.href = res.data.verifyUrl;
                  }
                });
                return;
              } else if (otherInfo.faceType === "Faceplusplus") {
                //face ++ 认证
                getFaceToken({
                  status: otherInfo.status,
                })
                  .then((res) => {
                    if (res.success) {
                      const token = res.data;

                      window.location.href = `${faceRedirectUrl}${token}`;
                    }
                  })
                  .catch((err) => {
                    console.log("err", err);
                  });
              }
            }
          }
          setTimeout(() => {
            this.submitting = false;
          }, 300);
        })
        .catch((err) => {
          setTimeout(() => {
            this.submitting = false;
          }, 300);
        });
    },
    getRepaymentData() {
      const {
        // goodsInfo: { goodPrice: amount },
        otherInfo: { amount, aliasDictId },
        selectStage: term,
      } = this;

      const resetPlanData = () => {
        this.repaymentPlanList = [];
        this.rateTotal = "--";
        this.totalAmount = "--";
      };

      getRepaymentData({
        amount,
        term,
        aliasDictId,
      })
        .then((res) => {
          if (res.success) {
            const { loanPlanList, rateTotal, repaymentTotal } = res.data;

            this.repaymentPlanList = loanPlanList;
            this.totalAmount = repaymentTotal;
            this.rateTotal = rateTotal;
          } else {
            resetPlanData();
          }
        })
        .catch((err) => {
          console.log("err", err);
          resetPlanData();
        });
    },
    viewRepaymentPlan() {
      this.isVisibleMask = true;
      this.$nextTick(() => {});
    },
    hideRepaymentPlan() {
      this.isVisibleMask = false;
    },
  },
  mounted() {
    console.log("token", this.$route.query.token);
    // 相当于登录了
    const { token } = this.$route.query;
    if (token) {
      setToken(token);
      store.commit("SET_TOKEN", token);
    }
    this.initPage();
  },
  activated() {
    !this.isFirstEnter && this.initPage();
    this.isFirstEnter = false;
  },
  beforeRouteEnter(to, form, next) {
    next();
  },
};
</script>

<style>

.my-title {
  padding-top:20px;
}
.my-title p{
    /* font-weight: bold; */
    line-height: 24px;
    font-size: 15px;
    color: #333;
    padding: 0 10px;
  }

.my-content{
  font-size: 15px;
  color: #999;
  text-align: center;
}

.close-icon{
  width: 15px;
  height: 15px;
  background: url("../../assets/images/icon-close.png") no-repeat;
  background-size: contain;
  position: absolute;
  right: 10px;
  top: 10px;
}

.warn-icon{
  font-size: 30px !important;
  margin-bottom: 20px;
  display: inline-block;
  width: 30px;
  height: 30px;
  padding: 10px;
  box-sizing: content-box;
  border-radius: 50%;
  color: #fc9153;
  background-color: #f3f4f5;
}
</style>

<style scoped lang="less">
.container {
  background: #eef1f6;
}



.product-title {
  line-height: 38px;
  color: rgba(125, 125, 125, 1);
  text-indent: 16px;
  text-align: left;
  font-size: 17px;
  font-weight: 500;
}

.product-info {
  padding: 0 16px;
  background: #fff;
  font-weight: 500;
  font-size: 15px;
  .product-info-section {
    height: 50px;
    .item-left {
      color: rgba(102, 102, 102, 1);
    }
    .item-right {
      flex: 1;
      text-align: right;
      color: rgba(51, 51, 51, 1);
      &.item-right-select {
        display: flex;
        align-items: center;
        .item-right-select-icon {
          margin-right: -6px;
          margin-left: 4px;
          color: #cdcdd1;
        }
      }
      .item-right-desc {
        margin-top: 4px;
        font-weight: 400;
        font-size: 12px;
        font-family: PingFang-SC-Regular;
      }
    }
  }
}

.tips{
  color: rgba(102, 102, 102, 1);
  font-size: 14px;
  margin-left: 40px;
  text-align: left;
  margin-top: 10px;
  span{
    color: red;
  }
}

.agreement-read-section {
  padding: 30px 26px 40px 0px;
  font-size: 14px;
  font-family: SourceHanSansCN-Regular;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 1.2;
  .with-click {
    font-size: 16px;
    &.cube-checkbox {
      padding: 0 2px 0 16px;
    }
  }
  .agreement-name-section {
    text-align: left;

    .agreement-link {
      color: #579af1;
      &:active {
        opacity: 0.6;
      }
    }
  }
}

.submit-section {
  padding: 0 16px;
  font-size: 16px;
  .submit-btn {
    border-radius: 4px;
    font-weight: 400;
    position: relative;
  }
  .cube-loading {
    display: none;
    position: absolute;
    left: 34%;
    top: 50%;
    z-index: 9;
    transform: translate(-50%, -50%);
  }
  .submitting {
    pointer-events: none;
    border-color: transparent;
    cursor: not-allowed;
    opacity: 0.8;
    .cube-loading {
      display: block;
    }
  }
}

.spinner {
  display: block;
  position: fixed;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .spinner-icon {
    width: 4.8vw;
    height: 4.8vw;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border: solid 0.53333vw transparent;
    border-top-color: #29d;
    border-left-color: #29d;
    border-radius: 50%;
    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
  }
}

.guide-section {
  margin-top: 50px;
  padding-bottom: 20px;
  .guide-title {
    // height: 14px;
    font-size: 14px;
    color: #579af1;
    display: flex;
    justify-content: center;

    .line-between {
      margin: 0 24px;
      color: #999;
    }
  }
  .guide-company {
    margin-top: 30px;
    font-size: 10px;
    color: #999;
    div {
      color: inherit;
      margin-bottom: 4px;
    }
  }
}
</style>


<style lang="less" scoped>
.cube-action-sheet-fade-enter,
.cube-action-sheet-fade-leave-active {
  opacity: 0;
}

.cube-action-sheet-fade-enter-active,
.cube-action-sheet-fade-leave-active {
  transition: all 0.3s ease-in-out;
}

.cube-action-sheet-move-enter,
.cube-action-sheet-move-leave-active {
  transform: translate3d(0, 100%, 0);
}

.cube-action-sheet-move-enter-active,
.cube-action-sheet-move-leave-active {
  transition: all 0.3s ease-in-out;
}

.cube-action-sheet-cancel span,
.cube-action-sheet-item {
  &:active {
    background-color: rgba(0, 0, 0, 0.04);
  }
}
.plan-content {
  background: #fff;
}

.cube-action-sheet-title {
  padding-top: 16px;
  padding-bottom: 16px;
  color: #666;
  font-size: 16px;
}

.cube-action-sheet-space {
  height: 6px;
  background-color: rgba(37, 38, 45, 0.4);
}

.scroll-list-wrap {
  height: 60vh;
}

.plan-list-section {
  padding: 18px 0 6px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(102, 102, 102, 1);
  .total-amount {
    margin: 6px 0 18px;
    font-weight: bold;
    color: rgba(51, 51, 51, 1);
    font-size: 24px;
  }

  .total-tate {
    font-size: 12px;
    margin-bottom: 20px;
  }

  .pan-tipes {
    margin: 8px 0 20px;
    font-size: 12px;
  }

  .plan-item-title {
    background: #eef1f6;
  }

  .plan-item {
    height: 40px;
    padding: 0 16px;
    line-height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
