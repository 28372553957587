import { RouteView } from "@/components/layouts";

import InstallmentLoan from "@views/application/InstallmentLoan";

const AgreementView = (resolve) => {
  import("@views/application/AgreementView").then((module) => {
    resolve(module);
  });
};

import RealNameAuthentication from "@views/application/RealNameAuthentication";
import FaceRedirect from "@views/application/FaceRedirect";
import ImproveInformation from "@views/application/ImproveInformation";
import AddBankCard from "@views/application/AddBankCard";
import SupportBank from "@views/application/SupportBank";
import AgreementPayment from "@views/application/AgreementPayment";
import Result from "@views/application/Result";
import faaResult from "@views/application/faaResult";
import LoanDetails from "@views/application/LoanDetails";
import LoanRepaymentHistory from "@views/application/LoanRepaymentHistory";
import InterceptOrderResult from "@views/application/InterceptOrderResult";
import NextApply from "@views/application/nextApply"

const applicationRouter = {
  path: "/application",
  name: "application",
  redirect: "/application/installmentLoan",
  component: RouteView,
  meta: { title: "申请流程" },
  children: [
    {
      path: "installmentLoan",
      name: "installmentLoan",
      meta: { title: "分期申请" },
      component: InstallmentLoan,
    },
    {
      path: "agreementView",
      name: "agreementView",
      meta: { title: "协议", keepAlive: true },
      component: AgreementView,
    },
    {
      path: "realNameAuthentication",
      name: "realNameAuthentication",
      meta: { title: "实名认证" },
      component: RealNameAuthentication,
    },

    {
      path: "faceredirect",
      name: "faceredirect",
      meta: { title: "跳转中" },
      component: FaceRedirect,
    },
    {
      path: "improveInformation",
      name: "improveInformation",
      meta: { title: "基本信息" },
      component: ImproveInformation,
    },
    {
      path: "addBankCard",
      name: "addBankCard",
      meta: { title: "添加银行卡" },
      component: AddBankCard,
    },
    {
      path: "supportBank",
      name: "supportBank",
      meta: { title: "支持银行" },
      component: SupportBank,
    },
    {
      path: "agreementPayment",
      name: "agreementPayment",
      meta: { title: "开通协议支付" },
      component: AgreementPayment,
    },
    {
      path: "result",
      name: "result",
      meta: { title: "处理结果" },
      component: Result,
    },
    {
      path: "nextApply",
      name: "nextApply",
      meta: { title: "确认订单" },
      component: NextApply,
    },
    {
      path: "faaResult",
      name: "faaResult",
      meta: { title: "授权处理结果" },
      component: faaResult,
    },
    {
      path: "applicationLoanDetails",
      name: "applicationLoanDetails",
      component: LoanDetails,
      meta: {
        title: "订单详情",
      },
    },
    {
      path: "applicationLoanRepaymentHistory",
      name: "applicationLoanRepaymentHistory",
      component: LoanRepaymentHistory,
      meta: {
        title: "分期记录",
      },
    },
    {
      path: "interceptOrderResult",
      name: "interceptOrderResult",
      component: InterceptOrderResult,
      meta: {
        title: "订单结果",
      },
    },
    
  ],
};

export default applicationRouter;
