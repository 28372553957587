<template>
  <div class="container">
    <!-- 成功 -->
    <div v-if="showResultType === 'success'">
      <img class="resutl-icon" src="@assets/images/success.png" />
      <div class="result-title">签约成功</div>
      <div class="result-tips">恭喜，您已签约成功</div>

      <!-- <cube-button @click="handleFinish" class="handle-btn">完成</cube-button> -->
    </div>

    <div v-if="showResultType === 'successed'">
      <img class="resutl-icon" src="@/assets/images/logo.png" />
      <!-- <div class="result-title">付款提交成功</div> -->
      <div style="margin-top:20px;" class="result-tips">您已签约成功，无须再进行签约</div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: "",
  components: {},
  data() {
    return {
      showResultType: ""
    };
  },
  computed: {},
  methods: {
    handleFinish() {
      this.$router.push({
        name: "mine"
      });
    }
  },
  mounted() {
    const { showResultType } = this.$route.query;
    showResultType && (this.showResultType = showResultType);
  }
};
</script>

<style scoped lang="less">
.container {
  padding: 90px 16px 0;
  text-align: center;

  .resutl-icon {
    //   display: block;
    width: 64px;
    height: 64px;
  }

  .resutl-gif {
    width: 272px;
    height: 213px;
  }

  .result-title {
    margin: 20px 0 16px;
    font-size: 20px;
    // line-height: 28px;
  }

  .result-tips {
    font-size: 14px;
    line-height: 1.3;
    color: #666;
  }

  .handle-btn {
    border-radius: 4px;
    margin-top: 92px;
  }
}
</style>
