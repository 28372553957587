var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("md-result-page", {
        attrs: {
          "img-url": require("@/assets/images/commited.png"),
          text: "资料已提交",
          subtext: "请稍后留意审核结果"
        }
      }),
      _c(
        "div",
        { staticClass: "pd20" },
        [
          _c(
            "md-button",
            {
              staticClass: "mgt30",
              attrs: { type: "primary" },
              on: { click: _vm.handleBackHome }
            },
            [_vm._v("返回首页")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }