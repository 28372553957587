const state = {
  cacheView: [],
};

const mutations = {
  ADD_CACHE_VIEW(state, view) {
    if (state.cacheView.includes(view.name)) {
      return;
    }
    state.cacheView.push(view.name);
  },
  CLEAR_CACHE_VIEW(state) {
    state.cacheView = [];
  },
};

const actions = {
  addCachedView({ commit }, view) {
    commit("ADD_CACHE_VIEW", view);
  },
  clearCacheView({ commit }) {
    commit("CLEAR_CACHE_VIEW");
  },
};

export default {
  state,
  mutations,
  actions,
};
