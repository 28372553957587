var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "spinner", style: _vm.style }, [
    _c("div", { staticClass: "bounce1" }),
    _c("div", { staticClass: "bounce2" }),
    _c("div", { staticClass: "bounce3" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }