// 已有全局filter
export default {
  filters: {
    firstNameFilter(el) {
      if (!el || typeof el !== "string") return "";

      return el.substring(0, 1);
    },
  },

  mobileFilter(el) {
    if (!el) return;

    let charArr = el.split("");

    let result = charArr.reduce((arr, next, index) => {
      index < 3 || index > 7 ? arr.push(next) : arr.push("*");
      return arr;
    }, []);

    return result.join("");
  },
};
