var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "tips-top",
        [
          _vm._v("\n    请添加本人名下的储蓄卡\n    "),
          _c("template", { slot: "extra" }, [
            _c(
              "div",
              {
                staticClass: "support-bank",
                on: { click: _vm.supportBankPage }
              },
              [_vm._v("支持银行")]
            )
          ])
        ],
        2
      ),
      _c("div", { staticClass: "fill-area" }, [
        _c("div", { staticClass: "fill-item border-bottom-1px" }, [
          _c("div", { staticClass: "fill-name" }, [_vm._v("姓ㅤㅤ名")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name"
              }
            ],
            staticClass: "fill-input no-modify-input",
            attrs: {
              readonly: "",
              type: "text",
              maxlength: "8",
              placeholder: "请输入姓名"
            },
            domProps: { value: _vm.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.name = $event.target.value
              }
            }
          })
        ]),
        _c("div", { staticClass: "fill-item border-bottom-1px" }, [
          _c("div", { staticClass: "fill-name" }, [_vm._v("预留手机")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.mobile,
                expression: "mobile",
                modifiers: { trim: true }
              }
            ],
            staticClass: "fill-input",
            attrs: {
              type: "tel",
              maxlength: "11",
              placeholder: "请输入预留手机号"
            },
            domProps: { value: _vm.mobile },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.mobile = $event.target.value.trim()
              },
              blur: function($event) {
                return _vm.$forceUpdate()
              }
            }
          })
        ]),
        _c("div", { staticClass: "fill-item border-bottom-1px" }, [
          _c("div", { staticClass: "fill-name" }, [_vm._v("银行卡号")]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.cardNo,
                expression: "cardNo"
              }
            ],
            staticClass: "fill-input",
            attrs: {
              type: "tel",
              maxlength: "19",
              placeholder: "请输入银行卡号"
            },
            domProps: { value: _vm.cardNo },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.cardNo = $event.target.value
              }
            }
          })
        ]),
        _c("div", { staticClass: "fill-item border-bottom-1px" }, [
          _c("div", { staticClass: "fill-name" }, [_vm._v("验证码")]),
          _c("div", { staticClass: "code-area flex" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.smsCode,
                  expression: "smsCode"
                }
              ],
              staticClass: "fill-input flex-1",
              attrs: {
                type: "tel",
                placeholder: "请输入验证码",
                maxlength: "6"
              },
              domProps: { value: _vm.smsCode },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.smsCode = $event.target.value
                }
              }
            }),
            _c(
              "div",
              {
                staticClass: "code-btn",
                class: { disabled: _vm.state.smsSendBtn },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.getCaptcha($event)
                  }
                }
              },
              [
                _vm._v(
                  "\n          " +
                    _vm._s(
                      _vm.state.smsSendBtn
                        ? _vm.state.time + "s后重发"
                        : "发送验证码"
                    ) +
                    "\n        "
                )
              ]
            )
          ])
        ])
      ]),
      _c(
        "div",
        { staticClass: "flex agreement-read-section" },
        [
          _c(
            "cube-checkbox",
            {
              staticClass: "with-click",
              attrs: { shape: "square", "hollow-style": false },
              model: {
                value: _vm.hasRead,
                callback: function($$v) {
                  _vm.hasRead = $$v
                },
                expression: "hasRead"
              }
            },
            [_c("a", { attrs: { href: "javascript:void()" } })]
          ),
          _c("div", { staticClass: "flex-1 agreement-name-section" }, [
            _vm._v("\n      本人已阅读并同意\n      "),
            _c(
              "a",
              {
                staticClass: "agreement-link",
                on: { click: _vm.showAgreementDialog }
              },
              [_vm._v("《支付开通及扣款授权确认书》")]
            )
          ])
        ],
        1
      ),
      _c("c-alert", { attrs: { title: _vm.errorMessages } }),
      _c(
        "div",
        { staticClass: "submit-section" },
        [
          _c(
            "c-button",
            {
              staticClass: "submit-btn",
              attrs: { disabled: _vm.submitting, type: "submit" },
              on: { click: _vm.handleSubmit }
            },
            [_vm._v("下一步")]
          )
        ],
        1
      ),
      _c("div", {
        attrs: { hidden: "" },
        domProps: { innerHTML: _vm._s(_vm.target) }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }