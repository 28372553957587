<template>
  <div class="container">
    <div class="total-repayment-section">
      <div>全部应付总额(元)</div>
      <div class="repayment-amount">{{totalReturn}}</div>
    </div>
    <div class="white-space"></div>
    <div>
      <div class="bill-section-tabbar border-bottom-1px">
        <div @click="selectBillBar(0)" :class="{'selected': selectedQutstanding}">未付账单</div>
        <div @click="selectBillBar(1)" :class="{'selected': selectHistorical}">历史账单</div>
        <!-- <span class="tabbar-slider"></span> -->
      </div>

      <!-- <cube-tab-bar
        v-model="selectBillBarIndex"
        showSlider
        :useTransition="true"
        ref="tabNav"
        :data="tabLabels"
      ></cube-tab-bar>-->

      <cube-slide
        ref="slide"
        @change="changeBillPage"
        @scroll="scrollBillPage"
        :initialIndex="selectBillBarIndex"
        :loop="false"
        :speed="600"
        :showDots="false"
        :autoPlay="false"
        :options="slideOptions"
      >
        <cube-slide-item class="bill-list qutstanding-bill">
          <cube-scroll>
            <c-spin
              :spinning="true"
              class="mgt40"
              v-if="!qutstandingBillLoaded"
              tip="Loading..."
              size="large"
            ></c-spin>
            <template v-else-if="qutstandingBillLoaded && qutstandingBill.length !== 0">
              <div
                v-for="(item,index) in qutstandingBill"
                :key="index"
                class="qutstanding-bill-item border-bottom-1px"
              >
                <mine-checkbox :index="index" :idx.sync="selectItemIndex" :value="item.checked"></mine-checkbox>
                <div class="flex-1 flex">
                  <div class="flex-1 bill-item-info">
                    <div class="bill-item-product">{{item.desc}}</div>
                    <div class="bill-item-date">{{item.loanDate}}</div>
                  </div>
                  <div @click="toBillDetails(item.receiptId)" style="text-align:right;">
                    <div class="bill-item-notreturn">￥{{item.notReturn}}</div>
                    <div>
                      <span>详情</span>
                      <span class="iconfont icon-jiantou item-right-select-icon"></span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div v-else-if="qutstandingBill.length === 0 && qutstandingBillLoaded">
              <img class="empty-list" src="@/assets/images/empty.png" alt />
              <div class="c-666 mgt10">暂无数据</div>
            </div>
          </cube-scroll>
        </cube-slide-item>

        <cube-slide-item class="bill-list historical-bill">
          <cube-scroll>
            <c-spin class="mgt40" v-if="!historicalBillLoaded" tip="Loading..." size="large"></c-spin>
            <template v-else-if="historicalBillLoaded && historicalBill.length !== 0">
              <div
                v-for="(item,index) in historicalBill"
                :key="index"
                class="historical-bill-item border-bottom-1px"
              >
                <div class="flex-1 flex">
                  <div class="flex-1 bill-item-info">
                    <div class="bill-item-product">{{item.desc}}</div>
                    <div class="bill-item-date">{{item.loanDate}}</div>
                  </div>
                  <div @click="toBillDetails(item.receiptId)" class="bill-item-status">已结清</div>
                  <span class="iconfont icon-jiantou item-right-select-icon"></span>
                </div>
              </div>
            </template>
            <div v-if="historicalBill.length === 0 && historicalBillLoaded">
              <img class="empty-list" src="@/assets/images/empty.png" alt />
              <div class="c-666 mgt10">暂无数据</div>
            </div>
          </cube-scroll>
        </cube-slide-item>
      </cube-slide>
    </div>

    <!-- 底部栏 -->
    <transition name="slide1">
      <div v-if="selectedQutstanding" class="qutstanding-bottom-bar flex border-top-1px">
        <mine-checkbox class="with-click" :value="true">已选金额</mine-checkbox>
        <div class="pay-bill-amount">￥{{repaymentAmount}}</div>
        <div @click="toRepayment" class="pay-bill-btn" :class="repaymentBtnClass">立即付款</div>
      </div>
    </transition>
  </div>
</template>

<script type="text/ecmascript-6">
import { userBill } from "@/api/repayment";
import { Slide } from "cube-ui";
import MineCheckbox from "@comp/Checkbox/CheckboxRadio";

export default {
  name: "myBill",
  components: {
    MineCheckbox,
    CubeSlide: Slide
  },
  data() {
    return {
      tabLabels: [
        {
          label: 0
        },
        {
          label: 1
        }
      ],
      selectItemIndex: NaN,
      selectBillBarIndex: 0,
      slideOptions: {
        listenScroll: false,
        probeType: 0,
        /* lock y-direction when scrolling horizontally and  vertically at the same time */
        directionLockThreshold: 0
      },
      totalReturn: "",
      qutstandingBill: [],
      qutstandingBillLoaded: false,
      historicalBill: [],
      historicalBillLoaded: false
    };
  },
  computed: {
    selectedQutstanding() {
      return this.selectBillBarIndex === 0;
    },
    selectHistorical() {
      return this.selectBillBarIndex === 1;
    },
    repaymentBtnClass() {
      return {
        disabled: this.hasSelectItem
      };
    },
    hasSelectItem() {
      return isNaN(this.selectItemIndex);
    },

    selectItemInfo() {
      const [selectItem] = this.qutstandingBill.filter(ele => {
        return ele.checked === true;
      });

      return selectItem;
    },
    repaymentAmount: {
      get() {
        if (this.selectItemInfo) {
          return this.selectItemInfo.notReturn;
        } else {
          return "00.00";
        }
      },
      set() {}
    }
  },

  methods: {
    selectBillBar(current) {
      this.selectBillBarIndex !== current &&
        (this.selectBillBarIndex = current);
    },
    changeBillPage(current) {
      this.selectBillBarIndex = current;
    },
    scrollBillPage(pos) {
      console.log(pos);
      // const x = Math.abs(pos.x);
      // const tabItemWidth = this.$refs.tabNav.$el.clientWidth;
      // const slideScrollerWidth = this.$refs.slide.slide.scrollerWidth;
      // const deltaX = (x / slideScrollerWidth) * tabItemWidth;
      // this.$refs.tabNav.setSliderTransform(deltaX);
    },

    toBillDetails(receiptId) {
      this.$router.push({
        name: "billDetails",
        query: {
          receiptId
        }
      });
    },

    toRepayment() {
      const { receiptId } = this.selectItemInfo;
      this.toBillDetails(receiptId);
      // this.$createDialog({
      //   type: "confirm",
      //   icon: "cubeic-alert",
      //   content: "该笔订单包含多笔分期记录，确定要全部付清吗？",
      //   confirmBtn: {
      //     text: "确定",
      //     active: true,
      //     disabled: false,
      //     href: "javascript:;"
      //   },
      //   cancelBtn: {
      //     text: "取消",
      //     active: false,
      //     disabled: false,
      //     href: "javascript:;"
      //   },
      //   onConfirm: () => {
      //  const { receiptId } = this.selectItemInfo;
      //     this.$router.push({
      //       name: "repayment",
      //       query: {
      //         receiptId,
      //         isAll: true
      //       }
      //     });
      //   }
      // }).show();
    }
  },
  watch: {
    selectItemIndex: {
      handler(newV) {
        this.qutstandingBill.forEach((ele, index) => {
          if (index == newV) {
            ele.checked = true;
          } else {
            ele.checked = false;
          }
        });
      },
      immediate: false
    }
  },

  activated() {
    userBill({
      isHistory: false
    }).then(res => {
      if (res.success) {
        const { xingShuaiUserBillInfoList, totalReturn } = res.data;

        xingShuaiUserBillInfoList.forEach((ele, index) => {
          if (index === this.selectItemIndex) {
            ele.checked = true;
          } else {
            ele.checked = false;
          }
          ele.desc = `${ele.productName} [${ele.termNow}/${ele.termNumber}]`;
        });

        if (xingShuaiUserBillInfoList.length !== 0 && this.hasSelectItem) {
          this.selectItemIndex = 0;
        }

        this.qutstandingBill = xingShuaiUserBillInfoList;
        this.totalReturn = totalReturn;
        this.qutstandingBillLoaded = true;
      }
    });

    userBill({
      isHistory: true
    }).then(res => {
      if (res.success) {
        const { xingShuaiUserBillInfoList, totalReturn } = res.data;
        xingShuaiUserBillInfoList.forEach(ele => {
          ele.desc = `${ele.productName} [${ele.termNow}/${ele.termNumber}]`;
        });
        this.historicalBill = xingShuaiUserBillInfoList;
        this.totalReturn = totalReturn;
        this.historicalBillLoaded = true;
      }
    });
  }
};
</script>

<style>
.cube-dialog-content-def {
  text-align: center;
}
</style>


<style scoped lang="less">
.container {
  .total-repayment-section {
    height: 130px;
    padding-top: 30px;
    background: #579af1;
    text-align: center;
    color: #fff;

    .repayment-amount {
      margin-top: 18px;
      font-size: 40px;
      font-weight: 500;
      // font-family:DINPro-Medium;
    }
  }

  .white-space {
    height: 10px;
    background: #eef1f6;
  }

  .bill-section-tabbar {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 60px;
    color: #999;
    font-family: SourceHanSansCN-Regular;
    div {
      position: relative;
      flex: 1;
      height: 100%;
      line-height: 60px;
      &:active {
        background: rgba(0, 0, 0, 0.04);
      }
      &.selected {
        color: #333;
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 40px;
          height: 2px;
          background: #4dd8bb;
        }
      }
    }

    .tabbar-slider {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 50%;
      background: #4dd8bb;
    }
  }

  .bill-list {
    padding: 0 16px;
    height: calc(100vh - 200px);
    overflow-y: scroll;

    &.qutstanding-bill {
      padding-left: 0;
      padding-bottom: 60px;
    }

    .qutstanding-bill-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 74px;
      font-size: 12px;
      color: #666;
    }

    .historical-bill-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 74px;
      font-size: 12px;
      color: #666;
    }

    .bill-item-info {
      // overflow: hidden;
      margin-left: 4px;
      text-align: left;
    }

    .bill-item-product {
      white-space: pre-wrap;
      font-size: 16px;
      color: #333;
    }

    .bill-item-date {
      margin-top: 10px;
    }

    .bill-item-notreturn {
      font-size: 16px;
      color: #333;
      margin-bottom: 8px;
    }

    .bill-item-status {
      line-height: 74px;
      min-width: 60px;
      padding-right: 10px;
      text-align: right;
      // background: cornflowerblue;
    }

    .item-right-select-icon {
      font-size: 12px;
      margin-right: -4px;
      color: #cdcdd1;
    }
  }

  .empty-list {
    width: 248px;
    height: 184px;
    margin-top: 50px;
  }

  .qutstanding-bill {
  }

  .with-click {
    .checkbox-label {
      margin-left: 10px;
    }
  }

  .qutstanding-bottom-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 60px;
    line-height: 60px;
    background: #fff;

    .pay-bill-amount {
      flex: 1;
      text-align: left;
      color: #f47636;
      font-size: 20px;
    }
    .pay-bill-btn {
      background: #579af1;
      min-width: 110px;
      color: rgba(238, 241, 246, 1);

      &:active {
        opacity: 0.7;
      }
    }
  }
}

&.slide1-enter-active {
  transition: all 0.1s ease-in;
}

&.slide1-leave-active {
  transition: all 0.2s ease-in;
}

&.slide1-enter,
&.slide1-leave-to {
  opacity: 0.5;
  transform: translate3d(0, 100%, 0);
}
</style>
