var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cube-upload" },
    [
      _vm._t("default", [
        _c(
          "div",
          { staticClass: "cube-upload-def clear-fix" },
          [
            _vm._l(_vm.files, function(file, i) {
              return _c("upload-file", {
                key: i,
                attrs: { file: file },
                on: { click: _vm.fileClick }
              })
            }),
            _c("upload-btn", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShowBtn,
                  expression: "isShowBtn"
                }
              ],
              attrs: {
                multiple: _vm.multiple,
                accept: _vm.accept,
                capture: _vm.capture
              }
            })
          ],
          2
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }