import { axios } from "@/utils/requestRepayment";

/**
 * 个人中心
 * @param {*} data
 */

export function userCenter(data = {}) {
  return axios({
    url: "/repayment/userCenter.do",
    method: "post",
    data,
  });
}

/**
 * 订单列表
 *  @param {*} data  Boolean isHistory
 */

export function userBill(data = {}) {
  return axios({
    url: "/repayment/userBill.do",
    method: "post",
    data,
  });
}

/**
 * 账单详情接口
 * @param {*} query  receiptId
 */

export function userBillInfo(query) {
  return axios({
    url: "/repayment/userBillInfo.do",
    method: "get",
    params: query,
  });
}

/**
 * 借款详情
 * @param {*} data  receiptId
 */

export function queryReceiptInfo(data = {}) {
  return axios({
    url: "/repayment/queryReceiptInfo.do",
    method: "post",
    data,
  });
}

/**
 * 获取付款信息
 * @param {*} query 参数Long receiptId, Boolean isAll, Long planId
 */

export function xingShuaiSureRepayment(data) {
  return axios({
    url: "/repayment/xsconfirmRepayment.do",
    method: "post",
    data,
  });
}

/**
 * 账单详情接口
 * @param {*} query 参数 Long planId
 */

export function xingShuaiRepaymentInfo(query) {
  return axios({
    url: "/repayment/xingShuaiRepaymentInfo.do",
    method: "get",
    params: query,
  });
}

/**
 * 借款详情里面的合同
 * @param {*} query 参数Long receiptId
 */

export function qeuryContract(query) {
  return axios({
    url: "/repayment/qeuryContract.do",
    method: "get",
    params: query,
  });
}

/**
 * 借款记录列表
 * @param {*} query
 */

export function queryPreLoanApplyPagred(query) {
  return axios({
    url: "/repayment/queryPreLoanApplyPagred.do",
    method: "get",
    params: query,
  });
}

/**
 * 付款接口
 * @param {*} data 参数Long receiptId, Boolean isAll, Long planId
 */
export function xingShuaiRepayment(data = {}) {
  return axios({
    // url: "/repayment/xingShuaiRepayment.do",
    url: "/repayment/xsRepayment.do",
    method: "post",
    data,
  });
}

/**
 * 确认还款短信
 * @param {*} data
 */
export function sendSMSCode(data = {}) {
  return axios({
    url: "/repayment/sendSMSCode.do",
    method: "post",
    data,
  });
}
/**
 * 查询支付返回结构
 * @param {*} query  batchId string
 */
export function repaymentInquiry(query) {
  return axios({
    url: "/repayment/repaymentInquiry.do",
    method: "get",
    params: query,
  });
}

/**
 * 发送短信
 * @param {*} data
 */
export function queryContractConvergence(data = {}) {
  return axios({
    url: "/repayment/queryContractConvergence.do",
    method: "post",
    data,
  });
}

/**
 *  确认短信验证码
 * @param {*} data 参数String smsCode
 */
export function querySigning(data = {}) {
  return axios({
    url: "/repayment/querySigning.do",
    method: "post",
    data,
  });
}

/**
 * 我的银行卡
 * @param {*} query  receiptId
 */

export function querybankCard(query) {
  return axios({
    url: "/repayment/querybankCard.do",
    method: "get",
    params: query,
  });
}



/**
 * 发送修改手机号短信
 * @param {*} data
 */
export function sendChangeMobile(data = {}) {
  return axios({
    url: "/repayment/sendChangeMobile.do",
    method: "post",
    data,
  });
}

/**
 * 确认提交修改验证码
 * @param {*} data 
 */
export function sendChangeMobileSmsCheck(data = {}) {
  return axios({
    url: "/repayment/sendChangeMobileSmsCheck.do",
    method: "post",
    data,
  });
}

/**
 * 修改该手机号发送协议支付验证码
 * @param {Object} phone
 */

export function changeMobilePayCode(data = {}) {
  return axios({
    url: "/repayment/changeMobilePayCode.do",
    method: "post",
    data,
  });
}

/**
 *  修改手机号开通协议支付
 * @param {*} data  String smsCode, String smsId
 */
export function changeMobile(data = {}) {
  return axios({
    url: "/repayment/changeMobile.do",
    method: "post",
    data,
  });
}
