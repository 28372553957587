import Vue from "vue";
import axios from "axios";
import store from "@/store";
import router from "@/router";
import { VueAxios } from "./axios";
import QS from "qs";

import { Toast } from "cube-ui";
Vue.use(Toast);

import { getToken } from "@/utils/auth";

export const transformRequestFn = (data = {}, headers) => {
  if (headers["Content-Type"] === "application/json;charset=UTF-8") {
    return JSON.stringify(data);
  }
  return QS.stringify(data, { arrayFormat: "brackets" });
};

export const paramsSerializer = (params) => {
  return QS.stringify(params, { arrayFormat: "brackets" });
};

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 30000,
  withCredentials: false,
  transformRequest: [transformRequestFn],
  paramsSerializer,
});

const err = (error) => {
  if (error.response) {
    const statusText = error.response.statusText;

    if (error.response.status === 401 || error.response.status === 403) {
      router.push({
        name: "expire",
      });
    } else if (error.response.status === 404) {
      Toast.$create({
        txt: "404 Not Found",
        type: "error",
        time: 1200,
      }).show();
    } else if (error.response.status === 500) {
      Toast.$create({
        txt: "服务器错误",
        type: "error",
        time: 1200,
      }).show();
    } else {
      let tipText = (statusText=='null'||statusText==null)?'操作失败':statusText
      Toast.$create({
        txt: "error",
        type: `error ${tipText}`,
        time: 1200,
      }).show();
    }
  } else {
    Toast.$create({
      txt: "error",
      type: `error`,
      time: 1200,
    }).show();
  }
  return Promise.reject(error);
};

// request interceptor
service.interceptors.request.use((config) => {
  const token = store.getters.token || getToken();

  if (token) {
    config.headers["Authorization"] = `xsjy_${token}`;
  }

  return config;
}, err);

export const handleErrorMsg = (res) => {
  return (
    (typeof res.data === "string" && res.data) ||
    (Object.prototype.toString.call(res.data) === "[object Object]" &&
      typeof res.data.msg === "string" &&
      res.data.msg.length < 40 &&
      res.data.msg) ||
    res.message ||
    res.msg ||
    res.errMsg ||
    "Error"
  );
};

service.interceptors.response.use((response) => {
  const res = response.data.response || response.data;

  console.log("request ajax 返回 ", res);

  if (res && res.data && res.data.code == "541") {
    router.replace({
      name: "installmentLoan",
    });
    Toast.$create({
      txt: "请勿重复提交信息",
      type: `warn`,
      time: 3000,
    }).show();
    return;
  } else if (res && res.data && res.data.code == "401") {
    router.push({
      name: "expire",
    });

    return;
  }

  if (res.success === true) {
    return res;
  } else if (res.success === false) {
    Toast.$create({
      txt: handleErrorMsg(res),
      type: "warn",
      time: 4000,
    }).show();
    return res;
  } else {
    // TODO 图片流 文件流捕获
    if (res instanceof Blob) {
      return res;
    }
    return Promise.reject(new Error(res.message || "Error"));
  }
}, err);

const installer = {
  vm: {},
  install(Vue, router = {}) {
    Vue.use(VueAxios, router, service);
  },
};

export { installer as VueAxios, service as axios };
