/* eslint-disable */
export const v_Regular = {
  tel: /^\d{7,12}$/,
  ///^((0[1-9]\d{1,2})|852|853|886|00852|00853|00886)(|-)\d{7,8}(-\d{1,4})?$/,  //固话
  mobile: /^1((3[0-9])|(4[5,7])|(5[0-3,5-9])|(7[0-9])|(8[0-9]))\d{8}$/, //手机
  money: /^(([1-9][0-9]*)|0)(\.\d{1,2})?$/, ///^\+?[1-9][0-9]*\.\d{2}$/,
  minmoney: /^\-?(([1-9][0-9]*)|0)(\.\d{1,2})?$/,
  email: /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/,
  url: /(http|https):\/\/[0-9a-zA-Z\-]+(\.[0-9a-zA-Z\-]+)+(:\d+)?(\/[^\/]+)*[\/]?/i,
  myCard: /^((1[1-5])|(2[1-3])|(3[1-7])|(4[1-6])|(5[0-4])|(6[1-5])|71|(8[12])|91)\d{4}(((((19|20)((\d{2}(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(\d{2}(0[13578]|1[02])31)|(\d{2}02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[48])0229)))|20000229)\d{3}(\d|X|x))|(((\d{2}(0[13-9]|1[012])(0[1-9]|[12]\d|30))|(\d{2}(0[13578]|1[02])31)|(\d{2}02(0[1-9]|1\d|2[0-8]))|(([13579][26]|[2468][048]|0[48])0229))\d{3}))$/,
  postcode: /^[0-8]\d{5}$/,
  userName: /^[\u4E00-\u9FA5a-zA-Z][\u4E00-\u9FA5a-zA-Z0-9_\s-,-.]*$/, //加空格、·、.,
  nameZhEn: /^([\u4e00-\u9fa5]|[a-zA-Z]|·)+$/,
  notSpecial: /^([\u4e00-\u9fa5]|[a-zA-Z0-9]| )+$/, //验证特殊字符
  canSpecial: /^([\u4e00-\u9fa5]|[a-zA-Z0-9]|\,|\.|\:|\ |\;|\"|\'|\?|\!|\，|\。|\：|\；|\“|\”|\‘|\’|\！|\？|\s|\、)*$/, //验证特殊字符
  number: /^[1-9][0-9]*$/,
  enUserName: /^[a-zA-Z]{1}[0-9a-zA-Z_]{1,}$/,
  dateTime: /^(?:19|20)[0-9][0-9]-(?:(?:0[1-9])|(?:1[0-2]))-(?:(?:[0-2][1-9])|(?:[1-3][0-1])) (?:(?:[0-2][0-3])|(?:[0-1][0-9])):[0-5][0-9]:[0-5][0-9]$/,
  float: /^[0-9]+(\.[0-9]+)?$/, //浮点类型,涵正整数和小数
  float1: /^\-?[0-9]+(\.[0-9]+)?$/, //浮点类型,涵正负整数和小数
  float2: /^[0-9]+(\.[0-9]+)?$/, //浮点类型,纯小数,不能大于1
  passport: /^1[45][0-9]{7}$|G[0-9]{8}$|E[0-9]{7,8}$|P[0-9]{7}$|S[0-9]{7,8}$|D[0-9]+$/, //护照加E开头
  hkongMacao: /^[HMhm]{1}([0-9]{10}|[0-9]{8})$/, // 港澳通行证
  zh: /^[\u4e00-\u9fa5]+$/,
  en: /^[a-zA-Z]+$/,
  pwd: /^(?=[a-zA-Z])(?=.*?[0-9])[a-zA-Z0-9]{6,20}$/, //验证密码
  userCode: /^[0-9a-zA-Z]+$/, //英文数字
  address: /^[\u4e00-\u9fa5_a-zA-Z0-9,.;，。# ]+$/, //地址
  account: /^[0-9a-zA-Z_-]+$/, //英文数字_-
  num2point: /^[0-9]+([.]{1}[0-9]{1,2})?$/, // 小数点后2位
  proportion: /^([1-9][0-9]?([.]{1}[0-9]{1,2})?|0\.[1-9][0-9]|0\.0[1-9])$/, // 小数点后2位且大于0，小于100
  proportion2: /^(\d{1,2}([.]{1}[0-9]{1,2})?)$/, // 小数点后2位且大于等于0，小于100
  companyName: /^[\u4e00-\u9fa5_a-zA-Z0-9,.;，。 &]+$/, // 单位名称或企业名称
  displaceDays: /^((-1)|(0)|(1))$/, //放款位移日
  fixedDays: /^([1-9]|1\d|2[0-8])$/, // 固定日必须是整数，不超过28号
  nonnegativeNum: /^([1-9]\d*|0)$/, //非负整数
  times: /^[1-9](\.\d{1,2})?$/ //1位整数，最多2位小数
};

export function vailPhone(number) {
  let flag = false;
  let myreg = /^(((13[0-9]{1})|(14[0-9]{1})|(17[0-9]{1})|(15[0-3]{1})|(15[5-9]{1})|(19[0-9]{1})|(18[0-9]{1}|166|147))+\d{8})$/;
  if (number.length != 11) {
    flag = false;
  } else if (!myreg.test(number)) {
    flag = false;
  } else {
    flag = true;
  }
  return flag;
}

/*
 * 身份证15位编码规则：dddddd yymmdd xx p
 * dddddd：6位地区编码
 * yymmdd: 出生年(两位年)月日，如：910215
 * xx: 顺序编码，系统产生，无法确定
 * p: 性别，奇数为男，偶数为女
 *
 * 身份证18位编码规则：dddddd yyyymmdd xxx y
 * dddddd：6位地区编码
 * yyyymmdd: 出生年(四位年)月日，如：19910215
 * xxx：顺序编码，系统产生，无法确定，奇数为男，偶数为女
 * y: 校验码，该位数值可通过前17位计算获得
 *
 * 前17位号码加权因子为 Wi = [ 7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2 ]
 * 验证位 Y = [ 1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2 ]
 * 如果验证码恰好是10，为了保证身份证是十八位，那么第十八位将用X来代替
 * 校验位计算公式：Y_P = mod( ∑(Ai×Wi),11 )
 * i为身份证号码1...17 位; Y_P为校验码Y所在校验码数组位置
 */
export function validateIdCard(idCard) {
  //15位和18位身份证号码的正则表达式
  var regIdCard = /^(^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$)|(^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[Xx])$)$/;

  //如果通过该验证，说明身份证格式正确，但准确性还需计算
  if (regIdCard.test(idCard)) {
    if (idCard.length == 18) {
      var idCardWi = new Array(
        7,
        9,
        10,
        5,
        8,
        4,
        2,
        1,
        6,
        3,
        7,
        9,
        10,
        5,
        8,
        4,
        2
      ); //将前17位加权因子保存在数组里
      var idCardY = new Array(1, 0, 10, 9, 8, 7, 6, 5, 4, 3, 2); //这是除以11后，可能产生的11位余数、验证码，也保存成数组
      var idCardWiSum = 0; //用来保存前17位各自乖以加权因子后的总和
      for (var i = 0; i < 17; i++) {
        idCardWiSum += idCard.substring(i, i + 1) * idCardWi[i];
      }
      var idCardMod = idCardWiSum % 11; //计算出校验码所在数组的位置
      var idCardLast = idCard.substring(17); //得到最后一位身份证号码
      //如果等于2，则说明校验码是10，身份证号码最后一位应该是X
      if (idCardMod == 2) {
        if (idCardLast == "X" || idCardLast == "x") {
          //alert("恭喜通过验证啦！");
          return true;
        } else {
          //alert("身份证号码错误！");
        }
      } else {
        //用计算出的验证码与最后一位身份证号码匹配，如果一致，说明通过，否则是无效的身份证号码
        if (idCardLast == idCardY[idCardMod]) {
          //alert("恭喜通过验证啦！");
          return true;
        } else {
          //alert("身份证号码错误！");
        }
      }
    }
  } else {
    //alert("身份证格式不正确!");
  }
}
