var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "upload-box-container" },
    [
      _c(
        "cube-upload",
        {
          ref: "upload",
          attrs: { action: _vm.action },
          on: { "files-added": _vm.addedHandler, "file-error": _vm.errHandler },
          model: {
            value: _vm.files,
            callback: function($$v) {
              _vm.files = $$v
            },
            expression: "files"
          }
        },
        [
          _c(
            "div",
            { staticClass: "clear-fix upload-box-wrapper" },
            [
              _vm._l(_vm.files, function(file, i) {
                return _c("cube-upload-file", {
                  key: i,
                  class: {
                    "multiple-upload-file": _vm.multiple,
                    "view-type": !_vm.editType
                  },
                  attrs: { file: file },
                  on: {
                    click: function($event) {
                      return _vm.showImagePreview(i)
                    }
                  }
                })
              }),
              _vm.editType
                ? _c(
                    "cube-upload-btn",
                    {
                      class: { "multiple-style": _vm.multiple },
                      attrs: { multiple: _vm.multiple }
                    },
                    [
                      _c("div", [
                        _c("i", [_vm._v("＋")]),
                        _c("p", [_vm._v("添加图片")])
                      ])
                    ]
                  )
                : _vm._e()
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }