var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "checkbox", class: _vm._containerClass }, [
    _c("label", { staticClass: "checkbox-wrap" }, [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.checkValue,
            expression: "checkValue"
          }
        ],
        staticClass: "checkbox-input",
        attrs: { type: "checkbox" },
        domProps: {
          checked: Array.isArray(_vm.checkValue)
            ? _vm._i(_vm.checkValue, null) > -1
            : _vm.checkValue
        },
        on: {
          change: function($event) {
            var $$a = _vm.checkValue,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && (_vm.checkValue = $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  (_vm.checkValue = $$a
                    .slice(0, $$i)
                    .concat($$a.slice($$i + 1)))
              }
            } else {
              _vm.checkValue = $$c
            }
          }
        }
      }),
      _vm._m(0),
      _vm.$slots.default
        ? _c("span", { staticClass: "checkbox-label" }, [_vm._t("default")], 2)
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "checkbox-ui cubeic-round-border" }, [
      _c("i", { staticClass: "cubeic-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }