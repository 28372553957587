<template>
  <div class="container">
    <template v-if="mainInfo.receiptId">
      <div v-if="mainInfo.isRepayment" class="amount-section flex">
        <div class="flex-1">
          <div class="fs20 bold">{{ mainInfo.nearAmount }}</div>
          <div class="fs14 mgt14">
            {{
              mainInfo.nearDate &&
              `${mainInfo.nearDate.substring(
                4,
                6
              )}月${mainInfo.nearDate.substring(6, 8)}日`
            }}应付(元)
          </div>
        </div>
        <div class="flex-1">
          <div class="fs20 bold">{{ mainInfo.notReturnAmount || "ㅤ" }}</div>
          <div class="fs14 mgt14">未付总额(元)</div>
        </div>
      </div>
      <div v-if="mainInfo.isRepayment" class="white-sapce"></div>

      <div class="loan-info-section">
        <div class="loan-title border-bottom-1px flex">
          <div>订单信息</div>
          <div @click="toLoanDetails" class="loan-more">更多</div>
        </div>

        <div class="lh30 fs14">
          <div class="flex space-between">
            <div class="c-666">分期金额：</div>
            <div class="c-333">{{ mainInfo.loanAmount | plusyuan }}</div>
          </div>

          <div class="flex space-between">
            <div class="c-666">应付总额：</div>
            <div class="c-333">{{ mainInfo.totalAmount | plusyuan }}</div>
          </div>

          <div class="flex space-between">
            <div class="c-666">申请时间：</div>
            <div class="c-333">{{ mainInfo.loanDate }}</div>
          </div>
        </div>
      </div>

      <div class="white-sapce"></div>

      <div
        class="bill-list qutstanding-bill"
        :class="mainInfo.isRepayment ? '' : 'history-bill'"
      >
        <cube-scroll>
          <div
            v-for="(item, index) in planList"
            :key="index"
            class="qutstanding-bill-item border-bottom-1px"
          >
            <div v-if="item.finish">
              <!-- <mine-checkbox style="opacity:0;" :value="item.checked"></mine-checkbox> -->
              <img
                style="width: 40px; padding: 0 6px"
                src="@/assets/images/finish.png"
                alt
              />
            </div>
            <mine-checkbox
              v-else
              :style="item.checked ? '' : 'color:#eee;'"
              :value="item.checked"
            ></mine-checkbox>
            <div
              @click="toRepaymentDetails(item.finish, item.planId)"
              class="flex-1 flex"
            >
              <div class="flex-1 bill-item-info">
                <div class="bill-item-number">{{ item.termOrder }}期</div>
                <div class="bill-item-desc">
                  {{ item.repaymentDate | dealDate
                  }}{{ item.finish ? `已付` : `应付` }}
                </div>
              </div>
              <div class="bill-item-amount">
                {{ item.repaymentBalance | plusyuan }}
              </div>
              <span
                :style="item.finish ? '' : 'opacity:0;'"
                class="iconfont icon-jiantou item-right-select-icon"
              ></span>
            </div>
          </div>
        </cube-scroll>
      </div>

      <!-- 底部栏 -->

      <div
        v-if="mainInfo.isRepayment"
        class="qutstanding-bottom-bar flex border-top-1px"
      >
        <div @click="handleCheckedAll">
          <mine-checkbox class="with-click" :value="checkedAll"
            >全选</mine-checkbox
          >
        </div>

        <div class="pay-bill-amount">￥{{ repaymentBalance }}</div>
        <div @click="toRepayment" class="pay-bill-btn">立即付款</div>
      </div>
    </template>

    <c-spin
      style="margin-top: 40vh"
      size="large"
      tip="Loading..."
      v-else
    ></c-spin>
  </div>
</template>

<script type="text/ecmascript-6">
import { userBillInfo } from "@/api/repayment";
import { accAdd } from "@/utils/computed";
import MineCheckbox from "@comp/Checkbox/CheckboxRadio";

export default {
  name: "",
  components: {
    MineCheckbox,
  },
  data() {
    return {
      // checked: true,
      checkedAll: false,

      mainInfo: {},
      planList: [],
    };
  },
  methods: {
    toLoanDetails() {
      const { receiptId } = this.$route.query;
      this.$router.push({
        name: "loanDetails",
        query: {
          receiptId,
        },
      });
    },
    toRepaymentDetails(finishStatus, planId) {
      if (finishStatus) {
        this.$router.push({
          name: "repaymentDetails",
          query: {
            planId,
          },
        });
      }
    },

    toRepayment() {
      // 新增需求，需要传递productId
      const {
        mainInfo: { receiptId, productId ,whetherXs},
        checkedAll: isAll,
      } = this;
      let planId;

      if (!isAll) {
        const [selectItem] = this.planList.filter((ele) => {
          return ele.checked === true;
        });
        planId = selectItem.planId;
      }

      this.$router.push({
        name: "repayment",
        query: {
          receiptId,
          isAll,
          planId,
          productId,
          // 是否为新邢帅，用于判断修改支付金额
          whetherXs
        },
      });
    },
    selsectDefult(list) {
      /**
       * 0 未付款
       * 1 部分付款
       * 2 付款完毕
       */
      let firstFlag = true;
      return list.map((ele) => {
        if (ele.repayStatus === "2") {
          ele.finish = true;
          ele.checked = false;
        } else if (
          (ele.repayStatus === "0" || ele.repayStatus === "1") &&
          firstFlag
        ) {
          ele.checked = true;
          firstFlag = false;
        } else if (ele.repayStatus === "0" || ele.repayStatus === "1") {
          ele.checked = false;
        }
        return ele;
      });
    },
    selectAll(list) {
      return list.map((ele) => {
        if (ele.repayStatus === "2") {
          ele.finish = true;
          ele.checked = false;
        } else if (ele.repayStatus === "0" || ele.repayStatus === "1") {
          ele.checked = true;
        }
        return ele;
      });
    },
    handleCheckedAll() {
      if (this.checkedAll) {
        this.planList = this.selsectDefult(this.planList);
        this.checkedAll = false;
      } else {
        this.planList = this.selectAll(this.planList);
        this.checkedAll = true;
      }
    },
  },

  computed: {
    repaymentBalance() {
      return this.planList.reduce((totle, ele) => {
        if (ele.checked) {
          return accAdd(totle, ele.repaymentBalance);
        } else {
          return totle;
        }
      }, 0);
    },
  },
  mounted() {
    const { receiptId } = this.$route.query;
    userBillInfo({
      receiptId,
    }).then((res) => {
      if (res.success) {
        const { planList, ...mainInfo } = res.data;
        mainInfo.nearDate = mainInfo.nearDate.toString();
        mainInfo.nearDate = String(mainInfo.nearDate);
        mainInfo.notReturnAmount
          ? (mainInfo.isRepayment = true)
          : (mainInfo.isRepayment = false);

        console.log("mainInfo", mainInfo);
        this.mainInfo = mainInfo;
        this.planList = this.selsectDefult(planList);
      }
    });
  },
};
</script>

<style scoped lang="less">
.amount-section {
  position: relative;
  height: 100px;
  color: #fff;
  background: #579af1;

  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    transform: translate(-50%, -50%) scale(0.5);
    width: 1px;
    height: 80px;
    background: #eef1f6;
  }

  .mgt14 {
    margin-top: 14px;
  }
}

.white-sapce {
  height: 10px;
  background: #eef1f6;
}

.loan-info-section {
  padding: 6px 16px;

  .loan-title {
    color: #333;
    font-size: 16px;
    line-height: 36px;
  }

  .loan-more {
    padding-left: 20px;
    color: #999;
    font-size: 14px;
  }
}

.bill-list {
  padding: 0 16px;
  height: calc(100vh - 258px);
  overflow-y: scroll;

  &.qutstanding-bill {
    padding-left: 0;
    padding-bottom: 60px;
  }

  &.history-bill {
    padding-bottom: 0;
    height: calc(100vh - 148px);
  }

  .qutstanding-bill-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 74px;
    font-size: 12px;
    color: #666;
  }

  .bill-item-info {
    margin-left: 4px;
    text-align: left;
  }

  .bill-item-number {
    font-size: 16px;
    color: #333;
  }

  .bill-item-desc {
    margin-top: 10px;
  }

  .bill-item-amount {
    line-height: 74px;
    min-width: 60px;
    padding-right: 10px;
    text-align: right;
    color: #333;
    font-size: 16px;
    // background: cornflowerblue;
  }

  .item-right-select-icon {
    font-size: 12px;
    margin-right: -4px;
    color: #cdcdd1;
  }
}

.qutstanding-bill {
}

.with-click {
  .checkbox-label {
    margin-left: 10px;
  }
}

.qutstanding-bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background: #fff;

  .pay-bill-amount {
    flex: 1;
    text-align: left;
    color: #f47636;
    font-size: 20px;
  }
  .pay-bill-btn {
    background: #579af1;
    min-width: 110px;
    color: rgba(238, 241, 246, 1);

    &:active {
      opacity: 0.7;
    }
  }
}
</style>
