var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cube-upload-btn" },
    [
      _vm._t("default", [_vm._m(0)]),
      _c("input", {
        staticClass: "cube-upload-input",
        attrs: {
          type: "file",
          multiple: _vm.multiple,
          accept: _vm.accept,
          capture: _vm.capture
        },
        on: { change: _vm.changeHandler }
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cube-upload-btn-def" }, [_c("i")])
  }
]
render._withStripped = true

export { render, staticRenderFns }