var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _vm.repaymentInfo.repayStatusCn
        ? [
            _c("div", { staticClass: "amount-section flex" }, [
              _c("div", { staticClass: "flex-1" }, [
                _c("div", { staticClass: "fs20 bold" }, [
                  _vm._v(_vm._s(_vm.repaymentInfo.realAmount))
                ]),
                _c("div", { staticClass: "fs14 mgt14" }, [
                  _vm._v("本期应付( 元)")
                ])
              ]),
              _vm.repaymentInfo.notRepaument
                ? _c("div", { staticClass: "flex-1 not-repayment" }, [
                    _c("div", { staticClass: "fs20 bold" }, [
                      _vm._v(_vm._s(_vm.repaymentInfo.none))
                    ]),
                    _c("div", { staticClass: "fs14 mgt14" }, [
                      _vm._v("未付总额(元)")
                    ])
                  ])
                : _vm._e()
            ]),
            _c("div", { staticClass: "white-sapce" }),
            _c("div", { staticClass: "repayment-info-list" }, [
              _c(
                "div",
                { staticClass: "flex space-between border-bottom-1px" },
                [
                  _c("div", { staticClass: "c-333" }, [_vm._v("应付金额：")]),
                  _c("div", { staticClass: "c-666" }, [
                    _vm._v(_vm._s(_vm.repaymentInfo.realAmount) + "元")
                  ])
                ]
              ),
              _c(
                "div",
                { staticClass: "flex space-between border-bottom-1px" },
                [
                  _c("div", { staticClass: "c-333" }, [_vm._v("付款账户：")]),
                  _c("div", { staticClass: "c-666" }, [
                    _vm._v(_vm._s(_vm.repaymentInfo.bankInfo))
                  ])
                ]
              ),
              _c(
                "div",
                { staticClass: "flex space-between border-bottom-1px" },
                [
                  _c("div", { staticClass: "c-333" }, [_vm._v("付款方式：")]),
                  _c("div", { staticClass: "c-666" }, [
                    _vm._v(_vm._s(_vm.repaymentInfo.typeCn))
                  ])
                ]
              ),
              _c(
                "div",
                { staticClass: "flex space-between border-bottom-1px" },
                [
                  _c("div", { staticClass: "c-333" }, [_vm._v("付款时间：")]),
                  _c("div", { staticClass: "c-666" }, [
                    _vm._v(_vm._s(_vm.repaymentInfo.repaymentTimeCn))
                  ])
                ]
              ),
              _c(
                "div",
                { staticClass: "flex space-between border-bottom-1px" },
                [
                  _c("div", { staticClass: "c-333" }, [_vm._v("付款状态：")]),
                  _c("div", { staticClass: "c-666" }, [
                    _vm._v(_vm._s(_vm.repaymentInfo.repayStatusCn))
                  ])
                ]
              )
            ])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }