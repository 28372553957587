var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pd20 flex" },
    [
      _c("OSSUpload", {
        staticClass: "mgt20",
        attrs: { multiple: true, tips: { title: "多选", desc: "123" } }
      }),
      _c("OSSUpload", {
        staticClass: "mgt20",
        attrs: {
          accept: "video/*",
          maxSize: 10 * 1024 * 1024,
          tips: { title: "录像", desc: "123" }
        }
      }),
      _c("OSSUpload", {
        staticClass: "mgt20",
        attrs: {
          capture: "user",
          accept: "video/*",
          tips: { title: "直接录像", desc: "前置摄像头" }
        }
      }),
      _c("OSSUpload", {
        staticClass: "mgt20",
        attrs: {
          capture: "user",
          accept: "video/*",
          multiple: true,
          tips: { title: "多选直接录像", desc: "前置摄像头" }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }