// import { RouteView } from "@/components/layouts";
// 导航组件 还需完善
import RouteView from "@/components/layouts/GuideButtonRouterView";

const Login = (resolve) => {
  import("@views/repayment/Login").then((module) => {
    resolve(module);
  });
};

const Mine = (resolve) => {
  import("@views/repayment/Mine").then((module) => {
    resolve(module);
  });
};

const MyBankCard = (resolve) => {
  import("@views/repayment/MyBankCard").then((module) => {
    resolve(module);
  });
};

const MyBill = (resolve) => {
  import("@views/repayment/MyBill").then((module) => {
    resolve(module);
  });
};

const BillDetails = (resolve) => {
  import("@views/repayment/BillDetails").then((module) => {
    resolve(module);
  });
};

const LoanRepaymentHistory = (resolve) => {
  import("@views/repayment/LoanRepaymentHistory").then((module) => {
    resolve(module);
  });
};

const Repayment = (resolve) => {
  import("@views/repayment/Repayment").then((module) => {
    resolve(module);
  });
};

const RepaymentDetails = (resolve) => {
  import("@views/repayment/RepaymentDetails").then((module) => {
    resolve(module);
  });
};

const LoanDetails = (resolve) => {
  import("@views/repayment/LoanDetails").then((module) => {
    resolve(module);
  });
};

const Result = (resolve) => {
  import("@views/repayment/Result").then((module) => {
    resolve(module);
  });
};

const SecuritySetting = (resolve) => {
  import("@views/repayment/SecuritySetting").then((module) => {
    resolve(module);
  });
};

const AuthentificationBindCard = (resolve) => {
  import("@views/repayment/AuthentificationBindCard").then((module) => {
    resolve(module);
  });
};

const AboutUs = (resolve) => {
  import("@views/repayment/AboutUs").then((module) => {
    resolve(module);
  });
};

const CustomerService = (resolve) => {
  import("@views/repayment/CustomerService").then((module) => {
    resolve(module);
  });
};

const repaymentRouter = {
  path: "/repayment",
  name: "repaymentMain",
  redirect: "/repayment/mine",
  component: RouteView,
  meta: { title: "付款流程" },
  children: [
    {
      path: "login",
      name: "login",
      component: Login,
      meta: {
        title: "豆乐派",
      },
    },
    {
      path: "mine",
      name: "mine",
      component: Mine,
      meta: {
        title: "我的",
        keepAlive: true,
      },
    },
    {
      path: "myBankCard",
      name: "myBankCard",
      component: MyBankCard,
      meta: {
        title: "我的银行卡",
      },
    },
    {
      path: "myBill",
      name: "myBill",
      component: MyBill,
      meta: {
        title: "我的账单",
        keepAlive: true,
      },
    },
    {
      path: "billDetails",
      name: "billDetails",
      component: BillDetails,
      meta: {
        title: "账单详情",
      },
    },
    {
      path: "loanRepaymentHistory",
      name: "loanRepaymentHistory",
      component: LoanRepaymentHistory,
      meta: {
        title: "分期记录",
      },
    },
    {
      path: "repayment",
      name: "repayment",
      component: Repayment,
      meta: {
        title: "付款",
      },
    },
    {
      path: "repaymentDetails",
      name: "repaymentDetails",
      component: RepaymentDetails,
      meta: {
        title: "账单详情",
      },
    },

    {
      path: "result",
      name: "resultRepayment",
      component: Result,
      meta: {
        title: "处理结果",
      },
    },
    {
      path: "loadDetails",
      name: "loanDetails",
      component: LoanDetails,
      meta: {
        title: "订单详情",
      },
    },
    {
      path: "securitySetting",
      name: "securitySetting",
      component: SecuritySetting,
      meta: {
        title: "安全设置",
      },
    },
    {
      path: "authentificationBindCard",
      name: "authentificationBindCard",
      component: AuthentificationBindCard,
      meta: {
        title: "绑卡鉴权确认",
      },
    },
    {
      path: "aboutUs",
      name: "aboutUs",
      component: AboutUs,
      meta: {
        title: "关于我们",
      },
    },
    {
      path: "customerService",
      name: "customerService",
      component: CustomerService,
      meta: {
        title: "我的客服",
      },
    },
  ],
};

export default repaymentRouter;
