var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { staticClass: "user-section" }, [
        _c("img", {
          staticClass: "avatar",
          attrs: {
            src: require("@/assets/images/avatar-default.png"),
            alt: "头像"
          }
        }),
        _c("div", { staticClass: "user-name" }, [
          _vm._v(_vm._s(_vm._f("nameFilter")(_vm.userName)))
        ]),
        _c("div", { staticClass: "user-mobile" }, [
          _vm._v(_vm._s(_vm._f("mobileFilter")(_vm.mobile)))
        ])
      ]),
      _c("div", { staticClass: "item-section flex" }, [
        _c(
          "div",
          { staticClass: "flex-1 left-item", on: { click: _vm.toMyBill } },
          [
            _c("div", { staticClass: "item-number" }, [
              _vm._v(_vm._s(_vm.nearMoney))
            ]),
            _c("div", { staticClass: "item-name" }, [_vm._v("近期待付(元)")])
          ]
        ),
        _c(
          "div",
          { staticClass: "flex-1", on: { click: _vm.toLoanRepaymentHistory } },
          [
            _c("div", { staticClass: "item-number" }, [
              _vm._v(_vm._s(_vm.receiptNum))
            ]),
            _c("div", { staticClass: "item-name" }, [_vm._v("分期记录")])
          ]
        )
      ]),
      _c("CWhiteSpace"),
      _c(
        "div",
        { staticClass: "field-section" },
        [
          _c("CFieldItem", {
            attrs: { iconName: "icon-zhangdan", title: "我的账单" },
            nativeOn: {
              click: function($event) {
                return _vm.toMyBill($event)
              }
            }
          }),
          _c("CFieldItem", {
            attrs: { iconName: "icon-yinhangqia", title: "我的银行卡" },
            nativeOn: {
              click: function($event) {
                return _vm.toMyBankCard($event)
              }
            }
          }),
          _c("CWhiteSpace"),
          _c("CFieldItem", {
            attrs: { iconName: "icon-kefu", title: "我的客服" },
            nativeOn: {
              click: function($event) {
                return _vm.toCustomerService($event)
              }
            }
          }),
          _c("CFieldItem", {
            attrs: { iconName: "icon-dunpai", title: "安全设置" },
            nativeOn: {
              click: function($event) {
                return _vm.toSecuritySetting($event)
              }
            }
          }),
          _c("CFieldItem", {
            attrs: { iconName: "icon-guanyu", title: "关于我们" },
            nativeOn: {
              click: function($event) {
                return _vm.toAboutUs($event)
              }
            }
          }),
          _c("CFieldItem", {
            attrs: { iconName: "icon-tuichudenglu", title: "退出登录" },
            nativeOn: {
              click: function($event) {
                return _vm.toLogout($event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }