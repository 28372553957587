<template>
  <div class="container">
    <tips-top>银行级安全防护，信息安全有保障。</tips-top>

    <div class="fill-section">
      <div class="fill-area">
        <div class="fill-item border-bottom-1px">
          <div class="fill-name">电子邮箱</div>
          <input
            value
            class="fill-input"
            v-model="email"
            maxlength="25"
            type="email"
            placeholder="请填写常用邮箱地址"
          />
        </div>
        <div class="fill-item border-bottom-1px">
          <div class="fill-name">现居城市</div>
          <div @click="selectCity" class="fill-select">
            <div class="flex-1" :class="{ 'c-666': addressArr.length }">
              {{ addressText() }}
            </div>
            <span class="iconfont icon-jiantou fill-select-icon"></span>
          </div>
        </div>
        <div class="fill-item border-bottom-1px">
          <div class="fill-name">详细地址</div>
          <!-- <input
            class="fill-input"
            v-model="detailAddress"
            type="text"
            placeholder="**路**小区**楼栋**房号"
          /> -->
          <input v-model="value1" class="input-box"/>
          <select @change="valueTap" class="select-box">
            <option label="街道" value="街道"/>
            <option label="乡镇" value="乡镇"/>
          </select>
          <input v-model="value3" class="input-box"/>
          <select @change="valueTap2" class="select-box">
            <option label="路" value="路"/>
            <option label="村" value="村"/>
          </select>
          <input v-model="value5" class="input-box"/>
          <span>{{value6}}</span>
        </div>
        
        <div class="fill-item border-bottom-1px">
          <div class="fill-name">单位类型</div>
          <div @click="selectCompany" class="fill-select">
            <div class="flex-1" :class="{ 'c-666': companyName }">
              {{companyName||'请选择'}}
            </div>
            <span class="iconfont icon-jiantou fill-select-icon"></span>
          </div>
        </div>
      </div>

      <div class="fill-area">
        <div class="fill-item border-bottom-1px">
          <div class="fill-name">亲属关系</div>
          <div @click="selectRelatives" class="fill-select">
            <div class="flex-1" :class="{ 'c-666': relativesArr.length }">
              {{ relativesText() }}
            </div>
            <span class="iconfont icon-jiantou fill-select-icon"></span>
          </div>
        </div>
        <div class="fill-item border-bottom-1px">
          <div class="fill-name">姓ㅤㅤ名</div>
          <input
            class="fill-input"
            v-model.trim="relativeName"
            maxlength="8"
            type="text"
            placeholder="请输入亲属姓名"
          />
        </div>
        <div class="fill-item border-bottom-1px">
          <div class="fill-name">手机号码</div>
          <input
            class="fill-input"
            v-model.trim="relativeMobile"
            maxlength="11"
            type="tel"
            placeholder="请输入亲属手机号"
          />
        </div>
      </div>

      <div class="fill-area">
        <div class="fill-item border-bottom-1px">
          <div class="fill-name">紧急联系</div>
          <div @click="selectSocialRelations" class="fill-select">
            <div class="flex-1" :class="{ 'c-666': socialRelationsArr.length }">
              {{ socialRelationsText() }}
            </div>
            <span class="iconfont icon-jiantou fill-select-icon"></span>
          </div>
        </div>
        <div class="fill-item border-bottom-1px">
          <div class="fill-name">姓ㅤㅤ名</div>
          <input
            class="fill-input"
            v-model.trim="emeryName"
            maxlength="8"
            type="text"
            placeholder="请输入紧急联系人姓名"
          />
        </div>
        <div class="fill-item border-bottom-1px">
          <div class="fill-name">手机号码</div>
          <input
            class="fill-input"
            v-model.trim="emeryMobile"
            maxlength="11"
            type="tel"
            placeholder="请输入紧急联系人手机号"
          />
        </div>
      </div>

      <div class="submit-section">
        <c-button
          @click="submit"
          class="submit-btn"
          >下一步</c-button
        >
      </div>
    </div>
    <!-- <div class="uploadFaceDialog" v-if="showFace">
      <div class="bg">
        <p class="tips"> 1.请上传<span>本人现拍照片，不能对着其他图片二次拍摄；</span></p>
        <p class="tips">2.要求<span>白色背景</span>，建议背靠墙壁或空旷场地，不能出现宿舍物品等内容。</p>  
        <p class="tips">3.注意拍照时光线明亮，照片清晰，显示完整。不符合要求需要重新上传。</p>
      </div>
      <img src="@assets/images/tip_img.png" class="tip_img"/>
      
      <div class="face-box">
        <OSSUpload :backgroundImgName='"img-back.png"' ref="upload1" title="人脸识别" capture='camera' :files.sync="faceImg"></OSSUpload>
      </div>
      <div class="bottom-box">
        <button class="active"  @click="submit" >下一步</button>
      </div>
    </div> -->
  </div>
</template>

<script type="text/ecmascript-6">
import { provinceObject } from "@/utils/address.js";
import OSSUpload from "@comp/Upload/OSSUpload";
const cityData = [];
console.time();

// Object.value() ES8

for (let prokey in provinceObject) {
  let obj = {};
  obj.text = prokey;
  obj.value = "待定";
  obj.children = [];

  let proList = provinceObject[prokey];
  let flag = true;
  for (let citykey in proList) {
    let cityList = proList[citykey];

    if (flag) {
      obj.value = cityList.id.toString().substring(0, 3) + "000";
      flag = false;
    }
    let obj1 = {};
    obj1.text = citykey;
    obj1.value = cityList.id;
    obj1.children = [];
    cityList.value.forEach((item) => {
      obj1.children.push({
        text: item[4],
        value: item[1],
      });
    });
    obj.children.push(obj1);
  }

  cityData.push(obj);
}

console.timeEnd();

import TipsTop from "@comp/TipsTop/TipsTop";

import { getAreaCode, getUserBorrow, handlerUserInfo } from "@/api/application";
import { queryDataDict } from "@/api/common";

import { nextProcess } from "./redirectAction";

import { Validator } from "@/utils/validate.js";
import { ButtonMixin } from "@comp/Button";

import fixedInput from "@/mixins/fixedInput";

export default {
  name: "",
  mixins: [ButtonMixin, fixedInput],

  data() {
    return {
      RelativeContact: [],
      EmergencyContactType: [],
      faceImg:[],
      showFace:false,
      email: "",
      value1:'',
      value2:'街道',
      value3:'',
      value4:'路',
      value5:'',
      value6:'号',
      companyArr:[
        {id:1,name:'政府/行政机关'},
        {id:2,name:'事业单位'},
        {id:3,name:'国有/集体企业'},
        {id:4,name:'上市公司'},
        {id:5,name:'民营企业'},
        {id:6,name:'外资企业'},
        {id:7,name:'合资企业'},
        {id:8,name:'自由职业'},
        {id:9,name:'其他'}
      ],
      addressArr: [],
      addressVal: [],
      detailAddress: "",
      companyName: "",

      relativesArr: [],
      relativesVal: [],
      relativeName: "",
      relativeMobile: "",
      submitting:false,

      socialRelationsArr: [],
      socialRelationsVal: [],
      emeryName: "",
      emeryMobile: "",
    };
  },
  computed: {},
  components: {
    TipsTop,OSSUpload
  },
  methods: {
    backpage(){
      document.title = '基本信息'
      this.showFace = false
    },
    valueTap(e){
      this.value2 = e.target.value
    },
    valueTap2(e){
      this.value4 = e.target.value
    },
    updated() {
      // 解决ios输入框弹出的页面样式问题
      document.querySelectorAll("input").forEach((item) => {
        item.onblur = this.temporaryRepair;
      });
    },
    addressText() {
      return this.addressArr.length ? this.addressArr.join(" ") : "请选择";
    },
    relativesText() {
      return this.relativesArr.length ? this.relativesArr.join(" ") : "请选择";
    },
    socialRelationsText() {
      return this.socialRelationsArr.length
        ? this.socialRelationsArr.join(" ")
        : "请选择";
    },
    companyText() {
      return this.companyArr.length
        ? this.companyArr.join(" ")
        : "请选择";
    },
    //   地址
    selectCity() {
      this.addressPicker.show();
    },
    selectAddressHandler(selectedVal, selectedIndex, selectedText) {
      console.log(selectedVal, selectedIndex, selectedText);
      this.addressArr = selectedText;
      this.addressVal = selectedVal;
    },
    // 亲属关系
    selectRelatives() {
      this.relativesPicker.show();
    },
    selectRelativesHandler(selectedVal, selectedIndex, selectedText) {
      console.log(selectedVal, selectedIndex, selectedText);
      this.relativesArr = selectedText;
      this.relativesVal = selectedVal;
    },
    // 社会关系
    selectSocialRelations() {
      this.socialRelationsPicker.show();
    },
    selectCompany(){
      this.companyPicker.show();
    },
    selectCompanyText(selectedVal, selectedIndex, selectedText){
      this.companyName = selectedText.join('')
    },
    selectSocialRelationslHandler(selectedVal, selectedIndex, selectedText) {
      console.log(selectedVal, selectedIndex, selectedText);
      this.socialRelationsArr = selectedText;
      this.socialRelationsVal = selectedVal;
    },
    getUserBorrow() {
      return getUserBorrow({}).then((res) => {
        if (res.success && res.data) {
          const {
            email,
            workProvinceCode,
            workCityCode,
            workDistrictCode,
            recentlyAddress: detailAddress,
            workUnit: companyName,
            relativeType,
            relativeName,
            relativeMobile,
            urgentUserType,
            urgentUserName: emeryName,
            urgentUserMobile: emeryMobile,
          } = res.data;

          this.email = email;
          console.log("城市数据", cityData);
          let cityList = [];
          let selectIndex = [];
          cityData.forEach((element, index) => {
            if (element.value == workProvinceCode) {
              cityList[0] = element.text;
              selectIndex[0] = index;
              element.children.forEach((element2, index) => {
                if (element2.value == workCityCode) {
                  cityList[1] = element2.text;
                  selectIndex[1] = index;
                  element2.children.forEach((element3, index) => {
                    if (element3.value == workDistrictCode) {
                      cityList[2] = element3.text;
                      selectIndex[2] = index;
                    }
                  });
                }
              });
            }
          });
          console.log("选择城市", cityList, selectIndex);
          this.addressArr = cityList;
          this.addressVal = [workProvinceCode, workCityCode, workDistrictCode];

          this.detailAddress = detailAddress;
          this.companyName = companyName;

          this.RelativeContact.forEach((element) => {
            if (element.id === relativeType) {
              this.relativesArr[0] = element.name;
              this.relativesVal[0] = relativeType;
            }
          });
          this.relativeName = relativeName;
          this.relativeMobile = relativeMobile;

          this.EmergencyContactType.forEach((element) => {
            if (element.id === urgentUserType) {
              this.socialRelationsArr[0] = element.name;
              this.socialRelationsVal[0] = urgentUserType;
            }
          });
          this.emeryName = emeryName;
          this.emeryMobile = emeryMobile;
        } else {
        }
      });
    },

    validateFunc: () => {
      let validator = new Validator();
      var errorMsg = validator.start();
      return errorMsg;
    },
    bfsubmit(){
      let {
        email,
        addressVal: [provinceCode, cityCode, districtCode],
        detailAddress,
        companyName,
        relativesVal: [relativeValue],
        relativeName,
        relativeMobile,
        socialRelationsVal: [emeryValue],
        emeryName,
        emeryMobile,
        value1,
        value2,
        value3,
        value4,
        value5,
        value6
      } = this;
      detailAddress = [value1,value2,value3,value4,value5,value6].join('')
      function validateFunc() {
        let validator = new Validator();

        validator.add(email, "emailFormat", "请确认邮箱格式");
        validator.add(districtCode, "isEmpty", "请选择现居城市");
        validator.add(detailAddress, "isEmpty", "请填写详细详细地址");
        validator.add(companyName, "isEmpty", "请填写详细单位名称");

        validator.add(relativeValue, "isEmpty", "请选择亲属关系");
        validator.add(relativeName, "minLength", "请填写亲属姓名");
        validator.add(relativeMobile, "mobileFormat", "亲属手机号码格式不正确");

        validator.add(emeryValue, "isEmpty", "请选择紧急联系人关系");
        validator.add(emeryName, "minLength", "请选择紧急联系人姓名");
        validator.add(
          emeryMobile,
          "mobileFormat",
          "紧急联系人手机号码格式不正确"
        );

        var errorMsg = validator.start(); // 获得效验结果
        return errorMsg; // 返回效验结果
      }

      
        
      let errMsg = validateFunc();
      console.log(relativeName,'------',emeryName)

      
      // const sensitive = ['学生','大学','中学','小学','学校','一中','二中','学院','技校','职校','高中','初中','师范'];

      // function containsSensitiveWords(input, sensitiveWords) {
      //     return sensitiveWords.some(word => input.includes(word));
      // }

      // function checkForSensitiveWords(dataToCheck) {
      //     return dataToCheck.some(data => containsSensitiveWords(data, sensitive));
      // }

      // const dataToCheck = [companyName, detailAddress];

      // if (checkForSensitiveWords(dataToCheck)) {
      //   return  this.$createToast({
      //         txt: '当前不接受在校学生申请，请核实填写信息是否有误',
      //         type: "error"
      //     }).show();
      // }

      let isflag = [value1,value3,value5].join('')

      if(!isflag){
        this.$createToast({txt: '请输入详细地址',type: "error",}).show();
        return;
      }

      if(!relativeName||relativeName.length<2){
        this.$createToast({txt: '亲属姓名不得少于2个字',type: "error",}).show();
        return;
      }
      if(!emeryName||emeryName.length<2){
        this.$createToast({txt: '紧急联系人姓名不得少于2个字',type: "error",}).show();
        return;
      }

      if(this.isEmojiCharacter(relativeName)) return this.$createToast({txt: '亲属姓名不允许输入表情符号，请检查',type: "error"}).show();

      if(this.isEmojiCharacter(emeryName)) return this.$createToast({txt: '紧急联系人姓名不允许输入表情符号，请检查',type: "error"}).show();
      
      if(relativeName===emeryName) return this.$createToast({txt: '禁止填写重复的联系人',type: "error"}).show();
      if(relativeMobile===emeryMobile) return this.$createToast({txt: '禁止填写重复的手机号码',type: "error"}).show();

      if (errMsg) {
        this.$createToast({
          txt: errMsg,
          type: "error",
        }).show();
        return;
      }

      this.showFace = true
      document.title = '补充资料'
    },
    isEmojiCharacter(substring) {  
      for ( var i = 0; i < substring.length; i++) {  
          var hs = substring.charCodeAt(i);  
          if (0xd800 <= hs && hs <= 0xdbff) {  
              if (substring.length > 1) {  
                  var ls = substring.charCodeAt(i + 1);  
                  var uc = ((hs - 0xd800) * 0x400) + (ls - 0xdc00) + 0x10000;  
                  if (0x1d000 <= uc && uc <= 0x1f77f) {  
                      return true;  
                  }  
              }  
          } else if (substring.length > 1) {  
              var ls = substring.charCodeAt(i + 1);  
              if (ls == 0x20e3) {  
                  return true;  
              }  
          } else {  
              if (0x2100 <= hs && hs <= 0x27ff) {  
                  return true;  
              } else if (0x2B05 <= hs && hs <= 0x2b07) {  
                  return true;  
              } else if (0x2934 <= hs && hs <= 0x2935) {  
                  return true;  
              } else if (0x3297 <= hs && hs <= 0x3299) {  
                  return true;  
              } else if (hs == 0xa9 || hs == 0xae || hs == 0x303d || hs == 0x3030  
                      || hs == 0x2b55 || hs == 0x2b1c || hs == 0x2b1b  
                      || hs == 0x2b50) {  
                  return true;  
              }  
          }  
      }  
    },
    submit() {
      let {
        email,
        addressVal: [provinceCode, cityCode, districtCode],
        detailAddress,
        companyName,
        relativesVal: [relativeValue],
        relativeName,
        relativeMobile,
        socialRelationsVal: [emeryValue],
        emeryName,
        emeryMobile,
        faceImg,
        value1,value2,value3,value4,value5,value6
      } = this;

      detailAddress = [value1,value2,value3,value4,value5,value6].join('')

      function validateFunc() {
        let validator = new Validator();

        validator.add(email, "emailFormat", "请确认邮箱格式");
        validator.add(districtCode, "isEmpty", "请选择现居城市");
        validator.add(detailAddress, "isEmpty", "请填写详细详细地址");
        validator.add(companyName, "isEmpty", "请选择单位类型");

        validator.add(relativeValue, "isEmpty", "请选择亲属关系");
        validator.add(relativeName, "minLength", "请填写亲属姓名");
        validator.add(relativeMobile, "mobileFormat", "亲属手机号码格式不正确");

        validator.add(emeryValue, "isEmpty", "请选择紧急联系人关系");
        validator.add(emeryName, "minLength", "请选择紧急联系人姓名");
        validator.add(
          emeryMobile,
          "mobileFormat",
          "紧急联系人手机号码格式不正确"
        );

        var errorMsg = validator.start(); // 获得效验结果
        return errorMsg; // 返回效验结果
      }

      
        
      let errMsg = validateFunc();


      if (errMsg) {
        this.$createToast({
          txt: errMsg,
          type: "error",
        }).show();
        return;
      }

      let isflag = [value1,value3,value5].join('')

      if(!isflag){
        this.$createToast({txt: '请输入详细地址',type: "error",}).show();
        return;
      }

      if(!relativeName||relativeName.length<2){
        this.$createToast({txt: '亲属姓名不得少于2个字',type: "error",}).show();
        return;
      }
      if(!emeryName||emeryName.length<2){
        this.$createToast({txt: '紧急联系人姓名不得少于2个字',type: "error",}).show();
        return;
      }

      if(relativeName===emeryName) return this.$createToast({txt: '禁止填写重复的联系人',type: "error"}).show();
      if(relativeMobile===emeryMobile) return this.$createToast({txt: '禁止填写重复的手机号码',type: "error"}).show();

      

      // if(faceImg.length===0){
      //   this.$createToast({txt: '请上传本人白色背景照片',type: "error",}).show();
      //   return;
      // }

      this.submitting = true;
        console.log('进入提交-----')
        try{
          handlerUserInfo({
            email,
            provinceCode,
            cityCode,
            districtCode,
            detailAddress,
            companyName,
            relativeValue,
            relativeName,
            relativeMobile,
            emeryValue,
            emeryName,
            emeryMobile,
            faceUrl:''
          })
            .then((res) => {
              if (res.success) {
                console.log('进入提交成功-----')
                const { other } = res;
                this.$createToast({txt: res.message,type: "success"}).show();
                nextProcess(other);
              }
              this.submitting = false
            })
            .catch((err) => {
              console.log('进入提交出错-----')
              this.submitting = false
            });
        }catch(err){
          console.log('出错-----',err)
        }
      
    },
  },
  mounted() {
    this.updated();
    this.addressPicker = this.$createCascadePicker({
      title: "现居城市",

      data: cityData,
      onSelect: this.selectAddressHandler,
    });

    /**
     * 获取联系人下拉
     */
    const requestOptions = (name, callback) => {
      return queryDataDict({ parentCode: name })
        .then((res) => {
          if (res.success) {
            const list = res.data;

            this[name] = list;
            if (typeof callback === "function") {
              callback();
            }
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    };

    const relativePromise = requestOptions("RelativeContact", () => {
      this.relativesPicker = this.$createCascadePicker(
        {
          title: "亲属关系",
          data: this.RelativeContact,
          onSelect: this.selectRelativesHandler,
          alias: { value: "id", text: "name" },
        },
        false
      );
    });

    const emergencyPromise = requestOptions("EmergencyContactType", () => {
      this.socialRelationsPicker = this.$createCascadePicker(
        {
          title: "社会关系",
          data: this.EmergencyContactType,
          onSelect: this.selectSocialRelationslHandler,
          alias: { value: "id", text: "name" },
        },
        false
      );
    });

    this.companyPicker = this.$createCascadePicker(
      {
        title: "单位类型",
        data: this.companyArr,
        onSelect: this.selectCompanyText,
        alias: { value: "id", text: "name" },
      },
      false
    );

    

    // 等待返回
    Promise.all([relativePromise, emergencyPromise]).then(() => {
      this.getUserBorrow();
    });

    // getAreaCode({
    //   cityType: "Province",
    //   parentId: "1"
    // }).then(res => {
    //   let proId = res.data[0].areaId;

    //   getAreaCode({
    //     cityType: "City",
    //     parentId: proId
    //   }).then(res => {
    //     let cityId = res.data[0].areaId;

    //     getAreaCode({
    //       cityType: "District",
    //       parentId: cityId
    //     }).then(res => {});
    //   });
    // });
  },
};
</script>

<style scoped lang="less">
.container {
  background: #eef1f6;

  .fill-section {
    overflow: hidden;
    font-size: 16px;

    .fill-area {
      margin-top: 10px;
      background: #fff;

      .fill-item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 50px;
        .fill-name {
          width: 100px;
          padding-left: 16px;
          text-align: left;
          //   font-weight: 500;
          color: rgba(51, 51, 51, 1);
          line-height: 28px;
        }
        .fill-input {
          height: 100%;
          flex: 1;
          background: none;
          outline: none;
          border: 0px;
          color: #666;
          &::-webkit-input-placeholder {
            color: #ccc;
          }
        }

        .fill-select {
          display: flex;
          align-items: center;
          flex: 1;
          height: 100%;
          padding-right: 10px;
          text-align: left;
          color: #ccc;
          .fill-select-icon {
            color: #ccc;
            font-size: 18px;
          }
        }
      }
    }
  }
}

.uploadFaceDialog{
  width:100vw;
  height:100vh;
  position:fixed;
  z-index:10;
  top:0;
  padding: 0 0 30px;
  left:0;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  .bg{
    background-color: #f1f1f1;
    // width:calc(100% - 40px);
    padding:  0 20px 10px;
  }
  .tips{
    color: #333;
    line-height: 1.5;
    margin-top: 10px;
    text-align: left;
    font-size: 16px;
    span{
      &:first-child{
        color:red;
      }
    //  &:last-child{
    //   color:#999;
    //  }
    }
  }
  .tip_img{
    width: 250px;
    height:auto;
    margin-top: 20px;
  }
  .face-box{
    width: 250px;
    margin-top: 20px;
  }
}
.input-box{
  width:40px;
  margin: 0 2px;
  font-size: 12px;
  height: 20px;
  border:1px solid #999;
}
.select-box{
  width:55px;
  height: 20px;
}
.backbtn{

}
.bottom-box{
  width: 90vw;
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: space-between;
  button{
    appearance: none;
    background-color: transparent;
    border: none;
    width: 100%;
    height: 40px;
    border-radius: 4px;

    &.active{
      background-color: #579af1;
      color: #fff;
    }
    &.backbtn{
      color: #333;
      border:1px solid #999;
    }
  }
}
.submit-section {
  padding: 50px 16px 20px;
  font-size: 16px;
  .submit-btn {
    border-radius: 4px;
    font-weight: 400;
  }
}
</style>
