<template>
  <transition name="cube-dialog-fade">
    <cube-popup
      ref="mydialog"
      type="dialog"
      :z-index="zIndex"
      :mask="true"
      :center="true"
      v-show="isVisible"
    >
      <div class="cube-dialog-main">
        <span class="cube-dialog-close" v-show="showClose" @click="close">
          <i class="cubeic-close"></i>
        </span>

        <div class="cube-dialog-confirm">
          <div class="cube-dialog-title">请输入短信验证码</div>
          <div class="cube-dialog-content">
            短信验证码发送至 {{ phone || "银行预留手机" }}
          </div>

          <div class="code-area flex border-bottom-1px">
            <input
              class="code-input flex-1"
              v-model="smsCode"
              type="tel"
              placeholder="请输入验证码"
              maxlength="6"
            />

            <div
              class="code-btn"
              v-bind:class="{ disabled: state.smsSendBtn }"
              @click.stop.prevent="getCaptcha"
            >
              {{ state.smsSendBtn ? state.time + "s后重发" : "发送验证码" }}
            </div>
          </div>

          <div class="cube-dialog-btns">
            <slot name="btns">
              <a
                class="cube-dialog-btn border-top-1px"
                :class="{
                  disabled: submitting,
                  'cube-dialog-btn_highlight': true,
                }"
                @click="confirm"
                >确认</a
              >
            </slot>
          </div>
        </div>
      </div>
    </cube-popup>
  </transition>
</template>

<script type="text/ecmascript-6">
import { queryContractConvergence, querySigning } from "@/api/repayment";
import { ButtonMixin } from "@comp/Button";
import visibilityMixin from "./mixins/visibility";
import popupMixin from "./mixins/popup";

const EVENT_CONFIRM = "confirm";
const EVENT_CANCEL = "cancel";
const EVENT_CLOSE = "close";

const LIMITTIME = 60;
export default {
  mixins: [visibilityMixin, popupMixin, ButtonMixin],
  name: "MyDialog",
  props: {
    showClose: {
      type: Boolean,
      default: true,
    },
    phone: {
      type: String,
      default: "",
    },
    bankCardNumber: {
      type: String,
      default: "",
    },
    bankcardId: {
      type: String,
      default: "",
    },
    receiptId: {
      type: String,
      default: "",
    },
  },
  components: {},
  data() {
    return {
      state: {
        time: LIMITTIME,
        smsSendBtn: false,
      },
      smsCode: "",
    };
  },
  computed: {},
  methods: {
    close(e) {
      this.hide();
      this.$emit(EVENT_CLOSE, e);
    },
    getCaptcha() {
      const { state, phone, bankCardNumber, receiptId } = this;

      // 恢复按钮
      const regainCheckBtn = () => {
        state.time = LIMITTIME;
        state.smsSendBtn = false;
      };

      setTimeout(() => {
        if (phone.length > 0) {
          const nowTime = +new Date();
          const prevTime = this.$ls.get("startTimePayCode");

          const timeDifference = ((nowTime - prevTime) / 1000) | 0;

          if (timeDifference < LIMITTIME) {
            state.time = LIMITTIME - timeDifference;
            state.smsSendBtn = true;
            const interval = window.setInterval(() => {
              if (state.time-- <= 0) {
                regainCheckBtn();
                window.clearInterval(interval);
              }
            }, 1000);
            return;
          }

          state.smsSendBtn = true;
          const interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              regainCheckBtn();
              window.clearInterval(interval);
            }
          }, 1000);

          const toastSuccess = this.$createToast({
            txt: "验证码发送中",
            type: "correct",
            time: 0,
          });
          toastSuccess.show();

          queryContractConvergence({
            mobile: phone,
            bankNo: bankCardNumber,
            receiptId,
          })
            .then((res) => {
              toastSuccess.hide();
              if (res && res.success) {
                this.$createToast({
                  txt: "验证码已发送，请留意",
                  type: "correct",
                }).show();
                // 记录发送时间
                this.$ls.set("startTimePayCode", +new Date());
                this.$ls.set("smsId", res.data.requestNo);
              } else {
                clearInterval(interval);
                regainCheckBtn();
              }
            })
            .catch((err) => {
              toastSuccess.hide();
              clearInterval(interval);
              regainCheckBtn();
            });
        } else {
          this.$createToast({
            txt: "请确认手机号",
            type: "error",
          }).show();
        }
      }, 0);
    },

    confirm() {
      const { smsCode, bankCardNumber, bankcardId } = this;

      if (smsCode.length < 4) {
        this.$createToast({
          txt: "请确认手机验证码",
          type: "warn",
        }).show();
        return;
      }

      this.submitting = true;
      const smsId = this.$ls.get("smsId");
      querySigning({
        smsCode,
        requestNo: smsId,
        bankCardNo: bankCardNumber,
        bankcardId,
      })
        .then((res) => {
          if (res && res.success) {
            this.hide();
            this.$emit(EVENT_CONFIRM, "e");
          }
          this.regainStatus();
        })
        .catch((err) => {
          console.log("err", err);
          this.regainStatus();
        });
    },
  },
  created() {
    console.log("my-dialog 创建了", this.phone);

    this.getCaptcha();
  },
};
</script>

<style scoped lang="less">
.cube-dialog-main {
  width: 270px;
  padding: 0;
  text-align: center;
  overflow: hidden;
  border-radius: 2px;
  background-color: "#fff";

  .cube-dialog-close {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    width: 32px;
    height: 32px;
    color: #333;
    font-size: 20px;
  }

  .cube-dialog-title {
    margin-top: 18px;
  }

  .cube-dialog-content {
    margin-top: 10px;
    text-align: center;
  }

  .code-area {
    height: 40px;
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 0 20px;

    .code-input {
      height: 100%;
      width: 80px;
      //   padding-left: 6px;
      outline: none;
      border: 0px;
      color: #666;
      &::-webkit-input-placeholder {
        color: #ccc;
      }
    }

    .code-btn {
      width: 90px;
      margin-left: 20px;
      padding: 10px 0;
      background: #579af1;
      font-size: 14px;
      color: #fff;
      border-radius: 4px;
      &:active {
        background: #5079f7;
      }
    }
  }

  .cube-dialog-confirm {
    position: relative;
    overflow: hidden;
  }

  .cube-dialog-btns {
    overflow: hidden;
    width: 100%;
    font-size: 0;
    .cube-dialog-btn {
      display: inline-block;
      width: 100%;
      padding: 17px 10px;
      margin: 0;
      font-size: 18px;
      line-height: 1;
      text-align: center;
      text-decoration: none;
      color: #579af1;
      background-color: #fefefe;
      background-clip: padding-box;
      box-sizing: border-box;
      &:active {
        background-color: #efefef;
      }
    }
  }
}

.cube-dialog-fade-enter-active {
  animation: dialog-fadein 0.4s;
  .cube-dialog-main {
    animation: dialog-zoom 0.4s;
  }
}

@keyframes dialog-fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes dialog-zoom {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
</style>
